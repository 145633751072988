import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./SportFormats.module.css";
import AddIcon from "@mui/icons-material/Add";
import { PaginationType } from "../../types/types";
import {
  getGenderFormat,
  getOfficiatedStatus,
  getSports,
  handleSportsFormatFilter,
  InitialStateVenueMaster,
} from "../../app/venueMasterSlice";
import Button from "../UI/Button/Button";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import ActionsCell from "../UI/ActionCell";
import { DataTable } from "../UI/Table/DataTable";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  SportFormatsQuery,
  useSportFormatsQuery,
} from "../../../src/generated/graphql";
import { LoadingMaterialUI } from "../UI";

const options = [{ id: 1, text: "Edit" }];

const SportFormats: React.FC = () => {
  //Pagination Page Size
  const pageSize = 25;

  const {
    sportsFormatFilter,
    selectedRegions,
    sports,
    genderFormat,
    officiatedStatuses,
  }: InitialStateVenueMaster = useSelector(
    (state: RootState) => state.venueMaster
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [pagination, setPagination] = React.useState<PaginationType>({
    page: 0,
    pageSize: pageSize,
    pageCount: undefined,
  });
  const [sportFormats, setSportFormat] = React.useState<
    SportFormatsQuery["sportFormats"]["sportsFormat"]
  >([]);

  const { loading: isLoading } = useSportFormatsQuery({
    variables: {
      sportFormatFilters: {
        ...sportsFormatFilter,
        regionId: !sportsFormatFilter.regionId
          ? selectedRegions.map((region) => region.id)
          : [sportsFormatFilter.regionId],
      },
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    onCompleted: (data) => {
      if (data.sportFormats) {
        setSportFormat(data.sportFormats.sportsFormat);
        setPagination((prevState) => {
          return {
            ...prevState,
            pageCount: data.sportFormats.count,
          };
        });
      }
    },
  });

  const redirectCreateSportFormat = () => {
    navigate(`/ops/sport-format/new`);
  };

  const handleSelectedOption = (
    row: SportFormatsQuery["sportFormats"]["sportsFormat"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/ops/sport-format/${row.id}`, {
        state: { id: row.id },
      });
    }
  };

  const handleChange = (name: string, value: number) => {
    dispatch(
      handleSportsFormatFilter({
        ...sportsFormatFilter,
        [name]: value === 0 ? null : value,
      })
    );
  };

  useEffect(() => {
    dispatch(getGenderFormat(""));
    dispatch(getSports(""));
    dispatch(getOfficiatedStatus(""));
  }, []);

  const columns: ColumnDef<
    SportFormatsQuery["sportFormats"]["sportsFormat"][0]
  >[] = [
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <a
            className="underline"
            href={`/ops/sport-format/${row.original.id}`}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      header: "Region",
      id: "region",
      accessorFn: (d) => d.region.name,
    },
    {
      header: "Sport",
      id: "sport",
      accessorFn: (d) => d.sport.name,
    },

    {
      header: "Gender Format",
      id: "genderFormat",
      accessorFn: (d) => d.genderFormat.name,
    },
    {
      header: "Officiated Status",
      id: "officiatedStatus",
      accessorFn: (d) => d.officiatedStatus.name,
    },
    {
      header: "People on Field",
      id: "peopleOnField",
      accessorFn: (d) => d.peopleOnField,
    },
    {
      header: "Minimum Men",
      id: "minMen",
      accessorFn: (d) => d.minMen,
    },
    {
      header: "Minimum Women",
      id: "minWomen",
      accessorFn: (d) => d.minWomen,
    },
    {
      header: "Max Roster Size Team",
      id: "maxRosterSizeTeam",
      accessorFn: (d) => d.maxRosterSizeTeam,
    },
    {
      header: " ",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row}
            options={options}
            handleSelectedOption={(_, i) =>
              handleSelectedOption(row.original, i)
            }
          />
        );
      },
    },
  ];

  if (isLoading) {
    return <LoadingMaterialUI />;
  }
  return (
    <main>
      <Headline1Variable>Sport Formats</Headline1Variable>
      <div className={classes.container}>
        <Fragment>
          <div className="flex items-end w-2/3 gap-5 my-5">
            <FormFieldSelect
              name="regionId"
              value={
                sportsFormatFilter.regionId
                  ? sportsFormatFilter.regionId.toString()
                  : "0"
              }
              inputChange={(value) => {
                handleChange("regionId", +value);
              }}
              label="Region"
              placeholder="Select Region"
            >
              {[...[{ id: "0", name: "All" }], ...selectedRegions]}
            </FormFieldSelect>
            <FormFieldSelect
              name="genderFormatId"
              value={sportsFormatFilter.genderFormatId?.toString() || "0"}
              inputChange={(value) => {
                handleChange("genderFormatId", +value);
              }}
              label="Gender Format"
              placeholder="Select Gender Format"
            >
              {[...genderFormat, ...[{ id: "0", name: "All" }]]}
            </FormFieldSelect>
            <FormFieldSelect
              name="sportId"
              value={sportsFormatFilter.sportId?.toString() || "0"}
              inputChange={(value) => {
                handleChange("sportId", +value);
              }}
              label="Sport"
              placeholder="Select Sport"
            >
              {[...sports, ...[{ id: "0", name: "All" }]]}
            </FormFieldSelect>
            <FormFieldSelect
              name="officiatedStatusId"
              value={sportsFormatFilter.officiatedStatusId?.toString() || "0"}
              inputChange={(value) => {
                handleChange("officiatedStatusId", +value);
              }}
              label="Officiated Status"
              placeholder="Select Officiated Status"
            >
              {[...officiatedStatuses, ...[{ id: "0", name: "All" }]]}
            </FormFieldSelect>
          </div>
        </Fragment>
        <div className="mb-4 text-right">
          <Button
            variant="primary"
            onClick={redirectCreateSportFormat}
          >
            <AddIcon />
            Add New Sport Format
          </Button>
        </div>
        {sportFormats.length > 0 && (
          <div className="flex flex-col gap-4">
            <DataTable
              data={sportFormats}
              columns={columns}
            />
            <PaginationDataTable
              pagination={pagination}
              setPagination={setPagination}
            />
          </div>
        )}

        {!(sportFormats.length > 0) && (
          <div className={classes.table__contracts}>No Data Found</div>
        )}
      </div>
    </main>
  );
};

export default SportFormats;
