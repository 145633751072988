"use client";

import * as React from "react";
import {
  Column,
  ColumnDef,
  Header,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "../shadcn/table";
import { cn } from "../../../lib/utils";
import { Pagination } from "@/src/types/types";
import TablePagination from "../Pagination/Pagination";
import { Loader2 } from "lucide-react";
import Headline2Variable from "../Text/Headline/Headline2Variable";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  rowClassName?: string;
  tableClassName?: string;
  cellClassName?: string;
  colsToPin?: string[];
  pagination?: Pagination;
  handleChangePagination?: (value: number) => void;
  showPagination?: boolean;
  loading?: boolean;
  hiddenColumns?: string[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
  rowClassName,
  tableClassName,
  cellClassName,
  colsToPin,
  handleChangePagination,
  showPagination = true,
  pagination,
  loading = false,
  hiddenColumns = [],
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      rowSelection,
    },
    initialState: {
      columnVisibility: hiddenColumns.reduce(
        (acc: Record<string, boolean>, column: string) => {
          acc[column] = false;
          return acc;
        },
        {}
      ),
    },
  });

  return (
    <div className="flex flex-col">
      {loading ? (
        <div className="flex flex-col items-center justify-center gap-6">
          <Loader2 className="w-32 h-32 text-primary-90 animate-spin" />
          <Headline2Variable>Loading</Headline2Variable>
        </div>
      ) : (
        <>
          <Table className={cn("bg-white rounded-xl", tableClassName)}>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const isSortable = header.column.getCanSort();
                    const sortDirection = header.column.getIsSorted();
                    return (
                      <TableHead
                        key={header.id}
                        className={cn(
                          "font-bold text-md whitespace-nowrap",
                          isSortable && "hover:underline"
                        )}
                        onClick={
                          isSortable
                            ? header.column.getToggleSortingHandler()
                            : undefined
                        }
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {isSortable && (
                          <span className="ml-2 text-sm">
                            {sortDirection === "asc"
                              ? "↑"
                              : sortDirection === "desc"
                              ? "↓"
                              : ""}
                          </span>
                        )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn(
                      "border-b transition-colors hover:bg-neutral-90 data-[state=selected]:bg-neutral-60",
                      rowClassName
                    )}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const { column } = cell;
                      return (
                        <TableCell
                          key={cell.id}
                          className={cn(cellClassName)}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                {table.getFooterGroups().map((footerGroup) =>
                  footerGroup.headers.map((footer) => (
                    <TableCell
                      key={footer.id}
                      className="font-extrabold border-t-2 border-gray-400"
                    >
                      {footer.isPlaceholder
                        ? null
                        : flexRender(
                            footer.column.columnDef.footer,
                            footer.getContext()
                          )}
                    </TableCell>
                  ))
                )}
              </TableRow>
            </TableFooter>
          </Table>

          {showPagination &&
            pagination !== undefined &&
            handleChangePagination !== undefined && (
              <div className="flex justify-end mt-4">
                <TablePagination
                  page={pagination?.page || 0}
                  pageCount={pagination?.pageCount || 0}
                  onChange={handleChangePagination}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
}
