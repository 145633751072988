import { client } from "../../graphql";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useRegistrationBatchesQuery } from "../../generated/graphql";
import Button from "../UI/Button/Button";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import { numberToMoney } from "../../utils/financialHelpers";
import { BarChart } from "@mui/x-charts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../UI/shadcn/select";
import { cn } from "../../lib/utils";

const TRIGGER_LIVE_DATA = gql`
  query ($regBatchId: Int!, $time: Int!) {
    triggerLiveRegOpening(regBatchId: $regBatchId, time: $time)
  }
`;

const LIVE_DATA_SUBSCRIPTION = gql`
  subscription ($regBatchId: Int!, $time: Int!) {
    regOpeningData(regBatchId: $regBatchId, time: $time)
  }
`;

export default function RegOpening() {
  const [regBatchId, setRegBatchId] = useState(0);
  const [regionData, setRegionData] = useState<
    | {
        region: string;
        total_regs: number;
        indy_regs: number;
        team_regs: number;
        total: number;
      }[]
    | null
  >(null);
  const [timeRegionData, setTimeRegionData] = useState<
    | {
        registration_interval: string;
        total_registrations: number;
      }[]
    | null
  >(null);
  const [timeInterval, setTimeInterval] = useState<number>(15);

  const { data: registrationBatches } = useRegistrationBatchesQuery();
  const [fetchLiveData] = useLazyQuery(TRIGGER_LIVE_DATA, {
    variables: {
      regBatchId: regBatchId,
      time: timeInterval * 60,
    },
  });

  useEffect(() => {
    if (regBatchId) {
      // Start the subscription using Apollo Client's subscribe method
      const observable = client.subscribe({
        query: LIVE_DATA_SUBSCRIPTION,
        variables: {
          regBatchId: regBatchId,
          time: timeInterval * 60,
        },
      });

      // Subscribe to the observable and handle incoming data
      const subscription = observable.subscribe({
        next(data) {
          setRegionData(data.data.regOpeningData?.refreshedData);
          setTimeRegionData(data.data.regOpeningData?.refreshedTimeData);
        },
        error(err) {
          console.error("Subscription error:", err);
        },
        complete() {
          console.log("Subscription completed.");
        },
      });

      // Cleanup the subscription manually when the component is unmounted
      return () => {
        subscription.unsubscribe(); // Manually unsubscribe
      };
    }
  }, [regBatchId, timeInterval]);

  const columns: ColumnDef<{
    region: string;
    total_regs: number;
    indy_regs: number;
    team_regs: number;
    total: number;
  }>[] = [
    {
      header: "Region",
      id: "region",
      accessorFn: (d) => d.region,
      footer: () => <div>Totals</div>,
    },
    {
      header: "Indy Registrations",
      id: "indy_regs",
      accessorFn: (d) => d.indy_regs,
      footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
          return sum + row.getValue<number>("indy_regs" || 0);
        }, 0);

        return <div>{total}</div>;
      },
    },
    {
      header: "Team Registrations",
      id: "team_regs",
      accessorFn: (d) => d.team_regs,
      footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
          return sum + row.getValue<number>("team_regs" || 0);
        }, 0);

        return <div>{total}</div>;
      },
    },
    {
      header: "Total Registrations",
      id: "total_regs",
      accessorFn: (d) => d.total_regs,
      footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
          return sum + row.getValue<number>("total_regs" || 0);
        }, 0);

        return <div>{total}</div>;
      },
    },
    {
      header: "Total Sales",
      id: "total",
      cell: ({ row }) => {
        return numberToMoney(row.original.total);
      },
      footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
          return sum + row.original.total;
        }, 0);

        return <div>{numberToMoney(total)}</div>;
      },
    },
  ];

  return (
    <main className="w-full">
      {registrationBatches?.registrationBatches && (
        <div className="flex items-end w-2/3 gap-2 mb-4">
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              if (value != 0) setRegBatchId(parseInt(value));
            }}
            name="registrationBatchId"
            label="Season"
            placeholder="Season"
            value={regBatchId.toString()}
          >
            {[
              ...registrationBatches.registrationBatches.registrationBatches,
              ...[{ id: "0", name: "Select a Season" }],
            ]}
          </FormFieldSelect>
          <Button
            variant={regBatchId !== 0 ? "primary" : "disabled"}
            onClick={() => fetchLiveData()}
            width="1/3"
            height="10"
            disabled={regBatchId === 0}
          >
            Get Live Data
          </Button>
        </div>
      )}
      <div>
        {regionData && (
          <DataTable
            columns={columns}
            data={regionData}
          />
        )}
      </div>
      <div className="flex flex-col mt-4">
        {timeRegionData && (
          <div>
            <div className={cn(`px-3 text-xs font-medium mb-2`)}>
              Time Interval (min)
            </div>
            <Select
              value={`${timeInterval}`}
              onValueChange={(value) => {
                setTimeInterval(parseInt(value));
              }}
            >
              <SelectTrigger className="h-8 w-[70px] bg-white">
                <SelectValue placeholder={timeInterval.toString()} />
              </SelectTrigger>
              <SelectContent side="top">
                {[1, 5, 10, 15, 30].map((val) => (
                  <SelectItem
                    key={val}
                    value={`${val}`}
                    className="hover:bg-primary-95"
                  >
                    {val}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <BarChart
              xAxis={[
                {
                  data: timeRegionData?.map((obj) => obj.registration_interval),
                  scaleType: "band",
                  label: "Time",
                },
              ]}
              yAxis={[
                {
                  label: "Number of Registrations",
                },
              ]}
              series={[
                {
                  data: timeRegionData?.map((obj) => obj.total_registrations),
                },
              ]}
              height={300}
            />
          </div>
        )}
      </div>
    </main>
  );
}
