import React, { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import moment from "moment";
import { LoadingMaterialUI } from "../UI";
import ActionsCell from "../UI/ActionCell";
import FormField from "../UI/FormField/FormField";
import { Pagination, PaginationType, Users } from "../../types/types";
import Button from "../UI/Button/Button";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_USERS } from "../../graphql/queries/user";
import { DataTable } from "../UI/Table/DataTable";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  GetUsersQuery,
  useGetUsersLazyQuery,
} from "../../../src/generated/graphql";

const options = [{ id: 1, text: "View" }];
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Players = () => {
  const publicUrl = process.env.PUBLIC_URL;
  //Pagination Page Size
  const pageSize = 50;
  const { isLoadingUserList }: any = useSelector(
    (state: RootState) => state.cx
  );
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: pageSize,
    pageCount: undefined,
  });
  const [userFilter, setUserFilter] = useState<string | null>(null);
  const [usersListAutoComplete, setUsersListAutoComplete] = useState<Users[]>(
    []
  );
  const [userList, setUserList] = useState<GetUsersQuery["getUsers"]["users"]>(
    []
  );

  const handleChangePagination = (value: number) => {
    setPagination({
      ...pagination,
      page: value - 1,
    });
  };

  const [
    getUserList,
    { data: userListData, loading: userListLoading, error: userListError },
  ] = useGetUsersLazyQuery({
    onCompleted: (data) => {
      if (data.getUsers) {
        if (data.getUsers.count)
          setPagination((prevState) => {
            return {
              ...prevState,
              pageCount: data.getUsers.count!,
            };
          });
        setUserList(data.getUsers.users);
        setUsersListAutoComplete([]);
      }
    },
    fetchPolicy: "no-cache",
  });

  const [
    getUserAutoCompleteList,
    {
      data: userAutoCompleteListData,
      loading: userAutoCompleteListLoading,
      error: userAutoCompleteListError,
    },
  ] = useLazyQuery(GET_ALL_USERS, {
    onCompleted: (data) => {
      setUsersListAutoComplete(data.getUsers.users);
    },
    fetchPolicy: "no-cache",
  });

  function resetPage() {
    setUserFilter("");
    getUserList({
      variables: {
        userFilter: "",
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }

  useEffect(() => {
    getUserList({
      variables: {
        userFilter: userFilter,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }, []);

  useEffect(() => {
    getUserList({
      variables: {
        userFilter: userFilter,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }, [pagination.page, pagination.pageSize]);

  useEffect(() => {
    getUserAutoCompleteList({
      variables: {
        userFilter: userFilter,
        page: null,
        pageSize: null,
      },
    });
    if (userFilter && emailRegex.test(userFilter)) {
      getUserList({
        variables: {
          userFilter: userFilter,
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
      });
    }
  }, [userFilter]);

  const handleSelectedOption = (
    row: GetUsersQuery["getUsers"]["users"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`${publicUrl}/players/user/${row.id}`, {
        state: { vendorId: row.id },
      });
    }
  };

  const columns: ColumnDef<GetUsersQuery["getUsers"]["users"][0]>[] = [
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <a
            className="underline"
            href={`/players/user/${row.original.id}`}
          >
            {row.original.firstName} {row.original.lastName}
          </a>
        );
      },
    },
    {
      header: "Email",
      id: "email",
      cell: ({ row }) => {
        return <div className="truncate text-nowrap">{row.original.email}</div>;
      },
    },
    {
      header: "Phone Number",
      id: "phoneNumber",
      cell: ({ row }) => {
        return <div className="truncate">{row.original.phoneNumber}</div>;
      },
    },
    {
      header: "Gender",
      id: "gender",
      accessorFn: (d) => d.genderIdentity?.name,
    },
    {
      header: "Last Login",
      id: "lastLogin",
      accessorFn: (d) =>
        d.lastLogin ? moment(d.lastLogin).local().format("YYYY-MM-DD") : "",
    },
    {
      header: "Created",
      id: "createdAt",
      accessorFn: (d) => moment(d.createdAt).local().format("YYYY-MM-DD"),
    },
    {
      header: " ",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row.original}
            options={options}
            handleSelectedOption={(_, i) =>
              handleSelectedOption(row.original, i)
            }
          />
        );
      },
    },
  ];

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Player Search</Headline1Variable>
        <div className="flex flex-row items-end h-full gap-4">
          <div className="w-96 h-[90%]">
            <FormField
              initialValue={userFilter || ""}
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 2) setUserFilter(e.target.value);
                else setUserFilter(null);
              }}
              onKeyDown={(event) => {
                if (
                  usersListAutoComplete.length > 0 &&
                  userFilter &&
                  event.key === "Enter"
                ) {
                  getUserList({
                    variables: {
                      userFilter: userFilter,
                      page: pagination.page,
                      pageSize: pagination.pageSize,
                    },
                  });
                  setUsersListAutoComplete([]);
                }
              }}
              name="search"
              placeholder="Search"
              label="Search by Email/Phone Number"
              width="full"
            ></FormField>
            {usersListAutoComplete.length > 0 && userFilter ? (
              <div className="absolute z-10 w-96">
                <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                  <div className="flow-root">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                      {usersListAutoComplete.map((e) => (
                        <li
                          className="py-3 cursor-pointer sm:py-2"
                          onClick={() => {
                            setUserFilter(e.email);

                            setUsersListAutoComplete([]);
                          }}
                        >
                          <div className="flex items-center space-x-4 rounded-md">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                {e.firstName} {e.lastName}
                              </p>
                              <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                {e.email} - {e.phoneNumber}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Button
            className="h-[90%] mb-1"
            variant="primary"
            onClick={() => {
              getUserList({
                variables: {
                  userFilter: userFilter,
                  page: pagination.page,
                  pageSize: pagination.pageSize,
                },
              });
              setUsersListAutoComplete([]);
            }}
          >
            <span> Search</span>
          </Button>
          <Button
            className="h-[90%] mb-1"
            variant="secondary"
            onClick={resetPage}
          >
            <span>Clear All</span>
          </Button>
        </div>
      </div>
      {isLoadingUserList && <LoadingMaterialUI />}
      {!isLoadingUserList && (
        <>
          {userList.length > 0 && (
            <div className="flex flex-col gap-4">
              <DataTable
                columns={columns}
                data={userList}
              />
              <PaginationDataTable
                pagination={pagination}
                setPagination={setPagination}
              />
            </div>
          )}
          {!(userList.length > 0) && <div>No Data Found</div>}
        </>
      )}
    </main>
  );
};

export default Players;
