import { gql } from "@apollo/client";

const GET_REFUND_APPROVALS = gql`
  query GetRefundApprovals($refundApprovalFilter: RefundApprovalFilter!) {
    getRefundApprovals(refundApprovalFilter: $refundApprovalFilter) {
      id
      status {
        name
        id
      }
      type {
        name
        id
      }
      reason {
        id
        name
      }
      itemId
      requestedBy {
        firstName
        lastName
      }
      requestedDatetime
      txnId
      reviewedByDateTime
      reviewedBy {
        firstName
        lastName
      }
      amount
      notes
      shoppingCartItem {
        region {
          name
        }
        session {
          league {
            name
          }
          registrationBatch {
            name
          }
        }
        tournament {
          name
        }
        shoppingCart {
          id
          user {
            firstName
            lastName
          }
        }
        itemAmount
        tax
        txnFee
        discount
      }
    }
  }
`;

const REJECT_REFUND = gql`
  mutation RejectRefund($id: Int!) {
    rejectRefund(id: $id) {
      success
      message
    }
  }
`;

const APPROVE_REFUND = gql`
  mutation ApproveRefund($ids: [Int]!) {
    approveRefund(ids: $ids) {
      success
      message
      invalidRefunds {
        id
        reason
      }
      failedRefunds {
        id
        reason
      }
      successfulRefunds {
        id
        type
        amount
      }
    }
  }
`;

const GET_REFUND_REASONS = gql`
  query GetRefundReasons(
    $isRefund: Boolean
    $isCredit: Boolean
    $isDiscount: Boolean
  ) {
    getRefundReasons(
      isRefund: $isRefund
      isCredit: $isCredit
      isDiscount: $isDiscount
    ) {
      id
      name
      isRefund
      isCredit
      isDiscount
    }
  }
`;
const GET_REFUND_STATUSES = gql`
  query GetRefundStatuses {
    getRefundStatuses {
      id
      name
    }
  }
`;
const GET_REFUND_TYPES = gql`
  query GetRefundTypes {
    getRefundTypes {
      id
      name
    }
  }
`;

gql`
  query GetDiscountRefundReasons {
    getDiscountRefundReasons {
      id
      name
    }
  }
`;

export {
  GET_REFUND_APPROVALS,
  APPROVE_REFUND,
  GET_REFUND_REASONS,
  GET_REFUND_STATUSES,
  GET_REFUND_TYPES,
  REJECT_REFUND,
};
