import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import Button from "../../UI/Button/Button";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { header } from "./Headers";
import { footer } from "./Footers";
import { emailButton } from "./EmailButton";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { useToast } from "../../UI/Toast/use-toast";
import { z } from "zod";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import {
  FormFieldSelectMulti,
  Item,
} from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import {
  getAllDayOfWeek,
  getAllGenderIdentity,
  getAllRegistrationTypes,
  getAllTeamPlayerRoles,
  getRegistrationBatch,
  getSports,
} from "../../../app/venueMasterSlice";
import FilterChip from "../../UI/Input/FilterChip";
import Disclaimer from "../../UI/Alerts/Disclaimer";
import dayjs from "dayjs";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import Caption1 from "../../UI/Text/Caption/Caption1";
import { DateTimePicker } from "../../UI/shadcn/Time/date-time-picker";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import {
  GetEmailTemplateInstanceQuery,
  ListAllOperations,
  useCreateEmailMarketingFilterMutation,
  useGetEmailMarketingFilterByEmailInstanceIdLazyQuery,
  useGetEmailMarketingFilterTypeQuery,
  useGetEmailTemplateInstanceByIdLazyQuery,
  useGetEmailTemplateInstanceQuery,
  useGetNumberOfRecipientsLazyQuery,
  useLeagueDynamicLazyQuery,
  useSportFormatDynamicLazyQuery,
  useUpdateEmailMarketingFilterMutation,
} from "../../../generated/graphql";
import EmailMarketingFilterSelect from "../../UI/EmailMarketingFilterSelect";
import { RegistrationType } from "@/src/types/types";
import { client } from "../../../graphql";
import { Checkbox } from "../../UI/shadcn/checkbox";
import { Label } from "../../UI/shadcn/label";
import { FormFieldSelectMultiSearch } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";

export const EmailTemplateSchema = z.object({
  id: z.number().optional(),
  subject: z.string().min(1, "Please enter a valid subject"),
  body: z.string().refine(
    (data) => {
      return data !== "<p><br></p>";
    },
    { message: "Please enter a valid body" }
  ),
});

export const EmailMarketingFilterSchema = z.object({
  id: z.number().optional(),
  emailTemplateInstanceId: z.number(),
  emailMarketingFilterTypeId: z.number(),
  recipientCount: z.number(),
  adminRecipient: z.string().nullable(),
  filter: z.object({
    regionIds: z.array(z.number()).catch([]), //Static
    sportIds: z.array(z.number()).catch([]), //Static
    regBatchIds: z.array(z.number()).catch([]), //Static
    dayIds: z.array(z.number()).catch([]), //Static
    genderIds: z.array(z.number()).catch([]), //Static
    regTypeIds: z.array(z.number()).catch([]), //[Team v Free Agent] Static
    playerRoleIds: z.array(z.number()).catch([]), //[Captain, Assistant, Teammate, etc.] Static
    leagueIds: z.array(z.number()).catch([]), //Dynamic
    sportFormatIds: z.array(z.number()).catch([]), //Dynamic
  }),
});

export type EmailTemplate = z.infer<typeof EmailTemplateSchema>;
export type EmailMarketingFilter = z.infer<typeof EmailMarketingFilterSchema>;

export const emptyEmailTemplate = {
  id: 0,
  subject: "",
  name: "",
  body: "<p><br></p>",
};

export const emptyEmailMarketingFilter = {
  id: undefined,
  emailTemplateInstanceId: 0,
  emailMarketingFilterTypeId: 0,
  recipientCount: 0,
  adminRecipient: null,
  filter: {
    regionIds: [],
    sportIds: [],
    regBatchIds: [],
    dayIds: [],
    genderIds: [],
    regTypeIds: [],
    playerRoleIds: [],
    leagueIds: [],
    sportFormatIds: [],
  },
};

const EmailToolMarketingFilter = () => {
  /*** Utility Definitions ***/

  const navigate = useNavigate();
  const {
    selectedRegions,
    sports,
    registrationBatches,
    genderIdentity,
    dayOfWeek,
    teamPlayerRoles,
    registrationTypes,
    isLoading,
  }: any = useSelector((state: RootState) => state.venueMaster);
  const { user }: any = useSelector((state: RootState) => state.auth);
  const params = useParams();
  const instanceId = params.id;
  const dispatch: any = useDispatch<AppDispatch>();

  /*** State ***/
  const [email, setEmail] = useState<EmailTemplate>(emptyEmailTemplate);
  const [filterTypes, setFilterTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [emailTemplateInstances, setEmailTemplateInstances] = useState<
    GetEmailTemplateInstanceQuery["getEmailTemplateInstance"]
  >([]);
  const [numberOfRecipients, setNumberOfRecipients] = useState<number>(0);
  const [emailMarketingFilter, setEmailMarketingFilter] =
    useState<EmailMarketingFilter>(emptyEmailMarketingFilter);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  /*** Queries ***/
  const [
    GetEmailTemplateInstanceById,
    { data: emailTemplateInstanceData, loading: emailTemplateInstanceLoading },
  ] = useGetEmailTemplateInstanceByIdLazyQuery({
    onCompleted: (data) => {
      setEmail(data.getEmailTemplateInstanceById);
    },
  });

  const [
    GetEmailMarketingFilterByEmailInstanceId,
    { data: emailMarketingFilterData, loading: emailMarketingFilterLoading },
  ] = useGetEmailMarketingFilterByEmailInstanceIdLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const res = EmailMarketingFilterSchema.safeParse(
        data.getEmailMarketingFilterByEmailInstanceId
      );
      if (res.success) {
        setEmailMarketingFilter(res.data);
      }
    },
    onError: (error) => {
      dispatch(
        displayAlertError(`Error fetching filter for instance id ${instanceId}`)
      );
    },
  });

  const { loading: loadingEmailMarketingFilterType } =
    useGetEmailMarketingFilterTypeQuery({
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        setFilterTypes(data.getEmailMarketingFilterType);
      },
    });

  const { loading: loadingEmailTemplateInstance } =
    useGetEmailTemplateInstanceQuery({
      variables: {
        emailTemplateInstanceStatusId: 1,
      },
      onCompleted(data) {
        setEmailTemplateInstances(data.getEmailTemplateInstance);
      },
    });

  const [getNumberOfRecipients, { loading: loadingNumberOfRecipeints }] =
    useGetNumberOfRecipientsLazyQuery();

  const [
    getSportFormats,
    { data: sportFormatData, loading: loadingSportFormat },
  ] = useSportFormatDynamicLazyQuery({
    variables: {
      sportFormatDynamicFilter: {
        regionIds: emailMarketingFilter.filter.regionIds,
        sportIds: emailMarketingFilter.filter.sportIds,
        regBatchIds: emailMarketingFilter.filter.regBatchIds,
        dayIds: emailMarketingFilter.filter.dayIds,
      },
    },
    fetchPolicy: "no-cache",
  });
  const [getLeagues, { data: leagueData, loading: loadingLeague }] =
    useLeagueDynamicLazyQuery({
      variables: {
        leagueDynamicFilter: {
          regionIds: emailMarketingFilter.filter.regionIds,
          sportIds: emailMarketingFilter.filter.sportIds,
          regBatchIds: emailMarketingFilter.filter.regBatchIds,
          dayIds: emailMarketingFilter.filter.dayIds,
          sportFormatIds: emailMarketingFilter.filter.sportFormatIds,
        },
      },
      fetchPolicy: "no-cache",
    });

  /*** Mutations ***/
  const [
    createEmailMarketingFilter,
    { loading: loadingCreateEmailMarketingFilter },
  ] = useCreateEmailMarketingFilterMutation();

  const [
    updateEmailMarketingFilter,
    { loading: loadingUpdateEmailMarketingFilter },
  ] = useUpdateEmailMarketingFilterMutation();

  /*** Use Effects ***/
  useEffect(() => {
    dispatch(getAllGenderIdentity(""));
    dispatch(getSports(""));
    dispatch(getRegistrationBatch(""));
    dispatch(getAllDayOfWeek(""));
    dispatch(getAllTeamPlayerRoles(""));
    dispatch(getAllRegistrationTypes(""));
  }, []);

  useEffect(() => {
    if (isFilterEmpty()) {
      return;
    }
    getNumberOfRecipients({
      variables: {
        getNumberOfRecipientsInput: {
          emailMarketingFilterTypeId:
            emailMarketingFilter.emailMarketingFilterTypeId,
          filter: emailMarketingFilter.filter,
          adminRecipient: emailMarketingFilter.adminRecipient,
        },
      },
      onCompleted: (data) => {
        if (!data.getNumberOfRecipients.success) {
          dispatch(displayAlertError(data.getNumberOfRecipients.message));
        }
        setNumberOfRecipients(data.getNumberOfRecipients.count);
      },
    });
  }, [emailMarketingFilter.filter, emailMarketingFilter.adminRecipient]);

  useEffect(() => {
    if (instanceId) {
      setEmailMarketingFilter((prevState) => ({
        ...prevState,
        emailTemplateInstanceId: +instanceId,
      }));
    }
  }, []);

  useEffect(() => {
    if (emailMarketingFilter.emailTemplateInstanceId) {
      GetEmailMarketingFilterByEmailInstanceId({
        variables: {
          instanceId: emailMarketingFilter.emailTemplateInstanceId,
        },
      });
      GetEmailTemplateInstanceById({
        variables: {
          id: emailMarketingFilter.emailTemplateInstanceId,
        },
      });
    }
  }, [emailMarketingFilter.emailTemplateInstanceId]);

  useEffect(() => {
    if (
      (emailMarketingFilter.filter.regionIds.length > 0 ||
        emailMarketingFilter.filter.sportIds.length > 0 ||
        emailMarketingFilter.filter.regBatchIds.length > 0 ||
        emailMarketingFilter.filter.dayIds.length > 0) &&
      emailMarketingFilter.emailMarketingFilterTypeId === 4
    ) {
      getSportFormats();
    }
  }, [
    emailMarketingFilter.filter.regionIds,
    emailMarketingFilter.filter.sportIds,
    emailMarketingFilter.filter.dayIds,
    emailMarketingFilter.filter.regBatchIds,
  ]);

  useEffect(() => {
    if (
      (emailMarketingFilter.filter.regionIds.length > 0 ||
        emailMarketingFilter.filter.sportIds.length > 0 ||
        emailMarketingFilter.filter.regBatchIds.length > 0 ||
        emailMarketingFilter.filter.dayIds.length > 0) &&
      emailMarketingFilter.emailMarketingFilterTypeId === 4
    ) {
      getLeagues();
    }
  }, [
    emailMarketingFilter.filter.regionIds,
    emailMarketingFilter.filter.sportIds,
    emailMarketingFilter.filter.dayIds,
    emailMarketingFilter.filter.regBatchIds,
    emailMarketingFilter.filter.sportFormatIds,
  ]);

  useEffect(() => {
    if (leagueData) {
      // If there is selected leagueIds that are not in the league data then remove them from the filter
      const newLeagueIds = emailMarketingFilter.filter.leagueIds.filter(
        (itemId) => leagueData.leagueDynamic.some((item) => +item.id === itemId)
      );
      if (
        newLeagueIds.length !== emailMarketingFilter.filter.leagueIds.length
      ) {
        setEmailMarketingFilter((prevState) => ({
          ...prevState,
          leagueIds: newLeagueIds,
        }));
      }
    }
  }, [leagueData]);

  useEffect(() => {
    if (sportFormatData) {
      // If there is selected sportFormatIds that are not in the league data then remove them from the filter
      const newSportFormatIds =
        emailMarketingFilter.filter.sportFormatIds.filter((itemId) =>
          sportFormatData.sportFormatDynamic.some((item) => +item.id === itemId)
        );
      if (
        newSportFormatIds.length !==
        emailMarketingFilter.filter.sportFormatIds.length
      ) {
        setEmailMarketingFilter((prevState) => ({
          ...prevState,
          sportFormatIds: newSportFormatIds,
        }));
      }
    }
  }, [sportFormatData]);
  useEffect(() => {
    if (iframeRef.current && email !== undefined) {
      const formattedBody = `${header}${email.body.replace(
        /%%button__(https:\/\/[a-zA-Z0-9._%?=+-\/]+)__([a-zA-Z0-9\s._%+-]+)%%/g,
        (_, link, text) => {
          return emailButton(link, text);
        }
      )}${footer({})}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [email]);

  /*** Utility functions ***/
  function isFilterEmpty(): boolean {
    return !(
      emailMarketingFilter.filter.regionIds.length > 0 ||
      emailMarketingFilter.filter.sportIds.length > 0 ||
      emailMarketingFilter.filter.regBatchIds.length > 0 ||
      emailMarketingFilter.filter.genderIds.length > 0 ||
      emailMarketingFilter.filter.dayIds.length > 0 ||
      emailMarketingFilter.filter.regTypeIds.length > 0 ||
      emailMarketingFilter.filter.playerRoleIds.length > 0 ||
      emailMarketingFilter.filter.sportFormatIds.length > 0 ||
      emailMarketingFilter.filter.leagueIds.length > 0
    );
  }

  function isFilterSavable(): boolean {
    // If nothing selected you can't save the filter
    if (isFilterEmpty()) {
      return false;
    }
    // If the filter is the sport specifc type at least one region and sport must be selected
    if (emailMarketingFilter.emailMarketingFilterTypeId === 3) {
      return (
        emailMarketingFilter.filter.regionIds.length > 0 &&
        emailMarketingFilter.filter.sportIds.length > 0
      );
    }
    return true;
  }

  async function handleSubmit() {
    if (emailMarketingFilter.id !== undefined) {
      await updateEmailMarketingFilter({
        variables: {
          updateEmailMarketingFilterInput: {
            id: emailMarketingFilter.id,
            emailTemplateInstanceId:
              emailMarketingFilter.emailTemplateInstanceId,
            emailMarketingFilterTypeId:
              emailMarketingFilter.emailMarketingFilterTypeId,
            filter: emailMarketingFilter.filter,
            recipientCount: numberOfRecipients,
            adminRecipient: emailMarketingFilter.adminRecipient,
          },
        },
        onCompleted: async (data, clientOptions) => {
          if (data.updateEmailMarketingFilter.success) {
            dispatch(
              displayAlertSuccess(data.updateEmailMarketingFilter.message)
            );
            await client.refetchQueries({
              include: [
                ListAllOperations.Query
                  .GetNumberOfEmailMarketingRecipientsByEmailMarketingFilterId,
                ListAllOperations.Query
                  .GetEmailMarketingFilterByEmailInstanceId,
              ],
            });
            navigate(
              `/marketing/email-tool/marketing/send/${emailMarketingFilter.emailTemplateInstanceId}`
            );
          } else {
            dispatch(
              displayAlertError(data.updateEmailMarketingFilter.message)
            );
          }
        },
        onError(error, clientOptions) {
          console.log(error);
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      await createEmailMarketingFilter({
        variables: {
          createEmailMarketingFilterInput: {
            emailTemplateInstanceId:
              emailMarketingFilter.emailTemplateInstanceId,
            emailMarketingFilterTypeId:
              emailMarketingFilter.emailMarketingFilterTypeId,
            filter: emailMarketingFilter.filter,
            recipientCount: numberOfRecipients,
            adminRecipient: emailMarketingFilter.adminRecipient,
          },
        },
        onCompleted: async (data, clientOptions) => {
          if (data.createEmailMarketingFilter.success) {
            dispatch(
              displayAlertSuccess(data.createEmailMarketingFilter.message)
            );
            await client.refetchQueries({
              include: [
                ListAllOperations.Query
                  .GetNumberOfEmailMarketingRecipientsByEmailMarketingFilterId,
                ListAllOperations.Query
                  .GetEmailMarketingFilterByEmailInstanceId,
              ],
            });
            navigate(
              `/marketing/email-tool/marketing/send/${emailMarketingFilter.emailTemplateInstanceId}`
            );
          } else {
            dispatch(
              displayAlertError(data.createEmailMarketingFilter.message)
            );
          }
        },
        onError(error, clientOptions) {
          console.log(error);
          dispatch(displayAlertError(error.message));
        },
      });
    }
  }

  if (isLoading || emailTemplateInstanceLoading) {
    return <LoadingDialog open={true} />;
  }

  return (
    <main className="flex flex-col gap-4 px-12 pb-10">
      <Headline1Variable>Marketing Email Filter</Headline1Variable>
      <div className="flex flex-row justify-between w-full gap-4">
        <div className="flex flex-col items-start w-1/2 gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <Caption1 whiteSpace="normal">
                1. Registration Opening Alerts - Use this for reg opening &
                deadline emails. Filter by region and you're good!
              </Caption1>
              <Caption1 whiteSpace="normal">
                2. Exclusive Offers & Player Perks - Use this for general
                marketing and partner promotions. Filter by region, and you're
                good!
              </Caption1>
              <Caption1 whiteSpace="normal">
                3. Sport Specific Emails - Use this for sport-specific marketing
                initiatives (driving reg for basketball, etc.) Filter by region
                and sport(s) and leagues(s) and you're good!
              </Caption1>
              <Caption1 whiteSpace="normal">
                4. League Specific Emails - Use this for more granular targeted
                marketing initatives (Driving reg for one specific program,
                offering a promo code to find women, etc.) This filter searches
                on player records that are opted in to sport specific marketing.{" "}
              </Caption1>
            </div>
            <div className="flex flex-row items-end gap-4">
              <FormFieldSelect
                value={emailMarketingFilter.emailMarketingFilterTypeId.toString()}
                inputChange={(value: string) => {
                  setEmailMarketingFilter((prevState) => ({
                    ...prevState,
                    emailMarketingFilterTypeId: +value,
                    filter: {
                      regionIds: [],
                      sportIds: [],
                      regBatchIds: [],
                      genderIds: [],
                      dayIds: [],
                      regTypeIds: [],
                      playerRoleIds: [],
                      sportFormatIds: [],
                      leagueIds: [],
                    },
                  }));
                }}
                placeholder="Select a status"
                className="h-fit"
                label="Filter Type"
                disabled={!emailMarketingFilter.emailTemplateInstanceId}
              >
                {[{ id: 0, name: "Select a Filter" }, ...filterTypes]}
              </FormFieldSelect>
              <Button
                variant={isFilterEmpty() ? "disabled" : "negative"}
                className="max-w-80 h-fit whitespace-nowrap"
                disabled={isFilterEmpty()}
                onClick={() => {
                  setEmailMarketingFilter((prevState) => ({
                    ...prevState,
                    filter: {
                      regionIds: [],
                      sportIds: [],
                      regBatchIds: [],
                      genderIds: [],
                      dayIds: [],
                      regTypeIds: [],
                      playerRoleIds: [],
                      sportFormatIds: [],
                      leagueIds: [],
                    },
                  }));
                }}
              >
                Clear All Filters
              </Button>
            </div>
          </div>
          {[1, 2, 3, 4].includes(
            emailMarketingFilter.emailMarketingFilterTypeId
          ) && (
            <EmailMarketingFilterSelect
              selectedItems={selectedRegions}
              filterCategory="regionIds"
              label="Region"
              placeholder="Region"
              filter={emailMarketingFilter.filter}
              setFilter={setEmailMarketingFilter}
            />
          )}
          {[3].includes(emailMarketingFilter.emailMarketingFilterTypeId) && (
            <EmailMarketingFilterSelect
              selectedItems={sports}
              filterCategory="sportIds"
              label="Sport"
              placeholder="Sport"
              filter={emailMarketingFilter.filter}
              setFilter={setEmailMarketingFilter}
            />
          )}
          {[4].includes(emailMarketingFilter.emailMarketingFilterTypeId) && (
            <>
              <EmailMarketingFilterSelect
                selectedItems={registrationBatches}
                filterCategory="regBatchIds"
                label="Registraion Batch"
                placeholder="Registration Batch"
                filter={emailMarketingFilter.filter}
                setFilter={setEmailMarketingFilter}
              />
              <EmailMarketingFilterSelect
                selectedItems={dayOfWeek}
                filterCategory="dayIds"
                label="Day of Week"
                placeholder="Day of Week"
                filter={emailMarketingFilter.filter}
                setFilter={setEmailMarketingFilter}
              />
              <EmailMarketingFilterSelect
                selectedItems={genderIdentity}
                filterCategory="genderIds"
                label="Gender"
                placeholder="Gender"
                filter={emailMarketingFilter.filter}
                setFilter={setEmailMarketingFilter}
              />
              <EmailMarketingFilterSelect
                selectedItems={teamPlayerRoles}
                filterCategory="playerRoleIds"
                label="Player Role"
                placeholder="Player Role"
                filter={emailMarketingFilter.filter}
                setFilter={setEmailMarketingFilter}
              />
              <EmailMarketingFilterSelect
                selectedItems={registrationTypes.map(
                  (regType: RegistrationType) => {
                    return {
                      id: regType.id,
                      name: regType.type,
                    };
                  }
                )}
                filterCategory="regTypeIds"
                label="Registration Type"
                placeholder="Registration Type"
                filter={emailMarketingFilter.filter}
                setFilter={setEmailMarketingFilter}
              />
              {!(
                emailMarketingFilter.filter.regionIds.length > 0 ||
                emailMarketingFilter.filter.sportIds.length > 0 ||
                emailMarketingFilter.filter.regBatchIds.length > 0 ||
                emailMarketingFilter.filter.dayIds.length > 0
              ) && (
                <div className="max-w-[500px]">
                  <Disclaimer
                    variant="info"
                    size="small"
                    persist={true}
                    content="Please select at least 1 of Region, Sport, Registration Batch, or Day of Week to populate the Sport Format and League fields. "
                  />
                </div>
              )}
              {[1, 2, 3].includes(
                emailMarketingFilter.emailMarketingFilterTypeId
              ) && (
                <>
                  <EmailMarketingFilterSelect
                    selectedItems={
                      sportFormatData
                        ? [
                            ...sportFormatData.sportFormatDynamic.map(
                              (sportFormat) => {
                                return {
                                  id: +sportFormat.id,
                                  name: sportFormat.name,
                                };
                              }
                            ),
                          ]
                        : []
                    }
                    filterCategory="sportFormatIds"
                    label="Sport Format"
                    placeholder="Sport Format"
                    filter={emailMarketingFilter.filter}
                    setFilter={setEmailMarketingFilter}
                  />
                </>
              )}
              <FormFieldSelectMultiSearch
                options={
                  leagueData
                    ? [
                        ...leagueData.leagueDynamic.map((league) => {
                          return {
                            value: league.id.toString(),
                            label: `${league.sportFormat.region.name} - ${league.name}`,
                          };
                        }),
                      ]
                    : []
                }
                onValueChange={(values) => {
                  setEmailMarketingFilter((prevState) => ({
                    ...prevState,
                    filter: {
                      ...prevState.filter,
                      leagueIds: values.map((value) => +value),
                    },
                  }));
                }}
                values={emailMarketingFilter.filter.leagueIds.map((value) =>
                  value.toString()
                )}
                label="League"
                placeholder="League"
              />
            </>
          )}
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-1">
              <Checkbox
                className="hover:bg-secondary-opacity-08 flex h-[25px] w-[25px] min-w-[25px] min-h-[25px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
                checked={emailMarketingFilter.adminRecipient !== null}
                onCheckedChange={(value: boolean) => {
                  setEmailMarketingFilter((prevState) => ({
                    ...prevState,
                    adminRecipient: value ? user.id : null,
                  }));
                }}
                id={`sendToSelf`}
                aria-label="sendToSelf"
              />
              <Label
                htmlFor={`sendToSelf`}
                className="hover:cursor-pointer"
              >
                <Body1 className="whitespace-nowrap">Send To Self</Body1>
              </Label>
            </div>
            <Body1>
              {isFilterSavable()
                ? `This filter will send to ${
                    loadingNumberOfRecipeints ? "updating" : numberOfRecipients
                  } people.`
                : "Select some filters to get a count"}
            </Body1>
          </div>
          <div className="flex flex-row gap-2">
            <Dialog>
              <DialogTrigger>
                <Button
                  variant={
                    isFilterSavable() && !loadingNumberOfRecipeints
                      ? "primary"
                      : "disabled"
                  }
                >
                  {emailMarketingFilter.id === undefined
                    ? "Create "
                    : "Update "}
                  Filter
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-80">
                <DialogHeader>
                  <DialogTitle>
                    {emailMarketingFilter.id === undefined
                      ? "Create "
                      : "Update "}
                    Filter
                  </DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  <div className="flex flex-col">
                    <Body1>
                      This filter will send an email to{" "}
                      {loadingNumberOfRecipeints
                        ? "updating"
                        : numberOfRecipients}{" "}
                      people.
                    </Body1>
                  </div>
                </DialogDescription>
                <div className="flex flex-row gap-2">
                  <DialogClose asChild>
                    <Button
                      className="w-full"
                      variant={
                        loadingNumberOfRecipeints ? "disabled" : "primary"
                      }
                      disabled={loadingNumberOfRecipeints}
                      onClick={handleSubmit}
                    >
                      Confirm
                    </Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button
                      className="w-full"
                      variant="negative"
                    >
                      Cancel
                    </Button>
                  </DialogClose>
                </div>
              </DialogContent>
            </Dialog>
            <Button
              variant={
                emailMarketingFilter.id === undefined ? "disabled" : "secondary"
              }
              onClick={() =>
                navigate(
                  `/marketing/email-tool/marketing/send/${emailMarketingFilter.emailTemplateInstanceId}`
                )
              }
            >
              Continue to Send
            </Button>
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-4 max-w-[600px]">
          <FormFieldSelect
            value={emailMarketingFilter.emailTemplateInstanceId.toString()}
            inputChange={(value: string) => {
              setEmailMarketingFilter((prevState) => ({
                ...emptyEmailMarketingFilter,
                emailTemplateInstanceId: +value,
              }));
              navigate(`/marketing/email-tool/marketing/filter/${value}`);
            }}
            placeholder="Select a status"
            className="h-fit "
            label="Email Instance"
          >
            {[
              { id: 0, name: "Select an Email Instance" },
              ...emailTemplateInstances.map((emailTemplateInstances) => {
                return {
                  id: emailTemplateInstances.id,
                  name: emailTemplateInstances.name,
                };
              }),
            ]}
          </FormFieldSelect>

          {emailTemplateInstanceData !== undefined && (
            <div className="flex flex-col items-start justify-start w-full gap-2">
              <div className="flex flex-row items-end w-full gap-2">
                <FormFieldViewOnly
                  label="Subject"
                  text={email.subject}
                />
                <Button
                  variant={
                    !emailMarketingFilter.emailTemplateInstanceId
                      ? "disabled"
                      : "secondary"
                  }
                  disabled={emailMarketingFilter.emailTemplateInstanceId === 0}
                  href={`/marketing/email-tool/marketing/${emailMarketingFilter.emailTemplateInstanceId}`}
                  className="whitespace-nowrap"
                >
                  Edit Instance
                </Button>
              </div>

              <iframe
                ref={iframeRef}
                title="Email Preview"
                style={{ width: "100%", height: "600px", border: "none" }}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default EmailToolMarketingFilter;
