import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { Pagination } from "../../../types/types";
import BaseTable from "../../UI/Table/Table";
import Button from "../../UI/Button/Button";
import ActionsCell from "../../UI/ActionCell";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import TablePagination from "../../UI/Pagination/Pagination";
import {
  CapacityGroupOverviewFilters,
  CapacityGroupsPaginatedQuery,
  ListAllOperations,
  useCapacityGroupsPaginatedLazyQuery,
  useCapacityGroupsPaginatedQuery,
  useLeagueDynamicQuery,
  useMergeCapacityGroupMutation,
  useVenuesQuery,
} from "../../../generated/graphql";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { getRegistrationBatch, getSports } from "../../../app/venueMasterSlice";
import { FormFieldSelectMulti } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import { dayOfWeek } from "../../../utils/dayOfWeek";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import Body1 from "../../UI/Text/Body/Body1";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import { z, ZodFormattedError } from "zod";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { Checkbox } from "../../UI/shadcn/checkbox";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import Caption1 from "../../UI/Text/Caption/Caption1";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../UI/shadcn/tooltip";
import FormFieldDate from "../../UI/FormField/FormFieldDate/FormFieldDate";
import dayjs from "dayjs";
import { FormFieldSelectMultiSearch } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import PaginationDataTable from "../../UI/Pagination/PaginationDataTable";

const MergeCapacityGroupSchema = z
  .object({
    name: z.string(),
    rootCapacityGroupId: z.number(),
    branchCapacityGroupId: z.array(
      z.object({ id: z.number(), name: z.string() })
    ),
  })
  .refine((data) => data.name.length > 0, {
    message: "Name cannot be empty",
    path: ["name"],
  });

const weekdaysArray: any = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

type MergeCapacityGroupFormValues = z.infer<typeof MergeCapacityGroupSchema>;

const initialMergeCapacityGroupData: MergeCapacityGroupFormValues = {
  rootCapacityGroupId: 0,
  name: "",
  branchCapacityGroupId: [],
};

type PaginationType = {
  page: number;
  pageSize: number;
  pageCount: number | undefined;
};

// List of options for action col
const options = [{ id: 1, text: "Edit" }];

const CapacityGroupOverview: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, registrationBatches, sports } = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 5;

  /*** STATES ***/
  const [mergeCapacityGroupData, setMergeCapacityGroupData] =
    useState<MergeCapacityGroupFormValues>(initialMergeCapacityGroupData);
  // Zod errors used to show errors on the form
  const [mergeZodErrors, setMergeZodErrors] = useState<
    ZodFormattedError<MergeCapacityGroupFormValues, string>
  >({ _errors: [] });
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageCount: 0,
    pageSize: pageSize,
  });
  const [capacityGroupData, setCapacityGroupData] = useState<
    CapacityGroupsPaginatedQuery["capacityGroupsPaginated"]["capacityGroups"]
  >([]);

  const initialFilter = {
    regionIds: [0],
    registrationBatchId: null,
    sportId: null,
    dayOfWeek: null,
    leagueIds: null,
    startDate: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    endDate: null,
  };

  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    leagueIds: number[] | null;
    dayOfWeek: number | null;
    registrationBatchId: number | null;
    regionIds: number[];
    sportId: number | null;
    startDate: string;
    endDate: string | null;
  }>(initialFilter);

  /*** QUERIES ***/
  const [getCapacityGroups, { loading: loadingCapacityGroups }] =
    useCapacityGroupsPaginatedLazyQuery({
      onCompleted: (data) => {
        setCapacityGroupData(data.capacityGroupsPaginated.capacityGroups);
        setPagination((prevState) => ({
          ...prevState,
          pageCount: data.capacityGroupsPaginated.count,
        }));
      },
    });

  const { data: dataLeagues, loading: loadingLeagues } = useLeagueDynamicQuery({
    variables: {
      leagueDynamicFilter: {
        regBatchIds: filter.registrationBatchId
          ? [filter.registrationBatchId]
          : [],
        regionIds:
          filter.regionIds[0] === 0
            ? selectedRegions.map((region) => region.id)
            : filter.regionIds,
        sportIds: filter.sportId ? [filter.sportId] : [],
        dayIds: filter.dayOfWeek ? [filter.dayOfWeek] : [],
        sportFormatIds: [],
      },
    },
  });

  /*** MUTATIONS ***/
  const [MergeCapacityGroup, { loading: loadingMerge }] =
    useMergeCapacityGroupMutation();

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (filter) {
      getCapacityGroups({
        variables: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          capacityGroupFilter: {
            regBatchIds: filter.registrationBatchId
              ? [filter.registrationBatchId]
              : [],
            regionIds:
              filter.regionIds[0] === 0
                ? selectedRegions.map((region) => region.id)
                : filter.regionIds,
            sportIds: filter.sportId ? [filter.sportId] : [],
            dayIds: filter.dayOfWeek ? [filter.dayOfWeek] : [],
            startDate: filter.startDate,
            endDate: filter.endDate,
            leagueIds: filter.leagueIds,
          },
        },
      });
    }
  }, [filter, pagination.page, pagination.pageSize, selectedRegions]);

  useEffect(() => {
    dispatch(getSports(""));
    dispatch(getRegistrationBatch(""));
  }, []);

  /*** UTILITY FUNCTIONS ***/
  async function handleSubmitMerge() {
    // Parses the form and sets errors if there is an issue with validation
    const result = MergeCapacityGroupSchema.safeParse(mergeCapacityGroupData);
    if (!result.success) {
      // setMergeZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is with the merge form"));
      return;
    }
    // Creates or updates data
    await MergeCapacityGroup({
      variables: {
        name: mergeCapacityGroupData.name,
        branchCapacityGroupIds: mergeCapacityGroupData.branchCapacityGroupId
          .map((branch) => branch.id)
          .filter(
            (branch) => branch !== mergeCapacityGroupData.rootCapacityGroupId
          ),
        rootCapacityGroupId: mergeCapacityGroupData.rootCapacityGroupId,
      },
      refetchQueries: [ListAllOperations.Query.CapacityGroupsPaginated],
      onCompleted: (data) => {
        if (data.mergeCapacityGroup.success) {
          dispatch(displayAlertSuccess(data.mergeCapacityGroup.message));
        } else {
          dispatch(displayAlertError(data.mergeCapacityGroup.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

    return;
  }

  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const handleSelectedOption = (
    row: CapacityGroupsPaginatedQuery["capacityGroupsPaginated"]["capacityGroups"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/ops/capacity-group/${row.id}`);
    }
  };

  // Table col definition
  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const COLUMNS: ColumnDef<
    CapacityGroupsPaginatedQuery["capacityGroupsPaginated"]["capacityGroups"][0]
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          onCheckedChange={(value) => {
            setMergeCapacityGroupData((prevState) => ({
              ...prevState,
              branchCapacityGroupId: value
                ? (() => {
                    let branchs: { id: number; name: string }[] = [];
                    for (let i = 0; i < table.getRowCount(); i++) {
                      branchs.push({
                        id: table.getRow(i.toString()).original.id,
                        name: table.getRow(i.toString()).original.name,
                      });
                    }
                    return branchs;
                  })()
                : [],
            }));
            table.setRowSelection((current) => {
              const newSelection: RowSelectionState = {};
              for (let i = 0; i < table.getRowCount(); i++) {
                const row = table.getRow(i.toString());
                newSelection[i] = !!value;
              }
              return newSelection;
            });
          }}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => {
        return (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              if (value) {
                setMergeCapacityGroupData((prevState) => ({
                  ...prevState,
                  branchCapacityGroupId: [
                    ...prevState.branchCapacityGroupId,
                    { id: row.original.id, name: row.original.name },
                  ],
                }));
              } else {
                setMergeCapacityGroupData((prevState) => ({
                  ...prevState,
                  branchCapacityGroupId: prevState.branchCapacityGroupId.filter(
                    (branch) => branch.id !== row.original.id
                  ),
                }));
              }
              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        );
      },
    },
    {
      header: "Id",
      id: "Id",
      accessorFn: (row) => row.id,
    },
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <a
            className="underline"
            href={`/ops/capacity-group/${row.original.id}`}
            target="_blank"
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      header: "Region",
      id: "Region",
      accessorFn: (row) =>
        row.capacityGroupSessions.map((cg) => cg.session.region.name),
    },
    {
      header: "Registration Batch",
      id: "Registration Batch",
      accessorFn: (row) =>
        row.capacityGroupSessions.map(
          (cg) => cg.session.registrationBatch.name
        ),
    },
    {
      header: "Day Of Week",
      id: "Day Of Week",
      accessorFn: (row) =>
        row.capacityGroupSessions.map(
          (cg) => dayOfWeek.find((d) => d.id === cg.session.dayOfWeek)?.name
        ),
    },
    {
      id: "action",
      header: "",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row.original}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loadingCapacityGroups || loadingMerge} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Capacity Group Overview</Headline1Variable>
        <Dialog>
          <DialogTrigger
            disabled={mergeCapacityGroupData.branchCapacityGroupId.length <= 1}
          >
            <Button
              variant={
                mergeCapacityGroupData.branchCapacityGroupId.length <= 1
                  ? "disabled"
                  : "secondary"
              }
              disabled={
                mergeCapacityGroupData.branchCapacityGroupId.length <= 1
              }
              className="w-60"
              onClick={() => {
                if (capacityGroupData) {
                  setMergeCapacityGroupData((prevState) => ({
                    ...prevState,
                    rootCapacityGroupId: prevState.branchCapacityGroupId[0].id,
                    name: capacityGroupData[0].name,
                    branchCapacityGroupId:
                      prevState.branchCapacityGroupId.filter(
                        (branch) =>
                          branch.id !== prevState.branchCapacityGroupId[0].id
                      ),
                  }));
                }
              }}
            >
              Merge
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-[600px]">
            <DialogHeader>Merge capacity groups</DialogHeader>
            <DialogDescription>
              <div className="flex flex-col gap-2">
                <Body1>
                  The following capactiy groups will be merged into one with a
                  new name
                </Body1>
                <div className="flex flex-col gap-1">
                  {mergeCapacityGroupData.branchCapacityGroupId.map(
                    (branch) => {
                      return <Caption1>{branch.name}</Caption1>;
                    }
                  )}
                </div>
                <Body1>This will be the new name of the capacity group</Body1>
                <FormFieldControlled
                  name="name"
                  value={mergeCapacityGroupData.name}
                  label="New Name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMergeCapacityGroupData((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
            </DialogDescription>
            <DialogFooter>
              <div className="flex flex-row gap-4">
                <DialogClose>
                  <Button variant="secondary">Cancel</Button>
                </DialogClose>
                <DialogClose>
                  <Button
                    variant="primary"
                    onClick={handleSubmitMerge}
                  >
                    Confirm
                  </Button>
                </DialogClose>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {/* Filtering section */}
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-3 gap-2">
          <div className="flex flex-col gap-2">
            <FormFieldSelect
              inputChange={(value) => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    registrationBatchId: +value,
                  };
                });
              }}
              label="Registration Batch"
              placeholder="Registration Batch"
              value={filter.registrationBatchId?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...registrationBatches]}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    regionIds: [+value],
                  };
                });
              }}
              label="Region"
              placeholder="Region"
              value={filter.regionIds[0]?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...selectedRegions]}
            </FormFieldSelect>
          </div>
          <div className="flex flex-col gap-2">
            <FormFieldSelect
              inputChange={(value) => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    sportId: +value,
                  };
                });
              }}
              label="Sport"
              placeholder="Sport"
              value={filter.sportId?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...sports]}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    dayOfWeek: +value,
                  };
                });
              }}
              label="Day Of Week"
              placeholder="Day Of Week"
              value={filter.dayOfWeek?.toString() ?? "-1"}
            >
              {[{ id: "-1", name: "All" }, ...weekdaysArray]}
            </FormFieldSelect>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-2">
            <FormFieldSelectMultiSearch
              label="Select Leagues"
              name="leagueIds"
              maxCount={2}
              options={[
                ...(dataLeagues?.leagueDynamic.map((league) => {
                  return { value: `${league.id}`, label: league.name };
                }) || []),
              ]}
              values={
                filter.leagueIds
                  ? filter.leagueIds.map((id) => id.toString())
                  : []
              }
              onValueChange={(values) => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    leagueIds: values.map((value) => +value),
                  };
                });
              }}
              placeholder="Select League"
            />
          </div>
          <div className="flex items-end">
            <Button
              variant="secondary"
              className="w-full h-10"
              onClick={() => {
                setFilter(initialFilter);
              }}
            >
              Reset All Filters
            </Button>
          </div>
        </div>
        <Subtitle1>Start Date Range</Subtitle1>
        <div className="grid items-end grid-cols-3 gap-2">
          <FormFieldDate
            label="Start*"
            initialValue={dayjs(filter.startDate).toDate()}
            dateChange={(date) => {
              setFilter((prevState) => {
                return {
                  ...prevState,
                  startDate: dayjs(date).format("YYYY-MM-DD"),
                };
              });
            }}
          />
          <FormFieldDate
            label="End"
            initialValue={
              filter.endDate ? dayjs(filter.endDate).toDate() : undefined
            }
            dateChange={(date) => {
              setFilter((prevState) => {
                return {
                  ...prevState,
                  endDate: dayjs(date).format("YYYY-MM-DD"),
                };
              });
            }}
          />
          <div className="flex flex-row w-full gap-4">
            <Button
              variant="secondary"
              className="w-full h-10"
              onClick={() => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    startDate: initialFilter.startDate,
                    endDate: null,
                  };
                });
              }}
            >
              Reset Dates
            </Button>
          </div>
        </div>
      </div>
      {/* Table section */}
      {capacityGroupData.length > 0 ? (
        <div className="flex flex-col gap-4">
          <DataTable
            columns={COLUMNS}
            data={capacityGroupData}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      ) : (
        <Headline2Variable>
          No capacity groups found with this filter
        </Headline2Variable>
      )}
    </main>
  );
};

export default CapacityGroupOverview;
