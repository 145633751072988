import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { Pagination, PaginationType } from "../../../../types/types";
import BaseTable from "../../../UI/Table/Table";
import Button from "../../../UI/Button/Button";
import ActionsCell from "../../../UI/ActionCell";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import TablePagination from "../../../UI/Pagination/Pagination";
import {
  GetRulesPaginatedQuery,
  SessionsLowFaPaginatedQuery,
  SessionsPaginatedQuery,
  useGetRulesPaginatedLazyQuery,
  useLeagueDynamicQuery,
  useSessionsLowFaPaginatedQuery,
  useSessionsPaginatedQuery,
} from "../../../../generated/graphql";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import {
  DayOfWeek,
  getRegistrationBatch,
  getSports,
} from "../../../../app/venueMasterSlice";
import dayjs from "dayjs";
import { DataTable } from "../../../UI/Table/DataTable";
import PaginationDataTable from "../../../UI/Pagination/PaginationDataTable";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../UI/shadcn/dropdown";
import { ColumnDef } from "@tanstack/react-table";
import Tooltip from "../../../UI/Tooltip/Tooltip";
import { Calendar, CalendarCheck, MoreVerticalIcon } from "lucide-react";
import { baseURL } from "../../../../../src/utils/baseUrl";
import { dayOfWeek } from "../../../../../src/utils/dayOfWeek";
import { FormFieldSelectMultiSearch } from "../../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import Subtitle1 from "../../../UI/Text/Subtitle/Subtitle1";
import FormFieldDate from "../../../UI/FormField/FormFieldDate/FormFieldDate";
import { cn } from "../../../../../src/lib/utils";

// Type that is needed for action col cell
interface CellParam {
  row: { original: GetRulesPaginatedQuery["getRulesPaginated"]["rules"][0] };
  rows: { original: GetRulesPaginatedQuery["getRulesPaginated"]["rules"][0] }[];
}

type SessionFilterWayfinder = {
  leagueIds: number[] | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  regionIds: number[];
  sportId: number | null;
  startDate: string;
  endDate: string | null;
};

const initialSessionFilters = {
  regionIds: [0],
  registrationBatchId: null,
  sportId: null,
  dayOfWeek: null,
  leagueIds: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: null,
};

const SessionFreeAgentBreakdown: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, sports, registrationBatches } = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** STATES ***/
  // Basic filter add new id types here for more filtering
  const [sessionFilters, setSessionFilters] = useState<SessionFilterWayfinder>(
    initialSessionFilters
  );
  const [sessionData, setSessionData] = useState<
    SessionsLowFaPaginatedQuery["sessionsLowFaPaginated"]["sessions"]
  >([]);

  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 25,
    pageCount: undefined,
  });
  /*** QUERIES ***/
  const { data: dataLeagues, loading: loadingLeagues } = useLeagueDynamicQuery({
    variables: {
      leagueDynamicFilter: {
        regBatchIds: sessionFilters.registrationBatchId
          ? [sessionFilters.registrationBatchId]
          : [],
        regionIds:
          sessionFilters.regionIds[0] === 0
            ? selectedRegions.map((region) => region.id)
            : sessionFilters.regionIds,
        sportIds: sessionFilters.sportId ? [sessionFilters.sportId] : [],
        dayIds:
          sessionFilters.dayOfWeek !== null && sessionFilters.dayOfWeek !== -1
            ? [sessionFilters.dayOfWeek]
            : [],
        sportFormatIds: [],
      },
    },
  });
  useEffect(() => {
    console.log(sessionFilters);
  }, [sessionFilters]);
  // Query to get whatever will be dislayed on the table
  const { data: dataSessions, loading: loadingSessions } =
    useSessionsLowFaPaginatedQuery({
      variables: {
        sessionsLowFaPaginatedInput: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          sessionFilters: {
            ...sessionFilters,
            regionIds:
              sessionFilters.regionIds[0] === 0
                ? selectedRegions.map((region) => region.id)
                : sessionFilters.regionIds,
            dayOfWeek:
              sessionFilters.dayOfWeek == -1 ? null : sessionFilters.dayOfWeek,
          },
        },
      },
      onCompleted: (data) => {
        if (data.sessionsLowFaPaginated) {
          setSessionData(data.sessionsLowFaPaginated.sessions);
          setPagination((prevState: PaginationType) => {
            return {
              ...prevState,
              pageCount: data.sessionsLowFaPaginated.count,
            };
          });
        }
      },
    });

  /*** USE EFFECTS ***/
  // Get any redux data needed for filters here
  useEffect(() => {
    dispatch(getSports(""));
    dispatch(getRegistrationBatch(""));
  }, [dispatch]);

  /*** UTILITY FUNCTIONS ***/

  const sessionColumns: ColumnDef<
    SessionsLowFaPaginatedQuery["sessionsLowFaPaginated"]["sessions"][0]
  >[] = [
    {
      accessorKey: "id",
      header: () => <div className="text-center">Session Id</div>,
      cell: ({ row }) => {
        return <p className="font-medium text-center">{row.getValue("id")}</p>;
      },
    },
    {
      accessorKey: "region.name",
      header: "Region",
    },
    {
      accessorKey: "league.name",
      header: "League",
    },
    {
      accessorKey: "dayOfWeek",
      header: () => <div className="text-left">Day of Week</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">{DayOfWeek[row.original.dayOfWeek]}</p>
        );
      },
    },
    {
      accessorKey: "startDate",
      header: () => <div className="text-left">Start Date</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">
            {dayjs(row.original.startDate).format("YYYY-MM-DD")}
          </p>
        );
      },
    },
    {
      accessorKey: "league.sportFormat.peopleOnField",
      header: "Team Size",
      cell: ({ row }) => {
        return (
          <div className="grid grid-cols-3 font-medium text-left">
            <p>T{row.original.league.sportFormat.peopleOnField}</p>
            <p>M{row.original.league.sportFormat.minMen}</p>
            <p>W{row.original.league.sportFormat.minWomen}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "indyTeams",
      header: () => (
        <Tooltip
          trigger="FA Teams"
          hoverText="This is the total number of free agent teams we have available for sale. The gender capacity for each free agent team is set at the sport format."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">{row.getValue("indyTeams")}</p>
        );
      },
    },
    {
      accessorKey: "setCapacity",
      header: () => (
        <Tooltip
          trigger="FA Capacity"
          hoverText="(People on field + 1) * FA Teams available"
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {(row.original.league.sportFormat.peopleOnField + 1) *
              row.original.indyTeams}
          </p>
        );
      },
    },
    {
      accessorKey: "freeAgentCount",
      header: () => (
        <Tooltip
          trigger="FA Registrations"
          hoverText="This is the total number of free agent registrations. (T: Total M: Men + Trans Men, W: Women + Trans Women, X: All other genders)"
        />
      ),

      cell: ({ row }) => {
        return (
          <div className="grid grid-cols-4 font-medium text-left">
            <p>T{row.original.freeAgentCount?.totalCount}</p>
            <p>M{row.original.freeAgentCount?.men}</p>
            <p>W{row.original.freeAgentCount?.women}</p>
            <p>X{row.original.freeAgentCount?.other}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "lowCount",
      header: () => (
        <Tooltip
          trigger="Low Count"
          hoverText="T = Total, M = Men, W = Women"
        />
      ),

      cell: ({ row }) => {
        return (
          <div className="grid grid-cols-3 font-medium text-left">
            {row.original.overallLow ? <p className="font-bold">T</p> : ""}
            {row.original.menLow ? <p className="font-bold">M</p> : ""}
            {row.original.womenLow ? <p className="font-bold">W</p> : ""}
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a
                  href={`/ops/free-agent-teams?regBatchId=${row.original.registrationBatch.id}&sessionId=${row.original.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    FA teams
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/session-hq/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Session HQ
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loadingSessions} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Low FA Sessions</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            href={"/ops/rule"}
          >
            <AddIcon />
            New Rule
          </Button>
        </div>
      </div>
      {/* Filtering section */}
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-3 gap-2">
          <div className="flex flex-col gap-2">
            <FormFieldSelect
              inputChange={(value) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    registrationBatchId: +value,
                  };
                });
              }}
              label="Registration Batch"
              placeholder="Registration Batch"
              value={sessionFilters.registrationBatchId?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...registrationBatches]}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    regionIds: [+value],
                  };
                });
              }}
              label="Region"
              placeholder="Region"
              value={sessionFilters.regionIds[0]?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...selectedRegions]}
            </FormFieldSelect>
          </div>
          <div className="flex flex-col gap-2">
            <FormFieldSelect
              inputChange={(value) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    sportId: +value,
                  };
                });
              }}
              label="Sport"
              placeholder="Sport"
              value={sessionFilters.sportId?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...sports]}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    dayOfWeek: +value,
                  };
                });
              }}
              label="Day Of Week"
              placeholder="Day Of Week"
              value={sessionFilters.dayOfWeek?.toString() ?? "-1"}
            >
              {[{ id: "-1", name: "All" }, ...dayOfWeek]}
            </FormFieldSelect>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-2">
            <FormFieldSelectMultiSearch
              label="Select Leagues"
              name="leagueIds"
              maxCount={2}
              options={[
                ...(dataLeagues?.leagueDynamic.map((league) => {
                  return { value: `${league.id}`, label: league.name };
                }) || []),
              ]}
              values={
                sessionFilters.leagueIds
                  ? sessionFilters.leagueIds.map((id) => id.toString())
                  : []
              }
              onValueChange={(values) => {
                setSessionFilters((prevState) => {
                  return {
                    ...prevState,
                    leagueIds: values.map((value) => +value),
                  };
                });
              }}
              placeholder="Select League"
            />
          </div>
          <div className="flex items-end">
            <Button
              variant="secondary"
              className="w-full h-10"
              onClick={() => {
                setSessionFilters(initialSessionFilters);
              }}
            >
              Reset All Filters
            </Button>
          </div>
        </div>
        <Subtitle1>Start Date Range</Subtitle1>
        <div className="grid items-end grid-cols-3 gap-2">
          <FormFieldDate
            label="Start*"
            initialValue={dayjs(sessionFilters.startDate).toDate()}
            dateChange={(date) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  startDate: dayjs(date).format("YYYY-MM-DD"),
                };
              });
            }}
          />
          <FormFieldDate
            label="End"
            initialValue={
              sessionFilters.endDate
                ? dayjs(sessionFilters.endDate).toDate()
                : undefined
            }
            dateChange={(date) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  endDate: dayjs(date).format("YYYY-MM-DD"),
                };
              });
            }}
          />
          <div className="flex flex-row w-full gap-4">
            <Button
              variant="secondary"
              className="w-full h-10"
              onClick={() => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    startDate: initialSessionFilters.startDate,
                    endDate: null,
                  };
                });
              }}
            >
              Reset Dates
            </Button>
          </div>
        </div>
      </div>
      {/* Table section */}
      {sessionData.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            data={sessionData}
            columns={sessionColumns}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
    </main>
  );
};

export default SessionFreeAgentBreakdown;
