import { gql } from "@apollo/client";

gql`
  query leagueDynamic($leagueDynamicFilter: LeagueDynamicFilter!) {
    leagueDynamic(leagueDynamicFilter: $leagueDynamicFilter) {
      id
      name
      sportFormat {
        region {
          id
          name
        }
      }
    }
  }
`;

gql`
  mutation LeagueCreate($createLeagueInput: LeagueInput!) {
    leagueCreate(createLeagueInput: $createLeagueInput) {
      code
      message
      success
    }
  }
`;

gql`
  mutation LeagueUpdate($updateLeagueInput: UpdateLeagueInput!) {
    leagueUpdate(updateLeagueInput: $updateLeagueInput) {
      code
      message
      success
    }
  }
`;

const GET_LEAGUES = gql`
  query Leagues($leagueFilters: LeagueFilters) {
    leagues(leagueFilters: $leagueFilters) {
      divisionName
      gameDuration
      gameTimeslot
      gamesPerOccurrence
      id
      name
      minAge
      sportFormat {
        name
        weeksOfPlayOffs
        region {
          id
          name
        }
        genderFormat {
          id
          name
        }
        sport {
          id
          name
        }
      }
      venues {
        name
        id
      }
    }
  }
`;

const GET_LEAGUE_BY_ID = gql`
  query League($leagueId: Int!) {
    league(id: $leagueId) {
      divisionName
      gameDuration
      gameTimeslot
      gamesPerOccurrence
      id
      leagueNotes
      name
      minAge
      sportFormat {
        name
        id
        region {
          id
        }
        sport {
          id
        }
      }
      venues {
        id
        name
      }
      imageLink
    }
  }
`;

const GET_LEAGUE_TYPES = gql`
  query LeagueTypes {
    leagueTypes {
      id
      name
    }
  }
`;

export { GET_LEAGUE_TYPES, GET_LEAGUES, GET_LEAGUE_BY_ID };
