import React, { useEffect, useMemo, useState } from "react";
import DeleteShift from "../DeleteShift";
import {
  getAllShifts,
  getAllStaffMembers,
  handleShiftFilters,
  deleteShift,
  addShiftNoteId,
} from "../../../../app/shiftSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Modal,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../../app/store";
import dayjs from "dayjs";
import FormFieldDropdown from "../../../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { Column, useSortBy, useTable } from "react-table";
import ActionsCell from "../../../UI/ActionCell";
import "react-datepicker/dist/react-datepicker.css";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import Headline2Variable from "../../../UI/Text/Headline/Headline2Variable";
import Subtitle1 from "../../../UI/Text/Subtitle/Subtitle1";
import Subtitle2 from "../../../UI/Text/Subtitle/Subtitle2";
import FormFieldDate from "../../../UI/FormField/FormFieldDate/FormFieldDate";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import Button from "../../../UI/Button/Button";
import { EPermission, isPermitted } from "../../../../utils/permissions";
import { displayAlertError } from "../../../../app/globalSlice";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import {
  FormFieldSelectMultiSearch,
  MultiSelectRef,
} from "../../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import { useShiftStatusUpdateMutation } from "../../../../../src/generated/graphql";

const initialStateEdit = {
  id: 0,
  venueId: 1,
  staffTypeId: 1,
  startDate: "",
  endDate: "",
  startTime: new Date(),
  endTime: new Date(),
  isShiftOvernight: false,
  assignedStaffId: "0",
  emailChecked: [],
  rate: 0,
  regionId: "0",
  reported: false,
  shiftStatusId: 1,
};

const initialShiftReport: {
  id: string;
  contents: { [key: string]: any };
  shiftReportStatusId: number;
  createdAt: string;
} = {
  id: "",
  contents: {},
  shiftReportStatusId: 0,
  createdAt: "",
};

const AllShifts: React.FC = () => {
  const { shifts, isLoading, shiftFilters, staffMembersOptions, venues } =
    useSelector((state: RootState) => state.shift);
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const { user } = useSelector((state: RootState) => state.auth);

  /*** REFERENCES ***/
  const multiSelectRef = React.useRef<MultiSelectRef>(null);

  const handleClear = () => {
    multiSelectRef.current?.clearState();
  };

  const [isModalVisible, setModalVisible] = useState(false);
  const [deleteShiftId, setdeleteShiftId] = useState("");
  const [open, setOpen] = useState(false);
  const [shiftReportModal, setShiftReportModal] = useState(false);
  const [shiftReportContent, setShiftReportContent] =
    useState(initialShiftReport);
  const [resetShiftStatusModal, setResetShiftStatusModal] = useState<{
    open: boolean;
    shift: {
      id: string;
      venue: string;
      startTime: string;
      endTime: string;
    } | null;
  }>({
    open: false,
    shift: null,
  });

  const publicUrl = process.env.PUBLIC_URL;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleShiftReportModal = (shiftReport: any) => {
    setShiftReportContent(shiftReport);
    setShiftReportModal(true);
  };

  const handleResetShiftStatusModal = (shift: any) => {
    setResetShiftStatusModal({
      open: true,
      shift: {
        id: shift.id,
        venue: shift.venue,
        startTime: shift.startTime,
        endTime: shift.endTime,
      },
    });
  };

  const [updateShiftStatus] = useShiftStatusUpdateMutation();

  useEffect(() => {
    if (!staffMembersOptions.length) {
      dispatch(getAllStaffMembers());
    }
    // if (!locationsOptions.length) {
    //   dispatch(getAllLocations());
    // }
    // if (!staffTypeOptions.length) {
    //   dispatch(getStaffType());
    // }
  }, [
    dispatch,
    staffMembersOptions,
    // locationsOptions, staffTypeOptions
  ]);

  useEffect(() => {
    dispatch(getAllShifts(shiftFilters));
  }, [dispatch, shiftFilters, selectedRegions]);

  const handleChange = ({ name, value }: { name: string; value: any }) => {
    dispatch(handleShiftFilters({ ...shiftFilters, [name]: value }));
  };

  const closeCartHandler = () => {
    setModalVisible(false);
  };

  const openCartHandler = (shiftId: string) => {
    setdeleteShiftId(shiftId);
    setModalVisible(true);
  };

  const displayedShifts = (
    <div>
      {shifts &&
        Object.keys(shifts)?.map((date, index) => {
          return (
            <div key={index}>
              {/* Date */}
              <h5 className="mt-5 mb-0">
                {dayjs(date).format("dddd MMM, DD YYYY")}
              </h5>
              {/* Table */}
              <ShiftTable
                shifts={shifts[date]}
                openCartHandler={openCartHandler}
                handleShiftReportModal={handleShiftReportModal}
                handleResetShiftStatusModal={handleResetShiftStatusModal}
              />
            </div>
          );
        })}
    </div>
  );

  if (isLoading) {
    return (
      <div>
        <LoadingDialog open={true} />
      </div>
    );
  }

  return (
    <main>
      <h3>View Staff Shifts from</h3>
      <div className="flex flex-row flex-wrap items-end gap-4">
        <div className="w-64">
          <FormFieldDate
            label="Start Date"
            name="fromDate"
            initialValue={dayjs(shiftFilters.fromDate).toDate()}
            dateChange={(date) => {
              handleChange({
                name: "fromDate",
                value: dayjs(date).format("YYYY-MM-DD"),
              });
            }}
          />
        </div>
        <div className="w-64">
          <FormFieldDate
            label="End Date"
            name="toDate"
            initialValue={dayjs(shiftFilters.toDate).toDate()}
            dateChange={(date) => {
              handleChange({
                name: "toDate",
                value: dayjs(date).format("YYYY-MM-DD"),
              });
            }}
          />
        </div>
        <div className="w-64">
          <FormFieldSelectMultiSearch
            ref={multiSelectRef}
            label="Staff Members"
            oneItem={true}
            options={[
              { value: "0", label: "All Staff" },
              { value: "1", label: "Open" }, // CHECK THIS OPEN IS 1?
              ...staffMembersOptions.map((staff: any) => {
                return {
                  value: staff.id,
                  label: staff.name,
                };
              }),
            ]}
            values={
              shiftFilters.assignedTo !== ""
                ? [shiftFilters.assignedTo]
                : undefined
            }
            onValueChange={(values) => {
              handleChange({
                name: "assignedTo",
                value: values.length > 0 ? values[0] : "0",
              });
            }}
            placeholder="Select Staff"
            maxCount={1}
          />
        </div>
        <div className="w-64">
          <FormFieldDropdown
            name="weekday"
            label="Day"
            placeholder="Day"
            initialValue={
              shiftFilters.weekday ? shiftFilters.weekday.toString() : "7"
            }
            value={shiftFilters.weekday}
            inputChange={(value: string) => {
              handleChange({
                name: "weekday",
                value: +value,
              });
            }}
          >
            {[
              { id: 0, name: "Sunday" },
              { id: 1, name: "Monday" },
              { id: 2, name: "Tuesday" },
              { id: 3, name: "Wednesday" },
              { id: 4, name: "Thursday" },
              { id: 5, name: "Friday" },
              { id: 6, name: "Saturday" },
              { id: 7, name: "All" },
            ]}
          </FormFieldDropdown>
        </div>
        <div className="w-64">
          <FormFieldDropdown
            name="isReported"
            label="Reported"
            placeholder="Reported"
            initialValue={
              shiftFilters.isReported ? shiftFilters.isReported.toString() : "0"
            }
            value={shiftFilters.isReported}
            inputChange={(value: string) => {
              handleChange({
                name: "isReported",
                value,
              });
            }}
          >
            {[
              { id: "0", name: "All" },
              { id: "1", name: "Unreported" },
              { id: "2", name: "Reported" },
            ]}
          </FormFieldDropdown>
        </div>
        <div className="w-64">
          <FormFieldDropdown
            name="shiftStatusId"
            label="Shift Status"
            placeholder="Shift Status"
            initialValue={shiftFilters.shiftStatus?.toString() || "0"}
            value={shiftFilters.shiftStatus}
            inputChange={(value: string) => {
              handleChange({
                name: "shiftStatus",
                value,
              });
            }}
          >
            {[
              { id: "0", name: "Pending and Accepted" },
              { id: "1", name: "Rejected" },
            ]}
          </FormFieldDropdown>
        </div>
        <div className="w-64">
          <Button
            variant="primary"
            onClick={() =>
              dispatch(
                handleShiftFilters({
                  toDate: dayjs().add(7, "days").format("YYYY-MM-DD"),
                  fromDate: dayjs().format("YYYY-MM-DD"),
                  assignedTo: "0",
                  weekday: 7,
                  isReported: "0",
                  shiftStatus: "0",
                })
              )
            }
          >
            Reset Filter
          </Button>
        </div>
      </div>
      {Object.keys(shifts)?.length !== 0 ? displayedShifts : `No such records`}
      <Modal
        open={isModalVisible}
        onClose={() => setModalVisible(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <DeleteShift
            onClose={() => setModalVisible(false)}
            onOkay={() => {
              dispatch(deleteShift(+deleteShiftId)).then(() => {
                dispatch(getAllShifts(shiftFilters));
              });
              closeCartHandler();
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={shiftReportModal}
        onClose={() => setShiftReportModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {
            <div>
              <Headline1Variable>Shift Report</Headline1Variable>
              <div className="mt-2">
                <Headline2Variable>Reported Date</Headline2Variable>
                <p>
                  {shiftReportContent?.createdAt
                    ? dayjs(shiftReportContent?.createdAt).format(
                        "dddd MMM, DD YYYY"
                      )
                    : "N/A"}
                </p>
              </div>
              <div className="mt-2">
                <Headline2Variable>Reported Notes</Headline2Variable>
                {Object.keys(shiftReportContent?.contents)?.map((key: any) => {
                  return (
                    <div
                      key={key}
                      className="mt-3"
                    >
                      <Subtitle1>{key}</Subtitle1>
                      <Subtitle2>
                        {shiftReportContent?.contents[key] === true
                          ? "Yes"
                          : shiftReportContent?.contents[key]}
                      </Subtitle2>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </Box>
      </Modal>
      <Modal
        open={resetShiftStatusModal.open}
        onClose={() =>
          setResetShiftStatusModal({ ...resetShiftStatusModal, open: false })
        }
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {
            <div>
              <Headline2Variable>Reset Shift Status</Headline2Variable>
              <div className="mt-2 text-light">
                Are you sure you want to reset the status for the shift at{" "}
                <span className="font-extrabold text-black">
                  {resetShiftStatusModal.shift?.venue}{" "}
                </span>
                from {resetShiftStatusModal.shift?.startTime} -{" "}
                {resetShiftStatusModal.shift?.endTime} to pending?
              </div>
              <div className="flex gap-2 mt-2 justify-self-end">
                <Button
                  variant="secondary"
                  onClick={() =>
                    setResetShiftStatusModal({
                      ...resetShiftStatusModal,
                      open: false,
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (!resetShiftStatusModal.shift?.id) return;
                    updateShiftStatus({
                      variables: {
                        shiftId: +resetShiftStatusModal.shift?.id,
                        shiftStatusId: 1,
                      },
                      onCompleted: (data) => {
                        if (data.shiftStatusUpdate?.success) {
                          dispatch(getAllShifts(shiftFilters));
                          setResetShiftStatusModal({
                            shift: null,
                            open: false,
                          });
                        } else {
                          // display error message
                          dispatch(
                            displayAlertError(data.shiftStatusUpdate?.message)
                          );
                        }
                      },
                      onError: (error) => {
                        dispatch(displayAlertError(error.message));
                      },
                    });
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          }
        </Box>
      </Modal>
    </main>
  );
};

export default AllShifts;

const ShiftTable: React.FC<{
  shifts: any;
  openCartHandler: any;
  handleShiftReportModal: any;
  handleResetShiftStatusModal: any;
}> = ({
  shifts,
  openCartHandler,
  handleShiftReportModal,
  handleResetShiftStatusModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const publicUrl = process.env.PUBLIC_URL;
  const { user } = useSelector((state: RootState) => state.auth);
  const options = [{ id: 1, text: "Create/View Notes" }];

  const handleAddNote = (shiftId: string) => {
    dispatch(addShiftNoteId(shiftId));
    // dispatch(getShiftNotes(shiftId));
    navigate(`${publicUrl}/staffing/shift-notes/${shiftId}`, {
      state: { shiftId },
    });
  };

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      // create/view notes
      handleAddNote(row.original.id);
    }
    if (idx === 2) {
      // edit
      navigate(`/staffing/shift/${row.original.id}`);
    }

    if (idx === 3) {
      // delete
      // disabled if it is past shift- Not added for now after DDM review
      // disabled if shift report has been reported
      // disabled if the shift is rejected- Not added for now after DDM review
      if (
        row.original.shiftReport?.id
        // || new Date().getTime() > new Date(row.original.shift.startLocal).getTime()
        // || row.original.shift?.shiftStatusId === 3
      ) {
        dispatch(displayAlertError("Cannot delete this shift"));
        return;
      }
      openCartHandler(row.original.id);
    }

    if (idx === 4) {
      // reset shift status
      handleResetShiftStatusModal({
        id: row.original.id,
        venue: row.original.venue.name,
        startTime: dayjs(row.original.startLocal).format("h:mma"),
        endTime: dayjs(row.original.endLocal).format("h:mma"),
        region: row.original.region.name,
      });
    }
  };

  const COLUMNS: Column<any>[] = [
    {
      Header: "Shift#",
      id: "id",
      accessor: (d: any) => {
        return <div>{`${d.id}`}</div>;
      },
    },
    {
      Header: "Shift Time",
      id: "startLocal",
      accessor: (d: any) => {
        return (
          <div>{`${dayjs.utc(d.startLocal).format("h:mma")} - ${dayjs
            .utc(d.endLocal)
            .format("h:mma")}`}</div>
        );
      },
    },
    {
      Header: "Shift Length",
      id: "shiftLenth",
      accessor: (d: any) => {
        return (
          <div>{`${(
            dayjs(d.endLocal).diff(d.startLocal, "minutes") / 60
          ).toFixed(2)} hours`}</div>
        );
      },
    },
    {
      Header: "Location",
      id: "venue.name",
      accessor: (d: any) => {
        return <div>{d.venue.name}</div>;
      },
    },
    {
      Header: "Assigned To",
      id: "user.firstName",
      accessor: (d: any) => {
        return (
          <a
            className={d.user ? "underline" : ""}
            href={d.user ? `/players/user/${d.user.id}` : "#"}
            target="_blank"
          >
            {d.user?.firstName
              ? `${d.user?.firstName} ${
                  d.user?.lastName ? d.user?.lastName : ""
                }`
              : `Open`}
          </a>
        );
      },
    },
    {
      Header: "Email",
      id: "user.email",
      accessor: (d: any) => {
        return <div>{d.user?.email || `Open`}</div>;
      },
    },
    {
      Header: "Rate",
      id: "rate",
      accessor: (d: any) => {
        const isPermissionGranted = isPermitted(user?.permission, {
          permission: EPermission["VIEW_SHIFT_STAFF_PAYRATES"],
          regionId: d.regionId,
        });
        return <div>{isPermissionGranted ? d.rate : ""}</div>;
      },
    },
    {
      Header: "Attendance",
      id: "shiftStatus.value",
      accessor: (d: any) => {
        return <div>{d.shiftStatus.value}</div>;
      },
    },
    {
      Header: "Reported",
      id: "shiftReport.shiftReportStatusId",
      accessor: (d: any) => {
        return (
          <div>
            {d.shiftReport?.shiftReportStatusId &&
            d.shiftReport?.shiftReportStatusId == 2 ? (
              <Button
                variant="secondary"
                onClick={() => {
                  handleShiftReportModal(d.shiftReport);
                }}
              >
                Reported
              </Button>
            ) : (
              "Unreported"
            )}
          </div>
        );
      },
    },
    {
      Header: " ",
      Cell: (data: any) => {
        let actionsOptions = [...options];
        // Edit Shift option if shift is rejected and unreported
        const rejectedAndUnreportedShift =
          data.row.original.shiftStatusId === 3 &&
          !data.row.original.shiftReport;

        const acceptedAndReportedShift =
          data.row.original.shiftStatusId === 2 &&
          data.row.original.shiftReport;

        const paidShift = data.row.original.shiftPaymentStatusId === 4;

        if (!rejectedAndUnreportedShift)
          actionsOptions.push({ id: 2, text: "Edit Shift" });

        if (!rejectedAndUnreportedShift && !acceptedAndReportedShift)
          actionsOptions.push({ id: 3, text: "Delete Shift" });

        // if shift is paid, remove edit and delete option. Only add notes option
        if (paidShift) {
          actionsOptions = [...options];
        }

        if (rejectedAndUnreportedShift) {
          actionsOptions.push({ id: 4, text: "Reset Shift Status" });
        }

        // setting permission
        const isPermissionGranted = isPermitted(user?.permission, {
          permission: EPermission["EDIT_SHIFT"],
          regionId: data.row.original.regionId,
        });

        return (
          <div className="flex flex-row items-center justify-center">
            <ActionsCell
              row={data.row}
              options={actionsOptions}
              handleSelectedOption={handleSelectedOption}
              disabled={!isPermissionGranted}
            />
            <div className="p-2">
              {data.row.original.shiftNotes.length === 0 ? (
                <Tooltip title="No Note">
                  <PostAddOutlinedIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Notes Added">
                  <GradingOutlinedIcon className="text-primary-30" />
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => shifts, [shifts]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        // initialState: {
        //   hiddenColumns: ['id'],
        // },
      },
      useSortBy
    );

  const shiftTable = (
    <TableContainer
      sx={{
        maxHeight: "80vh",
        width: "95vw",
        maxWidth: "100%",
        bgcolor: "white",
        padding: "20px",
        borderRadius: "12px",
      }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  sx={{
                    bgcolor: "white",
                    color: "var(--textColor)",
                    fontSize: "16px",
                    fontWeight: 550,
                    borderColor: "var(--table-border)",
                    textAlign: "left",
                    paddingBottom: "5px",
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                sx={{
                  borderBottom: "5px",
                  borderWidth: "2px",
                }}
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "var(--textColor)",
                        // borderWidth: '2px',
                        borderColor: "var(--table-border)",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                        // '&:hover': {
                        //   whiteSpace: 'wrap',
                        // },
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return <>{shiftTable}</>;
};
