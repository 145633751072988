import React, { Fragment, useEffect, useState } from "react";
import { Box, Modal, Pagination } from "@mui/material";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import dayjs from "dayjs";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { z } from "zod";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import Button from "../UI/Button/Button";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useSelector } from "react-redux";
import { RootState } from "@/src/app/store";
import { PaginationType } from "../../types/types";
import {
  GameReportFilters,
  GameReportsQuery,
  GameReportsQueryVariables,
  useGameReportTypesQuery,
  useGameReportsLazyQuery,
} from "../../../src/generated/graphql";
import PostAddOutlined from "@mui/icons-material/PostAddOutlined";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";

const GameReport: React.FC = () => {
  const { selectedRegions, sports, leagues, scoreStatuses }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const pageSize = 50;
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 10,
    pageCount: undefined,
  });
  const [openContentModal, setOpenContentModal] = useState<boolean>(false);
  const [contentModal, setContentModal] = useState<{ [key: string]: any }>({});
  const [gameReportFilters, setGameReportFilters] = useState<
    GameReportsQueryVariables["gameReportFilters"]
  >({});

  const { data: gameReportTypes } = useGameReportTypesQuery();

  const [
    getGameReports,
    {
      data: dataGameReports,
      loading: loadingGameReports,
      error: errorGameReports,
    },
  ] = useGameReportsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data) {
        setPagination((prevState) => {
          return {
            ...prevState,
            pageCount: data.gameReports.count,
          };
        });
      }
    },
  });

  useEffect(() => {
    getGameReports({
      variables: {
        gameReportFilters: gameReportFilters,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }, [pagination.page, pagination.pageSize]);

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  useEffect(() => {
    getGameReports({
      variables: {
        gameReportFilters: gameReportFilters,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    });
  }, [pagination.page]);

  const handleChangeFilter = (filter: GameReportFilters) => {
    setGameReportFilters(filter);
    getGameReports({
      variables: {
        gameReportFilters: filter,
      },
    });
  };

  const columnsGameReport: ColumnDef<
    GameReportsQuery["gameReports"]["gameReports"][0]
  >[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorFn: (row) =>
        row.submitUser.firstName + " " + row.submitUser.lastName,
      id: "submitUserId",
      header: "Submitting User",
    },
    {
      accessorFn: (row) => dayjs(row.createdAt).format("MMM DD YYYY, HH:mm"),
      header: "Date Submitted",
    },

    {
      accessorFn: (row) => row.gameReportType.name,
      id: "reportType",
      header: "Report Type",
    },
    {
      accessorFn: (row) =>
        `${dayjs(row.game.startDateTimeLocal).format("MMM DD YYYY, HH:mm")} (${
          row.game.homeTeam?.name
        } vs ${row.game.awayTeam?.name})`,
      id: "homeScore",
      header: "Game Matchup",
    },
    {
      header: " ",
      cell: ({ row }) => {
        return (
          <div>
            <Button
              variant="secondary"
              onClick={() => {
                setOpenContentModal(true);
                setContentModal(row.original.content);
              }}
            >
              <PostAddOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (loadingGameReports) return <LoadingDialog open={true} />;
  if (errorGameReports) return <div>Something went wrong</div>;

  return (
    <main className="flex flex-col justify-between w-full pb-4">
      <Modal
        open={openContentModal}
        onClose={() => setOpenContentModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="flex flex-col gap-2">
            <Headline2Variable>Game Report Content</Headline2Variable>
            {Object.keys(contentModal)?.map((key: any) => {
              return (
                <div
                  key={key}
                  className="mt-1"
                >
                  <Subtitle1 className="bolder">{key}</Subtitle1>
                  <Subtitle2>
                    {contentModal[key] === "" ? "N/A" : contentModal[key]}
                  </Subtitle2>
                </div>
              );
            })}
          </div>
        </Box>
      </Modal>
      <Headline1Variable>Game Reports</Headline1Variable>
      <div className="flex flex-col gap-2 mb-3">
        <div className="grid grid-cols-5 gap-4">
          <FormFieldSelect
            inputChange={(value) => {
              handleChangeFilter({
                reportType: +value === 0 ? undefined : +value,
              });
            }}
            label="Game Report Type"
            placeholder="Select Game Report Type"
            value={gameReportFilters?.reportType?.toString() ?? "0"}
          >
            {[
              { id: "0", name: "All" },
              ...(gameReportTypes?.gameReportTypes || []),
            ]}
          </FormFieldSelect>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <DataTable
          data={dataGameReports?.gameReports.gameReports || []}
          columns={columnsGameReport}
        />
        <PaginationDataTable
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    </main>
  );
};

export default GameReport;
