import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./ContractManagement.module.css";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import { PaginationType } from "../../types/types";
import { getRegistrationBatch } from "../../app/venueMasterSlice";
import { LoadingMaterialUI } from "../UI";
import Button from "../UI/Button/Button";
import ActionsCell from "../UI/ActionCell";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import { DataTable } from "../UI/Table/DataTable";
import {
  RegistrationBatchesQuery,
  useRegistrationBatchDeleteMutation,
} from "../../../src/generated/graphql";
import { ColumnDef } from "@tanstack/react-table";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../src/app/globalSlice";

const options = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Delete" },
];

const Seasons: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { registrationBatches, isLoading, registrationBatchesCount }: any =
    useSelector((state: RootState) => state.venueMaster);

  //Pagination Page Size
  const pageSize = 25;

  const [pagination, setPagination] = React.useState<PaginationType>({
    pageSize: pageSize,
    page: 0,
    pageCount: undefined,
  });

  const [DeleteRegistrationBatch, { loading: loadingDelete }] =
    useRegistrationBatchDeleteMutation();

  const redirectCreateSeason = () => {
    navigate(`/ops/season/`);
  };

  const handleSelectedOption = (
    row: RegistrationBatchesQuery["registrationBatches"]["registrationBatches"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/ops/season/${row.id}`);
    }
    if (idx === 2) {
      DeleteRegistrationBatch({
        variables: { registrationBatchDeleteId: +row.id },
      })
        .then(() => {
          dispatch(getRegistrationBatch(""));
          dispatch(
            displayAlertSuccess("Registration Batch successfully created")
          );
        })
        .catch((error) => {
          dispatch(displayAlertError(error.message));
        });
    }
  };

  useEffect(() => {
    dispatch(
      getRegistrationBatch({
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      })
    );
  }, []);

  useEffect(() => {
    if (pagination) {
      dispatch(
        getRegistrationBatch({
          page: pagination?.page,
          pageSize: pagination?.pageSize,
        })
      );
    }
  }, [pagination]);

  useEffect(() => {
    setPagination((prevState) => {
      return {
        ...prevState,
        pageCount: registrationBatchesCount,
      };
    });
  }, [registrationBatchesCount]);

  const columns: ColumnDef<
    RegistrationBatchesQuery["registrationBatches"]["registrationBatches"][0]
  >[] = [
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <a
            className="underline"
            href={`/ops/season/${row.original.id}`}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      header: "Reg Open Date",
      id: "regOpenDate",
      cell: ({ row }) => {
        return (
          <div>
            {moment(row.original.regOpenDate)
              .local()
              .format("YYYY-MM-DD HH:mm")}
          </div>
        );
      },
    },
    {
      header: "Reg Close Date",
      id: "regCloseDate",
      cell: ({ row }) => {
        return (
          <div>
            {moment(row.original.regCloseDate)
              .local()
              .format("YYYY-MM-DD HH:mm")}
          </div>
        );
      },
    },
    {
      header: "Display Price",
      id: "displayPrice",
      cell: ({ row }) => {
        return <div>{row.original.displayPrice ? "Yes" : "No"}</div>;
      },
    },
    {
      header: "Display Session",
      id: "displaySession",
      cell: ({ row }) => {
        return <div>{row.original.displaySession ? "Yes" : "No"}</div>;
      },
    },
    {
      header: " ",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row}
            options={options}
            handleSelectedOption={(_, i) =>
              handleSelectedOption(row.original, i)
            }
          />
        );
      },
    },
  ];

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Seasons</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            onClick={redirectCreateSeason}
          >
            <AddIcon />
            New Season
          </Button>
        </div>
      </div>
      {(isLoading || loadingDelete) && <LoadingMaterialUI />}
      {registrationBatches.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            columns={columns}
            data={registrationBatches}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}

      {!(registrationBatches.length > 0) && (
        <div className={classes.table__contracts}>No Data Found</div>
      )}
    </main>
  );
};

export default Seasons;
