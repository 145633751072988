import { gql } from "@apollo/client";

const GET_GAMESLOTS = gql`
  query Gameslots($gameslotFilters: GameslotFilters) {
    gameslots(gameslotFilters: $gameslotFilters) {
      id
      startDateTimeLocal
      duration
      venue {
        name
      }
      capacityGroup {
        name
      }
      division {
        id
        vmColor
        vmName
      }
    }
  }
`;

const GET_GAMESLOT_BY_ID = gql`
  query Gameslot($gameslotId: Int!) {
    gameslot(id: $gameslotId) {
      id
      startDateTimeLocal
      duration
      venue {
        id
        name
      }
      capacityGroup {
        id
        name
      }
      division {
        id
        vmColor
        vmName
      }
    }
  }
`;

const CREATE_GAMESLOT = gql`
  mutation GameslotCreate($gameslotArgs: CreateGameslotInput!) {
    gameslotCreate(gameslotArgs: $gameslotArgs) {
      code
      success
      message
      gameslot {
        id
        duration
      }
    }
  }
`;

gql`
  mutation BulkCreateGameslot($bulkCreateGameslotInput: [GameslotInput!]!) {
    bulkCreateGameslot(bulkCreateGameslotInput: $bulkCreateGameslotInput) {
      code
      success
      message
      gameslots {
        id
        duration
        startDateTimeLocal
      }
      gameslotInputWithIssue {
        type
        startDateTimeLocal
      }
    }
  }
`;

const UPDATE_GAMESLOT = gql`
  mutation GameslotUpdateOld($gameslotArgs: UpdateGameslotInput!) {
    gameslotUpdateOld(gameslotArgs: $gameslotArgs) {
      code
      success
      message
      gameslot {
        id
        division {
          id
          name
        }
      }
    }
  }
`;

gql`
  query GameslotRelational(
    $gameslotVenueOverviewFilters: GameslotVenueOverviewFilters!
  ) {
    gameslotRelational(
      gameslotVenueOverviewFilters: $gameslotVenueOverviewFilters
    ) {
      id
      venueId
      startDateTimeLocal
      duration
      capacityGroupId
      divisionId
      division {
        id
        name
      }
      capacityGroup {
        id
        name
      }
    }
  }
`;

gql`
  mutation GameslotDelete($id: Int!) {
    gameslotDelete(id: $id) {
      code
      message
      success
    }
  }
`;

gql`
  mutation GameslotUpdate($updateGameslotInput: UpdateGameslotInput!) {
    gameslotUpdate(updateGameslotInput: $updateGameslotInput) {
      code
      success
      message
      gameslot {
        id
        division {
          id
          name
        }
      }
    }
  }
`;

gql`
  query GameslotsBySessionId($sessionId: Int!) {
    gameslotsBySessionId(sessionId: $sessionId) {
      startDateTimeLocal
      duration
      venueId
    }
  }
`;

gql`
  query IsGameSpaceAvailable(
    $startDateTimeLocal: DateWithoutTimezone!
    $venueId: Int!
    $endDateTimeLocal: DateWithoutTimezone
    $duration: Int!
    $gameId: Int
  ) {
    isGameSpaceAvailable(
      startDateTimeLocal: $startDateTimeLocal
      venueId: $venueId
      endDateTimeLocal: $endDateTimeLocal
      duration: $duration
      gameId: $gameId
    )
  }
`;

gql`
  query GameslotGameConflictByCapacityGroup($capacityGroupId: Int!) {
    gameslotGameConflictByCapacityGroup(capacityGroupId: $capacityGroupId) {
      message
      success
      gameslots {
        gameslot {
          id
        }
        gameslotStates {
          gamesInfo {
            divisionId
            gameId
            sessionId
            divisionName
            divisionColor
          }
          stateIssueLevel
          type
          message
        }
      }
    }
  }
`;

export { GET_GAMESLOTS, GET_GAMESLOT_BY_ID, CREATE_GAMESLOT, UPDATE_GAMESLOT };
