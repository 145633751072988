export function CountFaIssues({
  faRegistrations,
  faTeams,
  faOrphans,
}: {
  faRegistrations: boolean | undefined;
  faTeams: boolean | undefined;
  faOrphans: boolean | undefined;
}): number {
  let issueCount = 0;
  if (faRegistrations === false) issueCount += 1;
  if (faTeams === false) issueCount += 1;
  if (faOrphans === true) issueCount += 1;
  return issueCount;
}

export function CountDivisionIssues({
  orphanTeams,
  divisionsCreated,
}: {
  orphanTeams: boolean | undefined;
  divisionsCreated: boolean | undefined;
}): number {
  let issueCount = 0;
  if (orphanTeams === true) issueCount += 1;
  if (divisionsCreated === false) issueCount += 1;
  return issueCount;
}

export function CountCapacityGroupIssues({
  gameslotReserved,
  createdGameslots,
  gameslotMatchReserve,
  numOfGameslots,
  numOfResserved,
}: {
  gameslotReserved: boolean | undefined;
  createdGameslots: boolean | undefined;
  gameslotMatchReserve: boolean | undefined;
  numOfGameslots: number | undefined;
  numOfResserved: number | undefined;
}): number {
  let issueCount = 0;
  if (gameslotReserved === false) issueCount += 1;
  if (createdGameslots === false) issueCount += 1;
  if (gameslotMatchReserve === false) issueCount += 1;
  return issueCount;
}

export function CountScheduleIssues({
  divisionsScheduleIssue,
}: {
  divisionsScheduleIssue:
    | {
        scheduleCreated: boolean | undefined;
        schedulePublished: boolean | undefined;
        gameErrors: boolean | undefined;
      }[]
    | null
    | undefined;
}): number {
  let issueCount = 0;
  if (divisionsScheduleIssue == null) {
    return -1;
  }
  divisionsScheduleIssue.forEach((div) => {
    if (div.scheduleCreated === false) issueCount += 1;
  });
  divisionsScheduleIssue.forEach((div) => {
    if (div.schedulePublished === false) issueCount += 1;
  });
  divisionsScheduleIssue.forEach((div) => {
    if (div.gameErrors === true) issueCount += 1;
  });
  return issueCount;
}
