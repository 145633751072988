import { useEffect, useRef, useState } from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import {
  GetEmailBatchByInstanceIdQuery,
  ListAllOperations,
  useCreateEmailBatchMutation,
  useGetEmailBatchByInstanceIdQuery,
  useGetEmailMarketingFilterByEmailInstanceIdLazyQuery,
  useGetEmailTemplateInstanceByIdLazyQuery,
  useGetNumberOfEmailMarketingRecipientsByEmailMarketingFilterIdLazyQuery,
  useSendTestEmailInstanceMutation,
  useUpdateEmailBatchMutation,
} from "../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import Button from "../../UI/Button/Button";
import dayjs from "dayjs";
import Body1 from "../../UI/Text/Body/Body1";
import Caption1 from "../../UI/Text/Caption/Caption1";
import { DateTimePicker } from "../../UI/shadcn/Time/date-time-picker";
import {
  EmailMarketingFilter,
  EmailMarketingFilterSchema,
  EmailTemplate,
  emptyEmailMarketingFilter,
  emptyEmailTemplate,
} from "./EmailToolMarketingFilter";
import { client } from "../../../graphql";
import Alert from "../../UI/Alerts/Alert";
import { emailButton } from "./EmailButton";
import { header } from "./Headers";
import { footer } from "./Footers";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import { useEmailToolMarketingContext } from "../../../context/EmailToolMarketingContext";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";

const EmailToolMarketingSend = () => {
  /*** Utitlity Definitions ***/
  const navigate = useNavigate();
  const params = useParams();
  const instanceId = params.id;
  const dispatch: any = useDispatch<AppDispatch>();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { user }: any = useSelector((state: RootState) => state.auth);
  const { selectedReportStatus, setSelectedReportStatus } =
    useEmailToolMarketingContext();

  /*** States ***/
  const [timeToSend, setTimeToSend] = useState<Date>(new Date());
  const [emailMarketingFilter, setEmailMarketingFilter] =
    useState<EmailMarketingFilter>(emptyEmailMarketingFilter);
  const [emailBatch, setEmailBatch] =
    useState<GetEmailBatchByInstanceIdQuery["getEmailBatchByInstanceId"]>(null);
  const [numberOfRecipients, setNumberOfRecipients] = useState<number>(0);
  const [email, setEmail] = useState<EmailTemplate>(emptyEmailTemplate);

  /*** Queries ***/
  const [
    GetEmailMarketingFilterByEmailInstanceId,
    { data: emailMarketingFilterData, loading: loadingEmailMarketingFilter },
  ] = useGetEmailMarketingFilterByEmailInstanceIdLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const res = EmailMarketingFilterSchema.safeParse(
        data.getEmailMarketingFilterByEmailInstanceId
      );
      if (res.success) {
        setEmailMarketingFilter(res.data);
        GetNumberOfRecipients({
          variables: {
            id: res.data.id!,
          },
        });
      } else {
        dispatch(
          displayAlertError(
            `Filter does not exist for instance with id: ${instanceId}`
          )
        );
      }
    },
    onError: (error) => {
      dispatch(
        displayAlertError(`Error fetching filter for instance id ${instanceId}`)
      );
    },
  });

  const [GetNumberOfRecipients, { loading: loadingRecipientCount }] =
    useGetNumberOfEmailMarketingRecipientsByEmailMarketingFilterIdLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (
          data.getNumberOfEmailMarketingRecipientsByEmailMarketingFilterId
            .success
        ) {
          setNumberOfRecipients(
            data.getNumberOfEmailMarketingRecipientsByEmailMarketingFilterId
              .count
          );
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
      },
    });

  const { loading: loadingEmailBatch } = useGetEmailBatchByInstanceIdQuery({
    variables: {
      instanceId: emailMarketingFilter.emailTemplateInstanceId,
    },
    skip: emailMarketingFilter.emailTemplateInstanceId === 0,
    onCompleted: (data) => {
      if (data.getEmailBatchByInstanceId) {
        setTimeToSend(new Date(data.getEmailBatchByInstanceId.timeToSend));
      }
      setEmailBatch(data.getEmailBatchByInstanceId);
    },
  });

  const [
    GetEmailTemplateInstanceById,
    { data: emailTemplateInstanceData, loading: emailTemplateInstanceLoading },
  ] = useGetEmailTemplateInstanceByIdLazyQuery({
    onCompleted: (data) => {
      setEmail(data.getEmailTemplateInstanceById);
    },
  });

  /*** Mutations ***/
  const [createEmailBatch, { loading: loadingCreate }] =
    useCreateEmailBatchMutation({
      variables: {
        emailTemplateInstanceId: +instanceId!,
        timeToSend: timeToSend,
      },
      onCompleted: async (data) => {
        if (data.createEmailBatch.success) {
          dispatch(displayAlertSuccess(data.createEmailBatch.message));
          await client.refetchQueries({
            include: [
              ListAllOperations.Query.GetEmailMarketingFilterByEmailInstanceId,
              ListAllOperations.Query.GetEmailBatchByInstanceId,
            ],
          });
          setSelectedReportStatus(2);
          navigate("/marketing/email-tool/marketing/report");
        } else {
          dispatch(displayAlertError(data.createEmailBatch.message));
        }
      },
      onError: (err) => {
        dispatch(displayAlertError("Something went wrong"));
      },
    });

  const [updateEmailBatch, { loading: loadingUpdate }] =
    useUpdateEmailBatchMutation({
      onCompleted: async (data) => {
        if (data.updateEmailBatch.success) {
          dispatch(displayAlertSuccess(data.updateEmailBatch.message));
          await client.refetchQueries({
            include: [
              ListAllOperations.Query.GetEmailMarketingFilterByEmailInstanceId,
              ListAllOperations.Query.GetEmailBatchByInstanceId,
            ],
          });
          setSelectedReportStatus(2);
          navigate("/marketing/email-tool/marketing/report");
        } else {
          dispatch(displayAlertError(data.updateEmailBatch.message));
        }
      },
      onError: (err) => {
        dispatch(displayAlertError("Something went wrong"));
      },
    });

  const [SendTestEmail, { loading: loadingSendEmail }] =
    useSendTestEmailInstanceMutation();

  /*** Use Effects ***/
  useEffect(() => {
    if (emailMarketingFilter.emailTemplateInstanceId) {
      GetEmailMarketingFilterByEmailInstanceId({
        variables: {
          instanceId: emailMarketingFilter.emailTemplateInstanceId,
        },
      });
      GetEmailTemplateInstanceById({
        variables: {
          id: emailMarketingFilter.emailTemplateInstanceId,
        },
      });
    }
  }, [emailMarketingFilter.emailTemplateInstanceId]);

  useEffect(() => {
    if (instanceId) {
      setEmailMarketingFilter((prevState) => ({
        ...prevState,
        emailTemplateInstanceId: +instanceId,
      }));
    }
  }, []);

  useEffect(() => {
    if (iframeRef.current && email !== undefined) {
      const formattedBody = `${header}${email.body.replace(
        /%%button_(https:\/\/[a-zA-Z0-9._=%+-\\&+\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
        (_, link, text) => {
          return emailButton(link, text);
        }
      )}${footer({})}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [email]);

  /*** Functions ***/

  async function handleSubmit() {
    if (emailBatch) {
      updateEmailBatch({
        variables: {
          id: emailBatch.id,
          timeToSend: timeToSend,
        },
      });
    } else if (emailMarketingFilter.id) {
      createEmailBatch({
        variables: {
          timeToSend: timeToSend,
          emailTemplateInstanceId: emailMarketingFilter.emailTemplateInstanceId,
        },
      });
    }
  }

  return (
    <main className="flex flex-col gap-4 px-12 pb-10">
      <LoadingDialog
        open={
          loadingCreate ||
          loadingUpdate ||
          loadingEmailBatch ||
          loadingEmailMarketingFilter ||
          emailTemplateInstanceLoading
        }
      />
      <div className="flex flex-row items-center justify-between">
        <Headline1Variable>Marketing Email Filter</Headline1Variable>
        <Button
          variant="secondary"
          href={"/marketing/email-tool/marketing/report"}
          className="h-fit w-fit whitespace-nowrap"
        >
          Report Page
        </Button>
      </div>
      <div className="flex flex-row justify-between w-full gap-4">
        <div className="flex flex-col items-start w-1/2 gap-2">
          <div className="flex flex-col w-full gap-4">
            {!loadingEmailMarketingFilter && (
              <Alert
                variant={emailMarketingFilter.id ? "success" : "error"}
                content={
                  emailMarketingFilter.id ? "Filter set" : "No filter set"
                }
                size="small"
                persist={true}
              />
            )}
            {!loadingEmailBatch && (
              <Alert
                variant={emailBatch ? "success" : "info"}
                content={emailBatch ? "Email Scheduled" : "Email Not Scheduled"}
                size="small"
                persist={true}
              />
            )}
            <Button
              variant={loadingSendEmail ? "disabled" : "secondary"}
              disabled={loadingSendEmail}
              onClick={() => {
                SendTestEmail({
                  variables: {
                    instanceId: emailMarketingFilter.emailTemplateInstanceId,
                  },
                  onCompleted: (data) => {
                    if (data.sendTestEmailInstance.success) {
                      dispatch(
                        displayAlertSuccess(data.sendTestEmailInstance.message)
                      );
                    } else {
                      dispatch(
                        displayAlertError(data.sendTestEmailInstance.message)
                      );
                    }
                  },
                  onError: (error) => {
                    dispatch(displayAlertError(error.message));
                  },
                });
              }}
              className="h-fit w-fit whitespace-nowrap"
            >
              Send Test Email
            </Button>
            <div className="flex flex-col gap-1">
              <Caption1 className="pl-3 font-medium">Time To Send</Caption1>
              <DateTimePicker
                date={timeToSend}
                setDate={(date) => setTimeToSend(date ?? new Date())}
              />
            </div>
            {timeToSend && (
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <Body1>Toronto: </Body1>
                  <Body1>
                    {dayjs(new Date(timeToSend))
                      .tz("America/Toronto")
                      .format("YYYY-MM-DD h:mm A")}
                  </Body1>
                </div>
                <div className="flex flex-row">
                  <Body1>Winnepeg: </Body1>
                  <Body1>
                    {dayjs(new Date(timeToSend))
                      .tz("America/Winnipeg")
                      .format("YYYY-MM-DD h:mm A")}
                  </Body1>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <Body1>
              This will send an email to{" "}
              {loadingRecipientCount ? "loading..." : numberOfRecipients}{" "}
              people.{" "}
              {numberOfRecipients !== emailMarketingFilter.recipientCount &&
                `(${emailMarketingFilter.recipientCount} at the time of setting the filter)`}{" "}
            </Body1>
          </div>
          <div>
            <div className="flex flex-row gap-2">
              {emailBatch !== null ? (
                <Dialog>
                  <DialogTrigger>
                    <Button
                      variant={emailBatch ? "primary" : "disabled"}
                      disabled={!emailBatch}
                      className="max-w-80"
                    >
                      Adjust Time
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="max-w-80">
                    <DialogHeader>
                      <DialogTitle>Adjust Email Time</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                      <div className="flex flex-col">
                        <Body1>The time to send will be changed to</Body1>
                        {timeToSend && (
                          <div className="flex flex-col">
                            <div className="flex flex-row">
                              <Body1>Toronto: </Body1>
                              <Body1>
                                {dayjs(new Date(timeToSend))
                                  .tz("America/Toronto")
                                  .format("YYYY-MM-DD h:mm A")}
                              </Body1>
                            </div>
                            <div className="flex flex-row">
                              <Body1>Winnepeg: </Body1>
                              <Body1>
                                {dayjs(new Date(timeToSend))
                                  .tz("America/Winnipeg")
                                  .format("YYYY-MM-DD h:mm A")}
                              </Body1>
                            </div>
                          </div>
                        )}
                      </div>
                    </DialogDescription>
                    <DialogFooter className="flex flex-row gap-2">
                      <DialogClose asChild>
                        <Button
                          className="w-full"
                          variant={"primary"}
                          onClick={async () => {
                            await handleSubmit();
                          }}
                        >
                          Confirm
                        </Button>
                      </DialogClose>
                      <DialogClose asChild>
                        <Button
                          className="w-full"
                          variant="negative"
                        >
                          Cancel
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              ) : (
                <Dialog>
                  <DialogTrigger>
                    <Button
                      variant={emailMarketingFilter.id ? "primary" : "disabled"}
                      disabled={!emailMarketingFilter.id}
                      className="max-w-80"
                    >
                      Schedule Email
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="max-w-80">
                    <DialogHeader>
                      <DialogTitle>Confirm Division Email</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                      <div className="flex flex-col">
                        <Body1>
                          This will send an email to{" "}
                          {loadingRecipientCount
                            ? "loading..."
                            : numberOfRecipients}{" "}
                          people. At the below times.
                        </Body1>
                        {timeToSend && (
                          <div className="flex flex-col">
                            <div className="flex flex-row">
                              <Body1>Toronto: </Body1>
                              <Body1>
                                {dayjs(new Date(timeToSend))
                                  .tz("America/Toronto")
                                  .format("YYYY-MM-DD h:mm A")}
                              </Body1>
                            </div>
                            <div className="flex flex-row">
                              <Body1>Winnepeg: </Body1>
                              <Body1>
                                {dayjs(new Date(timeToSend))
                                  .tz("America/Winnipeg")
                                  .format("YYYY-MM-DD h:mm A")}
                              </Body1>
                            </div>
                          </div>
                        )}
                      </div>
                    </DialogDescription>
                    <DialogFooter className="flex flex-row gap-2">
                      <DialogClose asChild>
                        <Button
                          className="w-full"
                          variant={"primary"}
                          onClick={async () => {
                            await handleSubmit();
                          }}
                        >
                          Confirm
                        </Button>
                      </DialogClose>
                      <DialogClose asChild>
                        <Button
                          className="w-full"
                          variant="negative"
                        >
                          Cancel
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}
              <Button
                variant={emailBatch === null ? "secondary" : "disabled"}
                disabled={emailBatch !== null}
                onClick={() =>
                  navigate(
                    `/marketing/email-tool/marketing/filter/${emailMarketingFilter.emailTemplateInstanceId}`
                  )
                }
              >
                {emailMarketingFilter.id ? "Adjust Filter" : "Create Filter"}
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-4 max-w-[600px]">
          {emailTemplateInstanceData !== undefined && (
            <div className="flex flex-col items-start justify-start w-full gap-2">
              <div className="flex flex-row items-end w-full gap-2">
                <FormFieldViewOnly
                  label="Subject"
                  text={email.subject}
                />
              </div>
              <iframe
                ref={iframeRef}
                title="Email Preview"
                style={{ width: "100%", height: "600px", border: "none" }}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default EmailToolMarketingSend;
