import React, { useEffect, useState } from "react";
import Button from "../UI/Button/Button";
import Card from "../UI/Card/Card";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountries,
  getStates,
  getVendors,
  getVenueTypes,
} from "../../app/venueMasterSlice";
import { AppDispatch, RootState } from "../../app/store";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { useNavigate, useParams } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../app/globalSlice";
import { AddOutlined, UndoOutlined } from "@mui/icons-material";
import { z } from "zod";
import { Textarea } from "@headlessui/react";
import {
  ListAllOperations,
  useCreateVenuePermitMutation,
  useDeleteVenuePermitMutation,
  useFacilityDetailTypesQuery,
  useGetVenuePermitsByVenueIdLazyQuery,
  useUpdateVenuePermitMutation,
  useVenueByIdLazyQuery,
  useVenueCreateMutation,
  useVenueSizeByTypeIdQuery,
  useVenueUpdateMutation,
} from "../../../src/generated/graphql";
import VenueImagesDialog from "../UI/Dialog/VenueImagesDialog";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../UI/shadcn/dialog";
import { Divider } from "@mui/material";
import Body1 from "../UI/Text/Body/Body1";
import { Loader2, Trash2 } from "lucide-react";
import { FileUploader } from "react-drag-drop-files";
import Disclaimer from "../UI/Alerts/Disclaimer";
import { multipartFetch } from "../../app/authSlice";
import AutoCompleteAddressFormField, {
  extractAddressFields,
} from "../UI/GoogleMaps/AutoCompleteAddressFormField";
import Caption1 from "../UI/Text/Caption/Caption1";
import { DateRangePicker } from "../UI/FormField/FormFieldDateRange/date-range-picker";
import dayjs from "dayjs";
import TextArea from "../UI/FormField/TextArea/TextArea";

const VenueSetupSchema = z.object({
  id: z.number().optional().nullable(),
  name: z.string().refine((data) => data.length > 0, {
    message: "Name is required",
  }),
  description: z.string().refine((data) => data.length > 0, {
    message: "Description is required",
  }),
  imageUrl: z.string().optional(),
  isDeleted: z.boolean().optional().nullable(),
});

const FacilityDetailsSchema = z.object({
  id: z.number().optional().nullable(),
  name: z.string().refine((data) => data.length > 0, {
    message: "Header is required",
  }),
  facilityDetailTypeId: z.number().refine((data) => data > 0, {
    message: "Type is required",
  }),
  description: z.string().refine((data) => data.length > 0, {
    message: "Description is required",
  }),
  isDeleted: z.boolean().optional().nullable(),
});

const VenuePermitsSchema = z.object({
  id: z.number().optional(),
  originalFileName: z.string(),
  gcpFileName: z.string(),
  publicUrl: z.string(),
  activeFrom: z.date(),
  activeTo: z.date(),
  name: z.string(),
});

const VenueSchema = z.object({
  id: z.number().optional().nullable(),
  name: z.string().refine((data) => data.length > 0, {
    message: "Name is required",
  }),
  startBuffer: z.number().optional(),
  endBuffer: z.number().optional(),
  intersection: z.string(),
  address: z.string().refine((data) => data.length > 0, {
    message: "Address is required",
  }),
  city: z.string().refine((data) => data.length > 0, {
    message: "City is required",
  }),
  zipCode: z.string().refine((data) => data.length > 0, {
    message: "Zip code is required",
  }),
  locationLink: z.string().optional(),
  latitude: z.number().refine((data) => data !== 0, {
    message: "Latitude is required",
  }),
  longitude: z.number().refine((data) => data !== 0, {
    message: "Longitude is required",
  }),
  parentId: z.number().nullable(),
  stateId: z.number().refine((data) => data !== 0, {
    message: "State is required",
  }),
  countryId: z.number().refine((data) => data !== 0, {
    message: "Country is required",
  }),
  vendorId: z.number().refine((data) => data !== 0, {
    message: "Vendor is required",
  }),
  regionId: z.number().refine((data) => data !== 0, {
    message: "Region is required",
  }),
  type: z.number().refine((data) => data !== 0, {
    message: "Type is required",
  }),
  size: z.number().refine((data) => data !== 0, {
    message: "Size is required",
  }),
  islights: z.boolean(),
  permit: z.boolean().optional(),
  facilityDescription: z.string().optional(),
  facilityImage: z.string().optional(),
  facilityDetails: z.array(FacilityDetailsSchema).optional(),
  venueSetupId: z.number().optional(),
  facilitySetups: z.array(VenueSetupSchema).optional(),
  venueDetailId: z.number().optional(),
});

type VenueType = z.infer<typeof VenueSchema>;
type FacilitySetupType = z.infer<typeof VenueSetupSchema>;
type FacilityDetailsType = z.infer<typeof FacilityDetailsSchema>;
type VenuePermitsSchemaType = z.infer<typeof VenuePermitsSchema>;

const initialVenue: VenueType = {
  id: 0,
  name: "",
  startBuffer: 0,
  endBuffer: 0,
  intersection: "",
  address: "",
  city: "",
  zipCode: "",
  locationLink: "",
  latitude: 0,
  longitude: 0,
  parentId: null,
  stateId: 0,
  countryId: 0,
  vendorId: 0,
  regionId: 0,
  type: 0,
  size: 0,
  islights: false,
  // for interface
  // permit: false,
  facilityDescription: "",
  facilityImage: "",
  facilityDetails: [],
  facilitySetups: [],
};

const fileTypes = ["PDF"];

const CreateVenue = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const { venueTypes, regions, vendorList, states, countries } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const [venue, setVenue] = useState<VenueType>(initialVenue);
  const [isEditVenue, setIsEditVenue] = useState<string | null>(
    params.id ? params.id : null
  );
  const [venuePermits, setVenuePermits] = useState<VenuePermitsSchemaType[]>(
    []
  );
  const [newVenuePermit, setNewVenuePermit] = useState<{
    files: FileList | null;
    activeFrom: string;
    activeTo: string;
    name: string;
  }>({
    files: null,
    activeFrom: dayjs().format("YYYY-MM-DD"),
    activeTo: dayjs().format("YYYY-MM-DD"),
    name: "",
  });

  const [venueErrors, setVenueErrors] = useState<{
    [key: string]: string;
  }>({
    name: "",
    startBuffer: "",
    endBuffer: "",
    intersection: "",
    address: "",
    city: "",
    zipCode: "",
    locationLink: "",
    latitude: "",
    longitude: "",
    stateId: "",
    countryId: "",
    vendorId: "",
    regionId: "",
    type: "",
    size: "",
    islights: "",
    facilityDescription: "",
    facilityImage: "",
  });
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const [openVenueImageDialog, setOpenVenueImageDialog] = useState(false);
  const [venueImageDialogImageOrSetup, setVenueImageDialogImageOrSetup] =
    useState<"image" | "setup">("setup");
  const [selectedFacilitySetupIndex, setSelectedFacilitySetupIndex] =
    useState(0);

  /*** QUERIES ***/
  const { data: facilityDetailTypes } = useFacilityDetailTypesQuery();
  const [getVenueById, { data: venueById }] = useVenueByIdLazyQuery({
    onCompleted: (data) => {
      if (data && data.venueById) {
        setVenue({
          id: +data.venueById.id || undefined,
          venueDetailId: data.venueById.venueDetailId || undefined,
          name: data.venueById.name,
          startBuffer: data.venueById.startBuffer || 0,
          endBuffer: data.venueById.endBuffer || 0,
          intersection: data.venueById.venueDetail?.intersection || "",
          address: data.venueById.venueDetail?.address || "",
          city: data.venueById.venueDetail?.city || "",
          zipCode: data.venueById.venueDetail?.zipCode || "",
          locationLink: data.venueById.venueDetail?.locationLink || "",
          latitude: data.venueById.venueDetail?.latitude || 0,
          longitude: data.venueById.venueDetail?.longitude || 0,
          parentId: data.venueById.parentId || null,
          stateId: data.venueById.venueDetail?.stateId || 0,
          countryId: data.venueById.venueDetail?.countryId || 0,
          vendorId: data.venueById.venueDetail?.vendorId || 0,
          regionId: data.venueById.venueDetail?.regionId || 0,
          type: data.venueById.typeId || 0,
          size: data.venueById.sizeId || 0,
          islights: data.venueById.islights || false,
          facilityDescription:
            data.venueById.venueDetail?.facilityDescription || "",
          facilityImage: data.venueById.venueDetail?.facilityImage || "",
          facilityDetails:
            data.venueById.facilityDetails?.map((item) => {
              return {
                id: item?.id,
                name: item?.name || "",
                facilityDetailTypeId: item?.facilityDetailTypeId || 0,
                description: item?.description || "",
              };
            }) || [],
          facilitySetups:
            data.venueById.facilitySetups?.map((item) => {
              return {
                id: item?.id,
                name: item?.name || "",
                description: item?.description || "",
                imageUrl: item?.imageUrl || "",
              };
            }) || [],
        });
      }
    },
    fetchPolicy: "network-only",
  });

  const [getVenuePermitsByVenueId] = useGetVenuePermitsByVenueIdLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setVenuePermits(
        data.getVenuePermitsByVenueId.map((permit) => ({
          ...permit,
          activeFrom: dayjs(permit.activeFrom).toDate(),
          activeTo: dayjs(permit.activeTo).toDate(),
        }))
      );
    },
  });

  const { loading: loadingVenueSizes, data: venueSizeData } =
    useVenueSizeByTypeIdQuery({
      variables: {
        venueTypeId: venue.type,
      },
    });
  /*** MUTATIONS ***/
  const [CreateVenue] = useVenueCreateMutation();
  const [UpdateVenue] = useVenueUpdateMutation();
  const [deleteVenuePermit, { loading: loadingDeleteVenuePermit }] =
    useDeleteVenuePermitMutation({
      onCompleted: (data) => {
        if (data.deleteVenuePermit.success) {
          dispatch(displayAlertSuccess(data.deleteVenuePermit.message));
        } else {
          dispatch(displayAlertError(data.deleteVenuePermit.message));
        }
      },
      refetchQueries: [ListAllOperations.Query.GetVenuePermitsByVenueId],
    });
  const [createVenuePermit, { loading: loadingCreateVenuePermit }] =
    useCreateVenuePermitMutation({
      onCompleted: (data) => {
        if (data.createVenuePermit.success) {
          dispatch(displayAlertSuccess(data.createVenuePermit.message));
        } else {
          dispatch(displayAlertError(data.createVenuePermit.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
      refetchQueries: [ListAllOperations.Query.GetVenuePermitsByVenueId],
    });
  const [updateVenuePermit, { loading: loadingUpdateVenuePermit }] =
    useUpdateVenuePermitMutation({
      onCompleted: (data) => {
        if (data.updateVenuePermit.success) {
          dispatch(displayAlertSuccess(data.updateVenuePermit.message));
        } else {
          dispatch(displayAlertError(data.updateVenuePermit.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
      refetchQueries: [ListAllOperations.Query.GetVenuePermitsByVenueId],
    });

  const handleMutations = () => {
    const facilityDetails = venue.facilityDetails?.filter(
      (item) => item.name !== "" && item.description !== ""
    );

    const facilitySetups = venue.facilitySetups?.filter(
      (item) => item.name !== "" && item.description !== ""
    );

    if (isEditVenue) {
      UpdateVenue({
        variables: {
          venueArgs: {
            id: +isEditVenue,
            venueInput: {
              name: venue.name,
              startBuffer: venue.startBuffer,
              endBuffer: venue.endBuffer,
              parentId: venue.parentId,
              venueSetupId: venue.venueSetupId,
              typeId: venue.type,
              sizeId: venue.size,
              islights: venue.islights,
              venueDetailId: venue.venueDetailId,
            },
            venueDetailInput: {
              address: venue.address,
              city: venue.city,
              zipCode: venue.zipCode,
              locationLink: venue.locationLink,
              latitude: venue.latitude,
              longitude: venue.longitude,
              stateId: venue.stateId,
              countryId: venue.countryId,
              vendorId: venue.vendorId,
              regionId: venue.regionId,
              intersection: venue.intersection,
              facilityDescription: venue.facilityDescription,
              facilityImage: venue.facilityImage,
            },
            facilityDetailInput: facilityDetails,
            facilitySetupInput: facilitySetups,
          },
        },
      })
        .then((res) => {
          if (res.data?.venueUpdate.success) {
            setVenue(initialVenue);
            dispatch(displayAlertSuccess(res.data.venueUpdate.message));
            setIsEditVenue(null);
            navigate(`${publicUrl}/ops/venues`);
          } else {
            dispatch(displayAlertError(res.data?.venueUpdate.message));
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    } else {
      //Add image URL to facility image venue input
      CreateVenue({
        variables: {
          venueArgs: {
            venueInput: {
              name: venue.name,
              startBuffer: venue.startBuffer,
              endBuffer: venue.endBuffer,
              parentId: venue.parentId,
              typeId: venue.type,
              sizeId: venue.size,
              islights: venue.islights,
              venueDetailId: null,
            },
            venueDetailInput: {
              address: venue.address,
              city: venue.city,
              zipCode: venue.zipCode,
              locationLink: venue.locationLink,
              latitude: venue.latitude,
              longitude: venue.longitude,
              stateId: venue.stateId,
              countryId: venue.countryId,
              vendorId: venue.vendorId,
              regionId: venue.regionId,
              intersection: venue.intersection,
              facilityDescription: venue.facilityDescription,
              facilityImage: venue.facilityImage,
            },
            facilityDetailInput: facilityDetails,
            facilitySetupInput: facilitySetups,
          },
        },
      })
        .then((res) => {
          if (res.data?.venueCreate.success) {
            setVenue(initialVenue);
            dispatch(displayAlertSuccess(res.data.venueCreate.message));
            setIsEditVenue(null);
            navigate(`${publicUrl}/ops/venues`);
          } else {
            dispatch(displayAlertError(res.data?.venueCreate.message));
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    }
  };

  const handleSubmitVenue = () => {
    // VALIDATIONS
    // venue parent should not exist
    if (venue.parentId) {
      dispatch(displayAlertError("Parent Venue should not exist"));
      return;
    }

    const result = VenueSchema.safeParse(venue);
    if (!result.success) {
      const newFormErrors = { ...venueErrors };
      const newFormErrorKeys = Object.keys(newFormErrors);
      let messages: string[] = [];
      newFormErrorKeys.forEach((field) => {
        const fieldError = result.error.errors.find(
          (error) => error.path[0] === field
        );
        if (fieldError) {
          newFormErrors[field] = fieldError.message; // Set the error message
          messages.push(`Error ${field}: ${fieldError.message}`);
        } else {
          newFormErrors[field] = ""; // No matching error, set to an empty string
        }
      });
      setVenueErrors(newFormErrors);
      dispatch(displayAlertError("Please fill mandatory fields"));
      return;
    }
    handleMutations();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVenue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (!newVenuePermit || !newVenuePermit.files || !venue || !venue.id) {
      return;
    }

    formData.append("files", newVenuePermit.files[0]);

    try {
      const response = await multipartFetch.post("/upload", formData);
      await createVenuePermit({
        variables: {
          createVenuePermitInput: {
            venueId: venue.id,
            venuePermits: response.data.files.map(
              (file: {
                originalFileName: string;
                gcpFileName: string;
                url: string;
              }) => {
                return {
                  originalFileName: file.originalFileName,
                  gcpFileName: file.gcpFileName,
                  publicUrl: file.url,
                  activeFrom: newVenuePermit.activeFrom,
                  activeTo: newVenuePermit.activeTo,
                  name: newVenuePermit.name,
                };
              }
            ),
          },
        },
      });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  useEffect(() => {
    dispatch(getVenueTypes(""));
    dispatch(getVendors(""));
    dispatch(getCountries(""));
    dispatch(getStates(venue.countryId ? venue.countryId : undefined));

    if (isEditVenue) {
      getVenueById({
        variables: {
          id: +isEditVenue,
        },
      });
      getVenuePermitsByVenueId({
        variables: {
          id: +isEditVenue,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedPlace) {
      return;
    }
    const placeId = selectedPlace.place_id;
    if (selectedPlace.address_components) {
      const { streetAddress, city, province, postalCode, country } =
        extractAddressFields(selectedPlace);
      const countryId = countries.find((cnty) => cnty.name === country)?.id;
      const stateId = states.find((state) => state.name === province)?.id;
      setVenue((prevState) => ({
        ...prevState,
        address: streetAddress,
        city: city,
        zipCode: postalCode,
        countryId: countryId ?? 0,
        stateId: stateId ?? 0,
      }));
    }
    const lat = selectedPlace.geometry?.location?.lat();
    const lng = selectedPlace.geometry?.location?.lng();
    if (lat != undefined && lng != undefined) {
      setVenue((prevState) => ({
        ...prevState,
        latitude: lat,
        longitude: lng,
      }));
    }
    if (placeId) {
      const googleMapsLink = `https://www.google.com/maps/place/?q=place_id:${placeId}`;
      setVenue((prevState) => ({
        ...prevState,
        locationLink: googleMapsLink,
      }));
    }
  }, [selectedPlace]);

  const pathsBreadcrumbs = [
    { name: "Venue", url: "/ops/venues" },
    { name: "Venue Details", url: "/ops/venues" },
  ];

  return (
    <main className="flex flex-col gap-4">
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/venues"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>
          {isEditVenue ? "Edit Venue" : "Create New Venue"}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={handleSubmitVenue}
          >
            {isEditVenue ? (
              <span> Edit Venue</span>
            ) : (
              <span> Create Venue</span>
            )}
          </Button>
        </div>
      </div>
      <Card className="flex flex-col gap-4">
        <Subtitle1>Venue Details</Subtitle1>
        <div className="flex flex-col">
          <div className="flex flex-row w-full gap-4">
            <FormField
              initialValue={venue.name ? venue.name : ""}
              inputChange={handleChange}
              name="name"
              label="Venue Name"
              error={venueErrors.name ? true : false}
              assistiveText={venueErrors.name}
            ></FormField>
            {/* For top level Venue, parentID = null,
          For sub-Venue, params will decide the parentID
          In both cases, its not an input field, disabled
          */}
            <FormFieldDropdown
              initialValue={venue.parentId ? venue.parentId.toString() : "0"}
              inputChange={(value) => {}}
              name="parentId"
              label="Parent Venue"
              placeholder="Parent Venue"
              disabled={true}
            >
              {[{ id: 0, name: "None" }]}
            </FormFieldDropdown>
            <FormFieldSelect
              value={venue.type ? venue.type.toString() : "0"}
              inputChange={(value) => {
                if (value == "0") {
                  setVenue((prevState) => ({
                    ...prevState,
                    size: 0,
                  }));
                }
                setVenue((prevState) => {
                  return {
                    ...prevState,
                    type: parseInt(value),
                    size: 0,
                  };
                });
              }}
              name="venueType"
              label="Venue Type"
              placeholder="Venue Type"
              error={venueErrors.type ? true : false}
              assistiveText={venueErrors.type}
            >
              {[{ id: 0, name: "Select Venue Type" }, ...venueTypes]}
            </FormFieldSelect>
            <FormFieldSelect
              value={venue.size ? venue.size.toString() : "0"}
              loading={loadingVenueSizes}
              inputChange={(value) => {
                setVenue((prevState) => {
                  return {
                    ...prevState,
                    size: parseInt(value),
                  };
                });
              }}
              name="venueSize"
              label="Venue Size"
              placeholder="Venue Size"
              error={venueErrors.size ? true : false}
              assistiveText={venueErrors.size}
            >
              {[
                ...[
                  {
                    id: 0,
                    name:
                      venueSizeData === undefined ||
                      venueSizeData.venueSizeByTypeId.length === 0
                        ? "Select Venue Type First"
                        : "Select Venue Size",
                  },
                ],
                ...(venueSizeData !== undefined
                  ? venueSizeData.venueSizeByTypeId.map((venueSize) => {
                      return {
                        id: venueSize.id,
                        name: venueSize.name,
                      };
                    })
                  : []),
              ]}
            </FormFieldSelect>
          </div>
          <div className="flex flex-row w-full gap-4">
            <FormFieldDropdown
              initialValue={venue.islights ? "1" : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  islights: value === "1" ? true : false,
                }));
              }}
              name="islights"
              label="Lights"
              placeholder="Lights"
              error={venueErrors.islights ? true : false}
            >
              {[
                { id: 0, name: "No" },
                { id: 1, name: "Yes" },
              ]}
            </FormFieldDropdown>
            {/* For top level Venue, parentID = null,
          For sub-Venue, params will decide the parentID
          In both cases, its not an input field, disabled
          */}
            <FormFieldDropdown
              initialValue={venue.parentId ? venue.parentId.toString() : "0"}
              inputChange={(value) => {}}
              name="venueSetup"
              label="Venue Setup"
              placeholder="Venue Setup"
              disabled={true}
            >
              {[{ id: 0, name: "None" }]}
            </FormFieldDropdown>
            <FormField
              initialValue={
                venue.startBuffer ? venue.startBuffer.toString() : "0"
              }
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVenue((prevState) => ({
                  ...prevState,
                  [e.target.name]: parseInt(e.target.value),
                }));
              }}
              name="startBuffer"
              label="Start Buffer"
              error={venueErrors.startBuffer ? true : false}
              assistiveText={venueErrors.startBuffer}
            ></FormField>
            <FormField
              initialValue={venue.endBuffer ? venue.endBuffer.toString() : "0"}
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVenue((prevState) => ({
                  ...prevState,
                  [e.target.name]: parseInt(e.target.value),
                }));
              }}
              name="endBuffer"
              label="End Buffer"
              error={venueErrors.endBuffer ? true : false}
              assistiveText={venueErrors.endBuffer}
            ></FormField>
          </div>
        </div>
      </Card>
      <Card className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <Subtitle1>Facility Information</Subtitle1>
          <div className="flex flex-row w-full gap-4">
            <FormFieldDropdown
              initialValue={venue.regionId ? venue.regionId.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  regionId: parseInt(value),
                }));
              }}
              name="regionId"
              label="Region"
              placeholder="Region"
              error={venueErrors.regionId ? true : false}
              assistiveText={venueErrors.regionId}
            >
              {[{ id: 0, name: "Select Region" }, ...regions]}
            </FormFieldDropdown>
            <FormFieldDropdown
              initialValue={venue.vendorId ? venue.vendorId.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  vendorId: parseInt(value),
                }));
              }}
              name="vendorId"
              label="Vendor"
              placeholder="Vendor"
              error={venueErrors.vendorId ? true : false}
              assistiveText={venueErrors.vendorId}
            >
              {[{ id: 0, name: "Select Vendor" }, ...vendorList]}
            </FormFieldDropdown>
            <FormFieldDropdown
              initialValue={venue.permit ? "1" : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  permit: value === "1" ? true : false,
                }));
              }}
              name="permit"
              label="Permit?"
              placeholder="Permit?"
            >
              {[
                { id: 0, name: "No" },
                { id: 1, name: "Yes" },
              ]}
            </FormFieldDropdown>
            <FormField
              initialValue={venue.intersection ? venue.intersection : ""}
              inputChange={handleChange}
              name="intersection"
              label="Intersection"
              error={venueErrors.intersection ? true : false}
              assistiveText={venueErrors.intersection}
            ></FormField>
          </div>
          <div className="flex flex-col w-1/2 pr-4">
            <Caption1>Address Auto Complete</Caption1>
            <AutoCompleteAddressFormField setSelectedPlace={setSelectedPlace} />
          </div>
          <div className="flex flex-row w-full gap-4">
            <FormField
              initialValue={venue.address ? venue.address : ""}
              inputChange={handleChange}
              name="address"
              label="Street Address"
              error={venueErrors.address ? true : false}
              assistiveText={venueErrors.address}
            ></FormField>
            <FormField
              initialValue={venue.city ? venue.city : ""}
              inputChange={handleChange}
              name="city"
              label="City"
              error={venueErrors.city ? true : false}
              assistiveText={venueErrors.city}
            ></FormField>
            <FormFieldDropdown
              initialValue={venue.stateId ? venue.stateId.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  stateId: parseInt(value),
                }));
              }}
              name="stateId"
              label="Province"
              placeholder="Province/State"
              error={venueErrors.stateId ? true : false}
              assistiveText={venueErrors.stateId}
            >
              {[{ id: 0, name: "Select Province" }, ...states]}
            </FormFieldDropdown>
            <FormField
              initialValue={venue.zipCode ? venue.zipCode : ""}
              inputChange={handleChange}
              name="zipCode"
              label="Postal Code"
              error={venueErrors.zipCode ? true : false}
              assistiveText={venueErrors.zipCode}
            ></FormField>
            <FormFieldDropdown
              initialValue={venue.countryId ? venue.countryId.toString() : "0"}
              inputChange={(value) => {
                setVenue((prevState) => ({
                  ...prevState,
                  countryId: parseInt(value),
                }));
                dispatch(getStates(value ? value : null)).then((res: any) => {
                  const isStateExist = res.payload.find((item: any) => {
                    return item.id == venue.stateId;
                  });

                  if (!isStateExist) {
                    setVenue((prevState) => ({
                      ...prevState,
                      stateId: 0,
                    }));
                  }
                });
              }}
              name="countryId"
              label="Country"
              placeholder="Country"
              error={venueErrors.countryId ? true : false}
              assistiveText={venueErrors.countryId}
            >
              {[{ id: 0, name: "Select Country" }, ...countries]}
            </FormFieldDropdown>
          </div>
        </div>
      </Card>
      <Card className="flex flex-col gap-4">
        <Subtitle1>Maps Information</Subtitle1>
        <Subtitle2 className="max-w-full">
          {`This Latitude & Longitude input is what controls where leagues are
          located in the discovery experience. Venues are linked at the league
          and session level, and the distance is calculated from this lat/long
          position. 
          This is probably the most important section.`}
        </Subtitle2>
        <div className="flex flex-row w-full gap-4">
          <FormField
            initialValue={venue.latitude ? venue.latitude.toString() : "0"}
            inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setVenue((prevState) => ({
                ...prevState,
                [e.target.name]: parseFloat(e.target.value),
              }));
            }}
            name="latitude"
            label="Latitude"
            width="100px"
            error={venueErrors.latitude ? true : false}
            assistiveText={venueErrors.latitude}
          ></FormField>
          <FormField
            initialValue={venue.longitude ? venue.longitude.toString() : "0"}
            inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setVenue((prevState) => ({
                ...prevState,
                [e.target.name]: parseFloat(e.target.value),
              }));
            }}
            name="longitude"
            label="Longitude"
            width="100px"
            error={venueErrors.longitude ? true : false}
            assistiveText={venueErrors.longitude}
          ></FormField>
          <FormField
            initialValue={
              venue.locationLink ? venue.locationLink.toString() : ""
            }
            inputChange={handleChange}
            name="locationLink"
            label="Direct Link"
          ></FormField>
        </div>
      </Card>
      <Card className="flex flex-col gap-4">
        <Subtitle1>Facility Image</Subtitle1>
        <div className="flex flex-row gap-4 lg:w-1/4 md:1/3">
          <div className="flex flex-row gap-2">
            {!venue.facilityImage && (
              <div className="flex flex-col gap-2">
                <div className="w-[120px] h-[120px] bg-gray-300 rounded-2xl flex items-center justify-center">
                  <FmdGoodIcon fontSize="large" />
                </div>
              </div>
            )}
            {venue.facilityImage && (
              <img
                className="mr-2 rounded-sm bg-neutral-80"
                src={venue.facilityImage}
                alt="image"
                width={250}
                height={250}
              />
            )}
            <Button
              variant={"secondary"}
              onClick={() => {
                setOpenVenueImageDialog(true);
                setVenueImageDialogImageOrSetup("image");
              }}
              className="h-fit whitespace-nowrap"
            >
              <div className="text-center">Select Image</div>
            </Button>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4">
          <TextArea
            name="facilityDescription"
            label="Facility Description"
            inputChange={handleChange}
            initialValue={venue.facilityDescription}
            rows={8}
            cols={50}
          />
        </div>
      </Card>
      <Card className="flex flex-col gap-4">
        <div className="flex justify-between ">
          <Subtitle1>Facility Details</Subtitle1>
          <Button
            variant="secondary"
            onClick={() => {
              setVenue((prevState) => ({
                ...prevState,
                facilityDetails: [
                  ...(prevState.facilityDetails || []),
                  {
                    name: "",
                    facilityDetailTypeId: 0,
                    description: "",
                  },
                ],
              }));
            }}
          >
            <AddOutlined />
            Add Detail
          </Button>
        </div>
        {venue.facilityDetails &&
          venue.facilityDetails.map((facilityDetail, index) => (
            <>
              <Card className="flex gap-3 p-4 mb-2 border rounded-md">
                <div className="flex flex-col w-1/3 gap-2">
                  <FormField
                    initialValue={facilityDetail.name}
                    inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setVenue((prevState) => ({
                        ...prevState,
                        facilityDetails: prevState.facilityDetails?.map(
                          (item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                name: e.target.value,
                              };
                            }
                            return item;
                          }
                        ),
                      }));
                    }}
                    name="name"
                    label="Detail Name"
                    disabled={!!facilityDetail.isDeleted}
                  ></FormField>
                  <FormFieldDropdown
                    initialValue={facilityDetail.facilityDetailTypeId.toString()}
                    inputChange={(value) => {
                      setVenue((prevState) => ({
                        ...prevState,
                        facilityDetails: prevState.facilityDetails?.map(
                          (item) => {
                            if (item.name === facilityDetail.name) {
                              return {
                                ...item,
                                facilityDetailTypeId: parseInt(value),
                              };
                            }
                            return item;
                          }
                        ),
                      }));
                    }}
                    name="facilityDetailTypeId"
                    label="Detail Type"
                    placeholder="Type"
                    disabled={!!facilityDetail.isDeleted}
                  >
                    {[
                      { id: 0, name: "Select Type" },
                      ...(facilityDetailTypes?.facilityDetailTypes || []),
                    ]}
                  </FormFieldDropdown>
                </div>
                <div className="w-full">
                  <div className="px-3 text-xs font-medium">
                    Detail Description
                  </div>
                  <Textarea
                    className="w-full p-2 border border-secondary-80 rounded-xl hover:border-secondary-50 focus:outline-2 focus:outline-primary-80"
                    placeholder="Enter details here"
                    value={facilityDetail.description}
                    disabled={!!facilityDetail.isDeleted}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setVenue((prevState) => ({
                        ...prevState,
                        facilityDetails: prevState.facilityDetails?.map(
                          (item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                description: e.target.value,
                              };
                            }
                            return item;
                          }
                        ),
                      }));
                    }}
                  />
                </div>
                <div className="pt-3">
                  <Button
                    variant="secondary"
                    style={{ padding: "0.5rem" }}
                    onClick={() => {
                      if (facilityDetail.id) {
                        setVenue((prevState) => ({
                          ...prevState,
                          facilityDetails: prevState.facilityDetails?.map(
                            (item) => {
                              if (item.name !== facilityDetail.name) {
                                return item;
                              } else {
                                return {
                                  ...item,
                                  isDeleted: !item.isDeleted,
                                };
                              }
                            }
                          ),
                        }));
                      } else {
                        setVenue((prevState) => ({
                          ...prevState,
                          facilityDetails: prevState.facilityDetails?.filter(
                            (item) => item.name !== facilityDetail.name
                          ),
                        }));
                      }
                    }}
                  >
                    {facilityDetail.isDeleted ? (
                      <UndoOutlined />
                    ) : (
                      <DeleteOutlineOutlinedIcon />
                    )}
                  </Button>
                </div>
              </Card>
            </>
          ))}
      </Card>
      <Card className="flex flex-col gap-4">
        <div className="flex justify-between ">
          <Subtitle1>Facility Setup</Subtitle1>
          <Button
            variant="secondary"
            onClick={() => {
              setVenue((prevState) => ({
                ...prevState,
                facilitySetups: [
                  ...(prevState.facilitySetups || []),
                  {
                    name: "",
                    description: "",
                    imageUrl: "",
                  },
                ],
              }));
            }}
          >
            <AddOutlined />
            Add Setup
          </Button>
        </div>
        {venue.facilitySetups &&
          venue.facilitySetups.map((facilitySetups, index) => (
            <>
              <Card className="flex justify-between gap-3 p-4 mb-2 border rounded-md">
                <div className="flex flex-col gap-2">
                  {!facilitySetups.imageUrl && (
                    <div className="flex flex-col gap-2">
                      <div className="w-[120px] h-[120px] bg-gray-300 rounded-2xl flex items-center justify-center">
                        <FmdGoodIcon fontSize="large" />
                      </div>
                    </div>
                  )}
                  {facilitySetups.imageUrl && (
                    <img
                      className="mr-2 rounded-sm bg-neutral-80"
                      src={facilitySetups.imageUrl}
                      alt="image"
                      width={250}
                      height={250}
                    />
                  )}
                  <Button
                    variant={
                      facilitySetups.isDeleted ? "disabled" : "secondary"
                    }
                    onClick={() => {
                      setOpenVenueImageDialog(true);
                      setVenueImageDialogImageOrSetup("setup");
                      setSelectedFacilitySetupIndex(index);
                    }}
                    disabled={!!facilitySetups.isDeleted}
                  >
                    <div className="text-center">Select Image</div>
                  </Button>
                </div>
                <div className="flex flex-col w-full gap-2">
                  <FormField
                    initialValue={facilitySetups.name}
                    inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setVenue((prevState) => ({
                        ...prevState,
                        facilitySetups: prevState.facilitySetups?.map(
                          (item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                name: e.target.value,
                              };
                            }
                            return item;
                          }
                        ),
                      }));
                    }}
                    name="name"
                    label="Setup Name"
                    disabled={!!facilitySetups.isDeleted}
                  ></FormField>
                  <div className="w-full">
                    <div className="px-3 text-xs font-medium">
                      Setup Description
                    </div>
                    <Textarea
                      className="w-full p-2 border border-secondary-80 rounded-xl hover:border-secondary-50 focus:outline-2 focus:outline-primary-80"
                      placeholder="Enter details here"
                      value={facilitySetups.description}
                      disabled={!!facilitySetups.isDeleted}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setVenue((prevState) => ({
                          ...prevState,
                          facilitySetups: prevState.facilitySetups?.map(
                            (item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  description: e.target.value,
                                };
                              }
                              return item;
                            }
                          ),
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="pt-3">
                  <Button
                    variant="secondary"
                    style={{ padding: "0.5rem" }}
                    onClick={() => {
                      if (facilitySetups.id) {
                        setVenue((prevState) => ({
                          ...prevState,
                          facilitySetups: prevState.facilitySetups?.map(
                            (item) => {
                              if (item.name !== facilitySetups.name) {
                                return item;
                              } else {
                                return {
                                  ...item,
                                  isDeleted: !item.isDeleted,
                                };
                              }
                            }
                          ),
                        }));
                      } else {
                        setVenue((prevState) => ({
                          ...prevState,
                          facilitySetups: prevState.facilitySetups?.filter(
                            (item) => item.name !== facilitySetups.name
                          ),
                        }));
                      }
                    }}
                  >
                    {facilitySetups.isDeleted ? (
                      <UndoOutlined />
                    ) : (
                      <DeleteOutlineOutlinedIcon />
                    )}
                  </Button>
                </div>
              </Card>
            </>
          ))}
      </Card>
      <Card className="flex flex-col gap-4">
        <Headline2Variable>Permits</Headline2Variable>
        {venue.id ? (
          <>
            <div className="flex flex-col gap-4">
              {venuePermits.map((permit) => {
                return (
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center h-full">
                      <a
                        className="underline transition-colors cursor-pointer hover:text-info-80 text-info-70"
                        href={`${permit.publicUrl}`}
                      >
                        <Subtitle1>{permit.originalFileName}</Subtitle1>
                      </a>
                    </div>
                    <div className="flex flex-row items-end justify-start gap-4">
                      <DateRangePicker
                        label="Active Range"
                        classNameTrigger="w-min"
                        onUpdate={async (values) => {
                          await updateVenuePermit({
                            variables: {
                              updateVenuePermitInput: {
                                venuePermitId: permit.id!,
                                activeFrom: dayjs(values.range.from).format(
                                  "YYYY-MM-DD"
                                ),
                                activeTo: dayjs(values.range.to).format(
                                  "YYYY-MM-DD"
                                ),
                                name: permit.name,
                              },
                            },
                          });
                        }}
                        initialDateFrom={dayjs(permit.activeFrom).format(
                          "YYYY-MM-DD"
                        )}
                        initialDateTo={dayjs(permit.activeTo).format(
                          "YYYY-MM-DD"
                        )}
                        align="start"
                        showCompare={false}
                        nextOrLast="next"
                      />
                      <FormField
                        inputChange={(event) =>
                          setVenuePermits((prevState) => {
                            return prevState.map((permitItem, permitIndex) => {
                              if (permitItem.id === permit.id) {
                                return {
                                  ...permitItem,
                                  name: event.target.value,
                                };
                              }
                              return permitItem;
                            });
                          })
                        }
                        initialValue={permit.name}
                        id="permitName"
                        name="permitName"
                        label="Player Visible Name"
                      />
                      <Button
                        variant={
                          loadingUpdateVenuePermit ? "disabled" : "secondary"
                        }
                        onClick={async () => {
                          await updateVenuePermit({
                            variables: {
                              updateVenuePermitInput: {
                                venuePermitId: permit.id!,
                                activeFrom: dayjs(permit.activeFrom).format(
                                  "YYYY-MM-DD"
                                ),
                                activeTo: dayjs(permit.activeTo).format(
                                  "YYYY-MM-DD"
                                ),
                                name: permit.name,
                              },
                            },
                          });
                        }}
                        className="whitespace-nowrap"
                        disabled={loadingUpdateVenuePermit}
                      >
                        <div className="flex flex-row gap-1">
                          Update Name
                          {loadingUpdateVenuePermit && (
                            <Loader2 className="animate-spin" />
                          )}
                        </div>
                      </Button>
                      {permit.id != undefined && (
                        <Dialog>
                          <DialogTrigger asChild>
                            <Button
                              variant={
                                loadingDeleteVenuePermit
                                  ? "disabled"
                                  : "secondary"
                              }
                              disabled={loadingDeleteVenuePermit}
                            >
                              <div className="flex flex-row gap-1">
                                {loadingDeleteVenuePermit ? (
                                  <Loader2 className="animate-spin" />
                                ) : (
                                  <Trash2 />
                                )}
                              </div>
                            </Button>
                          </DialogTrigger>
                          <DialogContent className="max-w-96">
                            <DialogHeader>
                              <DialogTitle>Deleting Attachment</DialogTitle>
                            </DialogHeader>
                            <DialogDescription>
                              <div className="flex flex-col gap-4">
                                <Body1>
                                  Are you sure you want to delete the attachment
                                </Body1>
                                <Body1>{permit.originalFileName}</Body1>
                              </div>
                            </DialogDescription>
                            <DialogClose>
                              <div className="flex flex-row justify-between w-full gap-4">
                                <Button
                                  className="w-full"
                                  variant="negative"
                                  onClick={() => {
                                    deleteVenuePermit({
                                      variables: {
                                        id: permit.id!,
                                      },
                                    });
                                  }}
                                >
                                  Delete
                                </Button>
                                <Button
                                  className="w-full"
                                  variant="secondary"
                                >
                                  Close
                                </Button>
                              </div>
                            </DialogClose>
                          </DialogContent>
                        </Dialog>
                      )}
                    </div>

                    <Divider className="pt-2" />
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col items-start w-full gap-4">
              <Dialog
                onOpenChange={() => {
                  setNewVenuePermit({
                    files: null,
                    activeFrom: dayjs().format("YYYY-MM-DD"),
                    activeTo: dayjs().format("YYYY-MM-DD"),
                    name: "",
                  });
                }}
              >
                <DialogTrigger asChild>
                  <Button variant="secondary">Add New Permit(s)</Button>
                </DialogTrigger>
                <DialogContent className="max-w-96 top-[60%]">
                  <DialogHeader>
                    <DialogTitle>New Venue Permit(s)</DialogTitle>
                  </DialogHeader>
                  <DialogDescription>
                    <div className="flex flex-col gap-4">
                      <Body1>
                        Select the files and active date range for permits at
                        this venue
                      </Body1>
                      <div className="max-w-lg">
                        <FileUploader
                          multiple={true}
                          handleChange={(fileList: FileList) => {
                            setNewVenuePermit((prevState) => ({
                              ...prevState,
                              files: fileList,
                            }));
                          }}
                          name="file"
                          types={fileTypes}
                        />
                      </div>
                      <DateRangePicker
                        label="Active Range"
                        onUpdate={async (values) => {
                          setNewVenuePermit((prevState) => ({
                            ...prevState,
                            activeFrom: dayjs(values.range.from).format(
                              "YYYY-MM-DD"
                            ),
                            activeTo: dayjs(values.range.to).format(
                              "YYYY-MM-DD"
                            ),
                          }));
                        }}
                        initialDateFrom={newVenuePermit.activeFrom}
                        initialDateTo={newVenuePermit.activeTo}
                        align="start"
                        showCompare={false}
                        nextOrLast="next"
                        classNameTrigger="w-full"
                      />
                      <FormField
                        inputChange={(event) =>
                          setNewVenuePermit((prevState) => ({
                            ...prevState,
                            name: event.target.value,
                          }))
                        }
                        initialValue={newVenuePermit.name}
                        id="newVenuePermitName"
                        name="newVenuePermitName"
                        label="Player Visible Name"
                      />
                    </div>
                  </DialogDescription>
                  <DialogClose>
                    <div className="flex flex-row justify-between w-full gap-4">
                      <Button
                        variant="secondary"
                        className="w-full"
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        className="w-full"
                        onClick={(e) => {
                          if (!newVenuePermit.files) {
                            e.preventDefault();
                            dispatch(
                              displayAlertWarning(
                                "No files submitted for upload"
                              )
                            );
                            return;
                          }
                          if (
                            newVenuePermit.activeFrom > newVenuePermit.activeTo
                          ) {
                            e.preventDefault();
                            dispatch(
                              displayAlertWarning(
                                "Active from date must be before To date."
                              )
                            );
                            return;
                          }
                          if (newVenuePermit.name === "") {
                            e.preventDefault();
                            dispatch(
                              displayAlertWarning(
                                "Player visible name cannot be empty"
                              )
                            );
                            return;
                          }
                          if (newVenuePermit.files.length > 1) {
                            e.preventDefault();
                            dispatch(
                              displayAlertWarning(
                                "Please upload one file at a time"
                              )
                            );
                            return;
                          }
                          handleUpload();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </DialogClose>
                </DialogContent>
              </Dialog>
            </div>
          </>
        ) : (
          <Disclaimer
            variant="info"
            persist={true}
            size="large"
            content="Please save the venue before adding permits."
          />
        )}
      </Card>
      <div className="flex justify-end">
        <Button
          variant="primary"
          onClick={handleSubmitVenue}
        >
          {isEditVenue ? <span> Edit Venue</span> : <span> Create Venue</span>}
        </Button>
      </div>
      <VenueImagesDialog
        open={openVenueImageDialog}
        onOpenChange={(value) => {
          setOpenVenueImageDialog(value);
        }}
        handleApplyImage={(imageUrl) => {
          if (venueImageDialogImageOrSetup === "setup") {
            setVenue((prevState) => ({
              ...prevState,
              facilitySetups: prevState.facilitySetups?.map((item, i) => {
                if (i === selectedFacilitySetupIndex) {
                  return {
                    ...item,
                    imageUrl: imageUrl,
                  };
                }
                return item;
              }),
            }));
            setSelectedFacilitySetupIndex(0);
          } else if (venueImageDialogImageOrSetup === "image") {
            setVenue((prevState) => ({
              ...prevState,
              facilityImage: imageUrl,
            }));
          }
        }}
      />
    </main>
  );
};

export default CreateVenue;
