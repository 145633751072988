import {
  Table as TableBase,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Column, useTable } from "react-table";
import dayjs from "dayjs";
import { Checkbox } from "../../UI/shadcn/checkbox";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import ActionsCell from "../../UI/ActionCell";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { ordinal } from "./ScheduleNew";
import Body1 from "../../UI/Text/Body/Body1";
import { GameStatus } from "@/src/types/types";
import TooltipMUI from "@mui/material/Tooltip";
import {
  Team,
  VenuesTreeQuery,
  useIsGameSpaceAvailableLazyQuery,
  useIsGameSpaceAvailableQuery,
} from "../../../generated/graphql";
import { RegularGame } from "./ScheduleTypes";
import { TeamList } from "./ScheduleNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "../../UI/Button/Button";
import UndoIcon from "@mui/icons-material/Undo";
import { isLocalDateTimePastCurrentTime } from "../../../utils/timeFunctions";
import { checkGameToGameslotAllocation } from "../../../utils/gameslotChecks";
import { useScheduleGameslotContext } from "../../../context/ScheduleGameslotContext";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Filter2OutlinedIcon from "@mui/icons-material/Filter2Outlined";
import EventRepeatOutlinedIcon from "@mui/icons-material/EventRepeatOutlined";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";

interface collapseVenueProps {
  teams: TeamList[];
  venues: VenuesTreeQuery["venuesTree"];
  handleChange: (
    index: number,
    column: keyof RegularGame,
    value: string
  ) => void;
  games: RegularGame[];
  isEdit: boolean;
  gameStatuses: GameStatus[];
  isPublishedSchedule: boolean;
  handleGameAction: (
    type: "Reschedule" | "Game Status" | "Score Standings",
    gameId: number
  ) => void;
  handleRemoveGame: (index: number) => void;
  regionId: number;
  duration: number;
}

interface GameScheduleType extends RegularGame {
  isPastGame?: boolean;
}

interface RegularGameExtended extends RegularGame {
  checkGames?: {
    icon: "time" | "error" | "filter2" | "none";
    text: string[];
  };
}

export function matchupValueToId(
  value: number | null,
  type: number | null
): string | null {
  if (value == null) {
    return null;
  }
  if (type == 1) {
    return `${value} Place`;
  } else if (type == 2) {
    return `${value < 0 ? "Loser" : "Winner"} Game ${
      value < 0 ? value * -1 + (value * -1 - 1) : value + value - 2
    }`;
  } else if (type == 3) {
    return value.toString();
  }
  return null;
}

export function matchupValueToName(
  value: number | null,
  type: number | null
): string | null {
  if (value == null) {
    return null;
  }
  if (type == 1) {
    return `${ordinal(value + 1)} Place Place`;
  } else if (type == 2) {
    return `${value < 0 ? "Loser" : "Winner"} Game ${Math.abs(value)}`;
  } else if (type == 3) {
    return value.toString();
  }
  return null;
}

const hours = Array.from({ length: 24 }, (_, i) => i + 1);
const minutes = Array.from({ length: 60 / 5 }, (_, i) => i * 5);

const GameScheduleNew: React.FC<collapseVenueProps> = (props) => {
  const {
    teams,
    venues,
    games: regularGames,
    isEdit,
    handleChange,
    gameStatuses,
    isPublishedSchedule,
    handleGameAction,
    handleRemoveGame,
    regionId,
    duration,
  } = props;

  const { gameslots } = useScheduleGameslotContext();

  const options: {
    id: number;
    text: string;
    type: "Reschedule" | "Game Status" | "Score Standings";
  }[] = [
    { id: 0, text: "Reschedule", type: "Reschedule" },
    { id: 1, text: "Change Game Status", type: "Game Status" },
    { id: 2, text: "Adjust Score Standings", type: "Score Standings" },
  ];

  const activeTeams = teams.filter((team) => !team.inactive);

  const [games, setGames] = useState<RegularGameExtended[]>(regularGames);
  const [getIsGameSpaceAvailable] = useIsGameSpaceAvailableLazyQuery({
    fetchPolicy: "network-only",
  });

  const checkIsGameSpaceAvailable = async (
    startDateTimeLocal: string,
    endDateTimeLocal: string,
    venueId: number,
    gameDuration: number,
    gameId?: number
  ): Promise<boolean> => {
    const gameStart = startDateTimeLocal;
    const gameEnd = endDateTimeLocal;

    const game = await getIsGameSpaceAvailable({
      variables: {
        startDateTimeLocal: gameStart,
        endDateTimeLocal: gameEnd,
        venueId: venueId,
        duration: gameDuration,
        gameId: gameId,
      },
    });
    return game.data?.isGameSpaceAvailable || false;
  };
  const previousGamesRef = useRef<RegularGameExtended[]>(regularGames); // Store the previous state

  useEffect(() => {
    // Define the async function inside useEffect
    const loadData = async () => {
      const newGames = await Promise.all(
        regularGames.map(async (game, index) => {
          // Track if game has changed (you can customize this logic)
          const isUpdated =
            game.startDateTimeLocal !==
              previousGamesRef.current[index]?.startDateTimeLocal ||
            game.venueId !== previousGamesRef.current[index]?.venueId ||
            !previousGamesRef.current[index]?.checkGames;

          if (isUpdated && game.startDateTimeLocal && game.venueId) {
            const checkGames: {
              icon: "time" | "error" | "filter2" | "none";
              text: string[];
            } = {
              icon: "none",
              text: [],
            };

            // If the game has been updated, proceed with the async checks
            const checkGameslotAvailable = checkGameToGameslotAllocation(
              gameslots,
              {
                startDateTimeLocal: game.startDateTimeLocal,
                duration: duration,
                venueId: game.venueId,
              }
            );

            const endDate = dayjs(game.startDateTimeLocal)
              .add(duration, "minutes")
              .format("YYYY-MM-DD HH:mm:ss");
            const checkGameSpaceAvailable = await checkIsGameSpaceAvailable(
              game.startDateTimeLocal,
              endDate,
              game.venueId,
              duration,
              game.id
            );
            if (!checkGameslotAvailable) {
              checkGames.icon = "time";
              checkGames.text.push(
                "Couldn't find any gameslots available for this game time and venue."
              );
            }
            if (!checkGameSpaceAvailable) {
              checkGames.icon = "error";
              checkGames.text.push(
                "There is a game already scheduled at this time and venue."
              );
            }
            if (!checkGameslotAvailable && !checkGameSpaceAvailable)
              checkGames.icon = "filter2";
            return {
              ...game,
              checkGames: checkGames,
            };
          } else {
            return {
              ...game,
              checkGames: previousGamesRef.current[index]?.checkGames,
            };
          }
        })
      );
      // Update the state with the new games
      setGames(newGames);
      previousGamesRef.current = newGames; // Update the ref to the current state
    };
    loadData();
  }, [regularGames]);

  const handleChangeRow = (
    index: number,
    column: keyof RegularGame,
    value: string
  ) => {
    handleChange(index, column, value);
  };

  const COLUMNS: Column<RegularGameExtended>[] = [
    {
      Header: "GameId",
      id: "id",
      accessor: (d: RegularGameExtended) => {
        const status: string =
          gameStatuses.find((status) => status.id == d.gameStatusId)?.name ||
          "";
        return (
          <TooltipMUI
            title={!d.checkGames ? status : d.checkGames.text.join("\n")}
            placement="top"
          >
            <div className="flex gap-2">
              <p>{d.id}</p>
              {d.gameStatusId == 2 && <HistoryToggleOffIcon />}
              {d.gameStatusId == 3 && <EventBusyIcon />}
              {d.gameStatusId == 4 && <EventRepeatOutlinedIcon />}
              {d.checkGames && d.checkGames.icon !== "none" && (
                <>
                  {d.checkGames.icon === "time" && (
                    <MoreTimeOutlinedIcon className="text-warning-50" />
                  )}
                  {d.checkGames.icon === "error" && (
                    <ErrorOutlineOutlinedIcon className="text-error-50" />
                  )}
                  {d.checkGames.icon === "filter2" && (
                    <Filter2OutlinedIcon className="text-error-50" />
                  )}
                </>
              )}
            </div>
          </TooltipMUI>
        );
      },
    },
    {
      Header: "Date",
      id: "date",
      accessor: (d: RegularGame) => {
        return (
          <div style={{ width: "100px" }}>
            {dayjs(d.startDateTimeLocal).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      Header: "Start Time",
      id: "startDateTimeLocal",
      accessor: (d: GameScheduleType, index: number) => {
        const date = new Date(d.startDateTimeLocal);
        const dateValue = dayjs(date);
        const hour = dateValue.hour();
        return (
          <div className="flex gap-1">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                let newHour = value;
                handleChangeRow(
                  index,
                  "startDateTimeLocal",
                  dayjs(dateValue.hour(newHour)).format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              name="hour"
              label="Hour"
              placeholder="hour"
              value={hour.toString()}
              disabled={d.isPastGame || d.isDeleted || false}
            >
              {hours.map((h) => ({ id: h.toString(), name: h }))}
            </FormFieldSelect>
            <div className="pt-5">:</div>
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                let newMinute = value;
                handleChangeRow(
                  index,
                  "startDateTimeLocal",
                  dayjs(dateValue.minute(newMinute)).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                );
              }}
              name="minutes"
              label="Minutes"
              placeholder="minutes"
              value={dateValue.minute().toString()}
              disabled={d.isPastGame || d.isDeleted || false}
            >
              {minutes.map((h) => ({ id: h.toString(), name: h }))}
            </FormFieldSelect>
          </div>
        );
      },
    },
    {
      Header: "Venue",
      id: "venue",
      accessor: (d: GameScheduleType, index: number) => {
        return (
          <div className="w-[300px] max-w-[400px]">
            <FormFieldSelect
              inputChange={(value) => {
                handleChangeRow(index, "venueId", value);
              }}
              placeholder="Venue"
              value={d.venueId.toString()}
              disabled={d.isPastGame || d.isDeleted || false}
            >
              {venues.map((venue) => {
                return {
                  id: venue.id,
                  name: venue.name,
                };
              })}
            </FormFieldSelect>
          </div>
        );
      },
    },
    {
      Header: "Matchup",
      id: "matchup",
      accessor: (d: GameScheduleType, index: number) => {
        return (
          <div className="flex flex-row items-center gap-2">
            <div>
              <FormFieldSelect
                inputChange={(value) => {
                  handleChangeRow(index, "homeTeamId", value);
                }}
                placeholder="Team"
                value={d.homeTeamId?.toString() || ""}
                disabled={d.isPastGame || d.isDeleted || false}
              >
                {d.isPastGame || d.isDeleted ? teams : activeTeams}
              </FormFieldSelect>
            </div>

            <Body1>VS</Body1>

            <div>
              <FormFieldSelect
                inputChange={(value) => {
                  handleChangeRow(index, "awayTeamId", value);
                }}
                placeholder="Team"
                value={d.awayTeamId?.toString() || ""}
                disabled={d.isPastGame || d.isDeleted || false}
              >
                {d.isPastGame || d.isDeleted ? teams : activeTeams}
              </FormFieldSelect>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Player Visibility",
      id: "isVisible",
      accessor: (d: GameScheduleType, index: number) => {
        return (
          <div className="flex flex-row items-center justify-center">
            <Checkbox
              checked={d.isVisible}
              className={
                "hover:bg-secondary-opacity-08 flex h-[20px] w-[20px] min-w-[20px] min-h-[20px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
              }
              onCheckedChange={(event) => {
                handleChangeRow(index, "isVisible", event ? "true" : "false");
              }}
              disabled={d.isPastGame || d.isDeleted || false}
            />
          </div>
        );
      },
    },
    {
      Header: "Double Header",
      id: "isDoubleHeader",
      accessor: (d: GameScheduleType, index: number) => {
        return (
          <div className="flex flex-row items-center justify-center">
            <Checkbox
              checked={d.isDoubleHeader}
              className={
                "hover:bg-secondary-opacity-08 flex h-[20px] w-[20px] min-w-[20px] min-h-[20px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
              }
              onCheckedChange={(event) => {
                handleChangeRow(
                  index,
                  "isDoubleHeader",
                  event ? "true" : "false"
                );
              }}
              disabled={d.isPastGame || d.isDeleted || false}
            />
          </div>
        );
      },
    },
  ];

  if (isEdit) {
    COLUMNS.push({
      Header: "Actions",
      Cell: (d: any, index: number) => {
        return (
          <>
            {options && (
              <ActionsCell
                disabled={d.row.original.isDeleted}
                row={d.row}
                options={options}
                handleSelectedOption={(option, optionId) => {
                  const type = options[optionId].type;
                  handleGameAction(type, d.row.original.id);
                }}
              />
            )}
          </>
        );
      },
    });
  }

  COLUMNS.push({
    Header: "Remove",
    Cell: (d: any, index: number) => {
      return (
        <Button
          variant={d.row.original.isPastGame ? "disabled" : "secondary"}
          onClick={() => {
            handleRemoveGame(d.row.index);
          }}
          disabled={d.row.original.isPastGame}
        >
          {!d.row.original.isDeleted && <DeleteOutlineIcon />}
          {d.row.original.isDeleted && <UndoIcon />}
        </Button>
      );
    },
  });

  const columns = useMemo(
    () => COLUMNS,
    [JSON.stringify(games), JSON.stringify(teams), venues]
  );
  const data = useMemo(() => {
    return games.map((game) => {
      if (
        isPublishedSchedule &&
        isLocalDateTimePastCurrentTime(game.startDateTimeLocal, regionId)
      )
        return {
          ...game,
          isPastGame: true,
        };
      else return game;
    });
  }, [JSON.stringify(games), JSON.stringify(teams), venues]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: data.map((game) => ({
        ...game,
        checkGames: game.checkGames,
      })),
    });

  return (
    <Fragment>
      <TableContainer
        sx={{
          bgcolor: "white",
          borderRadius: "12px",
          maxHeight: "100vh",
        }}
      >
        <TableBase
          stickyHeader
          aria-label="sticky table"
          sx={{
            maxHeight: "70vh",
          }}
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                sx={{ zIndex: 1 }}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell
                    sx={{
                      bgcolor: "white",
                      color: "var(--textColor)",
                      fontSize: "16px",
                      textAlign: "left",
                      fontWeight: 550,
                      borderColor: "var(--table-border)",
                      zIndex: "inherit",
                      padding: "10px",
                    }}
                    {...column.getHeaderProps()}
                  >
                    <div>{column.render("Header")}</div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  hover
                  sx={{
                    borderBottom: "5px",
                    borderWidth: "2px",
                  }}
                  role="checkbox"
                  tabIndex={-1}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{
                          textAlign: "left",
                          fontSize: "15px",
                          color: "var(--textColor)",
                          // borderWidth: '2px',
                          borderColor: "var(--table-border)",
                          paddingBottom: "5px",
                          paddingTop: "5px",
                        }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </TableBase>
      </TableContainer>
    </Fragment>
  );
};

export default GameScheduleNew;
