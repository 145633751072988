import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
} from "@mui/material";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import dayjs from "dayjs";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { z } from "zod";
import Caption1 from "../UI/Text/Caption/Caption1";
import { DatePicker } from "../UI/shadcn/Time/date-picker";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import Button from "../UI/Button/Button";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import {
  getLeagues,
  getScoreStatuses,
  getSports,
} from "../../app/venueMasterSlice";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../UI/shadcn/select";
import { PaginationType } from "../../types/types";
import { LoaderCircle, MoreVerticalIcon } from "lucide-react";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../app/globalSlice";
import Body1 from "../UI/Text/Body/Body1";
import {
  GameQuery,
  GameScoreReportQuery,
  ListAllOperations,
  useApproveScoreMutation,
  useDefaultReasonTypesQuery,
  useGameLazyQuery,
  useGameScoreReportLazyQuery,
  useScoreCreateMutation,
  useScoreResetMutation,
} from "../../generated/graphql";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
} from "../UI/shadcn/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UI/shadcn/dropdown";
import { cn } from "../../lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../UI/shadcn/tooltip";
import { Button as ShadCnButton } from "../UI/shadcn/button";
import { useSearchParams } from "react-router-dom";

const weekdaysArray: any = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

type Team = {
  id: string;
  name: string;
};

type ScoreStatus = {
  name: string;
  id: string;
};

type LatestScore = {
  id: number;
  homeScore: number;
  awayScore: number;
  scoreStatus: ScoreStatus;
  submittingTeamId: number;
  submittingUserId: string;
  submittingUser: {
    id: string;
    firstName: string;
    lastName: string;
  };
  dateSubmitted: string;
  dateConfirmed: string;
  submittingTeam: {
    id: string;
    name: string;
  };
  confirmedByUser: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

interface ScoresInput {
  homeTeamScore: number;
  awayTeamScore: number;
}
interface RecordForfeit {
  team: string;
  reason: string;
}

const GameScoreReportFilterSchema = z
  .object({
    startDate: z.date(),
    endDate: z.date().optional(),
    leagueId: z.number().optional(),
    dayOfWeek: z.number().optional(),
    regionId: z.number().optional(),
    sportId: z.number().optional(),
    gameId: z.number().optional(),
    status: z.number().default(2),
  })
  .refine(
    (data) => {
      return (
        data.endDate !== undefined &&
        data.startDate !== undefined &&
        data.endDate > data.startDate
      );
    },
    { message: "End date must occur after start date" }
  );

type GameScoreReportType = z.infer<typeof GameScoreReportFilterSchema>;

type GameScoreReportData =
  GameScoreReportQuery["gameScoreReport"]["gameReportData"][0];

const GameScoreReport: React.FC = () => {
  const { selectedRegions, sports, leagues, scoreStatuses }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const gameId = searchParams.get("gameId");
  const [reportedScores, setReportedScores] = useState<
    GameQuery["game"]["reportedScores"]
  >([]);
  const [reportedScoresModal, setReportedScoresModal] =
    useState<boolean>(false);
  const [newScoreModal, setNewScoreModal] = useState<boolean>(false);
  const [gameInfoData, setGameInfoData] = useState<
    GameScoreReportQuery["gameScoreReport"]["gameReportData"][0] | undefined
  >();
  const [scores, setScores] = useState<ScoresInput>({
    homeTeamScore: 0,
    awayTeamScore: 0,
  });
  const [gameScoreReportFilter, setGameScoreReportFilter] =
    useState<GameScoreReportType>({
      startDate: dayjs().subtract(1, "week").toDate(),
      endDate: new Date(),
      status: 2,
      gameId: gameId ? +gameId : undefined,
    });
  const [gameScoreReportData, setGameScoreReportData] = useState<
    GameScoreReportQuery["gameScoreReport"]["gameReportData"]
  >([]);
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 50,
    pageCount: undefined,
  });
  const [resetScoreModal, setResetScoreModal] = useState<boolean>(false);
  const [reportDefaultModal, setReportDefaultModal] = useState<boolean>(false);
  const [recordForfeit, setRecordForfeit] = useState<RecordForfeit>({
    team: "homeTeam",
    reason: "1",
  });

  const { data: defaultReasonData } = useDefaultReasonTypesQuery({});

  const [
    getGameScoreReport,
    { loading: loadingGameScoreReport, error: errorGameScoreReport },
  ] = useGameScoreReportLazyQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [
    getGamesScoreHistory,
    {
      data: scoreHistoryData,
      loading: scoreHistoryLoading,
      error: scoreHistoryError,
    },
  ] = useGameLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [approveScore] = useApproveScoreMutation({
    onCompleted: (approveScoreData) => {
      if (approveScoreData.approveScore.success) {
        dispatch(displayAlertSuccess(approveScoreData.approveScore.message));
      } else {
        dispatch(displayAlertError(approveScoreData.approveScore.message));
      }
    },
    refetchQueries: [ListAllOperations.Query.GameScoreReport],
  });

  const [CreateScore] = useScoreCreateMutation({
    onCompleted: (scoreData) => {
      if (scoreData.scoreCreate.success) {
        dispatch(displayAlertSuccess(scoreData.scoreCreate.message));
      } else {
        dispatch(displayAlertError(scoreData.scoreCreate.message));
      }
      setNewScoreModal(false);
    },
    refetchQueries: [ListAllOperations.Query.GameScoreReport],
  });

  const [ResetScore] = useScoreResetMutation({
    onCompleted: (data) => {
      if (data.scoreReset.success) {
        dispatch(displayAlertSuccess(data.scoreReset.message));
      } else {
        dispatch(displayAlertError(data.scoreReset.message));
      }
    },
    refetchQueries: [ListAllOperations.Query.GameScoreReport],
  });

  /*** USE EFFECTS ***/
  useEffect(() => {
    if (sports.length === 0) dispatch(getSports(""));
    if (leagues.length === 0) dispatch(getLeagues(""));
    if (scoreStatuses.length === 0) dispatch(getScoreStatuses(""));
  }, []);

  useEffect(() => {
    handleGetScoreData(
      gameId !== undefined && gameScoreReportFilter.gameId !== undefined
        ? true
        : false
    );
  }, [pagination.page, pagination.pageSize]);

  /*** UTILITY FUNCTIONS ***/

  async function handleGetScoreData(gameOnly: boolean) {
    await getGameScoreReport({
      variables: {
        gameScoreReportInput: gameOnly
          ? {
              startDate: dayjs(gameScoreReportFilter.startDate).format(
                "YYYY-MM-DD"
              ),
              gameId: gameScoreReportFilter.gameId,
              page: pagination.page,
              pageSize: pagination.pageSize,
              scoreStatus: gameScoreReportFilter.status,
            }
          : {
              startDate: dayjs(gameScoreReportFilter.startDate).format(
                "YYYY-MM-DD"
              ),
              endDate: gameScoreReportFilter.endDate
                ? dayjs(gameScoreReportFilter.endDate).format("YYYY-MM-DD")
                : undefined,
              regionId: gameScoreReportFilter.regionId,
              sportId: gameScoreReportFilter.sportId,
              leagueId: gameScoreReportFilter.leagueId,
              dayOfWeek: gameScoreReportFilter.dayOfWeek,
              page: pagination.page,
              pageSize: pagination.pageSize,
              scoreStatus: gameScoreReportFilter.status,
            },
      },
      onCompleted: (data) => {
        setGameScoreReportData(data.gameScoreReport.gameReportData);
        setPagination((prevState) => ({
          ...prevState,
          pageCount: data.gameScoreReport.count,
        }));
      },
    });
  }

  async function handleApproveScore(id: number) {
    await approveScore({
      variables: {
        id: +id,
      },
    });
  }

  async function handleResetScore(id: number) {
    await ResetScore({
      variables: {
        scoreResetId: +id,
      },
    });
  }

  const handleScoreViewAction = async (game: GameScoreReportData) => {
    setReportedScoresModal(true);
    await getGamesScoreHistory({
      variables: {
        id: +game.id,
      },
      onCompleted: (data) => {
        setReportedScores(data.game.reportedScores ?? []);
      },
    });
  };
  useEffect(() => {
    console.log(gameInfoData);
  }, [gameInfoData]);

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRecordForfeit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setScores((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitScore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (gameInfoData) {
      CreateScore({
        variables: {
          createScoreArgs: {
            gameId: +gameInfoData?.id,
            homeScore: +scores.homeTeamScore,
            awayScore: +scores.awayTeamScore,
            isWinByDefault: false,
          },
        },
      });
    }
  };

  const onSubmitDefaultScore = (e: React.MouseEvent<HTMLButtonElement>) => {
    let homeScore = 0;
    let awayScore = 0;
    if (recordForfeit.team === "homeTeam") {
      homeScore = 0;
      awayScore = 1;
    } else {
      homeScore = 1;
      awayScore = 0;
    }
    if (gameInfoData) {
      // Add reason of forfeit game as well!
      CreateScore({
        variables: {
          createScoreArgs: {
            gameId: +gameInfoData.id,
            homeScore: homeScore,
            awayScore: awayScore,
            isWinByDefault: true,
            defaultReasonTypeId: +recordForfeit.reason,
          },
        },
      });
    }
  };

  const columnsGameScoreReport: ColumnDef<
    GameScoreReportQuery["gameScoreReport"]["gameReportData"][0]
  >[] = [
    {
      accessorKey: "id",
      header: "Game ID",
    },
    {
      accessorFn: (row) =>
        dayjs(row.startDateTimeLocal).format("YYYY MMM, DD, HH:mm"),
      id: "startDateTimeLocal",
      header: "Game Date",
    },
    {
      accessorFn: (row) =>
        row.latestScore == null ? "No Score" : row.latestScore.scoreStatus.name,
      header: "Status",
    },
    {
      accessorFn: (row) =>
        row.latestScore != null && row.latestScore.submittingUser
          ? row.latestScore.submittingUser.firstName +
            " " +
            row.latestScore.submittingUser.lastName
          : "~ N/A ~",
      id: "submittingUser",
      header: "Submitting User",
    },
    {
      accessorFn: (row) =>
        row.latestScore?.dateSubmitted
          ? dayjs(row.latestScore?.dateSubmitted).format("YYYY MMM, DD, HH:mm")
          : "~ N/A ~",
      id: "dateSubmitted",
      header: "Date Submitted",
    },
    {
      accessorFn: (row) => row.homeTeam?.name || "~ N/A ~",
      id: "homeTeam",
      header: "Home Team",
    },
    {
      accessorFn: (row) =>
        row.latestScore == null ? "~ N/A ~" : row.latestScore.homeScore,
      id: "homeScore",
      header: "Home Score",
    },
    {
      accessorFn: (row) => row.awayTeam?.name || "~ N/A ~",
      id: "awayTeam",
      header: "Away Team",
    },
    {
      accessorFn: (row) =>
        row.latestScore == null ? "~ N/A ~" : row.latestScore.awayScore,
      id: "awayScore",
      header: "Away Score",
    },
    {
      accessorFn: (row) =>
        row.latestScore != null && row.latestScore.confirmedByUser
          ? row.latestScore.confirmedByUser.firstName +
            " " +
            row.latestScore.confirmedByUser.lastName
          : "~ N/A ~",
      id: "confirmedByUser",
      header: "Confirmed User",
    },
    {
      accessorFn: (row) =>
        row.latestScore?.dateConfirmed
          ? dayjs(row.latestScore?.dateConfirmed).format("YYYY MMM, DD, HH:mm")
          : "~ N/A ~",
      id: "dateConfirmed",
      header: "Date Confirmed",
    },
    {
      header: "Action",
      cell: ({ row }) => {
        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <MoreVerticalIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="flex flex-col gap-1">
                {row.original.latestScore != null && (
                  <DropdownMenuItem
                    className="hover:bg-primary-95"
                    onClick={() => {
                      setGameInfoData(row.original);
                      handleScoreViewAction(row.original);
                    }}
                  >
                    View Score
                  </DropdownMenuItem>
                )}
                {row.original.latestScore != null &&
                  row.original.latestScore.scoreStatus.id === 2 && (
                    <DropdownMenuItem
                      className="hover:bg-primary-95"
                      onClick={() => {
                        setGameInfoData(row.original);
                        handleApproveScore(row.original.latestScore!.id);
                      }}
                    >
                      Approve Score
                    </DropdownMenuItem>
                  )}
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      {row.original.isRegGameAndRegSeasonFinalized ? (
                        <DropdownMenuItem className="bg-neutral-100 text-neutral-40">
                          Submit Score
                        </DropdownMenuItem>
                      ) : (
                        <DropdownMenuItem
                          className={cn(
                            !row.original.isRegGameAndRegSeasonFinalized &&
                              "hover:bg-primary-95"
                          )}
                          onClick={() => {
                            setGameInfoData(row.original);
                            setNewScoreModal(true);
                          }}
                        >
                          Submit Score
                        </DropdownMenuItem>
                      )}
                    </TooltipTrigger>
                    {row.original.isRegGameAndRegSeasonFinalized && (
                      <TooltipContent>
                        Cannot submit score, game and season are finalized.
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      {row.original.isRegGameAndRegSeasonFinalized ? (
                        <DropdownMenuItem className="bg-neutral-100 text-neutral-40">
                          Report Default
                        </DropdownMenuItem>
                      ) : (
                        <DropdownMenuItem
                          className={cn(
                            !row.original.isRegGameAndRegSeasonFinalized &&
                              "hover:bg-primary-95"
                          )}
                          onClick={() => {
                            setGameInfoData(row.original);
                            setReportDefaultModal(true);
                          }}
                        >
                          Report Default
                        </DropdownMenuItem>
                      )}
                    </TooltipTrigger>
                    {row.original.isRegGameAndRegSeasonFinalized && (
                      <TooltipContent>
                        Cannot submit score, game and season are finalized.
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
                {row.original.latestScore != null &&
                  row.original.gameType.id !== 2 && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          {row.original.isRegGameAndRegSeasonFinalized ? (
                            <DropdownMenuItem className="bg-neutral-100 text-neutral-40">
                              Reset Score
                            </DropdownMenuItem>
                          ) : (
                            <DropdownMenuItem
                              className={cn(
                                !row.original.isRegGameAndRegSeasonFinalized &&
                                  "hover:bg-primary-95"
                              )}
                              disabled={
                                row.original.isRegGameAndRegSeasonFinalized
                              }
                              onClick={() => {
                                setGameInfoData(row.original);
                                setResetScoreModal(true);
                              }}
                            >
                              Reset Score
                            </DropdownMenuItem>
                          )}
                        </TooltipTrigger>
                        {row.original.isRegGameAndRegSeasonFinalized && (
                          <TooltipContent>
                            Cannot submit score, game and season are finalized.
                          </TooltipContent>
                        )}
                      </Tooltip>
                    </TooltipProvider>
                  )}
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        );
      },
    },
  ];

  const columnsForScoreHistory: ColumnDef<
    GameQuery["game"]["reportedScores"][0]
  >[] = [
    {
      accessorKey: "id",
      header: "Score ID",
    },
    {
      accessorFn: (row) => row.homeScore,
      id: "homeScore",
      header: "Home Score",
    },
    {
      accessorFn: (row) => row.awayScore,
      id: "awayScore",
      header: "Away Score",
    },
    {
      accessorFn: (row) => row.scoreStatus.name,
      id: "scoreStatus",
      header: "Score Status",
    },
    {
      header: "Submitted By",
      cell: ({ row }) => {
        return (
          <div>
            {row.original.submittingUser?.firstName}{" "}
            {row.original.submittingUser?.lastName}
          </div>
        );
      },
    },
    {
      header: "Submitting Team",
      cell: ({ row }) => {
        return <div>{row.original.submittingTeam?.name} </div>;
      },
    },
    {
      header: "Submitted At",
      cell: ({ row }) => {
        return (
          <div>
            {dayjs(row.original.dateSubmitted).format("YYYY-MM-DD HH:mm")}
          </div>
        );
      },
    },
    {
      header: "Confirmed By",
      cell: ({ row }) => {
        return (
          <div>
            {row.original.confirmedByUser?.firstName}{" "}
            {row.original.confirmedByUser?.lastName}
          </div>
        );
      },
    },
    {
      header: "Confirmed At",
      cell: ({ row }) => {
        return (
          <div>
            {row.original.dateConfirmed &&
              dayjs(row.original.dateConfirmed).format("YYYY-MM-DD HH:mm")}
          </div>
        );
      },
    },
  ];

  if (loadingGameScoreReport) return <LoadingDialog open={true} />;
  if (errorGameScoreReport) return <div>Something went wrong</div>;

  return (
    <main className="flex flex-col justify-between w-full pb-4">
      {/* Reset Score Modal */}
      <Dialog
        open={resetScoreModal}
        onOpenChange={setResetScoreModal}
      >
        <DialogContent className="w-fit">
          <div className="flex flex-col gap-6">
            <Headline2Variable>Reset Score</Headline2Variable>

            <Body1>
              This will delete ALL scores associated with this game. Are you
              sure you want to do this?
            </Body1>
            <Body1>
              If you want to override the current score you can do so using the
              submit score button.
            </Body1>
            <div className="flex flex-col gap-1">
              <Body1>Game Info</Body1>
              <Caption1>
                Start Date:{" "}
                {dayjs(gameInfoData?.startDateTimeLocal).format(
                  "ddd, MM D, YYYY HH:mm"
                )}
              </Caption1>
              <Caption1>Venue: {gameInfoData?.venue?.name}</Caption1>
              <Caption1>Team 1: {gameInfoData?.homeTeam?.name}</Caption1>
              <Caption1>
                Score (Team 1): {gameInfoData?.latestScore?.homeScore}
              </Caption1>
              <Caption1>Team 2: {gameInfoData?.awayTeam?.name}</Caption1>
              <Caption1>
                Score (Team 2): {gameInfoData?.latestScore?.awayScore}
              </Caption1>
            </div>

            <DialogFooter className="flex flex-wrap-reverse gap-4">
              <DialogClose>
                <Button
                  variant="primary"
                  onClick={() => {
                    gameInfoData && handleResetScore(gameInfoData.id);
                  }}
                >
                  Submit
                </Button>
              </DialogClose>
              <DialogClose>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>
      {/* View Scores Modal */}
      <Dialog
        open={reportedScoresModal}
        onOpenChange={setReportedScoresModal}
      >
        <DialogContent className="max-w-full w-fit">
          <div className="flex flex-col gap-6">
            <Headline2Variable>Past Reported Scores</Headline2Variable>
            {scoreHistoryLoading === true ? (
              <div className="flex flex-row items-center justify-center w-[700px] h-16">
                <LoaderCircle className="w-8 h-8 animate-spin" />
              </div>
            ) : (
              <DataTable
                data={reportedScores}
                columns={columnsForScoreHistory}
              />
            )}
          </div>
          <DialogFooter>
            <DialogClose>
              <Button variant="secondary">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {/* New Score Modal */}
      <Dialog
        open={newScoreModal}
        onOpenChange={setNewScoreModal}
      >
        <DialogContent className="w-fit">
          <div className="flex flex-col gap-y-6">
            <Headline2Variable>Submit Score</Headline2Variable>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center p-5 border shadow-lg border-secondary-90 rounded-xl">
                <div className="flex flex-col">
                  <div className="self-center">
                    <Subtitle1>{gameInfoData?.homeTeam?.name}</Subtitle1>
                  </div>
                  <FormField
                    inputChange={handleScoreChange}
                    name="homeTeamScore"
                    label="Final Score *"
                  />
                </div>
                <div className="mx-3">vs</div>
                <div className="flex flex-col">
                  <div className="self-center">
                    <Subtitle1>{gameInfoData?.awayTeam?.name}</Subtitle1>
                  </div>
                  <FormField
                    inputChange={handleScoreChange}
                    name="awayTeamScore"
                    label="Final Score *"
                  />
                </div>
              </div>
              <DialogClose className="self-start">
                <Button
                  variant="secondary"
                  onClick={() => setReportDefaultModal(true)}
                >
                  Report Default
                </Button>
              </DialogClose>
            </div>

            <DialogFooter className="self-end">
              <DialogClose>
                <Button
                  variant="primary"
                  onClick={onSubmitScore}
                >
                  Submit
                </Button>
              </DialogClose>
              <DialogClose>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
            </DialogFooter>
            <div className="flex flex-wrap-reverse gap-4"></div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Default Score Modal */}
      <Dialog
        open={reportDefaultModal}
        onOpenChange={setReportDefaultModal}
      >
        <DialogContent className="w-fit">
          <div className="flex flex-col">
            {/* Game Details */}
            <div className="mb-5">
              <Headline2Variable>Report Default</Headline2Variable>
              <Caption1>{gameInfoData?.venue.name}</Caption1>
            </div>
            <div className="flex flex-row items-center p-5 mb-5 border shadow-lg border-secondary-90 rounded-xl">
              <div className="flex flex-col">
                {/* Select Forfeitng Team- Radio Button */}
                <div className="mb-5">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      <Subtitle1>Select Forfeiting Team *</Subtitle1>
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={recordForfeit?.team}
                      name="team"
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel
                        value="homeTeam"
                        control={<Radio />}
                        label={gameInfoData?.homeTeam?.name}
                      />
                      <FormControlLabel
                        value="awayTeam"
                        control={<Radio />}
                        label={gameInfoData?.awayTeam?.name}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {/* Select Reason- Radio Button */}
                <div className="mb-5">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      <Subtitle1>Select Reason *</Subtitle1>
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={recordForfeit?.reason}
                      name="reason"
                      onChange={handleChangeRadio}
                    >
                      {defaultReasonData?.defaultReasonTypes.map((reason) => (
                        <FormControlLabel
                          value={reason.id}
                          control={<Radio />}
                          label={reason.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
            <DialogFooter className="flex flex-wrap-reverse gap-4">
              <DialogClose>
                <Button
                  variant="primary"
                  onClick={onSubmitDefaultScore}
                >
                  Submit
                </Button>
              </DialogClose>
              <DialogClose>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>

      <Headline1Variable>Game Scores</Headline1Variable>
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-5 gap-4">
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  regionId: +value,
                };
              });
            }}
            label="Region"
            placeholder="Region"
            value={gameScoreReportFilter.regionId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...selectedRegions]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  sportId: +value,
                };
              });
            }}
            label="Sport"
            placeholder="Sport"
            value={gameScoreReportFilter.sportId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...sports]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  leagueId: +value,
                };
              });
            }}
            label="League"
            placeholder="League"
            value={gameScoreReportFilter.leagueId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...leagues]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  dayOfWeek: +value,
                };
              });
            }}
            label="Day"
            placeholder="Day"
            value={gameScoreReportFilter.dayOfWeek?.toString() ?? "-1"}
          >
            {[{ id: "-1", name: "All" }, ...weekdaysArray]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  status: +value,
                };
              });
            }}
            label="Score Status"
            placeholder="Score Status"
            value={gameScoreReportFilter.status?.toString() ?? "0"}
          >
            {[
              { id: "1", name: "Final" },
              { id: "2", name: "Pending" },
              { id: "5", name: "System Generated" },
              { id: "-1", name: "No Score" },
            ]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-row items-end gap-4">
          <div className="flex flex-col gap-1">
            <Caption1 className="font-medium">Start Date</Caption1>
            <DatePicker
              date={gameScoreReportFilter.startDate}
              setDate={(date) =>
                setGameScoreReportFilter({
                  ...gameScoreReportFilter,
                  startDate: date ?? dayjs().subtract(1, "week").toDate(),
                })
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <Caption1 className="font-medium">End Date</Caption1>
            <DatePicker
              date={gameScoreReportFilter.endDate}
              setDate={(date) =>
                setGameScoreReportFilter({
                  ...gameScoreReportFilter,
                  endDate: date,
                })
              }
            />
          </div>
          <Button
            variant="primary"
            onClick={() => handleGetScoreData(false)}
            className="h-fit"
          >
            Filter
          </Button>
        </div>
        <div className="flex flex-row items-end gap-2">
          <div className="w-40">
            <FormField
              type="number"
              className="w-40 h-10"
              inputChange={(value) => {
                console.log(value.target.value);
                setGameScoreReportFilter((prevState: GameScoreReportType) => {
                  return {
                    ...prevState,
                    gameId:
                      value.target.value == ""
                        ? undefined
                        : +value.target.value,
                  };
                });
              }}
              label="Game ID override"
              initialValue={
                gameScoreReportFilter.gameId !== undefined
                  ? gameScoreReportFilter.gameId.toString()
                  : undefined
              }
              value={
                gameScoreReportFilter.gameId !== undefined
                  ? gameScoreReportFilter.gameId.toString()
                  : undefined
              }
            />
          </div>
          <Button
            variant="primary"
            onClick={() => handleGetScoreData(true)}
            className="h-fit"
          >
            Game Score Search
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {gameScoreReportData.length > 0 && (
          <div className="flex flex-col gap-4">
            <Headline2Variable>Scores</Headline2Variable>
            <DataTable
              data={gameScoreReportData}
              columns={columnsGameScoreReport}
            />
            {pagination.pageCount && (
              <Fragment>
                <div className="my-auto text-xs text-disabled">
                  Page {pagination.page + 1} of{" "}
                  {Math.ceil(pagination.pageCount / pagination.pageSize)}
                </div>
                <div className="flex flex-row">
                  <Pagination
                    page={pagination.page + 1}
                    count={Math.ceil(
                      pagination.pageCount / pagination.pageSize
                    )}
                    onChange={(event: any, value: number) => {
                      setPagination((prevState: PaginationType) => {
                        return {
                          ...prevState,
                          page: value - 1,
                        };
                      });
                    }}
                  />
                  <Select
                    value={`${pagination.pageSize.toString()}`}
                    onValueChange={(value) => {
                      setPagination((prevState: PaginationType) => {
                        return {
                          ...prevState,
                          page: Math.floor(
                            (prevState.page * prevState.pageSize) /
                              Number(value)
                          ),
                          pageSize: Number(value),
                        };
                      });
                    }}
                  >
                    <SelectTrigger className="h-8 w-[70px]">
                      <SelectValue
                        placeholder={pagination.pageSize.toString()}
                      />
                    </SelectTrigger>
                    <SelectContent side="top">
                      {[5, 10, 25, 50, 100].map((pageSize) => (
                        <SelectItem
                          key={pageSize}
                          value={`${pageSize}`}
                          className="hover:bg-primary-95"
                        >
                          {pageSize}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default GameScoreReport;
