import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { useNavigate, useParams } from "react-router-dom";
import { getDivisionScheduleById } from "../../../app/venueMasterSlice";
import ReactQuill from "react-quill";
import CheckBox from "../../UI/Checkbox/Checkbox";
import Button from "../../UI/Button/Button";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_EMAIL_TEMPLATES } from "../../../graphql/queries/email";

import { header } from "./Headers";
import { marketingFooter } from "./Footers";
import { emailButton } from "./EmailButton";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { LoaderCircle } from "lucide-react";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { useToast } from "../../UI/Toast/use-toast";
import { z } from "zod";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../UI/shadcn/dialog";
import { GET_NUMBER_OF_PLAYERS_ON_TEAMS } from "../../../graphql/queries/team";
import Caption1 from "../../UI/Text/Caption/Caption1";
import { DateTimePicker } from "../../UI/shadcn/Time/date-time-picker";
import dayjs from "dayjs";
import {
  CREATE_EMAIL_TEMPLATE_INSTANCE,
  GET_EMAIL_TEMPLATE_INSTANCE,
  UPDATE_EMAIL_TEMPLATE_INSTANCE,
} from "../../../graphql/queries/emailTemplateInstance";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import {
  useCreateEmailTemplateInstanceMutation,
  useGetEmailTemplateInstanceByIdQuery,
  useGetEmailTemplatesQuery,
} from "../../../generated/graphql";

const EmailTemplateSchema = z.object({
  id: z.number().optional(),
  subject: z.string().min(1, "Please enter a valid subject"),
  name: z.string(),
  body: z.string().refine(
    (data) => {
      return data !== "<p><br></p>";
    },
    { message: "Please enter a valid body" }
  ),
});

type EmailTemplate = z.infer<typeof EmailTemplateSchema>;

const emptyEmailTemplate = {
  id: 0,
  subject: "",
  name: "",
  body: "<p><br></p>",
};

const EmailToolMarketing = () => {
  const navigate = useNavigate();
  const params = useParams();
  const instanceId = params.id;

  const { user }: any = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  const [email, setEmail] = useState<EmailTemplate>(emptyEmailTemplate);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const { loading: loadingEmailTemplateInstanceById } =
    useGetEmailTemplateInstanceByIdQuery({
      variables: {
        id: +instanceId!,
      },
      onCompleted: (data) => {
        setEmail(data.getEmailTemplateInstanceById);
      },
      onError(error) {
        dispatch(
          displayAlertError(`Email instance of id ${instanceId} doesn't exist.`)
        );
      },
      skip: instanceId === undefined,
    });

  const { data: dataTemplates, loading: loadingTemplates } =
    useGetEmailTemplatesQuery({
      variables: {
        typeId: 2,
      },
      skip: instanceId !== undefined,
    });

  const [createEmailTemplateInstance] = useCreateEmailTemplateInstanceMutation({
    refetchQueries: [GET_EMAIL_TEMPLATE_INSTANCE, "getEmailTemplateInstance"],
    onCompleted: (data) => {
      if (
        data.createEmailTemplateInstance &&
        data.createEmailTemplateInstance.emailTemplateInstance
      ) {
        setEmail(data.createEmailTemplateInstance.emailTemplateInstance);
      }
      if (data.createEmailTemplateInstance.success) {
        dispatch(displayAlertSuccess(data.createEmailTemplateInstance.message));
        navigate(`/marketing/email-tool/marketing/report`);
      } else {
        dispatch(displayAlertError(data.createEmailTemplateInstance.message));
      }
    },
    onError: (err) => {
      console.log(err);
      dispatch(displayAlertError("Something went wrong"));
    },
  });
  const [updateEmailTemplateInstance] = useMutation(
    UPDATE_EMAIL_TEMPLATE_INSTANCE,
    {
      refetchQueries: [GET_EMAIL_TEMPLATE_INSTANCE, "getEmailTemplateInstance"],
      onCompleted: (data) => {
        if (
          data.updateEmailTemplateInstance &&
          data.updateEmailTemplateInstance.emailTemplateInstance
        ) {
          setEmail(data.updateEmailTemplateInstance.emailTemplateInstance);
        }
        if (data.updateEmailTemplateInstance.success) {
          dispatch(
            displayAlertSuccess(data.updateEmailTemplateInstance.message)
          );
        } else {
          dispatch(displayAlertError(data.updateEmailTemplateInstance.message));
        }
      },
      onError: (err) => {
        dispatch(displayAlertError("Something went wrong"));
      },
    }
  );

  useEffect(() => {
    if (iframeRef.current && email !== undefined) {
      const formattedBody = `${header}${email.body.replace(
        /%%button_(https:\/\/[a-zA-Z0-9._=%+-\\&+\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
        (_, link, text) => {
          return emailButton(link, text);
        }
      )}${marketingFooter({
        url: "https://jamsports.com/unsubscribe/test",
        email: "temp@jamgroup.com",
      })}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [email]);

  async function handleSendEmail() {
    const result = EmailTemplateSchema.safeParse(email);
    if (result.success) {
      navigate(`/marketing/email-tool/marketing/filter/${email.id}`);
    } else {
      dispatch(
        displayAlertError(
          `${result.error.issues.map((issue) => issue.message).join(",\n")}`
        )
      );
    }
  }

  async function handleSubmit() {
    const result = EmailTemplateSchema.safeParse(email);
    if (result.success) {
      // If the in progress email has an id that is in the dataEmailTemplateInstance.getEmailTemplateInstance then update
      if (email.id !== undefined && email.id !== 0) {
        updateEmailTemplateInstance({
          variables: {
            updateEmailTemplateInstanceInput: {
              id: email.id,
              name: email.name,
              subject: email.subject,
              body: email.body,
              emailTemplateInstanceStatusId: 1,
              updatedBy: user.id,
              updatedAt: new Date(),
            },
          },
        });
      } else {
        createEmailTemplateInstance({
          variables: {
            createEmailTemplateInstanceInput: {
              name: email.name,
              subject: email.subject,
              body: email.body,
              emailTemplateInstanceStatusId: 1,
              updatedBy: user.id,
              updatedAt: new Date(),
            },
          },
        });
      }
    } else {
      dispatch(
        displayAlertError(
          `${result.error.issues.map((issue) => issue.message).join(",\n")}`
        )
      );
    }
  }

  if (loadingTemplates || loadingEmailTemplateInstanceById) {
    return <LoadingDialog open={true} />;
  }

  return (
    <main className="flex flex-col gap-4">
      <Headline1Variable>Marketing Email Instance</Headline1Variable>
      <div className="flex flex-col items-start gap-2">
        <div className="flex flex-row gap-8">
          <div className="max-w-80 w-80">
            {dataTemplates && (
              <FormFieldSelect
                name="templateSelect"
                value={email?.id?.toString() ?? "0"}
                inputChange={(value) => {
                  if (value === "0") {
                    setEmail(emptyEmailTemplate);
                  } else {
                    const newEmail = dataTemplates.getEmailTemplates.find(
                      (template) => template.id === +value
                    );
                    if (newEmail) {
                      setEmail({ ...newEmail, id: undefined });
                    }
                  }
                }}
                label="Template"
                placeholder="Select Email Temaplte"
              >
                {[
                  { id: 0, name: "Select a Template" },
                  ...dataTemplates.getEmailTemplates.map((template) => {
                    return { id: template.id, name: template.name };
                  }),
                ]}
              </FormFieldSelect>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full gap-4">
        <div className="flex flex-col w-1/2 gap-4">
          <div>
            <FormFieldControlled
              onChange={(value) => {
                setEmail((prev) => ({
                  ...prev,
                  name: value.target.value,
                }));
              }}
              value={email.name}
              label="Email Name"
            />
          </div>
          <div>
            <FormFieldControlled
              onChange={(value) => {
                setEmail({
                  ...email,
                  subject: value.target.value,
                });
              }}
              value={email.subject}
              label="Subject"
            />
          </div>
          <div className="mb-[46px]">
            <ReactQuill
              theme="snow"
              value={email.body}
              onChange={(text: string) =>
                setEmail((prev) => ({
                  ...prev,
                  body: text,
                }))
              }
              style={{ height: "400px", width: "100%" }}
            />
          </div>
          <div className="flex flex-row gap-4">
            <Button
              variant="primary"
              onClick={() => handleSubmit()}
            >
              {email.id ? "Save" : "Create"}
            </Button>
            {email.id && (
              <Button
                variant="secondary"
                onClick={() => handleSendEmail()}
              >
                Define Recipient Filters
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-4 ">
          <div className="flex flex-col">
            <Headline2Variable>
              Email Variables (click to copy)
            </Headline2Variable>
            <div className="flex flex-col">
              <div className="flex flex-row gap-2">
                <Body1 className="w-32">First Name:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%first_name%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className=" bg-info-90 w-fit">%%first_name%%</Body1>
              </div>
              <div className="flex flex-row gap-2">
                <Body1 className="w-32">Button:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%button_link_text%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className=" bg-info-90 w-fit">
                  %%button_link_text%%
                </Body1>
                <Body1>%%button_https://jamsports.com_Home%%</Body1>
              </div>
            </div>
          </div>
          <div className="max-w-[600px]">
            <iframe
              ref={iframeRef}
              title="Email Preview"
              style={{ width: "100%", height: "600px", border: "none" }}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmailToolMarketing;
