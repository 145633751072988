import React, { useEffect, useMemo, useState } from "react";
import {
  useShoppingCartsLazyQuery,
  ShoppingCartsQuery,
} from "../../../../generated/graphql";
import { useSessionWorkflowContext } from "../../../../context/SessionWorkflowContext";
import dayjs from "dayjs";
import { DataTable } from "../../../UI/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { numberToMoney } from "../../../../utils/financialHelpers";

const SessionWorkflowIncompleteCart: React.FC = () => {
  /*** STATES ***/
  const { selectedSession, tabGroupValue } = useSessionWorkflowContext();
  const [shoppingCarts, setShoppingCarts] = useState<
    ShoppingCartsQuery["shoppingCarts"]["shoppingCarts"]
  >([]);

  /*** QUERIES ***/
  const [getShoppingCarts, { data, loading }] = useShoppingCartsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  /*** MUTATIONS ***/

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (selectedSession && tabGroupValue === "Incomplete Carts") {
      getShoppingCarts({
        variables: {
          shoppingCartFilters: {
            productIds: [selectedSession.id],
            shoppingCartStatusId: 2,
          },
        },
        onCompleted: (data) => {
          setShoppingCarts(data.shoppingCarts.shoppingCarts);
        },
      });
    } else {
      setShoppingCarts([]);
    }
  }, [selectedSession]);

  /*** UTILITY FUNCTIONS ***/
  const shoppingCartColumns: ColumnDef<
    ShoppingCartsQuery["shoppingCarts"]["shoppingCarts"][0]
  >[] = [
    {
      header: "Shopping Cart ID",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${row.original.id}`}
          >
            {row.original.id}
          </a>
        );
      },
    },
    {
      accessorFn: (row) => dayjs(row.createdAt).format("YYYY-MM-DD HH:mm"),
      header: "Time Created",
    },
    {
      accessorFn: (row) => row.user.firstName + " " + row.user.lastName,
      header: "Player",
    },
    { header: "Status", accessorFn: (row) => row.shoppingCartStatus?.name },
    {
      header: "Product Type",
      cell: ({ row }) => {
        const productType = row.original.shoppingCartItems
          .filter((sci) => sci.productId === selectedSession?.id)
          .map((sci) => sci.productType.name);
        return (
          <div>
            {productType
              .filter((item, index) => productType.indexOf(item) === index)
              .join(", ")}
          </div>
        );
      },
    },
    {
      header: "Last Active",
      accessorFn: (row) => dayjs(row.lastActive).format("YYYY-MM-DD HH:mm"),
    },
    {
      header: "Total",
      cell: ({ row }) => {
        let totalValue = 0;
        row.original.shoppingCartItems.forEach((shoppingcartItem) => {
          totalValue +=
            shoppingcartItem.itemAmount +
            (shoppingcartItem.txnFee ?? 0) +
            shoppingcartItem.tax;
        });
        return numberToMoney(totalValue);
      },
    },
    {
      header: "Transaction Amount",
      cell: ({ row }) => {
        let transactionAmount = 0;
        row.original.shoppingCartTransactions.forEach((transaction) => {
          if (transaction.success) {
            if (
              !transaction.transactionType.name.includes("Purchase") &&
              !(transaction.transactionType.name === "League Transfer In")
            )
              transactionAmount -= transaction.transactionAmount;
            else transactionAmount += transaction.transactionAmount;
          }
        });
        return <div>{numberToMoney(transactionAmount)}</div>;
      },
    },
  ];

  // Constants for the table
  const columns = useMemo(() => shoppingCartColumns, []);
  const tableData = useMemo(() => {
    if (shoppingCarts) return shoppingCarts;
  }, [shoppingCarts]);

  return (
    <main className="flex flex-col gap-4">
      {tableData && (
        <DataTable
          columns={columns}
          data={tableData}
        />
      )}
    </main>
  );
};

export default SessionWorkflowIncompleteCart;
