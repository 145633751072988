import { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import {
  DayOfWeek,
  getLeagues,
  getRegistrationBatch,
  getRegistrationReport,
  getRegistrationReportCount,
  getRegistrationTypes,
  getSports,
} from "../../app/venueMasterSlice";
import BaseTable from "../UI/Table/Table";
import { Column } from "react-table";
import {
  Pagination,
  PaginationType,
  RegistrationReportFilter,
  RegistrationType,
} from "../../types/types";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { dayOfWeek } from "../../utils/dayOfWeek";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Button from "../UI/Button/Button";
import dayjs from "dayjs";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import { RegistrationReportQuery } from "@/src/generated/graphql";

const Registrations = () => {
  const {
    registrationReport,
    registrationReportCount,
    sports,
    selectedRegions,
    leagues,
    registrationBatches,
    isLoading,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const pageSize = 50;

  const dispatch = useDispatch<AppDispatch>();

  const [pagination, setPagination] = useState<PaginationType>({
    pageCount: undefined,
    pageSize: pageSize,
    page: 0,
  });
  const [registrationReportFilter, setRegistrationReportFilter] =
    useState<RegistrationReportFilter>({
      regionId: null,
      sportId: null,
      leagueId: null,
      registrationBatchId: null,
      dayOfWeekId: null,
      registrationTypeId: null,
    });
  const [registrationTypes, setRegistrationTypes] = useState<
    RegistrationType[]
  >([]);
  const [loading, setLoading] = useState(false);

  const registrationReportData = useMemo(() => {
    if (registrationReport) {
      return registrationReport;
    } else {
      return [];
    }
  }, [registrationReport]);
  const registrationReportColumn: ColumnDef<
    RegistrationReportQuery["registrationReport"][0]
  >[] = [
    {
      id: "createdAt",
      header: "Created At",
      accessorFn: (d) => dayjs(d.createdAt).format("YYYY-MM-DD HH:mm"),
      enableSorting: true,
    },
    {
      id: "firstName",
      header: "Player Name",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/user/${row.original.user.id}`}
          >
            {row.original.user.firstName} {row.original.user.lastName}
          </a>
        );
      },
    },
    {
      id: "gender",
      header: "Gender",
      accessorFn: (row) => row.user.genderIdentity?.name,
    },
    {
      id: "email",
      header: "Email",
      accessorFn: (row) => row.user.email,
    },
    {
      id: "region",
      header: "Region",
      accessorFn: (row) => row.session.league.sportFormat.region.name,
    },
    {
      id: "registrationType",
      header: "Reg Type",
      accessorFn: (row) => row.registrationType.type,
    },
    {
      id: "teamName",
      header: "Team Name",
      accessorFn: (row) => row.team?.name || "N/A",
    },
    {
      id: "dayOfWeek",
      header: "Day",
      accessorFn: (row) => DayOfWeek[row.session.dayOfWeek],
    },
    {
      id: "league",
      header: "League",
      accessorFn: (row) => row.session.league.name,
    },
    {
      id: "season",
      header: "Season",
      accessorFn: (row) => row.session.registrationBatch.name,
    },
    {
      id: "shoppingCartId",
      header: "Shopping Cart Id",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${row.original.shoppingCart.id}`}
          >
            {row.original.shoppingCart.id}
          </a>
        );
      },
    },
    {
      id: "amountPaid",
      header: "Amount",
      accessorFn: (row) => row.shoppingCartItem.amountPaid,
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await dispatch(
          getRegistrationReport({
            registrationReportInput: registrationReportFilter,
            page: pagination.page,
            pageSize: pagination.pageSize,
          })
        );
      } catch (err) {
        dispatch(displayAlertError("Error fetching registration report"));
      }
      setLoading(false);
    })();
  }, [dispatch, pagination.page, pagination.pageSize]);

  const onApplyFilter = async () => {
    setLoading(true);
    try {
      await dispatch(
        getRegistrationReportCount({
          registrationReportInput: registrationReportFilter,
        })
      );
      await dispatch(
        getRegistrationReport({
          registrationReportInput: registrationReportFilter,
          page: pagination.page,
          pageSize: pagination.pageSize,
        })
      );
    } catch (err) {
      dispatch(displayAlertError("Error fetching registration report"));
    }
    setLoading(false);
  };
  useEffect(() => {
    setPagination((prevState) => {
      return {
        ...prevState,
        pageCount: registrationReportCount,
      };
    });
  }, [registrationReportCount]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getSports(""));
        await dispatch(
          getLeagues({ sportId: registrationReportFilter.sportId })
        );
        await dispatch(getRegistrationBatch(""));
        await dispatch(getRegistrationTypes("")).then((data) => {
          setRegistrationTypes(data.payload);
        });
      } catch (err) {
        dispatch(
          displayAlertError("Error fetching registration report filters")
        );
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(
      getLeagues({
        sportId: registrationReportFilter.sportId,
        regionId: registrationReportFilter.regionId,
      })
    ).then(() => {
      dispatch(displayAlertSuccess("League options updated"));
    });
  }, [registrationReportFilter.sportId, registrationReportFilter.regionId]);

  useEffect(() => {
    // If all the filters are set to null then fetch the registration report
    if (
      registrationReportFilter.regionId === null &&
      registrationReportFilter.sportId === null &&
      registrationReportFilter.leagueId === null &&
      registrationReportFilter.registrationBatchId === null &&
      registrationReportFilter.dayOfWeekId === null &&
      registrationReportFilter.registrationTypeId === null
    ) {
      onApplyFilter();
    }
  }, [registrationReportFilter]);

  return (
    <main>
      <div className="flex flex-col justify-between w-full gap-4 pb-8">
        <LoadingDialog open={isLoading || loading} />
        <Headline1Variable>Registrations</Headline1Variable>
        <div className="flex flex-row flex-wrap items-end gap-4">
          <div className="w-64">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                setRegistrationReportFilter((prevState) => {
                  return {
                    ...prevState,
                    sportId: value == "0" ? null : parseInt(value),
                  };
                });
              }}
              name="sportId"
              label="Sport"
              placeholder="Sport"
              value={registrationReportFilter.sportId?.toString() ?? "0"}
            >
              {[
                { id: 0, name: "All Sports" },
                ...sports.map((sport: any) => {
                  return { id: sport.id, name: sport.name };
                }),
              ]}
            </FormFieldSelect>
          </div>
          <div className="w-64">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                setRegistrationReportFilter((prevState) => {
                  return {
                    ...prevState,
                    regionId: value == "0" ? null : parseInt(value),
                  };
                });
              }}
              name="regionId"
              label="Region"
              placeholder="Region"
              value={registrationReportFilter.regionId?.toString() ?? "0"}
            >
              {[
                { id: 0, name: "All Regions" },
                ...selectedRegions.map((region: any) => {
                  return { id: region.id, name: region.name };
                }),
              ]}
            </FormFieldSelect>
          </div>
          <div className="w-64">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                setRegistrationReportFilter((prevState) => {
                  return {
                    ...prevState,
                    leagueId: value == "0" ? null : parseInt(value),
                  };
                });
              }}
              name="leagueId"
              label="League"
              placeholder="League"
              value={registrationReportFilter.leagueId?.toString() ?? "0"}
            >
              {[
                { id: 0, name: "All Leagues" },
                ...leagues.map((league: any) => {
                  return { id: league.id, name: league.name };
                }),
              ]}
            </FormFieldSelect>
          </div>
          <div className="w-64">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                setRegistrationReportFilter((prevState) => {
                  return {
                    ...prevState,
                    registrationBatchId: value == "0" ? null : parseInt(value),
                  };
                });
              }}
              name="registrationBatchId"
              label="Season"
              placeholder="Season"
              value={
                registrationReportFilter.registrationBatchId?.toString() ?? "0"
              }
            >
              {[
                { id: 0, name: "All Seasons" },
                ...registrationBatches.map((registrationBatch: any) => {
                  return {
                    id: registrationBatch.id,
                    name: registrationBatch.name,
                  };
                }),
              ]}
            </FormFieldSelect>
          </div>
          <div className="w-64">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                setRegistrationReportFilter((prevState) => {
                  return {
                    ...prevState,
                    registrationTypeId: value == "0" ? null : parseInt(value),
                  };
                });
              }}
              name="registrationTypeId"
              label="Registration Type"
              placeholder="Registration Type"
              value={
                registrationReportFilter.registrationTypeId?.toString() ?? "0"
              }
            >
              {[
                { id: 0, name: "All Reg Types" },
                ...registrationTypes.map((registrationType: any) => {
                  return {
                    id: registrationType.id,
                    name: registrationType.type,
                  };
                }),
              ]}
            </FormFieldSelect>
          </div>
          <div className="w-64">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                setRegistrationReportFilter((prevState) => {
                  return {
                    ...prevState,
                    dayOfWeekId: value == "-1" ? null : parseInt(value),
                  };
                });
              }}
              name="dayOfWeekId"
              label="Day of Week"
              placeholder="Day of Week"
              value={registrationReportFilter.dayOfWeekId?.toString() ?? "-1"}
            >
              {[
                { id: -1, name: "All Days" },
                ...dayOfWeek.map((day: any) => {
                  return {
                    id: day.id,
                    name: day.name,
                  };
                }),
              ]}
            </FormFieldSelect>
          </div>
          <div className="flex flex-row gap-4">
            <Button
              variant="primary"
              onClick={() => {
                onApplyFilter();
              }}
            >
              Apply
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setRegistrationReportFilter({
                  regionId: null,
                  sportId: null,
                  leagueId: null,
                  registrationBatchId: null,
                  dayOfWeekId: null,
                  registrationTypeId: null,
                });
              }}
            >
              Clear
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <DataTable
            data={registrationReportData}
            columns={registrationReportColumn}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      </div>
    </main>
  );
};

export default Registrations;
