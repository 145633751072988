import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { Pagination } from "../../../../types/types";
import BaseTable from "../../../UI/Table/Table";
import Button from "../../../UI/Button/Button";
import ActionsCell from "../../../UI/ActionCell";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import {
  GetRulesPaginatedQuery,
  ListAllOperations,
  RegistrationBySessionIdQuery,
  useGetRulesPaginatedLazyQuery,
  useRegistrationBySessionIdLazyQuery,
  useUpdateWaitListMutation,
  useWaitListsLazyQuery,
  useWaitListStatusesQuery,
  WaitListsQuery,
} from "../../../../generated/graphql";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { getSports } from "../../../../app/venueMasterSlice";
import { useSessionWorkflowContext } from "../../../../context/SessionWorkflowContext";
import dayjs from "dayjs";
import { dayOfWeek } from "../../../../utils/dayOfWeek";
import { DataTable } from "../../../UI/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { baseURL } from "../../../../utils/baseUrl";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../UI/shadcn/dropdown";
import { MoreVerticalIcon } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../../UI/shadcn/dialog";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../../app/globalSlice";
import FormField from "../../../UI/FormField/FormField";

// List of options for action col
const options = [{ id: 1, text: "Edit" }];

const SessionWorkflowWaitLists: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  /*** STATES ***/
  const { selectedSession, tabGroupValue } = useSessionWorkflowContext();
  const [waitLists, setWaitLists] = useState<WaitListsQuery["waitLists"]>([]);
  const [selectedWaitList, setSelectedWaitList] = useState<
    WaitListsQuery["waitLists"][0] | undefined
  >(undefined);
  const [priorityDialog, setPriorityDialog] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [priorityInput, setPriorityInput] = useState<number>(0);
  const [statusIdInput, setStatusIdInput] = useState<number>(0);

  /*** QUERIES ***/
  const [getWaitLists, { data, loading }] = useWaitListsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const { data: waitListStatusesData } = useWaitListStatusesQuery({});

  /*** MUTATIONS ***/
  const [updateWaitlist, { loading: loadingUpdateWaitList }] =
    useUpdateWaitListMutation({
      refetchQueries: [ListAllOperations.Query.WaitLists],
      onCompleted: (res) => {
        if (res.updateWaitList.success) {
          dispatch(displayAlertSuccess(res.updateWaitList.message));
        } else {
          dispatch(displayAlertError(res.updateWaitList.message));
        }
        setPriorityDialog(false);
        setPriorityInput(0);
        setSelectedWaitList(undefined);
      },
    });

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (selectedSession && tabGroupValue === "Waitlist") {
      getWaitLists({
        variables: {
          waitListInput: {
            sessionId: selectedSession.id,
          },
        },
        onCompleted: (data) => {
          setWaitLists(data.waitLists);
        },
      });
    } else {
      setWaitLists([]);
    }
  }, [selectedSession]);

  /*** UTILITY FUNCTIONS ***/
  const waitListColumns: ColumnDef<WaitListsQuery["waitLists"][0]>[] = [
    {
      accessorKey: "id",
      header: "Id",
      cell: ({ row }) => {
        return <p className="font-medium text-center">{row.getValue("id")}</p>;
      },
    },
    {
      accessorKey: "user",
      header: "Player",
      cell: ({ row }) => {
        return (
          <div>
            <p>
              <a
                className="underline cursor-pointer text-info-50"
                href={`${baseURL}/players/user/${row.original.user.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {row.original.user.firstName} {row.original.user.lastName}
              </a>
            </p>

            {/* <p className="font-medium">
              {row.original.user.firstName} {row.original.user.lastName}
            </p> */}
          </div>
        );
      },
    },
    {
      accessorKey: "user.email",
      header: "Email",
    },
    {
      accessorKey: "session",
      header: "League",
      cell: ({ row }) => {
        return (
          <p className="font-medium">{row.original.session.league.name}</p>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: "Added At",
      cell: ({ row }) => {
        return (
          <p className="font-medium">
            {dayjs(row.original.createdAt).format("YYYY-MM-DD HH:mm")}
          </p>
        );
      },
    },
    {
      accessorKey: "productType.name",
      header: "Product Type",
    },
    {
      accessorKey: "status.name",
      header: "Status",
    },
    {
      accessorKey: "priority",
      header: "Priority",
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  className="hover:bg-primary-95"
                  onClick={() => {
                    setSelectedWaitList(row.original);
                    setPriorityDialog(true);
                    setPriorityInput(row.original.priority);
                  }}
                >
                  Set Priority
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:bg-primary-95"
                  onClick={() => {
                    setSelectedWaitList(row.original);
                    setStatusDialog(true);
                    setStatusIdInput(row.original.statusId);
                  }}
                >
                  Set Status
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  // Constants for the table
  const columns = useMemo(() => waitListColumns, []);
  const tableData = useMemo(() => {
    if (waitLists) return waitLists;
  }, [waitLists]);

  return (
    <main className="flex flex-col gap-4">
      <Dialog
        open={priorityDialog}
        onOpenChange={setPriorityDialog}
      >
        <DialogContent>
          <DialogHeader>Select Priority</DialogHeader>
          <FormField
            type="number"
            label="Priority"
            initialValue={priorityInput ? priorityInput.toString() : "0"}
            inputChange={(e) => {
              setPriorityInput(e.target.value ? parseInt(e.target.value) : 0);
            }}
          ></FormField>
          <DialogFooter className="flex flex-row justify-start gap-2 sm:justify-start">
            <DialogClose>
              <Button
                width="full"
                variant="primary"
                onClick={() => {
                  if (selectedWaitList?.id) {
                    updateWaitlist({
                      variables: {
                        waitListId: selectedWaitList.id,
                        priority: priorityInput,
                      },
                    });
                  }
                }}
              >
                Confirm
              </Button>
            </DialogClose>
            <DialogClose>
              <Button variant="secondary">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={statusDialog}
        onOpenChange={setStatusDialog}
      >
        <DialogContent>
          <DialogHeader>Select Status</DialogHeader>
          <div>
            <FormFieldSelect
              value={statusIdInput.toString()}
              inputChange={(value) => setStatusIdInput(+value)}
              label="Status"
              placeholder="Select Status"
            >
              {[
                { id: 0, name: "Select Status" },
                ...(waitListStatusesData
                  ? waitListStatusesData.waitListStatuses
                  : []),
              ]}
            </FormFieldSelect>
          </div>
          <DialogFooter className="flex flex-row justify-start gap-2 sm:justify-start">
            <DialogClose>
              <Button
                variant="primary"
                onClick={() => {
                  if (selectedWaitList?.id) {
                    updateWaitlist({
                      variables: {
                        waitListId: selectedWaitList.id,
                        statusId: statusIdInput,
                      },
                    });
                  }
                }}
              >
                Confirm
              </Button>
            </DialogClose>
            <DialogClose>
              <Button variant="secondary">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {tableData && (
        <DataTable
          columns={columns}
          data={tableData}
        />
      )}
    </main>
  );
};

export default SessionWorkflowWaitLists;
