import { gql } from "@apollo/client";

const GET_REGIONS = gql`
  query Regions {
    regions {
      abbreviation
      id
      name
    }
  }
`;

const GET_SPORTS = gql`
  query Sports {
    sports {
      id
      name
      scoringType {
        id
        name
      }
    }
  }
`;

const GET_SPORTS_LIST = gql`
  query SportsList {
    sports {
      id
      name
    }
  }
`;

const GET_SPORTS_BY_USERID = gql`
  query SportsByUserId($userId: String!) {
    sportsByUserId(userId: $userId) {
      id
      name
    }
  }
`;

const GET_TIE_BREAK_POLICIES = gql`
  query TieBreakPolicy {
    tieBreakPolicies {
      id
      name
    }
  }
`;

const GET_GENDER_FORMAT = gql`
  query GenderFormats {
    genderFormats {
      id
      name
    }
  }
`;

const GET_VENUE_TYPE = gql`
  query VenueTypes {
    venueTypes {
      id
      name
    }
  }
`;

const GET_STAFF_TYPE = gql`
  query StaffTypes($regions: [Int]) {
    staffTypes(regions: $regions) {
      id
      baseRate
      name
      regionId
      isContractor
    }
  }
`;

const GET_OFFICIATED_STATUSES = gql`
  query OfficiatedStatuses {
    officiatedStatuses {
      id
      name
    }
  }
`;

const GET_VENUE_SIZES = gql`
  query VenueSizes {
    venueSizes {
      id
      name
    }
  }
`;

const CREATE_REGISTRATION_BATCH = gql`
  mutation RegistrationBatchCreate(
    $registrationBatchArgs: CreateRegistrationBatchInput!
  ) {
    registrationBatchCreate(registrationBatchArgs: $registrationBatchArgs) {
      code
      message
      success
    }
  }
`;

const UPDATE_REGISTRATION_BATCH = gql`
  mutation RegistrationBatchUpdate(
    $registrationBatchArgs: UpdateRegistrationBatchInput!
  ) {
    registrationBatchUpdate(registrationBatchArgs: $registrationBatchArgs) {
      code
      message
      success
    }
  }
`;

const DELETE_REGISTRATION_BATCH = gql`
  mutation RegistrationBatchDelete($registrationBatchDeleteId: Int!) {
    registrationBatchDelete(id: $registrationBatchDeleteId) {
      code
      message
      success
    }
  }
`;

const GET_REGISTRATION_BATCH = gql`
  query RegistrationBatches($page: Int, $pageSize: Int) {
    registrationBatches(page: $page, pageSize: $pageSize) {
      registrationBatches {
        id
        name
        regCloseDate
        regOpenDate
        displayPrice
        displaySession
      }
      count
    }
  }
`;

const GET_REGISTRATION_BATCH_BY_ID = gql`
  query RegistrationBatch($registrationBatchId: Int!) {
    registrationBatch(id: $registrationBatchId) {
      id
      name
      regCloseDate
      regOpenDate
      displayPrice
      displaySession
    }
  }
`;

export {
  GET_REGIONS,
  GET_SPORTS,
  GET_TIE_BREAK_POLICIES,
  GET_GENDER_FORMAT,
  GET_STAFF_TYPE,
  GET_VENUE_TYPE,
  GET_OFFICIATED_STATUSES,
  GET_VENUE_SIZES,
  GET_REGISTRATION_BATCH,
  CREATE_REGISTRATION_BATCH,
  UPDATE_REGISTRATION_BATCH,
  GET_REGISTRATION_BATCH_BY_ID,
  DELETE_REGISTRATION_BATCH,
  GET_SPORTS_LIST,
  GET_SPORTS_BY_USERID,
};
