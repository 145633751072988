import React, { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import { SwitchButton } from "../../UI";
import dayjs from "dayjs";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import Button from "../../UI/Button/Button";
import Disclaimer from "../../UI/Alerts/Disclaimer";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Card from "../../UI/Card/Card";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import {
  PlayoffGameInput,
  Team,
  useDivisionByScheduleIdLazyQuery,
  useScheduleStatusesQuery,
  useGameStatusesQuery,
  VenuesTreeQuery,
  DivisionByScheduleIdQuery,
  useScheduleCreateMutation,
  useScheduleUpdateMutation,
  useScheduleByIdLazyQuery,
  useDivisionRemoveTeamMutation,
  useDeleteSchedulesByDivisionIdMutation,
  useTeamsByIdsLazyQuery,
  useUpdateGameMutation,
  useDeleteGameMutation,
  useTeamByIdLazyQuery,
  useUpdateTeamStandingsMutation,
  PlayoffGameTeamsInput,
  useUpdatePlayoffGamesMutation,
  ListAllOperations,
  useDivisionStandingsLazyQuery,
  useVenueTypesLazyQuery,
  useVenuesLazyQuery,
  useGameslotsBySessionIdLazyQuery,
  useUpdateGamesBulkMutation,
  useScheduleTemplatesQuery,
  useScheduleTemplatesLazyQuery,
} from "../../../generated/graphql";
import { Separator } from "../../UI/shadcn/separator";
import GameScheduleNew from "./GameScheduleNew";
import Body1 from "../../UI/Text/Body/Body1";
import { z } from "zod";
import PlayoffGameSchedule from "./PlayoffGameSchedule";
import {
  DetailedTeamScoreRecord,
  GameForCalculatingStandings,
  PlacementToTeam,
  PlayoffGame,
  PlayoffGameNumber,
  RegularGame,
  Schedule,
  ScheduleDetails,
  ScheduleSchema,
  TeamPlayoffMatchupCategory,
} from "./ScheduleTypes";
import {
  createPlacementToTeam,
  createPlayoffMatchupSelectOptions,
  dateForWeek,
  generatePlayoffGames,
} from "./ScheduleHelpers";
import { Divider } from "@mui/material";
import ReplaceTeamsDialog from "../../UI/Dialog/ScheduleReplaceTeamDialog";
import ScheduleHistoryDialog from "../../UI/Dialog/ScheduleHistoryDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import {
  Dialog as ShadCnDialog,
  DialogClose as ShadCnDialogClose,
  DialogContent as ShadCnDialogContent,
  DialogDescription as ShadCnDialogDescription,
  DialogHeader as ShadCnDialogHeader,
  DialogTitle as ShadCnDialogTitle,
  DialogTrigger as ShadCnDialogTrigger,
} from "../../UI/shadcn/dialog";
import {
  Table as ShadcnTable,
  TableBody as ShadcnTableBody,
  TableCell as ShadcnTableCell,
  TableHead as ShadcnTableHead,
  TableHeader as ShadcnTableHeader,
  TableRow as ShadcnTableRow,
} from "../../UI/shadcn/table";
import Tooltip from "../../UI/Tooltip/Tooltip";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../UI/shadcn/select";
import { client } from "../../../graphql";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { DatePicker } from "../../UI/shadcn/Time/date-picker";
import TimeDistributionDialog from "../../UI/Dialog/TimeDistributionDialog";
import AutoScheduleDialog from "../../UI/Dialog/AutoScheduleDialog";
import {
  ScheduleGameslotContextProvider,
  useScheduleGameslotContext,
} from "../../../context/ScheduleGameslotContext";
import { checkGameToGameslotAllocation } from "../../../utils/gameslotChecks";
import { EyeIcon, Loader2 } from "lucide-react";
import TabGroup from "../../UI/Tab/TabGroup/TabGroup";
import TemplateScheduleDialog, {
  GamesByWeek,
  TemplateScheduleGame,
} from "../../UI/Dialog/TemplateScheduleDialog";

export interface TeamList {
  id: number;
  name: string;
  inactive?: boolean;
  regSeasonPlacement: number | null;
}

export interface Template {
  startDateTimeLocal: string;
  venueId: string;
}

export interface AutoScheduleGame {
  homeTeamId: number;
  awayTeamId: number;
  venueId: number;
  startDateTimeLocal: string;
}

const initialScheduleDetails: ScheduleDetails = {
  divisionId: 0,
  scheduleStatusId: 0,
  lastModifiedAt: new Date(),
};

const initialSchedule: Schedule = {
  currentScheduleId: undefined,
  scheduleDetails: initialScheduleDetails,
  regularGames: [],
  playoffGames: [],
};

export const ordinal = (n: number) => {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const hours = Array.from({ length: 24 }, (_, i) => i + 1);
const minutes = Array.from({ length: 60 / 5 }, (_, i) => i * 5);

export const clientURL = `${
  process.env.REACT_APP_ENV === "production"
    ? "https://jamsports.com"
    : process.env.REACT_APP_ENV === "preproduction"
    ? "https://preprod.jamsports.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://jamitall.com"
    : "http://localhost:4001"
}`;

const ScheduleNew: React.FC = () => {
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch<AppDispatch>();

  const { gameslots, setGameslots } = useScheduleGameslotContext();

  /** STATES */
  const [division, setDivision] = useState<
    DivisionByScheduleIdQuery["division"] | undefined
  >(undefined);
  // Set to true if all the teams in the schedule have a regSeasonStanding
  const [activeScheduleStatus, setActiveScheduleStatus] = useState<number>(1);
  const [scheduleStatuses, setScheduleStatuses] = useState<
    { id: number; scheduleStatusName: string }[]
  >([]);
  const [gameStatuses, setGameStatuses] = useState<
    { id: number; name: string }[]
  >([]);
  const [isUpdateSchedule, setIsUpdateSchedule] = useState(false);
  const [allGameVenue, setAllGameVenue] = useState<number>(0);
  const [venuesList, setVenuesList] = useState<VenuesTreeQuery["venuesTree"]>(
    []
  );
  const [schedule, setSchedule] = useState<Schedule>(initialSchedule);
  const [teamsList, setTeamsList] = useState<TeamList[]>([]);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<any>([]);
  const [playoffGameNumberOptions, setPlayoffGameNumberOptions] = useState<
    PlayoffGameNumber[]
  >([]);
  const [placementToTeam, setPlacementToTeam] = useState<PlacementToTeam[]>([]);
  const [openReplaceTeamsDialog, setOpenReplaceTeamsDialog] = useState(false);
  const [typeReplaceTeamsDialog, setTypeReplaceTeamsDialog] = useState<
    "Swap" | "Replace"
  >("Swap");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [typeConfirmDialog, setConfirmDialog] = useState<{
    type: "RemoveTeam" | "BlowUp";
    title: string;
    description: string;
    variables?: { teamId?: number; gameId?: number };
  }>({
    type: "RemoveTeam",
    title: "",
    description: "",
  });
  const [openGameActionDialog, setOpenGameActionDialog] = useState(false);
  const [bulkUpdateDialog, setBulkUpdateDialog] = useState<{
    open: boolean;
    type: "Reschedule" | "Game Status";
    gameIds: number[];
    startDateTimeLocal: string;
    gameStatusId: number;
  }>({
    open: false,
    type: "Reschedule",
    gameIds: [],
    startDateTimeLocal: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    gameStatusId: 1,
  });
  const [typeGameAction, setTypeGameAction] = useState<{
    type: "Reschedule" | "Game Status" | "Score Standings";
    title: string;
    data: any;
  }>({
    type: "Reschedule",
    title: "",
    data: undefined,
  });
  const [standingsData, setStandingsData] = useState<DetailedTeamScoreRecord[]>(
    []
  );
  const [isPlayoffFinalized, setIsPlayoffFinalized] = useState(false);
  const [openTimeDistributionDialog, setOpenTimeDistributionDialog] =
    useState(false);
  const [openAutoScheduleDialog, setOpenAutoScheduleDialog] = useState(false);
  const [openTemplateScheduleDialog, setOpenTemplateScheduleDialog] =
    useState(false);
  const [checkGames, setCheckGames] = useState<string[]>([]);

  /** MUTATIONS */
  const [
    CreateSchedule,
    { data: dataCreate, loading: loadingCreate, error: errorCreate },
  ] = useScheduleCreateMutation();
  const [
    UpdateSchedule,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useScheduleUpdateMutation();
  const [
    RemoveTeamFromDivision,
    { data: dataRemoveTeam, loading: loadingRemoveTeam },
  ] = useDivisionRemoveTeamMutation();
  const [
    DeleteScheduleByDivisionId,
    { data: dataDeleteSchedule, loading: loadingDeleteSchedule },
  ] = useDeleteSchedulesByDivisionIdMutation();
  const [UpdateGame, { data: dataUpdateGame, loading: loadingUpdateGame }] =
    useUpdateGameMutation();
  const [BulkUpdateGame] = useUpdateGamesBulkMutation();
  const [DeleteGame, { data: dataDeleteGame, loading: loadingDeleteGame }] =
    useDeleteGameMutation();

  /** QUERIES */

  const [getScheduleById, { data: scheduleById }] = useScheduleByIdLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data) {
        const regGames = [...data.scheduleById.regularGames].map((game) => {
          return { ...game, gameTypeId: 1 };
        });
        const playoffGames = [...data.scheduleById.playoffGames].map((game) => {
          return { ...game, gameTypeId: 2 };
        });
        const allGames = [...regGames, ...playoffGames];
        setScheduleModal(true);
        setSelectedSchedule(allGames);
      }
    },
  });

  const [getVenueTree, { loading: loadingVenueTree }] = useVenuesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data) {
        setVenuesList(data.venues.venues);
      }
    },
  });

  const [getDivisionStandings, { loading: loadingDivisionStandings }] =
    useDivisionStandingsLazyQuery({
      fetchPolicy: "network-only",

      onCompleted: (data) => {
        if (data && data.divisionStandings.length > 0) {
          const standings: DetailedTeamScoreRecord[] =
            data.divisionStandings.map((standing) => {
              return {
                teamId: standing?.teamId || 0,
                teamName: standing?.teamName || "",
                gamesPlayed: standing?.standings.gamesPlayed || 0,
                teamPointsRecord: {
                  pointsTotal: standing?.standings.pointsTotal || 0,
                  pointsFor: standing?.standings.scoringFor || 0,
                  pointsAgainst: standing?.standings.scoringAgainst || 0,
                  pointsDifference: standing?.standings.scoringDifference || 0,
                },
                scoreRecord: {
                  wins: standing?.standings.wins || 0,
                  losses: standing?.standings.losses || 0,
                  ties: standing?.standings.ties || 0,
                  defaultLosses: standing?.standings.defaultLosses || 0,
                },
              };
            });
          standings.sort((a, b) => {
            return (
              b.teamPointsRecord.pointsTotal - a.teamPointsRecord.pointsTotal
            );
          });
          setStandingsData(standings);
        }
      },
    });

  const [
    divisionByScheduleId,
    {
      data: divisionById,
      error: divisionError,
      loading: divisionLoading,
      refetch: refetchDivisionByScheduleId,
    },
  ] = useDivisionByScheduleIdLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        setDivision(data.division);

        getVenueTree({
          variables: {
            venueFilters: {
              regionId: [data.division.session.league.sportFormat.region.id],
              sizeId: data.division.session.league.sportFormat.venueSizeId,
            },
          },
        });
        if (data.division.session)
          getScheduleGameslots({
            variables: {
              sessionId: data.division.session.id,
            },
          });
        const allTeamsInDivision: TeamList[] = data.division.teams.map(
          (team) => {
            return {
              id: team.id,
              name: team.name,
              inactive: false,
              regSeasonPlacement:
                team.regSeasonStanding === undefined
                  ? null
                  : team.regSeasonStanding,
            };
          }
        );
        setTeamsList(allTeamsInDivision);

        const gamesPerOccurrence: number =
          data.division.session.league.gamesPerOccurrence || 1;
        /// number of games per week
        const numberOfGames =
          Math.ceil((data.division.teams?.length || 0) / 2) *
          gamesPerOccurrence;

        let playoffGameNumber = 0;

        setPlacementToTeam(createPlacementToTeam(data.division));
        //Update the schedule status
        if (data.division.latestSchedule) {
          setIsUpdateSchedule(true);
          setActiveScheduleStatus(
            data.division.latestSchedule.scheduleStatus.id
          );
          const allTeamsInDivision: number[] = data.division.teams.map(
            (team) => team.id
          );
          const teamsNotInDivision: number[] = [];
          setSchedule({
            currentScheduleId: data.division.latestSchedule.id,
            scheduleDetails: {
              divisionId: data.division.id,
              scheduleStatusId: data.division.latestSchedule.scheduleStatus.id,
              lastModifiedAt: new Date(
                data.division.latestSchedule.lastModifiedAt
              ),
            },
            playoffGames: data.division.latestSchedule.playoffGames
              .filter((game) => game.gameType.id === 2)
              .map((game) => {
                return {
                  id: game.id,
                  startDateTimeLocal: game.startDateTimeLocal,
                  venueId: +game.venue.id,
                  homeTeamId: game.homeTeam ? +game.homeTeam.id : undefined,
                  awayTeamId: game.awayTeam ? +game.awayTeam.id : undefined,
                  gameTypeId: +game.gameType.id,
                  isVisible: game.isVisible,
                  week: game.week,
                  gameStatusId: game.gameStatus ? +game.gameStatus.id : 1,
                  countHomeScore: !!game.countHomeScore,
                  countAwayScore: !!game.countAwayScore,
                  homeTeamType: game.gamePlayoffMatchup?.homeTeamType ?? 0,
                  homeTeamValue: game.gamePlayoffMatchup?.homeTeamValue ?? 0,
                  awayTeamType: game.gamePlayoffMatchup?.awayTeamType ?? 0,
                  awayTeamValue: game.gamePlayoffMatchup?.awayTeamValue ?? 0,
                  playoffGameNumber: game.playoffGameNumber ?? 0, // length of playoff?
                  isChampGame: game.isChampGame,
                  isDeleted: game.isDeleted,
                  isDoubleHeader: game.isDoubleHeader,
                  regionId: game.regionId,
                };
              })
              .sort(
                (a, b) =>
                  (a.playoffGameNumber ?? 0) - (b.playoffGameNumber ?? 0)
              ),
            regularGames: data.division.latestSchedule.regularGames
              .filter((game) => game.gameType.id === 1)
              .map((game) => {
                //Check if there are games with teams not in allTeamsInDivision
                if (
                  game.homeTeam &&
                  !allTeamsInDivision.includes(+game.homeTeam.id) &&
                  !teamsNotInDivision.includes(+game.homeTeam.id)
                )
                  teamsNotInDivision.push(+game.homeTeam.id);

                if (
                  game.awayTeam &&
                  !allTeamsInDivision.includes(+game.awayTeam.id) &&
                  !teamsNotInDivision.includes(+game.awayTeam.id)
                )
                  teamsNotInDivision.push(+game.awayTeam.id);

                return {
                  id: game.id,
                  startDateTimeLocal: game.startDateTimeLocal,
                  venueId: +game.venue.id,
                  homeTeamId: game.homeTeam ? +game.homeTeam.id : undefined,
                  awayTeamId: game.awayTeam ? +game.awayTeam.id : undefined,
                  gameTypeId: +game.gameType.id,
                  isVisible: game.isVisible,
                  week: game.week,
                  gameStatusId: game.gameStatus ? +game.gameStatus.id : 1,
                  countHomeScore: !!game.countHomeScore,
                  countAwayScore: !!game.countAwayScore,
                  isChampGame: game.isChampGame,
                  isDoubleHeader: game.isDoubleHeader,
                  regionId: game.regionId,
                };
              }),
          });
          if (teamsNotInDivision.length > 0) {
            teamsByIds({
              variables: {
                teamIds: teamsNotInDivision,
              },
            });
          }
          setIsPlayoffFinalized(
            data.division.teams.every((team) => team.regSeasonStanding !== null)
          );
          getDivisionStandings({
            variables: {
              divisionId: data.division.id,
            },
          });
          playoffGameNumber = data.division.latestSchedule.playoffGames.length;
        } else {
          let weekNumber = 0;
          let skippedWeek = 0;
          const regularGames: RegularGame[] = [];

          const teamsArray =
            data.division.teams?.filter(
              (team): team is Team => team !== null
            ) || [];

          let regularWeeks =
            data.division.numberOfWeeks - data.division.weeksOfPlayoffs;

          for (let iWeek = 0; iWeek < regularWeeks; iWeek++) {
            // setting consecutive weeks
            const week = dayjs(data.division.startDate).add(iWeek * 7, "days");
            const weekFormat = week.format("dddd YYYY-MM-DD");

            // check if week is an exclusion date: if so will not create GameSchedule Table
            const isExclusionDate = data.division.exceptionDates?.find(
              (date) => dayjs(date).format("dddd YYYY-MM-DD") === weekFormat
            );

            if (isExclusionDate) {
              skippedWeek++;
              regularWeeks++;
              continue;
            }
            weekNumber += 1;
            if (
              weekNumber <=
              data.division.numberOfWeeks - data.division.weeksOfPlayoffs
            ) {
              for (let iGame = 0; iGame < numberOfGames; iGame++) {
                regularGames.push({
                  id: undefined,
                  startDateTimeLocal: dayjs(weekFormat)
                    .hour(18)
                    .format("YYYY-MM-DD H:mm:ss"),
                  venueId: 0,
                  homeTeamId:
                    +teamsArray[(iGame % (numberOfGames / 2)) * 2]?.id,
                  awayTeamId:
                    +teamsArray[1 + (iGame % (numberOfGames / 2)) * 2]?.id,
                  gameTypeId: 1, //Regular Schedule
                  isVisible: true, //Not visible
                  week: weekNumber,
                  gameStatusId: 1,
                  countHomeScore: true,
                  countAwayScore: true,
                  isDeleted: false,
                  isChampGame: false,
                  isDoubleHeader: false,
                  regionId: data.division.session.league.sportFormat.region.id,
                });
              }
            }
          }
          const playoffGames = generatePlayoffGames(
            teamsArray,
            dayjs(data.division.startDate)
              .add(skippedWeek + weekNumber, "weeks")
              .format("YYYY-MM-DD"),
            data.division.weeksOfPlayoffs,
            data.division.numberOfWeeks - data.division.weeksOfPlayoffs,
            data.division.exceptionDates,
            data.division.session.league.gamesPerOccurrence,
            data.division.session.league.sportFormat.region.id
          );
          setSchedule({
            currentScheduleId: 0,
            scheduleDetails: {
              divisionId: data.division.id,
              scheduleStatusId: activeScheduleStatus,
              lastModifiedAt: new Date(),
            },
            regularGames: regularGames,
            playoffGames: playoffGames,
          });

          playoffGameNumber = data.division.weeksOfPlayoffs * numberOfGames;
        }

        setPlayoffGameNumberOptions(
          Array.from({ length: playoffGameNumber }, (_, index) => ({
            id: index + 1,
            name: `${index + 1}`,
          }))
        );
      }
    },
  });

  const { loading: loadingScheduleStatus } = useScheduleStatusesQuery({
    onCompleted: (data) => {
      if (data) {
        setScheduleStatuses(data.scheduleStatuses);
      }
    },
  });

  const { loading: loadingGameStatus } = useGameStatusesQuery({
    onCompleted: (data) => {
      if (data) {
        setGameStatuses(data.gameStatuses);
      }
    },
  });

  const [teamsByIds, { loading: loadingTeams }] = useTeamsByIdsLazyQuery({
    onCompleted: (data) => {
      if (data) {
        if (data.teamsByIds) {
          const inactiveTeams: TeamList[] = [];
          data.teamsByIds.map((team) => {
            if (team) {
              inactiveTeams.push({
                id: team.id,
                name: team.name,
                inactive: true,
                regSeasonPlacement: null,
              });
            }
          });
          setTeamsList([...teamsList, ...inactiveTeams]);
        }
      }
    },
  });

  const [teamById, { loading: loadingTeamById }] = useTeamByIdLazyQuery({
    onCompleted: (data) => {
      if (data && data.team) {
        const captain = data.team.teamPlayers.find(
          (teamPlayer) =>
            teamPlayer?.teamPlayerRole.teamPlayerRoleName === "captain"
        );
        const url = `${clientURL}/teams/team-info/${data.team.id}?userId=${captain?.playerUserProfile.id}`;
        window.open(url, "_blank");
      }
    },
  });

  const [getScheduleGameslots] = useGameslotsBySessionIdLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data) {
        setGameslots(data.gameslotsBySessionId);
      }
    },
  });

  const [updateTeamsStandings, { loading: loadingUpdateTeamsStandings }] =
    useUpdateTeamStandingsMutation();
  const [updatePlayoffGames, { loading: loadingUpdatePlayoffGames }] =
    useUpdatePlayoffGamesMutation();

  useEffect(() => {
    if (id) {
      divisionByScheduleId({
        variables: {
          divisionId: +id,
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (allGameVenue) {
      if (
        isUpdateSchedule &&
        division?.latestSchedule?.scheduleStatus.id === 2
      ) {
        setSchedule((prev) => ({
          ...prev,
          regularGames: prev.regularGames.map((game) => {
            if (game.startDateTimeLocal < dayjs().format("YYYY-MM-DD HH:mm:ss"))
              return game;
            else
              return {
                ...game,
                venueId: +allGameVenue,
              };
          }),
          playoffGames: prev.playoffGames.map((game) => {
            if (game.startDateTimeLocal < dayjs().format("YYYY-MM-DD HH:mm:ss"))
              return game;
            else
              return {
                ...game,
                venueId: +allGameVenue,
              };
          }),
        }));
      } else {
        setSchedule((prev) => ({
          ...prev,
          regularGames: prev.regularGames.map((game) => {
            return {
              ...game,
              venueId: +allGameVenue,
            };
          }),
          playoffGames: prev.playoffGames.map((game) => {
            return {
              ...game,
              venueId: +allGameVenue,
            };
          }),
        }));
      }
    }
  }, [allGameVenue]);

  useEffect(() => {
    if (gameslots) {
      checkGamesWarnings(divisionById?.division.latestSchedule);
    }
  }, [gameslots, divisionById?.division.latestSchedule]);

  /** FUNCTIONS */

  const checkGamesWarnings = (
    schedule: DivisionByScheduleIdQuery["division"]["latestSchedule"]
  ) => {
    const ids: string[] = [];
    if (!gameslots) return;
    schedule?.regularGames.forEach((game) => {
      if (game.startDateTimeLocal && game.venue.id) {
        const isGameslotAvailable = checkGameToGameslotAllocation(gameslots, {
          startDateTimeLocal: game.startDateTimeLocal,
          duration: divisionById?.division.session.league.gameTimeslot || 45,
          venueId: game.venue.id,
        });
        if (!isGameslotAvailable) {
          ids.push(game.id.toString());
        }
      }
    });
    schedule?.playoffGames.forEach((game) => {
      if (game.startDateTimeLocal && game.venue.id) {
        const isGameslotAvailable = checkGameToGameslotAllocation(gameslots, {
          startDateTimeLocal: game.startDateTimeLocal,
          duration: divisionById?.division.session.league.gameTimeslot || 45,
          venueId: game.venue.id,
        });
        if (!isGameslotAvailable) {
          ids.push(game.id.toString());
        }
      }
    });
    setCheckGames(ids);
  };

  const handleAddGame = (startDate: string, week: number, gameType: number) => {
    if (gameType === 1) {
      setSchedule((prev) => ({
        ...prev,
        regularGames: [
          ...prev.regularGames,
          {
            id: undefined,
            startDateTimeLocal: dayjs(startDate)
              .hour(18)
              .minute(0)
              .format("YYYY-MM-DD HH:mm:ss"),
            venueId: 0,
            homeTeamId: undefined,
            awayTeamId: undefined,
            gameTypeId: gameType,
            isVisible: false,
            week: week,
            gameStatusId: 1,
            countHomeScore: true,
            countAwayScore: true,
            isDeleted: false,
            isChampGame: false,
            isDoubleHeader: false,
            regionId:
              divisionById?.division.session.league.sportFormat.region.id || 0,
          },
        ],
      }));
    } else {
      setPlayoffGameNumberOptions((prevState) => [
        ...prevState,
        { id: prevState.length + 1, name: `${prevState.length + 1}` },
      ]);
      setSchedule((prev) => ({
        ...prev,
        playoffGames: [
          ...prev.playoffGames,
          {
            id: undefined,
            startDateTimeLocal: dayjs(startDate)
              .hour(18)
              .minute(0)
              .format("YYYY-MM-DD HH:mm:ss"),
            venueId: 0,
            homeTeamId: undefined,
            awayTeamId: undefined,
            gameTypeId: 2, // Playoff Schedule
            isVisible: false, //Not visible
            week: week,
            gameStatusId: 1,
            countHomeScore: true,
            countAwayScore: true,
            homeTeamType: 0,
            homeTeamValue: 0,
            awayTeamType: 0,
            awayTeamValue: 0,
            playoffGameNumber: prev.playoffGames.length + 1,
            isChampGame: false,
            isDeleted: false,
            isDoubleHeader: false,
            divisionId: id ? +id : 0,
            regionId:
              divisionById?.division.session.league.sportFormat.region.id || 0,
          },
        ],
      }));
    }
  };

  const handleRemoveGame = (week: number, index: number, gameType: number) => {
    if (gameType === 1) {
      // If on edit mode, set isDeleted to true
      const regularGames = [...schedule.regularGames];
      const weekGames = regularGames.filter((game) => game.week === week);
      const game = weekGames[index];
      if (game.id) {
        game.isDeleted = !game.isDeleted;
        setSchedule((prev) => ({
          ...prev,
          regularGames: regularGames,
        }));
        return;
      }

      if (index > -1 && index < weekGames.length) weekGames.splice(index, 1);

      const updatedRegularGames = [
        ...regularGames.filter((game) => game.week !== week),
        ...weekGames,
      ];

      setSchedule((prev) => ({
        ...prev,
        regularGames: updatedRegularGames,
      }));
    } else {
      // If on edit mode, set isDeleted to true
      const playoffGames = [...schedule.playoffGames];
      const weekGames = playoffGames.filter((game) => game.week === week);
      const game = weekGames[index];
      if (game.id) {
        game.isDeleted = !game.isDeleted;
        setSchedule((prev) => ({
          ...prev,
          playoffGames: playoffGames,
        }));
        return;
      }

      if (index > -1 && index < weekGames.length) weekGames.splice(index, 1);

      const updatedPlayoffGames = [
        ...playoffGames.filter((game) => game.week !== week),
        ...weekGames,
      ];
      setPlayoffGameNumberOptions((prevState) =>
        prevState.slice(0, prevState.length - 1)
      );

      setSchedule((prev) => ({
        ...prev,
        playoffGames: updatedPlayoffGames,
      }));
    }
  };

  const renderRegularWeeksOfPlay = () => {
    const weeksOfPlay = [];
    if (division) {
      for (
        let i = 1;
        i <= division.numberOfWeeks - division.weeksOfPlayoffs;
        i++
      ) {
        const isFutureGame =
          dayjs(dateForWeek(i, division)).isAfter(dayjs()) ||
          schedule.scheduleDetails.scheduleStatusId === 1;
        const gamesForWeek =
          schedule?.regularGames.filter((game) => game.week === i) || [];
        weeksOfPlay.push(
          <div>
            <div className="flex items-center justify-between mb-2">
              <Body1>{`Week ${i} - ${dateForWeek(i, division)}`}</Body1>
              <div className="flex flex-row gap-4">
                <Button
                  variant="secondary"
                  onClick={async () => {
                    const gameIds = gamesForWeek.map((game) => game.id ?? 0);
                    if (gameIds.some((id) => id === 0)) {
                      dispatch(
                        displayAlertWarning(
                          "All games must have ids to bulk update"
                        )
                      );
                    }
                    setBulkUpdateDialog((prev) => ({
                      ...prev,
                      open: true,
                      gameIds: gameIds,
                      startDateTimeLocal: gamesForWeek[0].startDateTimeLocal,
                    }));
                  }}
                >
                  Bulk Update
                </Button>
                <Button
                  variant={isFutureGame ? "secondary" : "disabled"}
                  onClick={() => {
                    handleAddGame(dateForWeek(i, division), i, 1);
                  }}
                  disabled={!isFutureGame}
                >
                  <AddIcon />
                  Add Game
                </Button>
              </div>
            </div>
            <GameScheduleNew
              key={i}
              games={
                schedule?.regularGames.filter((game) => game.week === i) || []
              }
              regionId={division.session.league.sportFormat.region.id}
              venues={venuesList}
              gameStatuses={gameStatuses}
              handleChange={(index, column: keyof RegularGame, value) => {
                handleRegularGameChange(i, index, column, value);
              }}
              teams={teamsList}
              isEdit={division?.latestSchedule ? true : false}
              isPublishedSchedule={
                division?.latestSchedule?.scheduleStatus.id === 2
              }
              handleGameAction={(type, gameId) => {
                const game = schedule?.regularGames.find(
                  (game) => game.id === gameId
                );
                if (!game) {
                  dispatch(
                    displayAlertError(`Couldn't find game with id ${gameId}`)
                  );
                  return;
                }
                const title =
                  type === "Reschedule"
                    ? "Reschedule Game"
                    : type === "Game Status"
                    ? "Change Game Status"
                    : "Score Standings";

                setOpenGameActionDialog(true);
                setTypeGameAction({
                  type: type,
                  title: title,
                  data: game,
                });
              }}
              handleRemoveGame={(index) => {
                handleRemoveGame(i, index, 1);
              }}
              duration={
                divisionById?.division.session.league.gameTimeslot || 45
              }
            />
          </div>
        );
      }
      return weeksOfPlay;
    }
  };

  const renderPlayoffWeeksOfPlay = () => {
    const weeksOfPlay = [];
    if (division) {
      for (
        let i = division.numberOfWeeks - division.weeksOfPlayoffs + 1;
        i <= division.numberOfWeeks;
        i++
      ) {
        const isFutureGame =
          dayjs(dateForWeek(i, division)).isAfter(dayjs()) ||
          schedule.scheduleDetails.scheduleStatusId === 1;
        const gamesForWeek =
          schedule?.playoffGames.filter((game) => game.week === i) || [];
        weeksOfPlay.push(
          <div>
            <div className="flex items-center justify-between mb-2">
              <Body1>{`Week ${i} - ${dateForWeek(i, division)}`}</Body1>
              <div className="flex flex-row gap-4">
                <Button
                  variant="secondary"
                  onClick={() => {
                    const gameIds = gamesForWeek.map((game) => game.id ?? 0);
                    if (gameIds.some((id) => id === 0)) {
                      dispatch(
                        displayAlertWarning(
                          "All games must have ids to bulk update"
                        )
                      );
                    }
                    setBulkUpdateDialog((prev) => ({
                      ...prev,
                      open: true,
                      gameIds: gameIds,
                      startDateTimeLocal: gamesForWeek[0].startDateTimeLocal,
                    }));
                  }}
                >
                  Bulk Update
                </Button>
                <Button
                  variant={isFutureGame ? "secondary" : "disabled"}
                  onClick={() => {
                    handleAddGame(dateForWeek(i, division), i, 2);
                  }}
                  disabled={!isFutureGame}
                >
                  <AddIcon />
                  Add Game
                </Button>
              </div>
            </div>
            <PlayoffGameSchedule
              key={i}
              games={
                schedule?.playoffGames.filter((game) => game.week === i) || []
              }
              venues={venuesList}
              gameStatuses={gameStatuses}
              handleChange={(index, column: keyof PlayoffGame, value) => {
                handlePlayoffGameChange(i, index, column, value);
              }}
              handleChangeMatchup={(
                index,
                column: keyof PlayoffGame,
                newMatchupValue
              ) => {
                handlePlayoffGameMatchupChange(
                  i,
                  index,
                  column,
                  newMatchupValue
                );
              }}
              teamPlayoffMatchups={createPlayoffMatchupSelectOptions(
                division,
                schedule.playoffGames.length
              )}
              isEdit={division?.latestSchedule ? true : false}
              isPublishedSchedule={
                division?.latestSchedule?.scheduleStatus.id === 2
              }
              handleRemoveGame={(index) => {
                handleRemoveGame(i, index, 2);
              }}
              handleGameAction={(type, gameId) => {
                const game = schedule?.playoffGames.find(
                  (game) => game.id === gameId
                );
                if (!game) {
                  dispatch(
                    displayAlertError(`Couldn't find game with id ${gameId}`)
                  );
                  return;
                }
                const title =
                  type === "Reschedule"
                    ? "Reschedule Game"
                    : type === "Game Status"
                    ? "Change Game Status"
                    : "Score Standings";

                setOpenGameActionDialog(true);
                setTypeGameAction({
                  type: type,
                  title: title,
                  data: game,
                });
              }}
              playoffGameNumberOptions={playoffGameNumberOptions}
              regionId={division.session.league.sportFormat.region.id}
              duration={
                divisionById?.division.session.league.gameTimeslot || 45
              }
            />
          </div>
        );
      }
      return weeksOfPlay;
    }
  };

  const handleRegularGameChange = (
    week: number,
    index: number,
    column: string,
    value: string
  ) => {
    setSchedule((prev) => ({
      ...prev,
      regularGames: [
        ...prev.regularGames.filter((game) => game.week !== week),
        ...prev.regularGames
          .filter((game) => game.week === week)
          .map((game, i) => {
            if (i === index && game.week === week) {
              let valueUpdate: string | number | boolean = value;
              if (
                column === "homeTeamId" ||
                column === "awayTeamId" ||
                column === "venueId"
              )
                valueUpdate = +valueUpdate;
              if (column == "isDoubleHeader" || column === "isVisible")
                valueUpdate = valueUpdate == "true" ? true : false;
              return {
                ...game,
                [column]: valueUpdate,
              };
            }
            return game;
          }),
      ],
    }));
  };

  const handlePlayoffGameChange = (
    week: number,
    index: number,
    column: string,
    value: string
  ) => {
    setSchedule((prev) => ({
      ...prev,
      playoffGames: [
        ...prev.playoffGames.filter((game) => game.week !== week),
        ...prev.playoffGames
          .filter((game) => game.week === week)
          .map((game, i) => {
            if (i === index && game.week === week) {
              let valueUpdate: string | number | boolean = value;
              if (
                column === "homeTeamId" ||
                column === "awayTeamId" ||
                column === "venueId" ||
                column === "playoffGameNumber"
              )
                valueUpdate = +valueUpdate;
              if (
                column == "isDoubleHeader" ||
                column === "isVisible" ||
                column === "isChampGame"
              )
                valueUpdate = valueUpdate == "true" ? true : false;
              return {
                ...game,
                [column]: valueUpdate,
              };
            }
            return game;
          }),
      ],
    }));
  };

  const handlePlayoffGameMatchupChange = (
    week: number,
    index: number,
    column: string,
    newMatchupValue: TeamPlayoffMatchupCategory | undefined
  ) => {
    if (!newMatchupValue) {
      dispatch(displayAlertError("Error: Invalid matchup value"));
      return;
    }
    let value = "";
    let type = "";
    if (column === "homeTeamId") {
      value = "homeTeamValue";
      type = "homeTeamType";
    } else {
      value = "awayTeamValue";
      type = "awayTeamType";
    }
    setSchedule((prev) => ({
      ...prev,
      playoffGames: [
        ...prev.playoffGames.filter((game) => game.week !== week),
        ...prev.playoffGames
          .filter((game) => game.week === week)
          .map((game, i) => {
            if (i === index) {
              // If the standings have been finalized and the matchup type is placement then also set the home or away team id based on the placementToTeam
              return {
                ...game,
                [value]: newMatchupValue.value,
                [type]: newMatchupValue.type,
                ...(newMatchupValue.type === 1 &&
                  isPlayoffFinalized && {
                    [column]:
                      placementToTeam.find(
                        (p) => p.placement == newMatchupValue.value
                      )?.teamId ?? undefined,
                  }),
              };
            }
            return game;
          }),
      ],
    }));
  };

  // Render Weeks of Play according to Division and Exclusion Dates
  async function handleSubmitSchedule() {
    // Error Handling: Make sure that venues are from choosen vendor only!
    // Make sure if vendor resets/changes then venues are reset to 0.
    // apply validations
    const validationResult = ScheduleSchema.safeParse(schedule);

    if (!validationResult.success) {
      validationResult.error.issues.map((issue) => {
        dispatch(displayAlertError(issue.message));
      });
      return;
    }

    const playoffsGames: PlayoffGameInput[] = schedule.playoffGames.map(
      (game) => {
        return {
          id: game.id,
          startDateTimeLocal: game.startDateTimeLocal,
          venueId: game.venueId,
          homeTeamId: game.homeTeamId,
          awayTeamId: game.awayTeamId,
          gameTypeId: game.gameTypeId,
          isVisible: game.isVisible,
          week: game.week,
          gameStatusId: game.gameStatusId,
          countHomeScore: game.countHomeScore,
          countAwayScore: game.countAwayScore,
          homeTeamType: game.homeTeamType,
          homeTeamValue: game.homeTeamValue,
          awayTeamType: game.awayTeamType,
          awayTeamValue: game.awayTeamValue,
          playoffGameNumber: game.playoffGameNumber,
          isChampGame: game.isChampGame,
          isDeleted: game.isDeleted,
          isDoubleHeader: game.isDoubleHeader,
          regionId: division?.session?.league?.sportFormat?.region?.id || 0,
        };
      }
    );

    const scheduleDetails = {
      divisionId: schedule.scheduleDetails.divisionId,
      scheduleStatusId: activeScheduleStatus,
    };

    if (!schedule.currentScheduleId) {
      await CreateSchedule({
        variables: {
          scheduleArgs: {
            scheduleInput: scheduleDetails,
            regularGames: schedule.regularGames,
            playoffGames: playoffsGames,
          },
        },
        refetchQueries: [ListAllOperations.Query.DivisionByScheduleId],
        onCompleted: (data) =>
          dispatch(displayAlertSuccess(data.scheduleCreate.message)),
        onError: (err) =>
          dispatch(displayAlertError(err.message ?? "Something went wrong!")),
      });
    } else {
      await UpdateSchedule({
        variables: {
          scheduleArgs: {
            scheduleInput: scheduleDetails,
            regularGames: schedule.regularGames,
            playoffGames: playoffsGames,
            currentScheduleId: schedule.currentScheduleId,
          },
        },
        refetchQueries: [ListAllOperations.Query.DivisionByScheduleId],
        onCompleted: (data) =>
          dispatch(displayAlertSuccess(data.scheduleUpdate.message)),
        onError: (err) =>
          dispatch(displayAlertError(err.message ?? "Something went wrong!")),
      });
    }
  }

  // const handleRestoreSchedule = () => {
  //   const scheduleDetails: ScheduleDetails = {
  //     divisionId: schedule.scheduleDetails.divisionId,
  //     scheduleStatusId: 1,
  //     lastModifiedAt: new Date(),
  //   };
  //   let restoreScheduleGames: GameInput[] = [];
  //   let restoreScheduleTeamsId: number[] = [];
  //   selectedSchedule.flat()?.map((game: any) => {
  //     const gameData = {
  //       id: +game.id,
  //       gameTypeId: +game.gameType.id,
  //       awayTeamId: +game.awayTeam?.id || null,
  //       homeTeamId: +game.homeTeam?.id || null,
  //       startDateTimeLocal: game.startDateTimeLocal,
  //       venueId: +game.venue.id,
  //       isVisible: +game.isVisible,
  //       week: +game.week,
  //       gameStatusId: +game.gameStatus.id,
  //       countAwayScore: !!game.countAwayScore,
  //       countHomeScore: !!game.countHomeScore,
  //       isDeleted: false,
  //       isDoubleHeader: game.isDoubleHeader ?? false,
  //       divisionId: game.divisionId,
  //     };
  //     restoreScheduleGames.push(gameData);
  //     if (
  //       game.homeTeam &&
  //       !restoreScheduleTeamsId.find((id) => id === game.homeTeam?.id)
  //     )
  //       restoreScheduleTeamsId.push(game.homeTeam?.id);
  //     if (
  //       game.awayTeam &&
  //       !restoreScheduleTeamsId.find((id) => id === game.awayTeam?.id)
  //     )
  //       restoreScheduleTeamsId.push(game.awayTeam?.id);

  //     restoreScheduleTeamsId.sort();
  //   });

  //   // Compare the teams in the division with the teams in the schedule
  //   const teamsInDivision =
  //     division?.teams?.map((team) => team.id).sort() || [];
  //   if (teamsInDivision.length !== restoreScheduleTeamsId.length) {
  //     dispatch(
  //       displayAlertError("Teams in the division do not match the schedule")
  //     );
  //     return;
  //   }
  //   const isSameTeams = teamsInDivision
  //     .map((value, index) => +value === restoreScheduleTeamsId[index])
  //     .every(Boolean);
  //   if (!isSameTeams) {
  //     dispatch(
  //       displayAlertError("Teams in the division do not match the schedule")
  //     );
  //     return;
  //   }
  //   RestoreSchedule({
  //     variables: {
  //       scheduleArgs: {
  //         scheduleInput: scheduleDetails,
  //         games: restoreScheduleGames,
  //         currentScheduleId: schedule.currentScheduleId!,
  //       },
  //     },
  //   })
  //     .then(() => {
  //       setScheduleModal(false);
  //       if (id) {
  //         // getScheduleById({
  //         //   variables: {
  //         //     scheduleById: +id,
  //         //   },
  //         // });
  //         divisionByScheduleId({
  //           variables: {
  //             divisionId: +id,
  //           },
  //         });
  //       }
  //       dispatch(displayAlertSuccess("Schedule Restored Sucessfully"));
  //     })
  //     .catch(() => dispatch(displayAlertError("Something went wrong!")));
  // };

  const handleReplaceTeams = (teamA: number, teamB: number) => {
    if (typeReplaceTeamsDialog === "Swap") {
      //Swap teams in the schedule
      const regGames = [
        ...schedule.regularGames.filter(
          (game) =>
            game.startDateTimeLocal < dayjs().format("YYYY-MM-DD HH:mm:ss")
        ),
        ...schedule.regularGames
          .filter(
            (game) =>
              game.startDateTimeLocal >= dayjs().format("YYYY-MM-DD HH:mm:ss")
          )
          .map((game) => {
            if (game.homeTeamId === teamA) {
              game.homeTeamId = teamB;
            } else if (game.homeTeamId === teamB) {
              game.homeTeamId = teamA;
            }
            if (game.awayTeamId === teamA) {
              game.awayTeamId = teamB;
            } else if (game.awayTeamId === teamB) {
              game.awayTeamId = teamA;
            }
            return game;
          }),
      ];
      setSchedule((prev) => ({
        ...prev,
        regularGames: regGames,
      }));
    } else {
      //Replace teams in the schedule
      setSchedule((prev) => ({
        ...prev,
        regularGames: [
          ...prev.regularGames.filter(
            (game) =>
              game.startDateTimeLocal < dayjs().format("YYYY-MM-DD HH:mm:ss")
          ),
          ...prev.regularGames
            .filter(
              (game) =>
                game.startDateTimeLocal >= dayjs().format("YYYY-MM-DD HH:mm:ss")
            )
            .map((game) => {
              if (game.homeTeamId === teamA) {
                game.homeTeamId = teamB;
              }
              if (game.awayTeamId === teamA) {
                game.awayTeamId = teamB;
              }
              return game;
            }),
        ],
      }));
    }
  };

  // const handleRemoveTeam = (teamId: number) => {
  //   //Check if the team is in any of the games not played yet
  //   const isTeamInGame = schedule.regularGames
  //     .filter((game) => {
  //       if (division?.latestSchedule?.scheduleStatus.id === 2) {
  //         return (
  //           game.startDateTimeLocal > dayjs().format("YYYY-MM-DD HH:mm:ss")
  //         );
  //       } else {
  //         return true;
  //       }
  //     })
  //     .find((game) => game.homeTeamId === teamId || game.awayTeamId === teamId);

  //   if (isTeamInGame) {
  //     dispatch(
  //       displayAlertError("Team cannot be removed, it's active in a game.")
  //     );
  //   } else {
  //     setOpenConfirmDialog(true);
  //     setConfirmDialog({
  //       type: "RemoveTeam",
  //       title: "Remove Team",
  //       description: `Are you sure you want to remove ${
  //         teamsList.find((team) => team.id === teamId)?.name
  //       } team?`,
  //       variables: { teamId: teamId },
  //     });
  //   }
  // };

  const handleConfirmDialog = () => {
    if (typeConfirmDialog.type === "RemoveTeam") {
      RemoveTeamFromDivision({
        variables: {
          divisionId: division?.id ?? 0,
          teamId: typeConfirmDialog.variables?.teamId ?? 0,
        },
      })
        .then(() => {
          if (id) {
            divisionByScheduleId({
              variables: {
                divisionId: +id,
              },
            });
          }
          dispatch(displayAlertSuccess("Team Removed Successfully"));
          setOpenConfirmDialog(false);
          setConfirmDialog({
            type: "RemoveTeam",
            title: "",
            description: "",
            variables: {},
          });
        })
        .catch(() => dispatch(displayAlertError("Something went wrong!")));
    }
    if (typeConfirmDialog.type === "BlowUp") {
      DeleteScheduleByDivisionId({
        variables: {
          divisionId: division?.id ?? 0,
        },
      })
        .then(() => {
          if (id) {
            divisionByScheduleId({
              variables: {
                divisionId: +id,
              },
            });
          }
          dispatch(displayAlertSuccess("Schedule Deleted Successfully"));
          setOpenConfirmDialog(false);
          setConfirmDialog({
            type: "RemoveTeam",
            title: "",
            description: "",
            variables: {},
          });
        })
        .catch(() => dispatch(displayAlertError("Something went wrong!")));
    }
  };

  const handleGameActionSave = () => {
    if (!typeGameAction.data || !typeGameAction.data.id) {
      dispatch(displayAlertError("Error: Invalid game data"));
      return;
    }
    UpdateGame({
      variables: {
        id: typeGameAction.data.id,
        gameInput: {
          startDateTimeLocal: typeGameAction.data.startDateTimeLocal,
          venueId: typeGameAction.data.venueId,
          homeTeamId: typeGameAction.data.homeTeamId,
          awayTeamId: typeGameAction.data.awayTeamId,
          gameTypeId: typeGameAction.data.gameTypeId,
          isVisible: typeGameAction.data.isVisible,
          week: typeGameAction.data.week,
          gameStatusId: typeGameAction.data.gameStatusId,
          countHomeScore: typeGameAction.data.countHomeScore ? true : false,
          countAwayScore: typeGameAction.data.countAwayScore ? true : false,
          homeTeamType: typeGameAction.data.homeTeamType,
          homeTeamValue: typeGameAction.data.homeTeamValue,
          awayTeamType: typeGameAction.data.awayTeamType,
          awayTeamValue: typeGameAction.data.awayTeamValue,
          playoffGameNumber: typeGameAction.data.playoffGameNumber,
        },
      },
      onCompleted: (data) => {
        if (data.updateGame.success) {
          dispatch(displayAlertSuccess(data.updateGame.message));
        } else {
          dispatch(displayAlertError(data.updateGame.message));
        }
        setOpenGameActionDialog(false);
        setTypeGameAction({
          type: "Reschedule",
          title: "",
          data: {},
        });
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message ?? "Error updating game"));
      },
      refetchQueries: [ListAllOperations.Query.DivisionByScheduleId],
    });
  };

  async function handleBulkUpdateGames() {
    BulkUpdateGame({
      variables: {
        gameInputs: bulkUpdateDialog.gameIds.map((id) => ({
          id: id,
          gameStatusId: bulkUpdateDialog.gameStatusId,
          startDateTimeLocal:
            bulkUpdateDialog.type === "Reschedule"
              ? bulkUpdateDialog.startDateTimeLocal
              : undefined,
        })),
      },
      onCompleted: (data) => {
        if (data.updateGamesBulk.success) {
          dispatch(displayAlertSuccess(data.updateGamesBulk.message));
        } else {
          dispatch(displayAlertError(data.updateGamesBulk.message));
        }
        setOpenGameActionDialog(false);
        setTypeGameAction({
          type: "Reschedule",
          title: "",
          data: {},
        });
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message ?? "Error updating game"));
      },
      refetchQueries: [ListAllOperations.Query.DivisionByScheduleId],
    });
  }

  async function handleUpdatePlayoffStandings() {
    if (schedule.playoffGames.some((game) => game.id === undefined)) {
      dispatch(
        displayAlertError("Please save the schedule before updating standings")
      );
      return;
    }
    if (placementToTeam.some((placement) => placement.teamId === undefined)) {
      dispatch(displayAlertError("Please assign teams to placements"));
      return;
    }
    const playoffGamesInput: PlayoffGameTeamsInput[] = schedule.playoffGames
      .filter((game) => game.id !== undefined)
      .map((game) => ({
        gameId: game.id!,
        homeTeamId:
          game.homeTeamType == 1
            ? placementToTeam.find((p) => p.placement == game.homeTeamValue)
                ?.teamId ?? undefined
            : game.homeTeamType == 3
            ? game.homeTeamId
            : undefined,
        awayTeamId:
          game.awayTeamType == 1
            ? placementToTeam.find((p) => p.placement == game.awayTeamValue)
                ?.teamId ?? undefined
            : game.awayTeamType == 3
            ? game.awayTeamId
            : undefined,
      }));
    const updateTeamsStatndingResult = await updateTeamsStandings({
      variables: {
        updateTeamStandings: placementToTeam
          .map((placement) => {
            if (!placement.teamId) {
              return null;
            }
            return {
              teamId: placement.teamId,
              teamPlacement: placement.placement,
            };
          })
          .filter((placement) => placement !== null),
      },
      onError: (error) => {
        dispatch(
          displayAlertError(error.message ?? "Error updating team standings")
        );
        return;
      },
    });
    const updatePlayoffGamesResult = await updatePlayoffGames({
      variables: {
        playoffGameTeamsInput: playoffGamesInput,
      },
      onCompleted: async (data) => {
        await refetchDivisionByScheduleId();
        dispatch(displayAlertSuccess("Playoff standings updated successfully"));
      },
      onError: (error) => {
        dispatch(displayAlertError("Error updating playoff games"));
        return null;
      },
    });
    if (!updatePlayoffGamesResult) {
      return;
    }
  }

  const handleCreateTemplateSchedule = async (
    scheduleTemplate: GamesByWeek
  ) => {
    // Update Regular Games with the new schedule
    setSchedule((prev) => ({
      ...prev,
      regularGames: prev.regularGames.map((game, index) => {
        const gamesForWeek = scheduleTemplate.get(game.week - 1);
        if (gamesForWeek) {
          return {
            ...game,
            startDateTimeLocal: dayjs(game.startDateTimeLocal)
              .hour(
                dayjs(
                  gamesForWeek[index % gamesForWeek.length].startDateTimeLocal,
                  "HH:mm"
                ).hour()
              )
              .minute(
                dayjs(
                  gamesForWeek[index % gamesForWeek.length].startDateTimeLocal,
                  "HH:mm"
                ).minute()
              )
              .format("YYYY-MM-DD HH:mm:ss"),
            venueId: gamesForWeek[index % gamesForWeek.length].venueId,
            homeTeamId: gamesForWeek[index % gamesForWeek.length].homeTeamId,
            awayTeamId: gamesForWeek[index % gamesForWeek.length].awayTeamId,
            isDoubleHeader:
              gamesForWeek[index % gamesForWeek.length].isDoubleHeader,
          };
        } else {
          dispatch(
            displayAlertWarning(`Could not apply template to week ${game.week}`)
          );
          return game;
        }
      }),
    }));
  };

  const handleCreateAutoSchedule = async (
    scheduleTemplate: AutoScheduleGame[]
  ) => {
    // Update Regular Games with the new schedule
    setSchedule((prev) => ({
      ...prev,
      regularGames: prev.regularGames.map((game, index) => ({
        ...game,
        startDateTimeLocal: dayjs(game.startDateTimeLocal)
          .hour(
            dayjs(scheduleTemplate[index].startDateTimeLocal, "HH:mm").hour()
          )
          .minute(
            dayjs(scheduleTemplate[index].startDateTimeLocal, "HH:mm").minute()
          )
          .format("YYYY-MM-DD HH:mm:ss"),
        venueId: scheduleTemplate[index].venueId,
        homeTeamId: scheduleTemplate[index].homeTeamId,
        awayTeamId: scheduleTemplate[index].awayTeamId,
      })),
    }));
    setOpenAutoScheduleDialog(false);
  };

  const scheduleHistory = (
    <div className="w-1/3 p-3 mt-3 border-2 ">
      <h4 className="mb-2">Schedule History</h4>
      {division &&
        division.previousSchedules?.map((schedule) => (
          <>
            <div className="flex items-center justify-between w-full gap-2 my-1">
              <div className="">
                {` ${dayjs(schedule?.lastModifiedAt).format(
                  "MMMM DD, YYYY h:mm a"
                )} by ${schedule?.lastModifiedBy?.firstName} ${
                  schedule?.lastModifiedBy?.lastName
                }`}
              </div>
              {/* <Button
                variant="secondary"
                onClick={() => {
                  getScheduleById({
                    variables: {
                      scheduleById: schedule?.id ? +schedule.id : 0,
                    },
                  });
                }}
              >
                Preview/Restore
              </Button> */}
            </div>
            <Divider />
          </>
        ))}
    </div>
  );

  const handleRedirectTeam = (teamId: number) => {
    teamById({
      variables: {
        id: teamId,
      },
    });
  };

  const handleAddGameEachWeek = () => {
    if (division) {
      let weekRegular = division.numberOfWeeks - division.weeksOfPlayoffs;
      // Regular games
      for (let i = 1; i <= weekRegular; i++) {
        const weekDate = dateForWeek(i, division);
        if (
          dayjs(weekDate).isAfter(dayjs()) ||
          schedule.scheduleDetails.scheduleStatusId === 1
        )
          handleAddGame(dateForWeek(i, division), i, 1);
      }
      // Playoff games
      for (let i = weekRegular + 1; i <= division.numberOfWeeks; i++) {
        const weekDate = dateForWeek(i, division);
        if (
          dayjs(weekDate).isAfter(dayjs()) ||
          schedule.scheduleDetails.scheduleStatusId === 1
        )
          handleAddGame(dateForWeek(i, division), i, 2);
      }
    }
  };

  if (
    loadingVenueTree ||
    divisionLoading ||
    loadingScheduleStatus ||
    loadingGameStatus ||
    loadingCreate ||
    loadingUpdate ||
    loadingDeleteSchedule ||
    loadingUpdateGame ||
    loadingRemoveTeam ||
    loadingDivisionStandings
  ) {
    return <LoadingDialog open={true} />;
  }

  const inactiveTeams = teamsList.filter((team) => team.inactive);

  const ScheduleDetailsCard: FC = () => {
    return (
      <Card className="flex max-w-lg flex-nowrap h-min">
        <div className="flex flex-col gap-4">
          <Headline2Variable>Schedule Details</Headline2Variable>
          <div className="flex flex-col gap-4">
            <FormFieldViewOnly
              disabled
              label="League"
              text={division?.session?.league.name || ""}
            />
          </div>
          <div className="flex items-end gap-4">
            <FormFieldViewOnly
              disabled
              label="Season:"
              text={division?.session?.registrationBatch.name || ""}
            />
            <FormFieldViewOnly
              disabled
              label="Division:"
              text={division?.name || ""}
            />
          </div>
          <div className="flex items-end gap-4">
            <FormFieldViewOnly
              disabled
              label="Weeks:"
              text={division?.numberOfWeeks.toString() || ""}
            />
            <FormFieldViewOnly
              disabled
              label="Game Duration (minutes)"
              text={division?.session?.league.gameDuration.toString() || ""}
            />
            <FormFieldViewOnly
              disabled
              label="Game Timeslot (minutes):"
              text={division?.session?.league.gameTimeslot.toString() || ""}
            />
          </div>
          <div className="flex flex-col gap-4">
            <FormFieldSelect
              inputChange={(value) => {
                setActiveScheduleStatus(+value);
              }}
              label="Status"
              placeholder="Status"
              value={activeScheduleStatus.toString()}
            >
              {scheduleStatuses.map((status) => {
                return {
                  id: status.id,
                  name: status.scheduleStatusName,
                };
              })}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setAllGameVenue(value);
              }}
              label="Set all venue locations"
              toolTipContent="This will set the venue for all the games both regular and playoff."
              placeholder="Venues"
              value={allGameVenue.toString()}
            >
              {venuesList}
            </FormFieldSelect>
          </div>
        </div>
      </Card>
    );
  };

  const TeamCard: FC = () => {
    return (
      <Card className="flex flex-shrink w-1/3 flex-nowrap h-min">
        <div className="flex flex-col w-full gap-3">
          <Headline2Variable>Active Teams</Headline2Variable>
          <div className="flex flex-col gap-1">
            {division?.teams?.map((team, index) => {
              if (team) {
                // List of teams names that are in the division
                return (
                  <>
                    <div
                      className="flex justify-between"
                      key={index}
                    >
                      <p>{team.name}</p>
                      <div className="flex self-center gap-2">
                        <Button
                          className="!p-1 !text-sm"
                          variant="secondary"
                          onClick={() => {
                            handleRedirectTeam(+team.id);
                          }}
                        >
                          <EyeIcon />
                        </Button>
                        <Button
                          className="!p-1 !text-sm"
                          variant="secondary"
                          onClick={() => {
                            const url = `/players/team/${team.id}`;
                            window.open(url, "_blank");
                          }}
                        >
                          HQ
                        </Button>
                      </div>
                    </div>
                    <Separator />
                  </>
                );
              }
            })}
          </div>
          {inactiveTeams && inactiveTeams.length > 0 && (
            <>
              <Headline2Variable>Inactive Teams</Headline2Variable>
              <div className="flex flex-col gap-2">
                {inactiveTeams.map((team, index) => {
                  return (
                    <>
                      <div
                        className="flex justify-between"
                        key={index}
                      >
                        <p>{team.name}</p>
                      </div>
                      <Separator />
                    </>
                  );
                })}
              </div>
            </>
          )}
          {!(
            division?.latestSchedule &&
            division.latestSchedule.regularGames.some(
              (game) =>
                game.startDateTimeLocal < dayjs().format("YYYY-MM-DD HH:mm:ss")
            )
          ) &&
            division &&
            division.session.league.gamesPerOccurrence === 1 && (
              <Button
                variant="secondary"
                onClick={() => setOpenAutoScheduleDialog(true)}
              >
                Auto Schedule
              </Button>
            )}{" "}
          {!(
            division?.latestSchedule &&
            division.latestSchedule.regularGames.some(
              (game) =>
                game.startDateTimeLocal < dayjs().format("YYYY-MM-DD HH:mm:ss")
            )
          ) &&
            division && (
              <Button
                variant="secondary"
                onClick={() => setOpenTemplateScheduleDialog(true)}
              >
                Template Schedule
              </Button>
            )}
          <Button
            variant="secondary"
            onClick={() => setOpenTimeDistributionDialog(true)}
          >
            View Distribution
          </Button>
          <Button
            variant="secondary"
            href={`/marketing/email-tool/division/${id}`}
            className="text-center"
          >
            Email Division
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <main>
      {/* Division and team info */}
      <div className="flex flex-row flex-shrink gap-8 py-4 flex-nowrap">
        <ScheduleDetailsCard />
        <TeamCard />
        <div className="flex flex-col gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              setOpenReplaceTeamsDialog(true);
              setTypeReplaceTeamsDialog("Swap");
            }}
          >
            Swap Teams
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenReplaceTeamsDialog(true);
              setTypeReplaceTeamsDialog("Replace");
            }}
          >
            Replace Team
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleAddGameEachWeek();
            }}
          >
            Add Games
          </Button>
          <Body1 className="text-xs text-center">
            {" "}
            Adds one game each week
          </Body1>
          <Button
            variant={
              !division?.latestSchedule ||
              division?.latestSchedule.regularGames.some(
                (game) =>
                  game.startDateTimeLocal <
                  dayjs().format("YYYY-MM-DD HH:mm:ss")
              )
                ? "disabled"
                : "secondary"
            }
            onClick={() => {
              setOpenConfirmDialog(true);
              setConfirmDialog({
                type: "BlowUp",
                title: "Blow Up Schedule",
                description: `Are you sure you want to blow up the schedule?`,
                variables: {},
              });
            }}
            disabled={
              !division?.latestSchedule ||
              division?.latestSchedule.regularGames.some(
                (game) =>
                  game.startDateTimeLocal <
                  dayjs().format("YYYY-MM-DD HH:mm:ss")
              )
            }
          >
            Blow up Schedule
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {division?.exceptionDates?.map((date, index) => {
            return (
              <Disclaimer
                key={index}
                variant="info"
                persist={true}
                title={`Exclusion date ${dayjs(date).format("YYYY-MM-DD")}`}
                size="large"
              />
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {checkGames.length > 0 && (
          <Disclaimer
            content={
              "There are some games that don't have gameslots available for the given date and time. Ids: " +
              checkGames.map((game) => game).join(", ")
            }
            variant="warning"
            persist={true}
            title="Gameslot Allocation"
            size="large"
          />
        )}
        <div className="flex flex-row justify-between ">
          <Headline2Variable>Regular Season</Headline2Variable>
          <Button
            variant="primary"
            onClick={() => {
              handleSubmitSchedule();
            }}
          >
            {isUpdateSchedule ? "Update" : "Create"} Schedule
          </Button>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {/* Regular Weeks */}
            {renderRegularWeeksOfPlay()}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Headline2Variable>Playoff Season</Headline2Variable>
        {schedule.currentScheduleId !== undefined &&
          schedule.currentScheduleId > 0 && (
            <div className="flex flex-col gap-2">
              {schedule.playoffGames.some(
                (game) =>
                  game.homeTeamId === undefined || game.awayTeamId === undefined
              ) ? (
                <Disclaimer
                  title="Playoffs missing team Ids"
                  size="large"
                  persist={true}
                  variant="info"
                  content={`The following playoff games do not have teams assigned: ${schedule.playoffGames
                    .filter(
                      (game) =>
                        game.homeTeamId === undefined ||
                        game.awayTeamId === undefined
                    )
                    .map((game) => game.id)
                    .join(", ")}`}
                />
              ) : (
                <Disclaimer
                  title="All Playoff Games have teams assigned"
                  size="large"
                  persist={false}
                  variant="success"
                />
              )}
              {!isPlayoffFinalized && (
                <Disclaimer
                  content={
                    "Playoff games are not finalized. Until standings are finalized placement numbers will just be placeholder and will not assign teams to the games."
                  }
                  variant="warning"
                  persist={true}
                  title="Finalized Scores"
                  size="large"
                />
              )}
            </div>
          )}
        {
          <ShadCnDialog>
            <ShadCnDialogTrigger
              className="py-2 w-fit"
              disabled={
                !(
                  schedule.regularGames.every(
                    (game) => game.id !== undefined
                  ) &&
                  schedule.playoffGames.every(
                    (game) => game.id !== undefined
                  ) &&
                  isUpdateSchedule
                )
              }
            >
              <Button
                className="w-fit"
                variant={
                  !(
                    schedule.regularGames.every(
                      (game) => game.id !== undefined
                    ) &&
                    schedule.playoffGames.every(
                      (game) => game.id !== undefined
                    ) &&
                    isUpdateSchedule
                  )
                    ? "disabled"
                    : "primary"
                }
                disabled={
                  !(
                    schedule.regularGames.every(
                      (game) => game.id !== undefined
                    ) &&
                    schedule.playoffGames.every(
                      (game) => game.id !== undefined
                    ) &&
                    isUpdateSchedule
                  )
                }
              >
                Finalize Standings
              </Button>
            </ShadCnDialogTrigger>
            <ShadCnDialogContent className="max-w-[80%]">
              <ShadCnDialogHeader>
                <ShadCnDialogTitle>Finalize Team Placement</ShadCnDialogTitle>
                <ShadCnDialogDescription>
                  Create the standings for the teams to finalize playoff
                  matchups.
                </ShadCnDialogDescription>
              </ShadCnDialogHeader>
              <div className="flex flex-col gap-4">
                <ShadcnTable>
                  <ShadcnTableHeader>
                    <ShadcnTableRow>
                      <ShadcnTableHead className="pl-0">
                        Team Name
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger="GP"
                          hoverText="Games Played"
                        />
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger="W"
                          hoverText="Wins"
                        />
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger="L"
                          hoverText="Losses *doesn't include default losses"
                        />
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger="T"
                          hoverText="Ties"
                        />
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger="DL"
                          hoverText="Default losses"
                        />
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger="PT"
                          hoverText="Points Total"
                        />
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger={
                            division?.session.league.sportFormat.scoringFormat
                              ?.scoringType.scoringForAbbreviation || "PF"
                          }
                          hoverText={
                            division?.session.league.sportFormat.scoringFormat
                              ?.scoringType.scoringFor || "Points For"
                          }
                        />
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger={
                            division?.session.league.sportFormat.scoringFormat
                              ?.scoringType.scoringAgainstAbbreviation || "PA"
                          }
                          hoverText={
                            division?.session.league.sportFormat.scoringFormat
                              ?.scoringType.scoringAgainst || "Points Against"
                          }
                        />
                      </ShadcnTableHead>
                      <ShadcnTableHead className="w-[53px] text-center">
                        <Tooltip
                          trigger="PD"
                          hoverText="Points Difference"
                        />
                      </ShadcnTableHead>
                    </ShadcnTableRow>
                  </ShadcnTableHeader>
                  <ShadcnTableBody className="text-center">
                    {standingsData.map(
                      (teamRecord: DetailedTeamScoreRecord, index: number) => {
                        return (
                          <ShadcnTableRow key={teamRecord.teamId}>
                            <ShadcnTableCell className="pl-0 text-left">
                              <div className="flex flex-row items-center gap-2">
                                <div className="flex-grow">
                                  {teamRecord.teamName}
                                </div>
                                <Select
                                  defaultValue={
                                    placementToTeam
                                      .find(
                                        (placement) =>
                                          placement.teamId == teamRecord.teamId
                                      )
                                      ?.placement.toString() ?? ""
                                  }
                                  value={
                                    placementToTeam
                                      .find(
                                        (placement) =>
                                          placement.teamId == teamRecord.teamId
                                      )
                                      ?.placement.toString() ?? ""
                                  }
                                  onValueChange={(placementNumber) => {
                                    const nameOfPlacementNumber =
                                      placementToTeam.find(
                                        (placement) =>
                                          placement.placement ===
                                          +placementNumber
                                      )?.name || "";
                                    // clear the team id from any other placement number
                                    setPlacementToTeam(
                                      (prev: PlacementToTeam[]) => [
                                        ...prev
                                          .filter(
                                            (placement) =>
                                              +placementNumber !==
                                              placement.placement
                                          )
                                          .map((place) => {
                                            if (
                                              place.teamId == teamRecord.teamId
                                            ) {
                                              place.teamId = null;
                                            }
                                            return place;
                                          }),
                                        {
                                          placement: +placementNumber,
                                          name: nameOfPlacementNumber,
                                          teamId: teamRecord.teamId,
                                        },
                                      ]
                                    );
                                  }}
                                >
                                  <SelectTrigger className="flex items-center justify-between w-[200px] bg-white border border-secondary-80 hover:border-secondary-50 focus:outline focus:outline-primary-80 focus:border-primary-80 rounded-xl">
                                    <SelectValue
                                      placeholder={`Select standing`}
                                    />
                                  </SelectTrigger>
                                  <SelectContent className="justify-start bg-white rounded-lg">
                                    <SelectGroup>
                                      {placementToTeam
                                        .sort(
                                          (a, b) => a.placement - b.placement
                                        )
                                        .map((placement, index) => {
                                          return (
                                            <SelectItem
                                              key={index}
                                              value={placement.placement.toString()}
                                              className="w-full hover:bg-primary-95 rounded-xl"
                                            >
                                              {placement.name}
                                            </SelectItem>
                                          );
                                        })}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.gamesPlayed}
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.scoreRecord.wins}
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.scoreRecord.losses}
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.scoreRecord.ties}
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.scoreRecord.defaultLosses}
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.teamPointsRecord.pointsTotal}
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.teamPointsRecord.pointsFor}
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.teamPointsRecord.pointsAgainst}
                            </ShadcnTableCell>
                            <ShadcnTableCell>
                              {teamRecord.teamPointsRecord.pointsDifference}
                            </ShadcnTableCell>
                          </ShadcnTableRow>
                        );
                      }
                    )}
                  </ShadcnTableBody>
                </ShadcnTable>
                <ShadCnDialogClose>
                  <Button
                    variant={
                      // If any of the placements are not set or if more than 1 are the same you can't finalize the standings
                      placementToTeam.some(
                        (placement) => placement.teamId == null
                      ) ||
                      placementToTeam.some(
                        (placement, index) =>
                          placementToTeam.findIndex(
                            (p) => p.teamId === placement.teamId
                          ) !== index
                      )
                        ? "disabled"
                        : "primary"
                    }
                    disabled={
                      placementToTeam.some(
                        (placement) => placement.teamId == null
                      ) ||
                      placementToTeam.some(
                        (placement, index) =>
                          placementToTeam.findIndex(
                            (p) => p.teamId === placement.teamId
                          ) !== index
                      )
                    }
                    onClick={() => {
                      handleUpdatePlayoffStandings();
                    }}
                  >
                    Save Standings
                  </Button>
                </ShadCnDialogClose>
              </div>
            </ShadCnDialogContent>
          </ShadCnDialog>
        }
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {/* Playoff Weeks */}
            {renderPlayoffWeeksOfPlay()}
          </div>
        </div>
      </div>
      {/* Playoff games schedule */}
      <Button
        variant="primary"
        onClick={() => {
          handleSubmitSchedule();
        }}
        className="mt-4"
      >
        {isUpdateSchedule ? "Update" : "Create"} Schedule
      </Button>
      <Button
        variant="secondary"
        className="mt-4 ml-4"
        href={`/ops/divisions`}
      >
        Cancel
      </Button>
      {division?.previousSchedules && division.previousSchedules.length > 0 && (
        <div>{scheduleHistory}</div>
      )}
      <ScheduleHistoryDialog
        open={scheduleModal}
        onOpenChange={() => setScheduleModal(false)}
        schedule={selectedSchedule}
        onRestoreSchedule={() => {}}
      />
      <ReplaceTeamsDialog
        open={openReplaceTeamsDialog}
        type={typeReplaceTeamsDialog}
        teams={teamsList}
        onReplaceTeams={(teamA, teamB) => {
          handleReplaceTeams(teamA, teamB);
        }}
        onOpenChange={(open) => {
          setOpenReplaceTeamsDialog(open);
        }}
      />
      <TimeDistributionDialog
        open={openTimeDistributionDialog}
        onOpenChange={() => setOpenTimeDistributionDialog(false)}
        divisionTeams={teamsList || []}
        venuesList={venuesList}
        gamesByWeek={[...schedule.regularGames]}
      />
      <AutoScheduleDialog
        open={openAutoScheduleDialog}
        onOpenChange={() => setOpenAutoScheduleDialog(false)}
        venues={venuesList}
        teams={teamsList}
        numberOfTeams={teamsList.length}
        numberOfWeeks={
          division ? division?.numberOfWeeks - division?.weeksOfPlayoffs : 0
        }
        onSubmitAutoSchedule={(template: AutoScheduleGame[]) => {
          handleCreateAutoSchedule(template);
        }}
      />
      {division && openTemplateScheduleDialog && (
        <TemplateScheduleDialog
          open={openTemplateScheduleDialog}
          onOpenChange={(open) => setOpenTemplateScheduleDialog(open)}
          venues={venuesList}
          teams={teamsList}
          gamesPerOccurrence={division.session.league.gamesPerOccurrence}
          teamCount={teamsList.length}
          weekCount={division.numberOfWeeks - division.weeksOfPlayoffs}
          onSubmitTemplateSchedule={(games: GamesByWeek) => {
            handleCreateTemplateSchedule(games);
          }}
        />
      )}
      <Dialog
        open={openConfirmDialog}
        sx={{
          zIndex: 30,
        }}
      >
        <div className="flex flex-row md:justify-between">
          <DialogTitle
            sx={{
              paddingBottom: "0px",
            }}
          >
            <Headline2Variable>{typeConfirmDialog.title}</Headline2Variable>
          </DialogTitle>
        </div>
        <DialogContent>
          <Body1>{typeConfirmDialog.description}</Body1>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => setOpenConfirmDialog(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleConfirmDialog()}
            >
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openGameActionDialog}
        maxWidth="md"
        sx={{
          zIndex: 30,
        }}
      >
        <div className="flex flex-row md:justify-between">
          <DialogTitle
            sx={{
              paddingBottom: "0px",
            }}
          >
            <Headline2Variable>{typeGameAction.title}</Headline2Variable>
          </DialogTitle>
        </div>
        <DialogContent>
          {typeGameAction.type === "Reschedule" && (
            <div className="flex flex-col gap-5 pb-3">
              <Body1>The start date and time will be updated on save.</Body1>
              <DatePicker
                label="Start Date"
                date={dayjs
                  .utc(typeGameAction.data?.startDateTimeLocal)
                  .toDate()}
                setDate={(e) => {
                  const newDate = dayjs.utc(e).format("YYYY-MM-DD");
                  const oldDate = dayjs(
                    typeGameAction.data.startDateTimeLocal
                  ).format("YYYY-MM-DD");

                  setTypeGameAction((prev) => ({
                    ...prev,
                    data: {
                      ...prev.data,
                      startDateTimeLocal: dayjs
                        .utc(e)
                        .hour(
                          dayjs(typeGameAction.data.startDateTimeLocal).hour()
                        )
                        .minute(
                          dayjs(typeGameAction.data.startDateTimeLocal).minute()
                        )
                        .format("YYYY-MM-DD HH:mm:ss"),
                      gameStatusId:
                        newDate !== oldDate ? 2 : prev.data.gameStatusId,
                    },
                  }));
                }}
              />
              <div className="flex w-2/3 gap-1">
                <FormFieldSelect
                  defaultValue="0"
                  inputChange={(value) => {
                    let newHour = value;
                    setTypeGameAction((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        startDateTimeLocal: dayjs
                          .utc(typeGameAction.data?.startDateTimeLocal)
                          .set("hour", newHour)
                          .format("YYYY-MM-DD HH:mm:ss"),
                      },
                    }));
                  }}
                  name="hour"
                  label="Hour"
                  placeholder="hour"
                  value={dayjs(typeGameAction.data?.startDateTimeLocal)
                    .hour()
                    .toString()}
                >
                  {hours.map((h) => ({ id: h.toString(), name: h }))}
                </FormFieldSelect>
                <div className="pt-5">:</div>
                <FormFieldSelect
                  defaultValue="0"
                  inputChange={(value) => {
                    let newMinute = value;
                    setTypeGameAction((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        startDateTimeLocal: dayjs
                          .utc(typeGameAction.data?.startDateTimeLocal)
                          .set("minute", newMinute)
                          .format("YYYY-MM-DD HH:mm:ss"),
                      },
                    }));
                  }}
                  name="minutes"
                  label="Minutes"
                  placeholder="minutes"
                  value={dayjs(typeGameAction.data?.startDateTimeLocal)
                    .minute()
                    .toString()}
                >
                  {minutes.map((h) => ({ id: h.toString(), name: h }))}
                </FormFieldSelect>
              </div>
            </div>
          )}
          {typeGameAction.type === "Game Status" && (
            <div className="flex flex-col w-min-[250px] pb-3 gap-3">
              <Body1>The game status will be updated on save.</Body1>
              <FormFieldSelect
                inputChange={(value) => {
                  setTypeGameAction((prev) => ({
                    ...prev,
                    data: {
                      ...prev.data,
                      gameStatusId: +value,
                    },
                  }));
                }}
                label="Status"
                placeholder="Status"
                value={
                  typeGameAction.data.gameStatusId
                    ? typeGameAction.data.gameStatusId.toString()
                    : "0"
                }
              >
                {gameStatuses.map((status) => {
                  return {
                    id: status.id,
                    name: status.name,
                  };
                })}
              </FormFieldSelect>
            </div>
          )}
          {typeGameAction.type === "Score Standings" && (
            <div className="flex flex-col gap-5 mb-3 text-lg">
              <Body1>The score standing status will be updated on save.</Body1>
              <div className="flex gap-5">
                <div>
                  <p>Home Team Score Standing:</p>
                  <p className="text-sm">
                    {
                      teamsList.find(
                        (team) => team.id == typeGameAction.data.homeTeamId
                      )?.name
                    }
                  </p>
                </div>
                <SwitchButton
                  labelStart="NO"
                  labelEnd="YES"
                  height="30"
                  width="50"
                  checked={typeGameAction.data.countHomeScore}
                  handleChange={(value) => {
                    setTypeGameAction((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        countHomeScore: value.target.checked ? true : false,
                      },
                    }));
                  }}
                />
              </div>
              <div className="flex gap-5">
                <div>
                  <p>Away Team Score Standing:</p>
                  <p className="text-sm">
                    {
                      teamsList.find(
                        (team) => team.id == typeGameAction.data.awayTeamId
                      )?.name
                    }
                  </p>
                </div>
                <SwitchButton
                  labelStart="NO"
                  labelEnd="YES"
                  height="30"
                  width="50"
                  checked={typeGameAction.data.countAwayScore}
                  handleChange={(value) => {
                    setTypeGameAction((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        countAwayScore: value.target.checked ? true : false,
                      },
                    }));
                  }}
                />
              </div>
            </div>
          )}
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                setOpenGameActionDialog(false);
                setTypeGameAction({
                  type: "Reschedule",
                  title: "",
                  data: {},
                });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleGameActionSave()}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ShadCnDialog
        open={bulkUpdateDialog.open}
        onOpenChange={(open) =>
          setBulkUpdateDialog((prev) => ({
            ...prev,
            open,
          }))
        }
      >
        <ShadCnDialogContent className="max-w-[40%]">
          <ShadCnDialogHeader>
            <ShadCnDialogTitle>Bulk Update Games</ShadCnDialogTitle>
            <ShadCnDialogDescription>
              Bulk update the start date or game status for games{" "}
              {bulkUpdateDialog.gameIds.map((id) => id).join(", ")}
            </ShadCnDialogDescription>
          </ShadCnDialogHeader>
          <div className="flex flex-col gap-4">
            <TabGroup
              content={["Reschedule", "Game Status"]}
              inputChange={(value) => {
                setBulkUpdateDialog((prev) => ({
                  ...prev,
                  type: value,
                }));
              }}
              value={bulkUpdateDialog.type}
              className="max-w-full w-fit"
            />
            {bulkUpdateDialog.type === "Reschedule" && (
              <div className="flex flex-col gap-5 pb-3">
                <Body1>The start date will be updated on save.</Body1>
                <DatePicker
                  label="Start Date"
                  date={dayjs(bulkUpdateDialog.startDateTimeLocal).toDate()}
                  setDate={(e) => {
                    const newDate = dayjs(e).format("YYYY-MM-DD");
                    const oldDate = dayjs(
                      bulkUpdateDialog.startDateTimeLocal
                    ).format("YYYY-MM-DD");

                    setBulkUpdateDialog((prev) => ({
                      ...prev,
                      startDateTimeLocal: dayjs(e)
                        .hour(dayjs(bulkUpdateDialog.startDateTimeLocal).hour())
                        .minute(
                          dayjs(bulkUpdateDialog.startDateTimeLocal).minute()
                        )
                        .format("YYYY-MM-DD HH:mm:ss"),
                      gameStatusId: newDate !== oldDate ? 2 : prev.gameStatusId,
                    }));
                  }}
                />
              </div>
            )}
            {bulkUpdateDialog.type === "Game Status" && (
              <div className="flex flex-col w-min-[280px] pb-3 gap-3">
                <Body1>The game status will be updated on save.</Body1>
                <div className="max-w-[280px]">
                  <FormFieldSelect
                    inputChange={(value) => {
                      setBulkUpdateDialog((prev) => ({
                        ...prev,
                        gameStatusId: +value,
                      }));
                    }}
                    label="Status"
                    placeholder="Status"
                    value={bulkUpdateDialog.gameStatusId.toString()}
                  >
                    {gameStatuses.map((status) => {
                      return {
                        id: status.id,
                        name: status.name,
                      };
                    })}
                  </FormFieldSelect>
                </div>
              </div>
            )}
            <ShadCnDialogClose>
              <Button
                variant="secondary"
                onClick={() => {
                  setBulkUpdateDialog({
                    open: false,
                    gameIds: [],
                    type: "Reschedule",
                    startDateTimeLocal: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                    gameStatusId: 0,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => handleBulkUpdateGames()}
              >
                Save
              </Button>
            </ShadCnDialogClose>
          </div>
        </ShadCnDialogContent>
      </ShadCnDialog>
    </main>
  );
};

export default ScheduleNew;
