import { Box, Button as MuiButton, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./ContractManagement.module.css";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Division } from "../../types/types";
import {
  cleanupDivisionToEdit,
  deleteDivision,
  getRegistrationBatch,
  getSports,
} from "../../app/venueMasterSlice";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import ActionsCell from "../UI/ActionCell";
import { DivisionsQuery, useDivisionsLazyQuery } from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Disclaimer from "../UI/Alerts/Disclaimer";
import { displayAlertError } from "../../app/globalSlice";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { DataTable } from "../UI/Table/DataTable";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";

const options = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Delete" },
];
interface DivisionFilter {
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  regionIds: number[];
  sportId: number | null;
}
interface PaginationType {
  page: number;
  pageSize: number;
  pageCount: number | undefined;
}

const weekdaysArray: any = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

const Divisions: React.FC = () => {
  const initialDivisionFilters = {
    regionIds: [0],
    registrationBatchId: null,
    sportId: null,
    dayOfWeek: null,
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = React.useState(0);
  const [divisions, setDivisions] = useState<
    DivisionsQuery["divisions"]["divisions"]
  >([]);
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 25,
    pageCount: undefined,
  });
  const [divisionFilters, setDivisionFilters] = useState<DivisionFilter>(
    initialDivisionFilters
  );

  enum DayOfWeek {
    Sunday = 0,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
  }

  const { selectedRegions, registrationBatches, sports } = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [getDivisions, { loading: loadingDivisions }] = useDivisionsLazyQuery({
    variables: {
      divisionFilters: {
        registrationBatchId: divisionFilters.registrationBatchId,
        sportId: divisionFilters.sportId,
        regions:
          divisionFilters.regionIds[0] === 0
            ? selectedRegions.map((region) => region.id)
            : divisionFilters.regionIds,
        day: divisionFilters.dayOfWeek == -1 ? null : divisionFilters.dayOfWeek,
      },
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setDivisions(data.divisions.divisions);
      setPagination((prevState: PaginationType) => {
        return {
          ...prevState,
          pageCount: data.divisions.count,
        };
      });
    },
  });

  useEffect(() => {
    getDivisions();
    dispatch(getSports(""));
    dispatch(getRegistrationBatch(""));
  }, []);

  useEffect(() => {
    getDivisions();
  }, [selectedRegions]);

  const redirectCreateDivision = () => {
    dispatch(cleanupDivisionToEdit(""));
    navigate(`/ops/division/`);
  };

  const redirectEditDivision = (divisionId: number) => {
    navigate(`/ops/division/${divisionId}`, {
      state: { divisionId },
    });
  };

  const redirectDeleteDivision = (divisionId: number, latestSchedule: any) => {
    if (latestSchedule) {
      dispatch(displayAlertError("Division has schedule, cannot be deleted"));
    } else {
      setDeleteModal(divisionId);
    }
  };

  const redirectSchedule = (divisionId: number) => {
    navigate(`/ops/division/${divisionId}/schedule`, {
      state: { divisionId },
    });
  };

  const handleDeleteDivision = () => {
    dispatch(deleteDivision(deleteModal)).then((res) => {
      if (res.payload.success) {
        getDivisions();
      }
      setDeleteModal(0);
    });
  };

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      // edit
      redirectEditDivision(row.original.id);
    }
    if (idx === 2) {
      // delete
      redirectDeleteDivision(row.original.id, row.original?.latestSchedule);
    }
  };

  const divisionColumns: ColumnDef<
    DivisionsQuery["divisions"]["divisions"][0]
  >[] = [
    {
      header: "Name",
      cell: ({ row }) => {
        return (
          <a
            className="underline"
            href={`/ops/division/${row.original.id}`}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      header: "Session",
      id: "session",
      cell: ({ row }) => {
        return (
          <div>{`${DayOfWeek[row.original.session.dayOfWeek]} ${
            row.original.session.league.name
          } (${dayjs(row.original.session.startDate).format(
            "MMM DD, YYYY"
          )})`}</div>
        );
      },
    },
    {
      header: "Day",
      id: "day",
      cell: ({ row }) => {
        return <div>{`${DayOfWeek[row.original.session.dayOfWeek]}`}</div>;
      },
    },
    {
      header: "Start Date",
      id: "startDate",
      cell: ({ row }) => {
        return <div>{dayjs(row.original.startDate).format("YYYY-MM-DD")}</div>;
      },
    },
    {
      header: "Teams",
      id: "endDate",
      cell: ({ row }) => {
        return <div>{row.original.teams.length}</div>;
      },
    },
    {
      header: "Schedule",
      cell: ({ row }) => {
        return (
          <div className={classes.format__options}>
            <MuiButton
              sx={{ color: row.original.latestSchedule ? "green" : "black" }}
              onClick={() => redirectSchedule(row.original.id)}
            >
              {<CalendarMonthIcon />}
            </MuiButton>
          </div>
        );
      },
    },
    {
      header: " ",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  return (
    <main className="flex flex-col">
      <LoadingDialog open={loadingDivisions} />
      <Headline1Variable>Divisions</Headline1Variable>
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-3 gap-2">
          <div className="flex flex-col gap-2">
            <FormFieldSelect
              inputChange={(value) => {
                setDivisionFilters((prevState: DivisionFilter) => {
                  return {
                    ...prevState,
                    registrationBatchId: +value,
                  };
                });
              }}
              label="Registration Batch"
              placeholder="Registration Batch"
              value={divisionFilters.registrationBatchId?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...registrationBatches]}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setDivisionFilters((prevState: DivisionFilter) => {
                  return {
                    ...prevState,
                    regionIds: [+value],
                  };
                });
              }}
              label="Region"
              placeholder="Region"
              value={divisionFilters.regionIds[0]?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...selectedRegions]}
            </FormFieldSelect>
          </div>
          <div className="flex flex-col gap-2">
            <FormFieldSelect
              inputChange={(value) => {
                setDivisionFilters((prevState: DivisionFilter) => {
                  return {
                    ...prevState,
                    sportId: +value,
                  };
                });
              }}
              label="Sport"
              placeholder="Sport"
              value={divisionFilters.sportId?.toString() ?? "0"}
            >
              {[{ id: "0", name: "All" }, ...sports]}
            </FormFieldSelect>
            <FormFieldSelect
              inputChange={(value) => {
                setDivisionFilters((prevState: DivisionFilter) => {
                  return {
                    ...prevState,
                    dayOfWeek: +value,
                  };
                });
              }}
              label="Day Of Week"
              placeholder="Day Of Week"
              value={divisionFilters.dayOfWeek?.toString() ?? "-1"}
            >
              {[{ id: "-1", name: "All" }, ...weekdaysArray]}
            </FormFieldSelect>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end w-full ">
          <Button
            variant="primary"
            className="h-fit"
            onClick={redirectCreateDivision}
          >
            <AddIcon
              fontSize="large"
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
            <span>New Division</span>
          </Button>
        </div>
        <div className="flex flex-col gap-2">
          {divisions
            .filter((division) =>
              division.latestSchedule?.regularGames.some(
                (game) => game.homeTeam === null || game.awayTeam === null
              )
            )
            .map((division) => {
              return (
                <Disclaimer
                  variant="error"
                  key={division.id}
                  title={`${division.name} (id ${division.id}) has invalid games`}
                  size="large"
                  persist={true}
                />
              );
            })}
        </div>
        <DataTable
          data={divisions}
          columns={divisionColumns}
        />
        <PaginationDataTable
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
      <Modal
        open={deleteModal ? true : false}
        onClose={() => {
          setDeleteModal(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <p> Do you want to delete the division ?</p>
          <div className={classes.actions}>
            <MuiButton
              variant="contained"
              className={classes.button__alt}
              onClick={handleDeleteDivision}
              sx={{
                marginRight: "10px",
                color: "var(--primary-blue-500)",
                bgcolor: "var(--primary-red-500)",
                "&:hover": { bgcolor: "var(--primary-red-600)" },
              }}
            >
              Delete
            </MuiButton>
            <MuiButton
              variant="contained"
              className={classes.button__alt}
              onClick={() => {
                setDeleteModal(0);
              }}
              sx={{
                marginRight: "10px",
                color: "var(--primary-blue-500)",
                bgcolor: "var(--primary-red-500)",
                "&:hover": { bgcolor: "var(--primary-red-600)" },
              }}
            >
              Cancel
            </MuiButton>
          </div>
        </Box>
      </Modal>
    </main>
  );
};

export default Divisions;
