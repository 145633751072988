import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../app/store";
import AddIcon from "@mui/icons-material/Add";
import { PaginationType } from "../../../../types/types";
import Button from "../../../UI/Button/Button";
import ActionsCell from "../../../UI/ActionCell";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import {
  StaffTypePaginatedQuery,
  useStaffTypePaginatedLazyQuery,
} from "../../../../generated/graphql";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useStaffTypeContext } from "../../../../context/StaffTypeContext";
import PaginationDataTable from "../../../../../src/components/UI/Pagination/PaginationDataTable";
import { DataTable } from "../../../../../src/components/UI/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";

// List of options for action col
const options = [{ id: 1, text: "Edit" }];

const StaffTypeManagementReport: React.FC = () => {
  const navigate = useNavigate();

  const { selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 10;

  /*** STATES ***/
  const { filter, setFilter } = useStaffTypeContext();
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageCount: undefined,
    pageSize: pageSize,
  });

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [GetStaffType, { loading, data }] = useStaffTypePaginatedLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      page: pagination.page,
      pageSize: pagination.pageSize,
      regionId: filter.regionId ?? null,
    },
    onCompleted: (data) => {
      setPagination((prevState) => {
        return {
          ...prevState,
          pageCount: data.staffTypePaginated.count,
        };
      });
    },
  });

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (filter) {
      GetStaffType();
    }
  }, [pagination.page, pagination.pageSize, filter.regionId]);

  useEffect(() => {
    setFilter((prevState) => {
      return {
        ...prevState,
        pagination: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          pageCount: pagination.pageCount,
        },
      };
    });
  }, [pagination]);

  /*** UTILITY FUNCTIONS ***/
  // Handles the state for the pagination component

  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const handleSelectedOption = (
    row: StaffTypePaginatedQuery["staffTypePaginated"]["staffType"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/staffing/staff-type-management/${row.id}`);
    }
  };

  // Table col definition
  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const columns: ColumnDef<
    StaffTypePaginatedQuery["staffTypePaginated"]["staffType"][0]
  >[] = [
    {
      header: "Id",
      id: "Id",
      accessorFn: (d) => d.id,
    },
    {
      header: "Name",
      id: "name",
      accessorFn: (d) => d.name,
    },
    {
      header: "Region",
      id: "region",
      cell: ({ row }) => {
        return (
          <div>{row.original.region ? row.original.region.name : "All"}</div>
        );
      },
    },
    {
      header: "Contrator",
      id: "isContrator",
      cell: ({ row }) => {
        return <div>{row.original.isContractor ? "Yes" : "No"}</div>;
      },
    },

    {
      header: " ",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row.original}
            options={options}
            handleSelectedOption={(_, i) =>
              handleSelectedOption(row.original, i)
            }
          />
        );
      },
    },
  ];

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loading} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Staff Management Type Report</Headline1Variable>

        <div className="mb-4 text-end">
          <Button
            variant="primary"
            href={"/staffing/staff-type-management/new"}
          >
            <AddIcon />
            New Staff Type
          </Button>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            value={filter.regionId ? filter.regionId.toString() : "0"}
            inputChange={(value) => {
              setFilter({
                regionId: +value,
              });
            }}
            name="regionId"
            id="regionId"
            label="Region"
            placeholder="Select Region"
            className="h-[46px] m-0 "
          >
            {selectedRegions.length > 0
              ? [...[{ id: 0, name: "All" }], ...selectedRegions]
              : [...[{ id: 0, name: "None" }]]}
          </FormFieldSelect>
        </div>
      </div>
      {data?.staffTypePaginated.staffType && (
        <div className="flex flex-col gap-4">
          <DataTable
            columns={columns}
            data={data.staffTypePaginated.staffType}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
    </main>
  );
};

export default StaffTypeManagementReport;
