import {
  Box,
  Chip,
  IconButton,
  Modal,
  Button as MuiButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingMaterialUI } from "../../UI";
import classes from "./CreateDivision.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import {
  getGenderFormat,
  getRegistrationBatch,
  getSports,
  InitialStateVenueMaster,
} from "../../../app/venueMasterSlice";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import moment from "moment";
import MultipleDatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Card from "../../UI/Card/Card";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import FormField from "../../UI/FormField/FormField";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  DivisionByScheduleIdQuery,
  ListAllOperations,
  SessionsForDivisionQuery,
  useDivisionByIdLazyQuery,
  useDivisionCreateMutation,
  useDivisionUpdateMutation,
  useGetTeamsRatingsBySessionLazyQuery,
  useSessionByDivisionIdLazyQuery,
  useSessionsForDivisionLazyQuery,
} from "../../../generated/graphql";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import Body1 from "../../UI/Text/Body/Body1";
import Disclaimer from "../../UI/Alerts/Disclaimer";
import { Separator } from "../../UI/shadcn/separator";
import { QuestionMark } from "@mui/icons-material";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import FormFieldDate from "../../UI/FormField/FormFieldDate/FormFieldDate";
import dayjs from "dayjs";
import Alert from "../../UI/Alerts/Alert";
import { number, z, ZodFormattedError } from "zod";
import { dayOfWeek, DayOfWeekArray } from "../../../utils/dayOfWeek";
import Subtitle2 from "../../UI/Text/Subtitle/Subtitle2";
import { FormFieldSelectMultiSearch } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import { message } from "@/src/app/shiftSlice";
import { renderZodErrors } from "../../../utils/zodErrors";
import { Dialog, DialogClose, DialogContent } from "../../UI/shadcn/dialog";
import { generateRandomLightColor } from "../../../utils/colour";

type SessionFilter = {
  regionId: number | null;
  sportId: number | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  genderFormatId: number | null;
};

const DivisionSchema = z
  .object({
    id: z.number().optional(),
    name: z
      .string()
      .min(5, "Name must be at least 5 characters long.")
      .default(""),
    vmName: z
      .string()
      .min(3, "Venue Master Name must be at least 3 characters long.")
      .default(""),
    vmColor: z.string().min(5, "You must create a venue color.").default(""),
    sessionId: z.number().min(1, "You must select a session.").default(0),
    teams: z.array(z.number()).default([]),
    startDate: z
      .string()
      .default("")
      .refine((value) => {
        return moment(value).isValid();
      }, "Invalid date format"),

    endDate: z
      .string()
      .default("")
      .refine((value) => {
        return moment(value).isValid();
      }, "Invalid date format"),
    exceptionDates: z.array(z.string()).default([]),
    numberOfWeeks: z
      .number()
      .min(1, "Number of weeks must be at least 1.")
      .default(0),
    weeksOfPlayoffs: z.number().default(0),
    playoffId: z.number().default(1),
    skillChoiceId: z.number().optional(),
  })
  .refine(
    (data) => {
      return data.startDate < data.endDate;
    },
    {
      path: ["startDate"],
      message: "Start date must be before end date",
    }
  )
  .superRefine((data, ctx) => {
    const numberOfWeeksRequired =
      dayjs(data.endDate).diff(data.startDate, "weeks") -
      data.exceptionDates.length +
      1;

    if (data.numberOfWeeks !== numberOfWeeksRequired) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Not enough weeks for play. Must have ${numberOfWeeksRequired} weeks with current dates.`,
        path: ["numberOfWeeks"],
      });
      return false;
    } else {
      return true;
    }
  })
  .refine(
    (data) => {
      // Check all exception dates lie on the same day of the week as the start date
      return data.exceptionDates.every((date) => {
        return dayjs(date).day() === dayjs(data.startDate).day();
      });
    },
    {
      path: ["exceptionDates"],

      message:
        "Exception dates must be on the same day of the week as the start date.",
    }
  )
  .refine(
    (data) => {
      return dayjs(data.startDate).day() === dayjs(data.endDate).day();
    },
    {
      path: ["startDate", "endDate"],
      message: "Start date and end date must be on the same day of the week.",
    }
  );
type DivisionFormValues = z.infer<typeof DivisionSchema>;

const CreateDivision = () => {
  const { selectedRegions, genderFormat, sports, registrationBatches } =
    useSelector((state: RootState) => state.venueMaster);

  const navigate = useNavigate();
  const params = useParams();
  const isEditDivision = params.id;
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();

  const [division, setDivision] = useState<DivisionFormValues>({
    id: 0,
    name: "",
    vmName: "",
    vmColor: "",
    sessionId: 0,
    teams: [],
    startDate: "",
    endDate: "",
    exceptionDates: [],
    numberOfWeeks: 0,
    weeksOfPlayoffs: 0,
    playoffId: 1,
    skillChoiceId: undefined,
  });
  const [isSchedulePublished, setIsSchedulePublished] = useState(false);
  const [selectedSession, setSelectedSession] = useState<
    (SessionsForDivisionQuery["sessions"][0] & { name: string }) | undefined
  >(undefined);
  const [sessionsForDivision, setSessionsForDivision] = useState<
    (SessionsForDivisionQuery["sessions"][0] & { name: string })[]
  >([]);
  const [sessionFilter, setSessionFilter] = useState<SessionFilter>(() => {
    const urlParamRegBatchId = searchParams.get("regBatchId");
    return {
      regionId: 0,
      sportId: 0,
      dayOfWeek: 7,
      registrationBatchId: urlParamRegBatchId ? +urlParamRegBatchId : 0,
      genderFormatId: 0,
    };
  });
  const [openSelectSessionModal, setOpenSelectSessionModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<DivisionFormValues, string>
  >({ _errors: [] });

  /*** QUERIES ***/
  const [
    divisionById,
    { data: divisionByIdData, error: divisionError, loading: divisionLoading },
  ] = useDivisionByIdLazyQuery();

  const [getSessionRatings, { data: sessionRatings, loading: loadingRatings }] =
    useGetTeamsRatingsBySessionLazyQuery({
      fetchPolicy: "network-only",
    });

  const [
    getSessionsForDivision,
    { data: dataSessionsForDivision, loading: loadingSessionsForFilter },
  ] = useSessionsForDivisionLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.sessions) {
        setSessionsForDivision(
          data.sessions.map((session) => ({
            ...session,
            name: `${DayOfWeekArray[session.dayOfWeek]} ${
              session.league.name
            } (${moment(session.startDate).format("MMM DD, YYYY")})`,
          }))
        );
      }
    },
  });

  const [getSessionByDivisionId, {}] = useSessionByDivisionIdLazyQuery();

  /*** MUTATIONS ***/
  const [createDivision, {}] = useDivisionCreateMutation();
  const [updateDivision, {}] = useDivisionUpdateMutation();

  useEffect(() => {
    if (isEditDivision) {
      divisionById({
        notifyOnNetworkStatusChange: true,
        variables: { divisionId: +isEditDivision },
        onCompleted: async (data) => {
          const teams = data.division.teams?.map((team) => team.id);
          setIsSchedulePublished(
            data.division.scheduleStatusId === 2 ? true : false
          );
          setDivision({
            id: data.division.id,
            name: data.division.name,
            vmName: data.division.vmName,
            vmColor: data.division.vmColor,
            sessionId: data.division.session.id,
            teams: teams,
            startDate: data.division.startDate,
            endDate: data.division.endDate,
            exceptionDates: data.division.exceptionDates,
            numberOfWeeks: data.division.numberOfWeeks,
            weeksOfPlayoffs: data.division.weeksOfPlayoffs,
            skillChoiceId: data.division.skillChoiceId ?? undefined,
            playoffId: 1,
          });
          await getSessionByDivisionId({
            variables: {
              divisionId: data.division.id,
            },
            onCompleted: (data) => {
              setSelectedSession({
                ...data.sessionByDivisionId,
                name: `${DayOfWeekArray[data.sessionByDivisionId.dayOfWeek]} ${
                  data.sessionByDivisionId.league.name
                } (${moment(data.sessionByDivisionId.startDate).format(
                  "MMM DD, YYYY"
                )})`,
              });
            },
          });
          await getSessionRatings({
            variables: {
              sessionId: data.division.session.id,
            },
          });
        },
      });
    }
    dispatch(getGenderFormat(""));
    dispatch(getSports(""));
    dispatch(getRegistrationBatch(""));
  }, []);

  useEffect(() => {
    const { regionId, ...otherFilters } = sessionFilter;
    if (isEditDivision === undefined) {
      getSessionsForDivision({
        variables: {
          sessionFilters: {
            ...otherFilters,
            regions: regionId === 0 || regionId == null ? null : [regionId],
          },
        },
      });
    }
  }, [sessionFilter]);

  useEffect(() => {
    const urlParamSessionId = searchParams.get("sessionId");
    if (urlParamSessionId !== null && sessionsForDivision.length > 0) {
      handleChangeSessionForCreate(urlParamSessionId);
    }
  }, [sessionsForDivision, searchParams]);

  function generateRandomColor() {
    // Generate a random hex color
    const randomColor = generateRandomLightColor();
    setDivision((prevState) => ({
      ...prevState,
      vmColor: randomColor,
    }));
  }

  async function handleChangeSessionForCreate(selectedValue: string) {
    if (+selectedValue === 0) {
      return;
    }
    const sessionId = +selectedValue;
    const session = sessionsForDivision?.find((session) => {
      return +session.id === sessionId;
    });

    setDivision((prevState) => ({
      ...prevState,
      sessionId,
    }));
    if (session) {
      setSelectedSession({
        ...session,
        name: `${DayOfWeekArray[session.dayOfWeek]} ${
          session.league.name
        } (${moment(session.startDate).format("MMM DD, YYYY")})`,
      });
      setDivision((prevState) => ({
        ...prevState,
        startDate: moment(session.startDate).format("YYYY-MM-DD"),
        endDate: moment(session.startDate)
          .add(session.numberOfWeeks - 1, "weeks")
          .format("YYYY-MM-DD"),
        exceptionDates: session.exceptionDates,
        numberOfWeeks: session.numberOfWeeks,
        weeksOfPlayoffs: session.weeksOfPlayoffs,
        name: session.league.name,
        vmColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
        vmName: session.league.divisionName,
      }));

      await getSessionRatings({
        variables: {
          sessionId: sessionId,
        },
      });
    }
  }

  const handleTeamChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList: number[] = [];
    if (division.teams) {
      updatedList = [...division.teams];
    }
    const teamId = +e.target.value;
    if (updatedList.includes(teamId)) {
      updatedList = updatedList.filter((item) => item !== teamId);
    } else {
      updatedList = [...updatedList, teamId];
    }
    setDivision({ ...division, teams: updatedList });
  };

  async function handleSubmitForm(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const result = DivisionSchema.safeParse(division);
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertError(result.error.errors[0].message));
      return;
    }

    if (isEditDivision && division.id) {
      await updateDivision({
        variables: {
          divisionArgs: {
            id: division.id,
            divisionInput: {
              name: division.name,
              vmName: division.vmName,
              vmColor: division.vmColor,
              sessionId: division.sessionId,
              teams: division.teams,
              startDate: division.startDate,
              endDate: division.endDate,
              exceptionDates: division.exceptionDates,
              numberOfWeeks: division.numberOfWeeks,
              weeksOfPlayoffs: division.weeksOfPlayoffs,
              playoffId: division.playoffId,
              skillChoiceId: division.skillChoiceId,
            },
          },
        },
        refetchQueries: [ListAllOperations.Query.DivisionById],
        onCompleted: (data) => {
          if (data.divisionUpdate.success) {
            dispatch(displayAlertSuccess(data.divisionUpdate.message));
            setZodErrors({ _errors: [] });
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      await createDivision({
        variables: {
          divisionArgs: {
            divisionInput: {
              name: division.name,
              vmName: division.vmName,
              vmColor: division.vmColor,
              sessionId: division.sessionId,
              teams: division.teams,
              startDate: division.startDate,
              endDate: division.endDate,
              exceptionDates: division.exceptionDates,
              numberOfWeeks: division.numberOfWeeks,
              weeksOfPlayoffs: division.weeksOfPlayoffs,
              playoffId: division.playoffId,
              skillChoiceId: division.skillChoiceId,
            },
          },
        },
        refetchQueries: [ListAllOperations.Query.DivisionById],
        onCompleted: (data) => {
          if (data.divisionCreate.success) {
            dispatch(displayAlertSuccess(data.divisionCreate.message));
            navigate(`/ops/divisions/`);
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    }
  }

  const unpooledTeams = (): React.ReactNode => {
    if (!selectedSession) {
      return <p>No session selected</p>;
    }
    const teams = selectedSession!.teams;
    let pooledTeams: SessionsForDivisionQuery["sessions"][0]["teams"] = [];
    selectedSession.divisions.forEach((division) => {
      pooledTeams = [...pooledTeams, ...division.teams];
    });

    if (teams.length === 0) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50">
          <p>Teams are yet to be created</p>{" "}
        </div>
      );
    }

    if (teams.length === pooledTeams.length) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50">
          <p>All teams are pooled</p>{" "}
        </div>
      );
    }

    const unpooledTeams = teams
      .filter(
        (team) => !pooledTeams.some((pooledTeam) => pooledTeam.id === team.id)
      )
      .map((team) => {
        const teamRating = sessionRatings?.getTeamsRatingsForSession.find(
          (rating) => rating?.teamId === team.id
        );

        // Create a new object with the original team properties and add rating/confidence
        return {
          ...team, // Copy existing properties of the team
          rating: teamRating ? teamRating.rating : 0,
          confidence: teamRating ? teamRating.confidence : 0,
          opFlag: teamRating?.opFlag,
          ncFlag: teamRating?.ncFlag,
        };
      })
      .sort((a, b) => (a.rating && b.rating && a.rating < b.rating ? 1 : -1));

    if (unpooledTeams.length > 0) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50 ">
          <p>Unpooled Teams</p>
          {unpooledTeams.map((team) => {
            const skill = team.registration
              ? team.registration.regQuestionChoice?.regSkillChoice?.name.substring(
                  0,
                  3
                )
              : null;
            return (
              <div key={team.id}>
                <div className="flex flex-row items-center gap-2 my-1">
                  {!isSchedulePublished && (
                    <input
                      type="checkbox"
                      value={team.id}
                      onChange={handleTeamChecked}
                    />
                  )}
                  <div className="flex items-center gap-2">
                    <span>{team.name}</span>
                    {skill && (
                      <Chip
                        size="small"
                        label={skill}
                        variant="outlined"
                      />
                    )}
                    <Chip
                      size="small"
                      label={`${team.rating}R - ${team.confidence}C${
                        team.opFlag ? "+" : team.ncFlag ? "*" : ""
                      }`}
                      variant="outlined"
                    />
                  </div>
                </div>
                <Separator />
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p>All teams are pooled</p>;
    }
  };

  const pathsBreadcrumbs = [{ name: "Divisions", url: "/ops/divisions" }];
  if (divisionLoading) {
    return <LoadingDialog open={true} />;
  }

  return (
    <main>
      <Dialog
        open={openSelectSessionModal}
        onOpenChange={(open) => {
          setOpenSelectSessionModal(open);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent className="min-w-[50%]">
          <div className="min-w-[50%]">
            <Headline1Variable>Select Session</Headline1Variable>
            <div className="mt-5">
              <Subtitle1>Session Filters</Subtitle1>
              <div className="flex flex-col gap-2">
                <FormFieldSelect
                  value={
                    sessionFilter.regionId
                      ? sessionFilter.regionId.toString()
                      : "0"
                  }
                  name="regionId"
                  placeholder="All"
                  inputChange={(value: string) => {
                    setSessionFilter((prevState) => ({
                      ...prevState,
                      regionId: +value,
                    }));
                  }}
                  label="Region"
                >
                  {[...[{ id: 0, name: "All" }, ...selectedRegions]]}
                </FormFieldSelect>
                <FormFieldSelect
                  value={
                    sessionFilter.sportId
                      ? sessionFilter.sportId.toString()
                      : "0"
                  }
                  name="sportId"
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      sportId: parseInt(value),
                    }));
                  }}
                  label="Sport"
                  placeholder="All"
                >
                  {[...sports, ...[{ id: 0, name: "All" }]]}
                </FormFieldSelect>
                <FormFieldSelect
                  name="dayOfWeek"
                  value={
                    sessionFilter.dayOfWeek != null
                      ? sessionFilter.dayOfWeek.toString()
                      : "7"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState) => ({
                      ...prevState,
                      dayOfWeek: parseInt(value),
                    }));
                  }}
                  label="Day"
                  placeholder="All"
                >
                  {[{ id: 7, name: "All" }, ...dayOfWeek]}
                </FormFieldSelect>
                <FormFieldSelect
                  name="registrationBatchId"
                  value={
                    sessionFilter.registrationBatchId
                      ? sessionFilter.registrationBatchId.toString()
                      : "0"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      registrationBatchId: parseInt(value),
                    }));
                  }}
                  label="Registration Batch"
                  placeholder="All"
                >
                  {[...registrationBatches, ...[{ id: 0, name: "All" }]]}
                </FormFieldSelect>
                <FormFieldSelect
                  name="genderFormatId"
                  value={
                    sessionFilter.genderFormatId
                      ? sessionFilter.genderFormatId.toString()
                      : "0"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      genderFormatId: parseInt(value),
                    }));
                  }}
                  label="Gender Format"
                  placeholder="All"
                >
                  {[...genderFormat, ...[{ id: 0, name: "All" }]]}
                </FormFieldSelect>
              </div>
            </div>
            <div className="mt-5">
              <Subtitle1>Select Session</Subtitle1>
              <FormFieldSelectMultiSearch
                label="Session"
                values={selectedSession ? [selectedSession.id.toString()] : []}
                oneItem={true}
                options={sessionsForDivision.map((session) => {
                  return { value: `${session.id}`, label: session.name };
                })}
                onValueChange={(values) => {
                  if (values.length > 0) {
                    handleChangeSessionForCreate(values[0]);
                  } else {
                    setSelectedSession(undefined);
                  }
                }}
                placeholder="Select Session"
                loading={loadingSessionsForFilter}
                maxCount={1}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/divisions"
      ></BreadCrumbs>
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between w-full mt-6">
          <Headline1Variable>
            {isEditDivision ? "Edit Division" : "Create New Division"}
          </Headline1Variable>
          <div className="flex flex-row gap-2">
            <Button
              variant="primary"
              className="h-fit"
              onClick={(e) => handleSubmitForm(e)}
            >
              {isEditDivision ? (
                <span> Edit Division</span>
              ) : (
                <span> Create New Division</span>
              )}
            </Button>
            <Button
              variant={isEditDivision ? "secondary" : "disabled"}
              className="h-fit"
              disabled={!isEditDivision}
              href={`${+isEditDivision!}/schedule`}
            >
              Schedule
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-2">
          {renderZodErrors(zodErrors)}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {divisionByIdData?.division?.latestSchedule?.regularGames.some(
          (game) => game.homeTeam === null || game.awayTeam === null
        ) && (
          <Disclaimer
            variant="error"
            title={`This division has games with missing teams. Please go to the schedule to fix this.`}
            size="large"
            persist={true}
          />
        )}
      </div>
      <form autoComplete="off">
        <Card className="mt-6">
          <Subtitle1>Division Details</Subtitle1>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormField
                type="name"
                name="name"
                value={division.name}
                label="Name"
                inputChange={(e) => {
                  setDivision((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
                initialValue={division.name ? division.name : ""}
                error={zodErrors.name !== undefined}
                assistiveText={
                  zodErrors.name !== undefined ? zodErrors.name._errors[0] : ""
                }
              />
              <FormField
                type="vmName"
                name="vmName"
                value={division.vmName}
                label="Venue Master Name"
                initialValue={division.vmName ? division.vmName : ""}
                inputChange={(e) => {
                  setDivision((prevState) => ({
                    ...prevState,
                    vmName: e.target.value,
                  }));
                }}
                error={zodErrors.vmName !== undefined}
                assistiveText={
                  zodErrors.vmName !== undefined
                    ? zodErrors.vmName._errors[0]
                    : ""
                }
              />
              <div>
                <FormField
                  type="vmColor"
                  name="vmColor"
                  value={division.vmColor}
                  label="Venue Master Color"
                  inputChange={(value) => {
                    setDivision((prevState) => ({
                      ...prevState,
                      vmColor: value,
                    }));
                  }}
                  color={division.vmColor}
                  initialValue={division.vmColor ? division.vmColor : ""}
                  error={zodErrors.vmColor !== undefined}
                  assistiveText={
                    zodErrors.vmColor !== undefined
                      ? zodErrors.vmColor._errors[0]
                      : ""
                  }
                />
                <div className="mt-3">
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      generateRandomColor();
                    }}
                  >
                    Generate
                  </Button>
                </div>
                <div
                  style={{
                    backgroundColor: division.vmColor,
                    height: "35px",
                    width: "35px",
                    marginTop: "10px",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="flex flex-col gap-4 mt-6">
          {isEditDivision !== undefined && selectedSession ? (
            <div className="flex flex-col w-full">
              <Subtitle2>Session Name</Subtitle2>
              <div className="grid grid-cols-3">
                <div className="col-span-2">
                  <FormFieldViewOnly
                    text={selectedSession.name}
                    className="w-full max-w-full"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <>
                <div className="col-span-2">
                  <FormFieldSelectMultiSearch
                    label="Session"
                    values={
                      selectedSession ? [selectedSession.id.toString()] : []
                    }
                    oneItem={true}
                    options={sessionsForDivision.map((session) => {
                      return { value: `${session.id}`, label: session.name };
                    })}
                    onValueChange={(values) => {
                      if (values.length > 0) {
                        handleChangeSessionForCreate(values[0]);
                      } else {
                        setSelectedSession(undefined);
                      }
                    }}
                    placeholder="Select Session"
                    loading={loadingSessionsForFilter}
                    maxCount={1}
                  />
                </div>
                <div className="flex items-end gap-2">
                  <Button
                    className="mt-4 w-fit"
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenSelectSessionModal(true);
                    }}
                  >
                    <div className="flex flex-row items-center justify-center gap-1">
                      <FilterListIcon
                        fontSize="small"
                        sx={{
                          width: "18px",
                          height: "18px",
                        }}
                      />
                      <span> Filters</span>
                    </div>
                  </Button>
                  <Alert
                    className="p-0 h-fit"
                    size="small"
                    persist={true}
                    variant="info"
                    content={"Select a session using filter."}
                  />
                </div>
              </>
            </div>
          )}
          {selectedSession && (
            <>
              <Subtitle1>Division Info</Subtitle1>
              <div className="flex flex-col gap-2">
                <>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
                    <FormFieldDate
                      label="Start Date"
                      initialValue={
                        division.startDate != ""
                          ? dayjs(division.startDate).toDate()
                          : undefined
                      }
                      dateChange={(date) => {
                        setDivision((prevState) => ({
                          ...prevState,
                          startDate: dayjs(date).format("YYYY-MM-DD"),
                        }));
                      }}
                      error={zodErrors.startDate !== undefined}
                      assistiveText={
                        zodErrors.startDate !== undefined
                          ? zodErrors.startDate._errors[0]
                          : ""
                      }
                    />
                    <FormFieldDate
                      label="End Date"
                      initialValue={
                        division.endDate != ""
                          ? dayjs(division.endDate).toDate()
                          : undefined
                      }
                      dateChange={(date) => {
                        setDivision((prevState) => ({
                          ...prevState,
                          endDate: dayjs(date).format("YYYY-MM-DD"),
                        }));
                      }}
                      error={zodErrors.endDate !== undefined}
                      assistiveText={
                        zodErrors.endDate !== undefined
                          ? zodErrors.endDate._errors[0]
                          : ""
                      }
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
                    <div className="md:col-span-2">
                      <div className={`flex flex-col w-full text-black`}>
                        <div className={`px-3 text-xs font-medium`}>
                          Exception Dates
                        </div>
                        <MultipleDatePicker
                          multiple
                          plugins={[<DatePanel />]}
                          inputClass={classes["multi-DatePicker-input"]}
                          containerClassName={
                            classes["multi-DatePicker-container"]
                          }
                          value={division.exceptionDates as string[]}
                          name="exceptionDates"
                          //onChange throws Date as DateObject only
                          onChange={(selectedDates: DateObject[]) => {
                            if (Array.isArray(selectedDates)) {
                              const dates = selectedDates?.map((date) => {
                                return new Date(date.format()).toISOString();
                              });
                              setDivision((prevState) => ({
                                ...prevState,
                                exceptionDates: dates,
                              }));
                            }
                          }}
                        />
                        {zodErrors.exceptionDates !== undefined && (
                          <div
                            className={`px-3 text-xxs font-normal text-error-10`}
                          >
                            {zodErrors.exceptionDates._errors[0]}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
                    <FormField
                      name="numberOfWeeks"
                      label="Total Weeks of Play"
                      initialValue={
                        division.numberOfWeeks
                          ? division.numberOfWeeks.toString()
                          : "0"
                      }
                      inputChange={(e) => {
                        setDivision({
                          ...division,
                          numberOfWeeks: +e.target.value,
                        });
                      }}
                      error={zodErrors.numberOfWeeks !== undefined}
                      assistiveText={
                        zodErrors.numberOfWeeks !== undefined
                          ? zodErrors.numberOfWeeks._errors[0]
                          : ""
                      }
                    />
                    <FormField
                      name="weeksOfPlayoffs"
                      label="Weeks of PlayOffs"
                      initialValue={
                        division.weeksOfPlayoffs
                          ? division.weeksOfPlayoffs.toString()
                          : "0"
                      }
                      inputChange={(e) => {
                        setDivision({
                          ...division,
                          weeksOfPlayoffs: +e.target.value,
                        });
                      }}
                      error={zodErrors.weeksOfPlayoffs !== undefined}
                      assistiveText={
                        zodErrors.weeksOfPlayoffs !== undefined
                          ? zodErrors.weeksOfPlayoffs._errors[0]
                          : ""
                      }
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
                    <div className="md:col-span-2">
                      <FormFieldSelect
                        name="skillChoiceId"
                        placeholder="Select skill level"
                        label="Skill Level"
                        value={
                          division.skillChoiceId
                            ? division.skillChoiceId.toString()
                            : "0"
                        }
                        inputChange={(value: string) => {
                          setDivision({
                            ...division,
                            skillChoiceId: parseInt(value),
                          });
                        }}
                      >
                        {[
                          { id: 0, name: "Select Skill Level" },
                          ...selectedSession.regSkillChoices,
                        ]}
                      </FormFieldSelect>
                    </div>
                  </div>
                </>
              </div>
            </>
          )}
        </Card>
        <Card className="mt-6">
          {divisionByIdData?.division.id != null && (
            <div>
              <Button
                variant="secondary"
                href={`/ops/division/reseed/${divisionByIdData?.division.id}`}
                className="flex"
                width="1/4"
              >
                <Body1 className="w-full text-center">Re-seed Divisions</Body1>
              </Button>
            </div>
          )}
          <div className="flex gap-2 mt-3">
            <Subtitle1>Teams and Divisions </Subtitle1>
            <IconButton
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                border: "1px solid #585858",
                padding: "12px",
              }}
              onClick={() => {
                setOpenInfoModal(true);
              }}
            >
              <QuestionMark />
            </IconButton>
          </div>
          {selectedSession ? (
            <div className="flex">
              {/* Unpooled Teams */}
              {unpooledTeams()}
              {/* Pooled Teams */}
              <div className="flex flex-wrap w-full">
                {selectedSession.divisions.length > 0 &&
                  selectedSession.divisions.map((sessionDivision) => {
                    return (
                      <div
                        key={sessionDivision.id}
                        className="w-1/3 p-4 m-2 bg-neutral-70"
                      >
                        <div className="flex flex-row items-center gap-2">
                          <div
                            style={{
                              backgroundColor: sessionDivision.vmColor,
                              height: "16px",
                              width: "16px",
                              borderRadius: "10px",
                            }}
                          ></div>
                          <p>{sessionDivision.name}</p>
                        </div>
                        <div>
                          {sessionDivision.teams
                            .map((team) => {
                              const teamRating =
                                sessionRatings?.getTeamsRatingsForSession.find(
                                  (rating) => rating?.teamId === team.id
                                );

                              // Create a new object with the original team properties and add rating/confidence
                              return {
                                ...team, // Copy existing properties of the team
                                rating: teamRating ? teamRating.rating : 0,
                                confidence: teamRating
                                  ? teamRating.confidence
                                  : 0,
                                nhFlag: teamRating?.ncFlag,
                                opFlag: teamRating?.opFlag,
                              };
                            })
                            .sort((a, b) =>
                              a.rating && b.rating && a.rating < b.rating
                                ? 1
                                : -1
                            )
                            .map((team: any, index: number) => {
                              const skill = team.registration
                                ? team.registration.regQuestionChoice?.regSkillChoice?.name.substring(
                                    0,
                                    3
                                  )
                                : null;

                              return (
                                <div key={team.id}>
                                  <div className="flex items-center my-1">
                                    {!isSchedulePublished &&
                                      division?.id === sessionDivision.id && (
                                        <input
                                          className="mr-2"
                                          type="checkbox"
                                          value={team.id}
                                          defaultChecked={
                                            !sessionDivision.teams.includes(
                                              team.id
                                            )
                                          }
                                          // checked={!division.teams.includes(+team.id)}
                                          onChange={handleTeamChecked}
                                        />
                                      )}
                                    <div className="flex items-center gap-2">
                                      <span>{team.name}</span>
                                      {skill && (
                                        <Chip
                                          size="small"
                                          label={skill}
                                          variant="outlined"
                                        />
                                      )}
                                      <Chip
                                        size="small"
                                        label={`${team.rating}R - ${
                                          team.confidence
                                        }C${
                                          team.opFlag
                                            ? "+"
                                            : team.ncFlag
                                            ? "*"
                                            : ""
                                        }`}
                                        variant="outlined"
                                      />
                                    </div>
                                  </div>
                                  <Separator />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div>no session selected</div>
          )}
        </Card>
        <div className="flex flex-row-reverse gap-3 mt-5">
          <Button
            variant="primary"
            onClick={(e) => handleSubmitForm(e)}
          >
            {isEditDivision ? (
              <span> Edit Division</span>
            ) : (
              <span> Create New Division</span>
            )}
          </Button>
          <Button
            variant="secondary"
            href={"/ops/divisions"}
          >
            Cancel
          </Button>
          <Dialog
            open={openInfoModal}
            onOpenChange={(open) => {
              setOpenInfoModal(open);
            }}
            aria-labelledby="info-modal"
            aria-describedby="info-modal"
          >
            <DialogContent>
              {/* Icons Info */}
              <div className="flex flex-col gap-3">
                <div className="text-xl font-bold">
                  Team Rating and Confidence{" "}
                  <span className="text-light">(eg. 77R - 88C)</span>
                </div>
                <div>
                  <span className="font-extrabold text-dark">
                    Team ratings and confidence are specific to the sport being
                    played.
                  </span>{" "}
                  The left number is a rating that has been calculated for the
                  team, based on playerson the roster. It takes into account
                  many factos. In general, teams should fall within the
                  following:
                </div>
                <ul className="list-disc">
                  <li>Open: 40-80</li>
                  <li>Rec: 50-80</li>
                  <li>Int: 75-95</li>
                  <li>Adv: 85-100</li>
                </ul>
                <div>
                  The higher the team is rated, the higher the division it
                  should be in.
                </div>
                <div>
                  The second number is the confidence in the rating. The more
                  history we have for players on the team the higher the
                  confidence in the rating{" "}
                </div>
                <div>
                  The special case of{" "}
                  <span className="font-extrabold text-dark">0R-0C</span> means
                  we have no history for anybody in the team.
                </div>
                <div>Other thins to note:</div>
                <ul className="list-disc">
                  <li>
                    C* - At least one player on the team has no history (is new
                    to this sport)
                  </li>
                  <li>C+ - The team only has one player on the roster</li>
                </ul>
              </div>
              <div className="flex justify-end gap-3 mt-5">
                <DialogClose>
                  <Button variant="secondary">Close</Button>
                </DialogClose>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </form>
    </main>
  );
};

export default CreateDivision;
