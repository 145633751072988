import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import AddIcon from "@mui/icons-material/Add";
import { PaginationType } from "../../../../types/types";
import Button from "../../../UI/Button/Button";
import ActionsCell from "../../../UI/ActionCell";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import {
  ThirdPartyContractorPaginatedQuery,
  useThirdPartyContractorPaginatedLazyQuery,
} from "../../../../generated/graphql";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { DataTable } from "../../../../../src/components/UI/Table/DataTable";
import PaginationDataTable from "../../../../../src/components/UI/Pagination/PaginationDataTable";
import { ColumnDef } from "@tanstack/react-table";

// List of options for action col
const options = [{ id: 1, text: "Edit" }];

const ThirdPartyContractorReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 10;

  /*** STATES ***/

  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    regionId: number;
  }>({
    regionId: 0,
  });
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: pageSize,
    pageCount: undefined,
  });

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [getThirdPartyContractors, { loading, data }] =
    useThirdPartyContractorPaginatedLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setPagination((prevState) => {
          return {
            ...prevState,
            pageCount: data.thirdPartyContractorPaginated.count,
          };
        });
      },
    });

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (filter) {
      getThirdPartyContractors({
        variables: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          regionId: filter.regionId ?? null,
        },
      });
    }
  }, [pagination.page, pagination.pageSize, filter.regionId]);

  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const handleSelectedOption = (
    row: ThirdPartyContractorPaginatedQuery["thirdPartyContractorPaginated"]["thirdPartyContractor"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/staffing/third-party-contractor-management/${row.id}`);
    }
  };

  // Table col definition
  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const columns: ColumnDef<
    ThirdPartyContractorPaginatedQuery["thirdPartyContractorPaginated"]["thirdPartyContractor"][0]
  >[] = [
    {
      header: "Id",
      id: "Id",
      accessorFn: (d) => d.id,
    },
    {
      header: "Name",
      id: "name",
      accessorFn: (d) => d.name,
    },
    {
      header: "Email",
      id: "email",
      accessorFn: (d) => d.email,
    },
    {
      header: "Region",
      id: "region",
      cell: ({ row }) => {
        return (
          <div>{row.original.region ? row.original.region.name : "All"}</div>
        );
      },
    },
    {
      header: " ",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row.original}
            options={options}
            handleSelectedOption={(_, i) =>
              handleSelectedOption(row.original, i)
            }
          />
        );
      },
    },
  ];

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loading} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>
          Third Party Contractor Management Report
        </Headline1Variable>

        <div className="mb-4 text-end">
          <Button
            variant="primary"
            onClick={() =>
              navigate("/staffing/third-party-contractor-management/new")
            }
          >
            <AddIcon />
            New Third Party Contractor
          </Button>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            value={filter.regionId ? filter.regionId.toString() : "0"}
            inputChange={(value) => {
              setFilter((prevState) => ({
                ...prevState,
                regionId: +value,
              }));
            }}
            name="regionId"
            id="regionId"
            label="Region"
            placeholder="Select Region"
            className="h-[46px] m-0 "
          >
            {selectedRegions.length > 0
              ? [...[{ id: 0, name: "All" }], ...selectedRegions]
              : [...[{ id: 0, name: "None" }]]}
          </FormFieldSelect>
        </div>
      </div>
      {data && data.thirdPartyContractorPaginated.thirdPartyContractor && (
        <div className="flex flex-col gap-4">
          <DataTable
            columns={columns}
            data={data.thirdPartyContractorPaginated.thirdPartyContractor}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
    </main>
  );
};

export default ThirdPartyContractorReport;
