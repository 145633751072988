import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import AddIcon from "@mui/icons-material/Add";
import { PaginationType } from "../../types/types";
import { getSports } from "../../app/venueMasterSlice";
import Button from "../UI/Button/Button";
import ActionsCell from "../UI/ActionCell";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import {
  GetRulesPaginatedQuery,
  useGetRulesPaginatedLazyQuery,
} from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import { DataTable } from "../UI/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";

const options = [{ id: 1, text: "Edit" }];

const RulesReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { isLoading, selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 10;

  const [filter, setFilter] = useState<{
    sportId: number | null;
    regionId: number | null;
  }>({
    sportId: 0,
    regionId: 0,
  });
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageCount: undefined,
    pageSize: pageSize,
  });

  const [getRules, { loading: loadingRules, data: dataRules }] =
    useGetRulesPaginatedLazyQuery({
      onCompleted: (data) => {
        setPagination((prevState) => {
          return {
            ...prevState,
            pageCount: data.getRulesPaginated.count,
          };
        });
      },
    });

  useEffect(() => {
    if (filter) {
      getRules({
        variables: {
          regionId: filter.regionId ?? null,
          sportId: filter.sportId ?? null,
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
      });
    }
  }, [filter.regionId, filter.sportId, pagination.page, pagination.pageSize]);

  // Get all the sports on load
  useEffect(() => {
    dispatch(getSports(""));
  }, [dispatch]);

  const handleSelectedOption = (
    row: GetRulesPaginatedQuery["getRulesPaginated"]["rules"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/ops/rule/${row.id}`);
    }
    if (idx === 2) {
    }
  };
  const columns: ColumnDef<
    GetRulesPaginatedQuery["getRulesPaginated"]["rules"][0]
  >[] = [
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <a
            className="underline"
            href={`/ops/rule/${row.original.id}`}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      header: "Region",
      id: "region",
      cell: ({ row }) => {
        return (
          <div>{row.original.region ? row.original.region.name : "All"}</div>
        );
      },
    },
    {
      header: "Sport",
      id: "sport",
      accessorFn: (d) => d.sport.name,
    },

    {
      header: " ",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row.original}
            options={options}
            handleSelectedOption={(_, i) =>
              handleSelectedOption(row.original, i)
            }
          />
        );
      },
    },
  ];

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loadingRules} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Rules</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            href={"/ops/rule"}
          >
            <AddIcon />
            New Rule
          </Button>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="max-w-60 min-w-60">
          {sports.length > 0 && (
            <FormFieldSelect
              name="sportId"
              id="sportId"
              value={filter.sportId ? filter.sportId.toString() : "0"}
              inputChange={(value) => {
                setFilter((prevState) => ({
                  ...prevState,
                  sportId: +value,
                }));
              }}
              label="Sport"
              placeholder="Select Sport"
              className="m-0"
            >
              {[...sports, ...[{ id: 0, name: "All" }]]}
            </FormFieldSelect>
          )}
        </div>
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            value={filter.regionId ? filter.regionId.toString() : "0"}
            inputChange={(value) => {
              setFilter((prevState) => ({
                ...prevState,
                regionId: +value,
              }));
            }}
            name="regionId"
            id="regionId"
            label="Region"
            placeholder="Select Region"
            className="m-0 "
          >
            {selectedRegions.length > 0
              ? [...[{ id: 0, name: "All" }], ...selectedRegions]
              : [...[{ id: 0, name: "None" }]]}
          </FormFieldSelect>
        </div>
      </div>
      {dataRules && (
        <div className="flex flex-col gap-4">
          <DataTable
            columns={columns}
            data={dataRules.getRulesPaginated.rules}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
    </main>
  );
};

export default RulesReport;
