// baseURL
export const baseApiUrl = `${
  process.env.REACT_APP_ENV === "production"
    ? "https://admin.jamsports.com"
    : process.env.REACT_APP_ENV === "preproduction"
    ? "https://ppadmin.jamsports.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://admin.jamitall.com"
    : "http://localhost:3001"
}/api/v1`;

export const baseURL = `${
  process.env.REACT_APP_ENV === "production"
    ? "https://jamsports.com"
    : process.env.REACT_APP_ENV === "preproduction"
    ? "https://preprod.jamsports.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://jamitall.com"
    : "http://localhost:4001"
}`;

export const baseAdminURL = `${
  process.env.REACT_APP_ENV === "production"
    ? "https://admin.jamsports.com"
    : process.env.REACT_APP_ENV === "preproduction"
    ? "https://ppadmin.jamsports.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://admin.jamitall.com"
    : "http://localhost:4000"
}`;
