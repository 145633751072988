import React, { FC } from "react";
import { FormFieldSelect } from "../FormField/FormFieldDropdown/FormFieldSelectV2";
import { FormFieldSelectMultiSearch } from "../FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import Button from "../Button/Button";
import Subtitle1 from "../Text/Subtitle/Subtitle1";
import FormFieldDate from "../FormField/FormFieldDate/FormFieldDate";
import { dayOfWeek } from "../../../utils/dayOfWeek";
import dayjs from "dayjs";
import { PaginationType } from "../../../types/types";

export type WayfinderFilter = {
  leagueIds: number[] | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  regionIds: number[];
  sportId: number | null;
  startDate: string;
  endDate: string | null;
};

type FilterOption = {
  id: number;
  name: string;
};

const initialFilter = {
  regionIds: [0],
  registrationBatchId: null,
  sportId: null,
  dayOfWeek: null,
  leagueIds: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: null,
};

const initialPagination = {
  page: 0,
  pageSize: 25,
  pageCount: undefined,
};

type WayfinderFilterProps = {
  setFilter: React.Dispatch<React.SetStateAction<WayfinderFilter>>;
  filter: WayfinderFilter;
  setPagination: React.Dispatch<React.SetStateAction<PaginationType>>;
  registrationBatches: FilterOption[];
  selectedRegions: FilterOption[];
  sports: FilterOption[];
  leagues: FilterOption[] | undefined;
};

const WayfinderFilter: FC<WayfinderFilterProps> = ({
  filter,
  setFilter,
  setPagination,
  registrationBatches,
  selectedRegions,
  sports,
  leagues,
}: WayfinderFilterProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-3 gap-2">
        <div className="flex flex-col gap-2">
          <FormFieldSelect
            inputChange={(value) => {
              setFilter((prevState: WayfinderFilter) => {
                return {
                  ...prevState,
                  registrationBatchId: +value,
                };
              });
            }}
            label="Registration Batch"
            placeholder="Registration Batch"
            value={filter.registrationBatchId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...registrationBatches]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setFilter((prevState: WayfinderFilter) => {
                return {
                  ...prevState,
                  regionIds: [+value],
                };
              });
            }}
            label="Region"
            placeholder="Region"
            value={filter.regionIds[0].toString()}
          >
            {[{ id: "0", name: "All" }, ...selectedRegions]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-col gap-2">
          <FormFieldSelect
            inputChange={(value) => {
              setFilter((prevState: WayfinderFilter) => {
                return {
                  ...prevState,
                  sportId: +value,
                };
              });
            }}
            label="Sport"
            placeholder="Sport"
            value={filter.sportId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...sports]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setFilter((prevState: WayfinderFilter) => {
                return {
                  ...prevState,
                  dayOfWeek: +value,
                };
              });
            }}
            label="Day Of Week"
            placeholder="Day Of Week"
            value={filter.dayOfWeek?.toString() ?? "-1"}
          >
            {[{ id: "-1", name: "All" }, ...dayOfWeek]}
          </FormFieldSelect>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-2">
          <FormFieldSelectMultiSearch
            label="Select Leagues"
            name="leagueIds"
            maxCount={2}
            options={[
              ...(leagues?.map((league) => {
                return { value: `${league.id}`, label: league.name };
              }) || []),
            ]}
            values={
              filter.leagueIds
                ? filter.leagueIds.map((id) => id.toString())
                : []
            }
            onValueChange={(values) => {
              setFilter((prevState) => {
                return {
                  ...prevState,
                  leagueIds: values.map((value) => +value),
                };
              });
            }}
            placeholder="Select League"
          />
        </div>
        <div className="flex items-end">
          <Button
            variant="secondary"
            className="w-full h-10"
            onClick={() => {
              setFilter(initialFilter);
              setPagination(initialPagination);
            }}
          >
            Reset All Filters
          </Button>
        </div>
      </div>
      <Subtitle1>Start Date Range</Subtitle1>
      <div className="grid items-end grid-cols-3 gap-2">
        <FormFieldDate
          label="Start*"
          initialValue={dayjs(filter.startDate).toDate()}
          dateChange={(date) => {
            setFilter((prevState: WayfinderFilter) => {
              return {
                ...prevState,
                startDate: dayjs(date).format("YYYY-MM-DD"),
              };
            });
          }}
        />
        <FormFieldDate
          label="End"
          initialValue={
            filter.endDate ? dayjs(filter.endDate).toDate() : undefined
          }
          dateChange={(date) => {
            setFilter((prevState: WayfinderFilter) => {
              return {
                ...prevState,
                endDate: dayjs(date).format("YYYY-MM-DD"),
              };
            });
          }}
        />
        <div className="flex flex-row w-full gap-4">
          <Button
            variant="secondary"
            className="w-full h-10"
            onClick={() => {
              setFilter((prevState: WayfinderFilter) => {
                return {
                  ...prevState,
                  startDate: initialFilter.startDate,
                  endDate: null,
                };
              });
            }}
          >
            Reset Dates
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WayfinderFilter;
