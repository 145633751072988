import { useEffect, useState } from "react";
import Button from "../Button/Button";
import Headline2Variable from "../Text/Headline/Headline2Variable";
import {
  ScheduleTemplatesQuery,
  useScheduleTemplatesQuery,
  VenuesTreeQuery,
} from "../../../generated/graphql";
import Body1 from "../Text/Body/Body1";
import { FormFieldSelect } from "../FormField/FormFieldDropdown/FormFieldSelectV2";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TeamList, Template } from "../../Admin/Schedule/ScheduleNew";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../UI/shadcn/table";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../../UI/shadcn/dialog";
import Subtitle1 from "../Text/Subtitle/Subtitle1";
import { Separator } from "../shadcn/separator";
import { Loader2, LoaderCircle } from "lucide-react";
import Disclaimer from "../Alerts/Disclaimer";
import { z, ZodFormattedError } from "zod";
import { useDispatch } from "react-redux";
import { renderZodErrors } from "../../../utils/zodErrors";
import { AppDispatch } from "../../../app/store";
import {
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import Alert from "../Alerts/Alert";

dayjs.extend(customParseFormat);

const TemplateScheduleGameSchema = z.object({
  startDateTimeLocal: z.string(),
  venueId: z.number(),
  homeTeamId: z.number().min(1),
  awayTeamId: z.number().min(1),
  isDoubleHeader: z.boolean(),
});
const GamesByWeekSchema = z
  .map(z.number(), z.array(TemplateScheduleGameSchema))
  .refine((data) => {
    for (const games of Array.from(data.values())) {
      const keys = games.map(
        (game) => `${game.venueId}-${game.startDateTimeLocal}`
      );
      if (new Set(keys).size !== games.length) {
        return false;
      }
    }
    return true;
  });

export type TemplateScheduleGame = z.infer<typeof TemplateScheduleGameSchema>;
export type GamesByWeek = z.infer<typeof GamesByWeekSchema>;

interface TemplateScheduleDialogProps {
  venues: VenuesTreeQuery["venuesTree"];
  teams: TeamList[];
  teamCount: number;
  weekCount: number;
  gamesPerOccurrence: number;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmitTemplateSchedule: (
    games: Map<number, TemplateScheduleGame[]>
  ) => void;
}

/**
 * A dialog that allows the user to swap two teams
 * @param venues Array of venue options
 * @param teams Array of team options
 * @param teamCount Number of teams in the division
 * @param weekCount Number of weeks in the division
 * @param gamesPerOccurrence The number of games per week
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @param onSubmitTemplateSchedule Function to submit the auto schedule
 * @returns
 */
const TemplateScheduleDialog = ({
  venues,
  teams,
  teamCount,
  weekCount,
  gamesPerOccurrence,
  open,
  onOpenChange,
  onSubmitTemplateSchedule,
}: TemplateScheduleDialogProps) => {
  const numberOfTimeslots = Math.ceil(teamCount / 2) * gamesPerOccurrence;
  const templateInit = Array.from({ length: numberOfTimeslots }).map(
    (_, index) => ({
      startDateTimeLocal: "18:00",
      venueId: "",
    })
  );
  const hours = Array.from({ length: 24 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 60 / 5 }, (_, i) => i * 5);

  const dispatch = useDispatch<AppDispatch>();

  const [venueTimeTemplate, setVenueTimeTemplate] =
    useState<Template[]>(templateInit);
  const [scheduleTemplate, setScheduleTemplate] = useState<
    ScheduleTemplatesQuery["scheduleTemplates"][0] | undefined
  >();
  const [gamesByWeek, setGamesByWeek] = useState<GamesByWeek>(
    new Map<number, TemplateScheduleGame[]>(
      Array.from({ length: weekCount }, (_, i) => {
        return [
          i,
          Array.from({ length: numberOfTimeslots }, (_, j) => {
            return {
              startDateTimeLocal: "18:00",
              venueId: 0,
              homeTeamId: 0,
              awayTeamId: 0,
              isDoubleHeader: false,
            };
          }),
        ];
      })
    )
  );
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<GamesByWeek, string>
  >({ _errors: [] });

  const [timeDistribution, setTimeDistribution] = useState<{
    [timeSlot: string]: {
      [teamName: string]: { count: number };
    };
  }>({});
  const [gamesDistribution, setGamesDistribution] = useState<{
    [homeTeamName: string]: {
      [awayTeamName: string]: { count: number };
    };
  }>({});

  const { data: scheduleTemplateData, networkStatus } =
    useScheduleTemplatesQuery({
      variables: {
        teamCount,
        weekCount,
        gamesPerOccurrence,
      },
      skip: !open,
    });

  useEffect(() => {
    const allGames = Array.from(gamesByWeek.values()).flatMap((week) => week);
    // unique teams in division
    const teamIds: number[] = teams.map((team) => team.id);

    // extract unique venueIds from gamesByWeek
    const venueIds: number[] = [];
    allGames.map((game) => {
      if (!venueIds.includes(game.venueId)) {
        venueIds.push(game.venueId);
      }
    });

    // Initialize venueDistribution 2-D array
    const venueDistribution: {
      [venueId: string]: {
        [teamName: string]: { count: number };
      };
    } = {};
    venueIds.forEach((venueId) => {
      venueDistribution[venueId] = {};
      teamIds.forEach((teamId) => {
        venueDistribution[venueId][teamId] = {
          count: 0,
        };
      });
    });

    // 2-D array of teamIds and venueIds from gamesByWeek
    allGames.forEach((game) => {
      if (game.homeTeamId) {
        if (!venueDistribution[game.venueId][game.homeTeamId]) {
          venueDistribution[game.venueId][game.homeTeamId] = {
            count: 0,
          };
        }

        venueDistribution[game.venueId][game.homeTeamId] = {
          count: venueDistribution[game.venueId][game.homeTeamId].count
            ? venueDistribution[game.venueId][game.homeTeamId].count + 1
            : 0,
        };
      }

      if (game.awayTeamId) {
        if (!venueDistribution[game.venueId][game.awayTeamId]) {
          venueDistribution[game.venueId][game.awayTeamId] = {
            count: 0,
          };
        }

        venueDistribution[game.venueId][game.awayTeamId] = {
          count: venueDistribution[game.venueId][game.awayTeamId]
            ? venueDistribution[game.venueId][game.awayTeamId].count + 1
            : 0,
        };
      }
    });

    // extract unique timeSlots from gamesByWeek
    const timeSlots: string[] = [];

    // Collect unique time slots
    allGames.forEach((game) => {
      const timeSlot = game.startDateTimeLocal;
      if (!timeSlots.includes(timeSlot)) {
        timeSlots.push(timeSlot);
      }
    });

    timeSlots.sort();

    // Initialize the timeDistribution object
    const timeDistribution: {
      [timeSlot: string]: {
        [teamName: string]: { count: number };
      };
    } = {};

    allGames.forEach((game) => {
      const timeSlot = game.startDateTimeLocal;

      // Initialize the timeSlot in timeDistribution if not already present
      if (!timeDistribution[timeSlot]) {
        timeDistribution[timeSlot] = {};
      }

      // Initialize home team count if it doesn't exist, then increment
      if (game.homeTeamId) {
        if (!timeDistribution[timeSlot][game.homeTeamId]) {
          timeDistribution[timeSlot][game.homeTeamId] = { count: 1 };
        } else {
          timeDistribution[timeSlot][game.homeTeamId].count += 1;
        }
      }

      // Initialize away team count if it doesn't exist, then increment
      if (game.awayTeamId) {
        if (!timeDistribution[timeSlot][game.awayTeamId]) {
          timeDistribution[timeSlot][game.awayTeamId] = { count: 1 };
        } else {
          timeDistribution[timeSlot][game.awayTeamId].count += 1;
        }
      }
    });

    // Map team IDs to team names
    const teamIdToName: { [id: string]: string } = {};
    teams.forEach((team) => {
      teamIdToName[team.id] = team.name;
    });

    // Create a new timeDistribution that uses team names instead of IDs
    const newTimeDistribution: {
      [timeSlot: string]: {
        [teamName: string]: { count: number };
      };
    } = {};

    Object.keys(timeDistribution).forEach((timeSlot) => {
      newTimeDistribution[timeSlot] = {};
      Object.keys(timeDistribution[timeSlot]).forEach((teamId) => {
        const teamName = teamIdToName[teamId];
        newTimeDistribution[timeSlot][teamName] =
          timeDistribution[timeSlot][teamId];
      });
    });

    //Games Distribution by matchups
    const newGamesDistribution: {
      [homeTeamName: string]: {
        [awayTeamName: string]: { count: number };
      };
    } = {};

    teams.forEach((team) => {
      newGamesDistribution[team.name] = {};
      teams.forEach((team2) => {
        newGamesDistribution[team.name][team2.name] = {
          count: 0,
        };
      });
    });

    allGames.forEach((game) => {
      if (game.homeTeamId !== null || game.awayTeamId !== null) {
        const homeTeamName = teamIdToName[game.homeTeamId || 0];
        const awayTeamName = teamIdToName[game.awayTeamId || 0];
        if (!homeTeamName || !awayTeamName) return;
        if (!newGamesDistribution[homeTeamName][awayTeamName]) {
          newGamesDistribution[homeTeamName][awayTeamName] = {
            count: 0,
          };
        }
        if (!newGamesDistribution[awayTeamName][homeTeamName]) {
          newGamesDistribution[awayTeamName][homeTeamName] = {
            count: 0,
          };
        }

        newGamesDistribution[homeTeamName][awayTeamName] = {
          count: newGamesDistribution[homeTeamName][awayTeamName].count + 1,
        };
        newGamesDistribution[awayTeamName][homeTeamName] = {
          count: newGamesDistribution[awayTeamName][homeTeamName].count + 1,
        };
      }
    });

    setTimeDistribution(newTimeDistribution);
    setGamesDistribution(newGamesDistribution);
  }, [gamesByWeek]);

  useEffect(() => {
    // Change the games per week based on the venue/time updates
    const newGamesByWeek = new Map(gamesByWeek);
    newGamesByWeek.forEach((value, key) => {
      newGamesByWeek.set(
        key,
        value.map((matchup, index) => {
          return {
            ...matchup,
            startDateTimeLocal: venueTimeTemplate[index].startDateTimeLocal,
            venueId: +venueTimeTemplate[index].venueId,
          };
        })
      );
    });
    setGamesByWeek(newGamesByWeek);
  }, [venueTimeTemplate]);
  useEffect(() => {
    if (scheduleTemplate) {
      // Change the games per week based on the selected schedule template
      const newGamesByWeek = new Map(gamesByWeek);
      newGamesByWeek.forEach((value, key) => {
        const scheduleTemplateForWeek =
          scheduleTemplate.scheduleTemplateMatchups
            .filter((matchup) => matchup.weekNumber - 1 === key)
            .sort((a, b) => a.gameNumber - b.gameNumber);

        newGamesByWeek.set(
          key,
          value.map((matchup, index) => {
            return {
              ...matchup,
              homeTeamId:
                teams[scheduleTemplateForWeek[index].homeTeamNumber - 1].id,
              awayTeamId:
                teams[scheduleTemplateForWeek[index].awayTeamNumber - 1].id,
            };
          })
        );
      });
      newGamesByWeek.forEach((games, week) => {
        const frequency: { [teamId: number]: number } = {};
        games.forEach((game) => {
          if (game.homeTeamId) {
            frequency[game.homeTeamId] = (frequency[game.homeTeamId] || 0) + 1;
          }
          if (game.awayTeamId) {
            frequency[game.awayTeamId] = (frequency[game.awayTeamId] || 0) + 1;
          }
        });
        newGamesByWeek.set(
          week,
          games.map((game) => ({
            ...game,
            isDoubleHeader:
              frequency[game.homeTeamId] > 1 || frequency[game.awayTeamId] > 1,
          }))
        );
      });

      setGamesByWeek(newGamesByWeek);
    }
  }, [scheduleTemplate]);

  function checkGamesByWeek(): boolean {
    const result = GamesByWeekSchema.safeParse(gamesByWeek);
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is an issue with the form"));
      return false;
    }
    dispatch(displayAlertSuccess("Applied template successfully"));
    onOpenChange(false);
    return result.success;
  }

  const createTimeDistributionRows = () => {
    const rows: any[] = [];
    Object.keys(timeDistribution).forEach((timeSlot) => {
      const row: any = [];
      row.push(<TableCell>{timeSlot}</TableCell>);
      Object.keys(timeDistribution[timeSlot]).forEach((teamName) => {
        row.push(
          <TableCell>
            <div>{timeDistribution[timeSlot][teamName].count || 0}</div>
          </TableCell>
        );
      });
      rows.push(<TableRow key={timeSlot}>{row}</TableRow>);
    });
    return rows;
  };

  const createGamesDistributionRows = () => {
    const rows: any[] = [];
    Object.keys(gamesDistribution).forEach((homeTeamName) => {
      const row: any = [];
      row.push(<TableCell>{homeTeamName}</TableCell>);
      Object.keys(gamesDistribution[homeTeamName]).forEach((awayTeamName) => {
        row.push(
          <TableCell>
            <div>
              {gamesDistribution[homeTeamName][awayTeamName].count === 0
                ? "-"
                : gamesDistribution[homeTeamName][awayTeamName].count}
            </div>
          </TableCell>
        );
      });
      rows.push(<TableRow>{row}</TableRow>);
    });
    return rows;
  };

  const scheduleDataExists = () => {
    return (
      scheduleTemplateData !== undefined &&
      scheduleTemplateData.scheduleTemplates.length > 0
    );
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => onOpenChange(open)}
    >
      <DialogContent className="max-h-[65vh] flex flex-col w-full max-w-3xl overflow-scroll">
        <DialogTitle>
          <Headline2Variable>Template Schedule</Headline2Variable>
          {networkStatus === 1 ? (
            <Loader2 className="animate-spin" />
          ) : (
            <>
              {!scheduleDataExists() && (
                <Alert
                  variant="warning"
                  content={`There are no templates for ${teamCount} teams over ${weekCount} weeks.`}
                  size="large"
                  persist={true}
                />
              )}
              {scheduleDataExists() && (
                <Body1>
                  Select the time slot and venue distribution for each week, for{" "}
                  {teamCount} teams over {weekCount} weeks.
                </Body1>
              )}
            </>
          )}
        </DialogTitle>
        {renderZodErrors(zodErrors)}

        {scheduleDataExists() && (
          <>
            <FormFieldSelect
              inputChange={(value) => {
                const scheduleTemplateValue =
                  scheduleTemplateData?.scheduleTemplates.find(
                    (sch) => sch.id === +value
                  );
                setScheduleTemplate(scheduleTemplateValue);
              }}
              label="Select Template"
              placeholder="Templates"
              value={scheduleTemplate ? scheduleTemplate.id.toString() : "0"}
            >
              {scheduleTemplateData
                ? scheduleTemplateData.scheduleTemplates.map((schT) => ({
                    id: schT.id.toString(),
                    name: schT.name,
                  }))
                : []}
            </FormFieldSelect>
            {venueTimeTemplate.map((temp, index) => {
              const dateValue = dayjs(temp.startDateTimeLocal, "HH:mm");
              const hour = dateValue.hour();
              return (
                <div
                  className="flex flex-row gap-2"
                  key={index}
                >
                  <div className="flex gap-1 max-w-fit">
                    <FormFieldSelect
                      defaultValue="0"
                      inputChange={(value) => {
                        let newHour = value;
                        const newValue = dayjs(dateValue.hour(newHour)).format(
                          "HH:mm"
                        );
                        setVenueTimeTemplate((prev) => {
                          const newTemplate = [...prev];
                          newTemplate[index] = {
                            ...newTemplate[index],
                            startDateTimeLocal: newValue,
                          };
                          return newTemplate;
                        });
                      }}
                      name="hour"
                      label="Hour"
                      placeholder="hour"
                      value={hour.toString()}
                    >
                      {hours.map((h) => ({ id: h.toString(), name: h }))}
                    </FormFieldSelect>
                    <div className="pt-5">:</div>
                    <FormFieldSelect
                      defaultValue="0"
                      inputChange={(value) => {
                        let newMinute = value;
                        setVenueTimeTemplate((prev) => {
                          const newTemplate = [...prev];
                          newTemplate[index] = {
                            ...newTemplate[index],
                            startDateTimeLocal: dayjs(
                              dateValue.minute(newMinute)
                            ).format("HH:mm"),
                          };
                          return newTemplate;
                        });
                      }}
                      name="minutes"
                      label="Minutes"
                      placeholder="minutes"
                      value={dateValue.minute().toString()}
                    >
                      {minutes.map((h) => ({ id: h.toString(), name: h }))}
                    </FormFieldSelect>
                  </div>
                  <div className="w-full">
                    <FormFieldSelect
                      inputChange={(value) => {
                        setVenueTimeTemplate((prev) => {
                          const newTemplate = [...prev];
                          newTemplate[index] = {
                            ...newTemplate[index],
                            venueId: value,
                          };
                          return newTemplate;
                        });
                      }}
                      label="Select venue"
                      placeholder="Venues"
                      value={temp.venueId}
                    >
                      {venues}
                    </FormFieldSelect>
                  </div>
                </div>
              );
            })}
            <div className="flex flex-row gap-4">
              <Button
                variant={"primary"}
                onClick={() => {
                  if (checkGamesByWeek()) {
                    onSubmitTemplateSchedule(gamesByWeek);
                  }
                }}
              >
                Apply
              </Button>
            </div>
          </>
        )}
        <DialogClose>
          <Button variant="secondary">Close</Button>
        </DialogClose>
        {scheduleDataExists() && gamesByWeek.size !== 0 && (
          <div className="max-h-[70vh]">
            <div className="flex flex-col gap-2">
              <Subtitle1>Time Slot Distribution</Subtitle1>
              <Separator />
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="pl-0">TimeSlots</TableHead>
                    {teams.map((team) => {
                      return (
                        <TableHead
                          className="pl-0"
                          key={team.id}
                        >
                          {team.name}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                </TableHeader>
                <TableBody className="text-center">
                  {createTimeDistributionRows()}
                </TableBody>
              </Table>
            </div>
            <div className="flex flex-col gap-2">
              <Subtitle1>Games Distribution</Subtitle1>
              <Separator />
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="pl-0"></TableHead>
                    {teams.map((team) => {
                      return (
                        <TableHead className="pl-0">{team.name}</TableHead>
                      );
                    })}
                  </TableRow>
                </TableHeader>
                <TableBody className="text-center">
                  {createGamesDistributionRows()}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TemplateScheduleDialog;
