import React, { useEffect, useMemo, useState } from "react";
import {
  DayOfWeek,
  getRegistrationBatch,
  getSports,
} from "../../../app/venueMasterSlice";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../../UI/Table/DataTable";
import { Calendar, CalendarCheck, Loader2 } from "lucide-react";
import dayjs from "dayjs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../UI/shadcn/dropdown";
import {
  Tooltip as TooltipBody,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../UI/shadcn/tooltip";
import { MoreVerticalIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import Tooltip from "../../UI/Tooltip/Tooltip";
import {
  CapacityGroupWayfinderQuery,
  DivisionWayfinderQuery,
  FreeAgentWayfinderQuery,
  ScheduleWayfinderQuery,
  SessionsPaginatedQuery,
  useCapacityGroupWayfinderLazyQuery,
  useDivisionWayfinderLazyQuery,
  useFreeAgentWayfinderLazyQuery,
  useFreeAgentWayfinderQuery,
  useLeagueDynamicQuery,
  useScheduleWayfinderLazyQuery,
  useSessionsPaginatedQuery,
} from "../../../generated/graphql";
import { rootUrl } from "../../../utils/environmentDependantVars";
import PaginationDataTable from "../../UI/Pagination/PaginationDataTable";
import Cookies from "js-cookie";
import WayfinderFilter from "../../UI/Filter/WayfinderFilter";
import { PaginationType } from "@/src/types/types";
import { PersonOutlineOutlined } from "@mui/icons-material";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import Body1 from "../../UI/Text/Body/Body1";
import { cn } from "../../../lib/utils";
import {
  CountCapacityGroupIssues,
  CountDivisionIssues,
  CountFaIssues,
  CountScheduleIssues,
} from "./leagueWayfinderHelpers";
import Caption1 from "../../UI/Text/Caption/Caption1";

type SessionFilterWayfinder = {
  leagueIds: number[] | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  regionIds: number[];
  sportId: number | null;
  startDate: string;
  endDate: string | null;
};

const initialSessionFilters = {
  regionIds: [0],
  registrationBatchId: null,
  sportId: null,
  dayOfWeek: null,
  leagueIds: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: null,
};

const initialPagination = {
  page: 0,
  pageSize: 25,
  pageCount: undefined,
};

type ExtendedSession =
  SessionsPaginatedQuery["sessionsPaginated"]["sessions"][0] & {
    faRegistrations: boolean | undefined;
    faTeams: boolean | undefined;
    faOrphans: boolean | undefined;
    divisionsCreated: boolean | undefined;
    orphanTeams: boolean | undefined;
    gameslotReserved: boolean | undefined;
    createdGameslots: boolean | undefined;
    gameslotMatchReserve: boolean | undefined;
    numOfGameslots: number | undefined;
    numOfResserved: number | undefined;
    divisionsScheduleIssue:
      | {
          id: number | null | undefined;
          scheduleCreated: boolean | undefined;
          schedulePublished: boolean | undefined;
          gameErrors: boolean | undefined;
        }[]
      | undefined
      | null;
  };

const Wayfinder: React.FC = () => {
  const { selectedRegions, registrationBatches, sports } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const dispatch: any = useDispatch<AppDispatch>();

  const [sessionFilters, setSessionFilters] = useState<SessionFilterWayfinder>(
    () => {
      const sessionFilter = Cookies.get("sessionFilters");
      if (sessionFilter) {
        const filter = JSON.parse(sessionFilter);
        return filter;
      } else {
        return initialSessionFilters;
      }
    }
  );
  const [pagination, setPagination] = useState<PaginationType>(() => {
    const wayfinderPagination = Cookies.get("leagueWayfinderPagination");
    if (wayfinderPagination) {
      return JSON.parse(wayfinderPagination);
    } else {
      return initialPagination;
    }
  });

  const [sessionData, setSessionData] = useState<ExtendedSession[]>([]);

  const { data: dataLeagues, loading: loadingLeagues } = useLeagueDynamicQuery({
    variables: {
      leagueDynamicFilter: {
        regBatchIds: sessionFilters.registrationBatchId
          ? [sessionFilters.registrationBatchId]
          : [],
        regionIds:
          sessionFilters.regionIds[0] === 0
            ? selectedRegions.map((region) => region.id)
            : sessionFilters.regionIds,
        sportIds: sessionFilters.sportId ? [sessionFilters.sportId] : [],
        dayIds: sessionFilters.dayOfWeek ? [sessionFilters.dayOfWeek] : [],
        sportFormatIds: [],
      },
    },
  });

  const { data: dataSessions, loading: loadingSessions } =
    useSessionsPaginatedQuery({
      variables: {
        sessionsPaginatedInput: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          sessionFilters: {
            ...sessionFilters,
            regionIds:
              sessionFilters.regionIds[0] === 0
                ? selectedRegions.map((region) => region.id)
                : sessionFilters.regionIds,
            dayOfWeek:
              sessionFilters.dayOfWeek == -1 ? null : sessionFilters.dayOfWeek,
          },
        },
      },
      onCompleted: (data) => {
        if (data.sessionsPaginated) {
          const sessions: ExtendedSession[] =
            data.sessionsPaginated.sessions.map((session) => ({
              ...session,
              faRegistrations: undefined,
              faTeams: undefined,
              faOrphans: undefined,
              divisionsCreated: undefined,
              orphanTeams: undefined,
              gameslotReserved: undefined,
              createdGameslots: undefined,
              gameslotMatchReserve: undefined,
              numOfGameslots: undefined,
              numOfResserved: undefined,
              divisionsScheduleIssue: undefined,
            }));
          setSessionData(sessions);
          if (sessions.length > 0) {
            freeAgentWayfinder({
              variables: {
                sessionIds: sessions.map((session) => session.id),
              },
              onCompleted: (data) => {
                const sessionToFreeAgentData = freeAgentDataToMap(
                  data.freeAgentWayfinder
                );
                setSessionData((prev) =>
                  prev.map((session) => {
                    const sessionData = sessionToFreeAgentData.get(session.id);
                    return {
                      ...session,
                      faRegistrations: sessionData?.faRegistrations,
                      faTeams: sessionData?.faTeams,
                      faOrphans: sessionData?.faOrphans,
                    };
                  })
                );
              },
            });

            divisionWayfinder({
              variables: {
                sessionIds: sessions.map((session) => session.id),
              },
              onCompleted: (data) => {
                const sessionToDivisionData = divisionDataToMap(
                  data.divisionWayfinder
                );
                setSessionData((prev) =>
                  prev.map((session) => {
                    const sessionData = sessionToDivisionData.get(session.id);
                    return {
                      ...session,
                      divisionsCreated: sessionData?.divisionsCreated,
                      orphanTeams: sessionData?.orphanTeams,
                    };
                  })
                );
              },
            });
            capacityGroupWayfinder({
              variables: {
                sessionIds: sessions.map((session) => session.id),
              },
              onCompleted: (data) => {
                const sessionToCapacityGroupData = capacityGroupDataToMap(
                  data.capacityGroupWayfinder
                );
                setSessionData((prev) =>
                  prev.map((session) => {
                    const sessionData = sessionToCapacityGroupData.get(
                      session.id
                    );
                    return {
                      ...session,
                      gameslotReserved: sessionData?.gameslotReserved,
                      createdGameslots: sessionData?.createdGameslots,
                      gameslotMatchReserve: sessionData?.gameslotMatchReserve,
                      numOfGameslots: sessionData?.numOfGameslots,
                      numOfResserved: sessionData?.numOfResserved,
                    };
                  })
                );
              },
            });
            scheduleWayfinder({
              variables: {
                sessionIds: sessions.map((session) => session.id),
              },
              onCompleted: (data) => {
                const sessionToScheduleData = scheduleDataToMap(
                  data.scheduleWayfinder
                );
                setSessionData((prev) =>
                  prev.map((session) => {
                    const sessionData = sessionToScheduleData.get(session.id);
                    return {
                      ...session,
                      divisionsScheduleIssue: sessionData,
                    };
                  })
                );
              },
            });
          }
          setPagination((prevState: PaginationType) => {
            return {
              ...prevState,
              pageCount: data.sessionsPaginated.count,
            };
          });
        }
      },
    });

  const [
    freeAgentWayfinder,
    { data: freeAgentWayfinderData, loading: freeAgentWayfinderLoading },
  ] = useFreeAgentWayfinderLazyQuery({});

  function freeAgentDataToMap(
    freeAgentData: FreeAgentWayfinderQuery["freeAgentWayfinder"]
  ) {
    const sessionToFreeAgentData: Map<
      number,
      {
        faRegistrations: boolean;
        faTeams: boolean;
        faOrphans: boolean;
      }
    > = new Map();
    freeAgentData.results.forEach((res) => {
      sessionToFreeAgentData.set(res.id, {
        faRegistrations: res.faRegistrations,
        faTeams: res.faTeams,
        faOrphans: res.faOrphans,
      });
    });
    return sessionToFreeAgentData;
  }

  const [
    divisionWayfinder,
    { data: divisionWayfinderData, loading: divisionWayfinderLoading },
  ] = useDivisionWayfinderLazyQuery({});

  function divisionDataToMap(
    divisionData: DivisionWayfinderQuery["divisionWayfinder"]
  ) {
    const sessionToDivisionData: Map<
      number,
      {
        divisionsCreated: boolean;
        orphanTeams: boolean;
      }
    > = new Map();
    divisionData.results.forEach((res) => {
      sessionToDivisionData.set(res.id, {
        divisionsCreated: res.divisionsCreated,
        orphanTeams: res.orphanTeams,
      });
    });
    return sessionToDivisionData;
  }

  const [
    capacityGroupWayfinder,
    {
      data: capacityGroupWayfinderData,
      loading: capacityGroupWayfinderLoading,
    },
  ] = useCapacityGroupWayfinderLazyQuery({});

  function capacityGroupDataToMap(
    capacityGroupData: CapacityGroupWayfinderQuery["capacityGroupWayfinder"]
  ) {
    const sessionToCapacityGroupData: Map<
      number,
      {
        gameslotReserved: boolean;
        createdGameslots: boolean;
        gameslotMatchReserve: boolean;
        numOfGameslots: number;
        numOfResserved: number;
      }
    > = new Map();
    capacityGroupData.results.forEach((res) => {
      sessionToCapacityGroupData.set(res.id, {
        gameslotReserved: res.gameslotReserved,
        createdGameslots: res.createdGameslots,
        gameslotMatchReserve: res.gameslotMatchReserve,
        numOfGameslots: res.numOfGameslots,
        numOfResserved: res.numOfResserved,
      });
    });
    return sessionToCapacityGroupData;
  }

  const [
    scheduleWayfinder,
    { data: scheduleWayfinderData, loading: scheduleWayfinderLoading },
  ] = useScheduleWayfinderLazyQuery({});

  function scheduleDataToMap(
    scheduleData: ScheduleWayfinderQuery["scheduleWayfinder"]
  ) {
    const sessionToScheduleData: Map<
      number,
      | {
          id: number | null | undefined;
          scheduleCreated: boolean | undefined;
          schedulePublished: boolean | undefined;
          gameErrors: boolean | undefined;
        }[]
      | null
    > = new Map();
    scheduleData.results.forEach((res) => {
      sessionToScheduleData.set(
        res.id,
        res.divisions
          ? res.divisions.map((division) => ({
              id: division.id,
              scheduleCreated: division.scheduleCreated,
              schedulePublished: division.schedulePublished,
              gameErrors: division.gameErrors,
            }))
          : null
      );
    });
    return sessionToScheduleData;
  }

  useEffect(() => {
    dispatch(getSports(""));
    dispatch(getRegistrationBatch(""));
  }, []);

  useEffect(() => {
    if (sessionFilters === initialSessionFilters) {
      Cookies.remove("sessionFilters");
    } else {
      Cookies.set("sessionFilters", JSON.stringify(sessionFilters));
    }
  }, [sessionFilters]);
  useEffect(() => {
    if (pagination === initialPagination) {
      Cookies.remove("leagueWayfinderPagination");
    } else {
      Cookies.set("leagueWayfinderPagination", JSON.stringify(pagination));
    }
  }, [pagination]);

  useEffect(() => {
    if (selectedRegions.length === 0) {
      return;
    }
    const regionIdsOnSelectionChange = sessionFilters.regionIds.filter(
      (sessionFilterRegion) =>
        selectedRegions.map((region) => region.id).includes(sessionFilterRegion)
    );
    setSessionFilters((prevState) => ({
      ...prevState,
      regionIds:
        regionIdsOnSelectionChange.length > 0
          ? regionIdsOnSelectionChange
          : [0],
    }));
  }, [selectedRegions]);

  const sessionColumns: ColumnDef<ExtendedSession>[] = [
    {
      accessorKey: "id",
      header: () => <div className="text-center">Session Id</div>,
      cell: ({ row }) => {
        return <p className="font-medium text-center">{row.getValue("id")}</p>;
      },
    },
    {
      accessorKey: "region.name",
      header: "Region",
    },
    {
      accessorKey: "league.name",
      header: "League",
    },
    {
      accessorKey: "dayOfWeek",
      header: () => <div className="text-left">Day of Week</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">{DayOfWeek[row.original.dayOfWeek]}</p>
        );
      },
    },
    {
      accessorKey: "startDate",
      header: () => <div className="text-left">Start Date</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">
            {dayjs(row.original.startDate).format("YYYY-MM-DD")}
          </p>
        );
      },
    },
    {
      accessorKey: "setCapacity",
      header: () => (
        <Tooltip
          trigger="Set Capacity"
          hoverText="This is the set number of teams we have for sale, including free agent teams."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {row.getValue("setCapacity")}
          </p>
        );
      },
    },
    {
      accessorKey: "indyTeams",
      header: () => (
        <Tooltip
          trigger="Set FA Teams"
          hoverText="This is the total number of free agent teams we have available for sale. The gender capacity for each free agent team is set at the sport format."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">{row.getValue("indyTeams")}</p>
        );
      },
    },
    {
      accessorKey: "teamCount",
      header: () => (
        <Tooltip
          trigger="Team Regs"
          hoverText="This is the total number of team registrations for this session."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">{row.getValue("teamCount")}</p>
        );
      },
    },
    {
      accessorKey: "freeAgentCount",
      header: () => (
        <Tooltip
          trigger="Free Agents Regs"
          hoverText="This is the total number of free agent registrations. (T: Total M: Men + Trans Men, W: Women + Trans Women, X: All other genders)"
        />
      ),

      cell: ({ row }) => {
        return (
          <div className="grid grid-cols-4 font-medium text-left">
            <p>T{row.original.freeAgentCount?.totalCount}</p>
            <p>M{row.original.freeAgentCount?.men}</p>
            <p>W{row.original.freeAgentCount?.women}</p>
            <p>X{row.original.freeAgentCount?.other}</p>
          </div>
        );
      },
    },
    {
      id: "faAction",
      header: () => (
        <Tooltip
          trigger="FA"
          hoverText="Free Agent info for this session"
        />
      ),
      cell: ({ row }) => {
        const faIssuesForSession = CountFaIssues(row.original);
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none text-primary-40 data-[state=open]:text-primary-90">
                {freeAgentWayfinderLoading ? (
                  <Loader2 className="animate-spin text-info-40" />
                ) : (
                  <>
                    {faIssuesForSession > 0 ? (
                      <TooltipProvider delayDuration={100}>
                        <TooltipBody>
                          <TooltipTrigger className="flex flex-row items-center max-w-full gap-0 truncate">
                            {freeAgentWayfinderLoading ? (
                              <Loader2 className="animate-spin" />
                            ) : (
                              <div className="relative">
                                <PersonOutlineOutlined
                                  className={cn(
                                    "transition-colors",
                                    faIssuesForSession === 0
                                      ? "text-inherit hover:text-primary-90"
                                      : "text-error-40 hover:text-error-90"
                                  )}
                                />
                                {faIssuesForSession > 1 && (
                                  <div className="absolute top-0 right-0 flex items-center justify-center w-3 h-3 text-center border border-white rounded-full bg-tertiary-30">
                                    <p className="p-0 m-0 text-[8px] text-center text-white">
                                      {faIssuesForSession}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="flex flex-col gap-1">
                              <Body1>
                                {row.original.faTeams === false &&
                                  "No FA teams for this session"}
                              </Body1>
                              <Body1>
                                {row.original.faRegistrations === false &&
                                  "No FA registrations for this session"}
                              </Body1>
                              <Body1>
                                {row.original.faOrphans === true &&
                                  "There are FA orphans for this session"}
                              </Body1>
                            </div>
                          </TooltipContent>
                        </TooltipBody>
                      </TooltipProvider>
                    ) : (
                      <PersonOutlineOutlined
                        className={cn(
                          "transition-colors text-inherit hover:text-primary-90"
                        )}
                      />
                    )}
                  </>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a
                  href={`/ops/free-agent-teams?regBatchId=${row.original.registrationBatch.id}&sessionId=${row.original.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    FA teams
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
    {
      id: "divisionAction",
      header: () => (
        <Tooltip
          trigger="DIV"
          hoverText="Division info for this session"
        />
      ),
      cell: ({ row }) => {
        const divisionIssuesForSession = CountDivisionIssues(row.original);
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none text-primary-40 data-[state=open]:text-primary-90">
                {divisionWayfinderLoading ? (
                  <Loader2 className="animate-spin text-info-40" />
                ) : (
                  <>
                    {divisionIssuesForSession > 0 ? (
                      <TooltipProvider delayDuration={100}>
                        <TooltipBody>
                          <TooltipTrigger className="flex flex-row items-center max-w-full gap-0 truncate">
                            {divisionWayfinderLoading ? (
                              <Loader2 className="animate-spin" />
                            ) : (
                              <div className="relative">
                                <AccountTreeOutlinedIcon
                                  className={cn(
                                    "transition-colors",
                                    divisionIssuesForSession === 0
                                      ? "text-inherit hover:text-primary-90"
                                      : "text-error-40 hover:text-error-90"
                                  )}
                                />
                                {divisionIssuesForSession > 1 && (
                                  <div className="absolute top-0 right-0 flex items-center justify-center w-3 h-3 text-center border border-white rounded-full bg-tertiary-30">
                                    <p className="p-0 m-0 text-[8px] text-center text-white">
                                      {divisionIssuesForSession}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="flex flex-col gap-1">
                              <Body1>
                                {row.original.orphanTeams === true &&
                                  "There are orphan teams for this session"}
                              </Body1>
                              <Body1>
                                {row.original.divisionsCreated === false &&
                                  "There are no divisions created for this session"}
                              </Body1>
                            </div>
                          </TooltipContent>
                        </TooltipBody>
                      </TooltipProvider>
                    ) : (
                      <AccountTreeOutlinedIcon
                        className={cn(
                          "transition-colors text-inherit hover:text-primary-90"
                        )}
                      />
                    )}
                  </>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {row.original.divisions.length > 0 ? (
                  row.original.divisions.map((division) => {
                    return (
                      <DropdownMenuItem
                        key={division.id}
                        className="hover:bg-primary-95"
                      >
                        <a href={`/ops/division/${division.id}`}>
                          {division.name}
                        </a>
                      </DropdownMenuItem>
                    );
                  })
                ) : (
                  <a
                    href={`/ops/division/?regBatchId=${row.original.registrationBatch.id}&sessionId=${row.original.id}`}
                  >
                    <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                      Create divisions
                    </DropdownMenuItem>
                  </a>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
    {
      id: "capacityGroupAction",
      header: () => (
        <Tooltip
          trigger="CG"
          hoverText="Capacity group info for this session"
        />
      ),
      cell: ({ row }) => {
        const capacityGroupIssuesForSession = CountCapacityGroupIssues(
          row.original
        );
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger
                className="outline-none text-primary-40 data-[state=open]:text-primary-90"
                disabled={row.original.capacityGroup == undefined}
              >
                {capacityGroupWayfinderLoading ? (
                  <Loader2 className="animate-spin text-info-40" />
                ) : (
                  <>
                    {row.original.capacityGroup == undefined ? (
                      <TooltipProvider delayDuration={100}>
                        <TooltipBody>
                          <TooltipTrigger className="flex flex-row items-center max-w-full gap-0 truncate">
                            <RecentActorsOutlinedIcon className="text-disabled hover:text-neutral-400" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="flex flex-col gap-1">
                              <Body1>
                                There is no capacity group linked to this
                                session
                              </Body1>
                            </div>
                          </TooltipContent>
                        </TooltipBody>
                      </TooltipProvider>
                    ) : capacityGroupIssuesForSession > 0 ? (
                      <TooltipProvider delayDuration={100}>
                        <TooltipBody>
                          <TooltipTrigger className="flex flex-row items-center max-w-full gap-0 truncate">
                            {capacityGroupWayfinderLoading ? (
                              <Loader2 className="animate-spin" />
                            ) : (
                              <div className="relative">
                                <RecentActorsOutlinedIcon
                                  className={cn(
                                    "transition-colors",
                                    capacityGroupIssuesForSession === 0
                                      ? "text-inherit hover:text-primary-90"
                                      : "text-error-40 hover:text-error-90"
                                  )}
                                />
                                {capacityGroupIssuesForSession > 1 && (
                                  <div className="absolute top-0 right-0 flex items-center justify-center w-3 h-3 text-center border border-white rounded-full bg-tertiary-30">
                                    <p className="p-0 m-0 text-[8px] text-center text-white">
                                      {capacityGroupIssuesForSession}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="flex flex-col gap-1">
                              <Body1>
                                {row.original.gameslotReserved === false &&
                                  "There are no gameslots RESERVED for this capacity group"}
                              </Body1>
                              <Body1>
                                {row.original.createdGameslots === false &&
                                  "There are no gameslots CREATED for this capacity group"}
                              </Body1>
                              <Body1>
                                {row.original.gameslotMatchReserve === false &&
                                  "The number of reserved gameslots doesn't matched booked slots"}
                              </Body1>
                            </div>
                          </TooltipContent>
                        </TooltipBody>
                      </TooltipProvider>
                    ) : (
                      <RecentActorsOutlinedIcon
                        className={cn(
                          "transition-colors text-inherit hover:text-primary-90"
                        )}
                      />
                    )}
                  </>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a
                  href={
                    row.original.capacityGroup
                      ? `/ops/capacity-group/${row.original.capacityGroup.id}`
                      : ""
                  }
                >
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-primary-95"
                    disabled={row.original.capacityGroup == null}
                  >
                    Capacity Group
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
    {
      id: "scheduleAction",
      header: () => (
        <Tooltip
          trigger="SCH"
          hoverText="Schedule group info for this session"
        />
      ),
      cell: ({ row }) => {
        const scheduleIssuesForSession = CountScheduleIssues(row.original);
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger
                className="outline-none text-primary-40 data-[state=open]:text-primary-90"
                disabled={row.original.divisions.length === 0}
              >
                {scheduleWayfinderLoading ? (
                  <Loader2 className="animate-spin text-info-40" />
                ) : (
                  <>
                    {row.original.divisions.length === 0 ? (
                      <TooltipProvider delayDuration={100}>
                        <TooltipBody>
                          <TooltipTrigger className="flex flex-row items-center max-w-full gap-0 truncate">
                            <Calendar className="text-disabled hover:text-neutral-400" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="flex flex-col gap-1">
                              <Body1>
                                There are no schedules for this session
                              </Body1>
                            </div>
                          </TooltipContent>
                        </TooltipBody>
                      </TooltipProvider>
                    ) : scheduleIssuesForSession > 0 ? (
                      <TooltipProvider delayDuration={100}>
                        <TooltipBody>
                          <TooltipTrigger className="flex flex-row items-center max-w-full gap-0 truncate">
                            {scheduleWayfinderLoading ? (
                              <Loader2 className="animate-spin" />
                            ) : (
                              <div className="relative">
                                <CalendarCheck
                                  className={cn(
                                    "transition-colors",
                                    scheduleIssuesForSession === 0
                                      ? "text-inherit hover:text-primary-90"
                                      : "text-error-40 hover:text-error-90"
                                  )}
                                />

                                {scheduleIssuesForSession > 1 && (
                                  <div className="absolute top-0 right-0 flex items-center justify-center w-3 h-3 text-center border border-white rounded-full bg-tertiary-30">
                                    <p className="p-0 m-0 text-[8px] text-center text-white">
                                      {scheduleIssuesForSession}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </TooltipTrigger>
                          <TooltipContent>
                            {row.original.divisions.map((division) => {
                              const issuesForDiv =
                                row.original.divisionsScheduleIssue?.find(
                                  (issue) => issue.id == division.id
                                );

                              return (
                                <>
                                  {issuesForDiv ? (
                                    <div className="flex flex-col items-start">
                                      <Body1>Division: {division.name}</Body1>
                                      <div className="flex flex-col items-start gap-1">
                                        <Caption1>
                                          {issuesForDiv.scheduleCreated ===
                                            false && "No schedule created"}
                                        </Caption1>
                                        <Caption1>
                                          {issuesForDiv.schedulePublished ===
                                            false &&
                                            "The schedule for this division is not published"}
                                        </Caption1>
                                        <Caption1>
                                          {issuesForDiv.gameErrors === true &&
                                            "There are game errors in this schedule"}
                                        </Caption1>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                          </TooltipContent>
                        </TooltipBody>
                      </TooltipProvider>
                    ) : (
                      <CalendarCheck
                        className={cn(
                          "transition-colors text-inherit hover:text-primary-90"
                        )}
                      />
                    )}
                  </>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {row.original.divisions.map((division) => {
                  return (
                    <DropdownMenuItem
                      key={division.id}
                      className="hover:bg-primary-95"
                    >
                      <a href={`/ops/division/${division.id}/schedule`}>
                        {division.name}
                      </a>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a
                  href={`/ops/division/?regBatchId=${row.original.registrationBatch.id}&sessionId=${row.original.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Create divisions
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/session/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Edit Session
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/session-hq/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Session HQ
                  </DropdownMenuItem>
                </a>

                <a
                  href={`/players/waitlist?leagueId=${
                    row.original.league.id
                  }&startDate=${dayjs(row.original.startDate).format(
                    "YYYY-MM-DD"
                  )}&regBatchId=${row.original.registrationBatch.id}&day=${
                    row.original.dayOfWeek
                  }`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    View Waitlist
                  </DropdownMenuItem>
                </a>
                <a
                  href={`${rootUrl}/program-discovery/league-details/${row.original.league.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Preview League
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return (
    <main className="flex flex-col justify-between w-full gap-8 pb-4">
      <LoadingDialog open={loadingSessions} />
      <Headline1Variable>League Wayfinder</Headline1Variable>
      <WayfinderFilter
        filter={sessionFilters}
        setFilter={setSessionFilters}
        setPagination={setPagination}
        registrationBatches={registrationBatches}
        selectedRegions={selectedRegions}
        sports={sports.map((sport) => ({ id: +sport.id, name: sport.name }))}
        leagues={dataLeagues?.leagueDynamic}
      />
      {sessionData.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            data={sessionData}
            columns={sessionColumns}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
    </main>
  );
};

export default Wayfinder;
