import React, { useEffect, useMemo, useState } from "react";
import {
  DayOfWeek,
  getRegistrationBatch,
  getSports,
} from "../../../app/venueMasterSlice";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../../UI/Table/DataTable";
import { Calendar, CalendarCheck, Loader2 } from "lucide-react";
import dayjs from "dayjs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../UI/shadcn/dropdown";
import {
  Tooltip as TooltipBody,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../UI/shadcn/tooltip";
import { MoreVerticalIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import Tooltip from "../../UI/Tooltip/Tooltip";
import {
  DivisionByLastRegGameStartPaginatedQuery,
  useDivisionByLastRegGameStartPaginatedQuery,
  useLeagueDynamicQuery,
  usePlayoffHqLazyQuery,
} from "../../../generated/graphql";
import { rootUrl } from "../../../utils/environmentDependantVars";
import PaginationDataTable from "../../UI/Pagination/PaginationDataTable";
import Cookies from "js-cookie";
import { PaginationType } from "@/src/types/types";
import { PersonOutlineOutlined } from "@mui/icons-material";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import Body1 from "../../UI/Text/Body/Body1";
import { cn } from "../../../lib/utils";
import Caption1 from "../../UI/Text/Caption/Caption1";
import WayfinderFilter, {
  WayfinderFilter as WayfinderFilterType,
} from "../../UI/Filter/WayfinderFilter";

const initialFilters = {
  regionIds: [0],
  registrationBatchId: null,
  sportId: null,
  dayOfWeek: null,
  leagueIds: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: null,
};

const initialPagination = {
  page: 0,
  pageSize: 25,
  pageCount: undefined,
};

type ExtendedDivision =
  DivisionByLastRegGameStartPaginatedQuery["divisionByLastRegGameStartPaginated"]["divisions"][0] & {
    lastRegularSeasonGame: string | undefined;
    allPlayoffGamesVisible: boolean | undefined;
    unsubmittedScoreCount: number | undefined;
    standingsFinalized: boolean | undefined;
    autoZeroTieCount: number | undefined;
  };

const PlayoffHq: React.FC = () => {
  const { selectedRegions, registrationBatches, sports } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const dispatch: any = useDispatch<AppDispatch>();

  const [divisionFilters, setDivisionFilters] = useState<WayfinderFilterType>(
    () => {
      const divisionFilter = Cookies.get("playoffHqFilters");
      if (divisionFilter) {
        const filter = JSON.parse(divisionFilter);
        return filter;
      } else {
        return initialFilters;
      }
    }
  );
  const [pagination, setPagination] = useState<PaginationType>(() => {
    const wayfinderPagination = Cookies.get("playoffHqPagination");
    if (wayfinderPagination) {
      return JSON.parse(wayfinderPagination);
    } else {
      return initialPagination;
    }
  });

  const [divisionData, setDivisionData] = useState<ExtendedDivision[]>([]);

  const { data: dataLeagues, loading: loadingLeagues } = useLeagueDynamicQuery({
    variables: {
      leagueDynamicFilter: {
        regBatchIds: divisionFilters.registrationBatchId
          ? [divisionFilters.registrationBatchId]
          : [],
        regionIds:
          divisionFilters.regionIds[0] === 0
            ? selectedRegions.map((region) => region.id)
            : divisionFilters.regionIds,
        sportIds: divisionFilters.sportId ? [divisionFilters.sportId] : [],
        dayIds: divisionFilters.dayOfWeek ? [divisionFilters.dayOfWeek] : [],
        sportFormatIds: [],
      },
    },
  });

  const {
    data: dataDivisions,
    loading: loadingDivisions,
    observable,
  } = useDivisionByLastRegGameStartPaginatedQuery({
    variables: {
      filter: {
        ...divisionFilters,
        regionIds:
          divisionFilters.regionIds[0] === 0
            ? selectedRegions.map((region) => region.id)
            : divisionFilters.regionIds,
        dayOfWeek:
          divisionFilters.dayOfWeek == -1 ? null : divisionFilters.dayOfWeek,
      },
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    onCompleted: (data) => {
      if (data.divisionByLastRegGameStartPaginated) {
        playoffHq({
          variables: {
            divisionIds: data.divisionByLastRegGameStartPaginated.divisions.map(
              (division) => division.id
            ),
          },
          onCompleted: (data) => {
            const divisionToPlayoffData: Map<
              number,
              {
                lastRegularSeasonGame: string;
                allPlayoffGamesVisible: boolean;
                unsubmittedScoreCount: number;
                standingsFinalized: boolean;
                autoZeroTieCount: number;
              }
            > = new Map();
            data.playoffHq.results.forEach((res) => {
              divisionToPlayoffData.set(res.divisionId, {
                lastRegularSeasonGame: res.lastRegularSeasonGame,
                allPlayoffGamesVisible: res.allPlayoffGamesVisible,
                unsubmittedScoreCount: res.unsubmittedScoreCount,
                standingsFinalized: res.standingsFinalized,
                autoZeroTieCount: res.autoZeroTieCount,
              });
            });
            setDivisionData((prev) =>
              prev.map((division) => {
                const divisionData = divisionToPlayoffData.get(division.id);
                return {
                  ...division,
                  lastRegularSeasonGame: divisionData?.lastRegularSeasonGame,
                  allPlayoffGamesVisible: divisionData?.allPlayoffGamesVisible,
                  unsubmittedScoreCount: divisionData?.unsubmittedScoreCount,
                  standingsFinalized: divisionData?.standingsFinalized,
                  autoZeroTieCount: divisionData?.autoZeroTieCount,
                };
              })
            );
          },
        });
        const divisions =
          data.divisionByLastRegGameStartPaginated.divisions.map(
            (division) => ({
              ...division,
              lastRegularSeasonGame: undefined,
              allPlayoffGamesVisible: undefined,
              unsubmittedScoreCount: undefined,
              standingsFinalized: undefined,
              autoZeroTieCount: undefined,
            })
          );
        setDivisionData(divisions);
        setPagination((prevState: PaginationType) => {
          return {
            ...prevState,
            pageCount: data.divisionByLastRegGameStartPaginated.count,
          };
        });
      }
    },
  });

  const [playoffHq, { data: playoffHqData, loading: playoffHqLoading }] =
    usePlayoffHqLazyQuery({});

  useEffect(() => {
    dispatch(getSports(""));
    dispatch(getRegistrationBatch(""));
  }, []);

  useEffect(() => {
    if (divisionFilters === initialFilters) {
      Cookies.remove("playoffHqFilters");
    } else {
      Cookies.set("playoffHqFilters", JSON.stringify(divisionFilters));
    }
  }, [divisionFilters]);
  useEffect(() => {
    if (pagination === initialPagination) {
      Cookies.remove("leagueWayfinderPagination");
    } else {
      Cookies.set("leagueWayfinderPagination", JSON.stringify(pagination));
    }
  }, [pagination]);

  useEffect(() => {
    if (selectedRegions.length === 0) {
      return;
    }
    const regionIdsOnSelectionChange = divisionFilters.regionIds.filter(
      (divisionFilterRegion) =>
        selectedRegions
          .map((region) => region.id)
          .includes(divisionFilterRegion)
    );
    setDivisionFilters((prevState) => ({
      ...prevState,
      regionIds:
        regionIdsOnSelectionChange.length > 0
          ? regionIdsOnSelectionChange
          : [0],
    }));
  }, [selectedRegions]);

  const divisionColumns: ColumnDef<ExtendedDivision>[] = [
    {
      accessorKey: "id",
      header: "Division Id",
      cell: ({ row }) => {
        return <p className="font-medium text-center">{row.original.id}</p>;
      },
    },

    {
      accessorKey: "lastRegularSeasonGame",
      header: "Date of Last Regular Season Game",
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {row.original.lastRegularSeasonGame}
          </p>
        );
      },
    },
    {
      accessorKey: "unsubmittedScoreCount",
      header: "Unsubmitted Score Regular Season Count",
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {row.original.unsubmittedScoreCount}
          </p>
        );
      },
    },
    {
      accessorKey: "standingsFinalized",
      header: "Are standings finalized",
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {row.original.standingsFinalized === true ? "Yes" : "No"}
          </p>
        );
      },
    },
    {
      accessorKey: "autoZeroTieCount",
      header: "0-0 Regular season ties",
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {row.original.autoZeroTieCount}
          </p>
        );
      },
    },
    {
      accessorKey: "allPlayoffGamesVisible",
      header: "All playoff games visible",
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {row.original.allPlayoffGamesVisible === true ? "Yes" : "No"}
          </p>
        );
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a href={`/ops/division/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Division
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/division/${row.original.id}/schedule`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Schedule
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return (
    <main className="flex flex-col justify-between w-full gap-8 pb-4">
      <LoadingDialog open={loadingDivisions} />
      <Headline1Variable>League Wayfinder</Headline1Variable>
      <WayfinderFilter
        filter={divisionFilters}
        setFilter={setDivisionFilters}
        setPagination={setPagination}
        registrationBatches={registrationBatches}
        selectedRegions={selectedRegions}
        sports={sports.map((sport) => ({ id: +sport.id, name: sport.name }))}
        leagues={dataLeagues?.leagueDynamic}
      />
      {divisionData.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            data={divisionData}
            columns={divisionColumns}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
    </main>
  );
};

export default PlayoffHq;
