import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Button from "../../UI/Button/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../UI/shadcn/dialog";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../UI/shadcn/hoverCard";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Body1 from "../../UI/Text/Body/Body1";
import TextArea from "../../UI/FormField/TextArea/TextArea";
import {
  GetContractApprovalUsersQuery,
  useContractCreateMutation,
  useCreateContractNoteMutation,
  useGetContractApprovalUsersQuery,
  useGetContractStatusesQuery,
  useVenuesByVendorIdQuery,
  useVenuesHierarchyByVendorIdQuery,
  useVenuesTreeQuery,
  VenuesByVendorIdQuery,
  VenuesHierarchyByVendorIdQuery,
} from "../../../generated/graphql";
import MultipleDatePicker, { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { dateWithoutTimezone } from "../../../utils/timeFunctions";
import { z, ZodFormattedError } from "zod";
import dayjs from "dayjs";
import Card from "../../UI/Card/Card";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import FormField from "../../UI/FormField/FormField";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { cn } from "../../../lib/utils";
import Caption1 from "../../UI/Text/Caption/Caption1";
import { TimePickerDemo } from "../../UI/shadcn/Time/time-picker-demo";
import { Checkbox } from "../../UI/shadcn/checkbox";
import { Label } from "../../UI/shadcn/label";
import { CircleHelp, Trash2 } from "lucide-react";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import { dayOfWeek } from "../../../utils/dayOfWeek";
import { DatePicker } from "../../UI/shadcn/Time/date-picker";
import { Divider } from "@mui/material";
import {
  getAllVenuesByVendorId,
  getVendors,
} from "../../../app/venueMasterSlice";
import Headline2 from "../../UI/Text/Headline/Headline2";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import Alert from "../../UI/Alerts/Alert";
import { message } from "@/src/app/shiftSlice";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useNavigate } from "react-router-dom";

// Define the individual schemas
const ContractItemSchema = z
  .object({
    contractId: z.number(),
    venueId: z.number().refine((value) => value !== 0, {
      message: "A venue must be selected for all contractItems",
    }),
    startDate: z.date(),
    endDate: z.date(),
    startTime: z.date(),
    endTime: z.date(),
    goesPastMidnight: z.boolean(),
    day: z.number(),
    exclusionDates: z.array(z.string()),
  })
  .refine(
    (data) => {
      return data.startDate <= data.endDate;
    },
    { path: ["startDate"], message: `Start date must be before end date.` }
  )
  .refine(
    (data) => {
      return dayjs(data.startDate).day() === dayjs(data.endDate).day();
    },
    {
      path: ["day"],
      message: `Start and end date must be on the same day`,
    }
  )
  .refine(
    (data) => {
      return (
        data.startTime < data.endTime ||
        (data.startTime > data.endTime && data.goesPastMidnight)
      );
    },
    { path: ["startTime"], message: `Start time must be before end date.` }
  )
  .refine(
    (data) => {
      if (data.exclusionDates.length > 0) {
        const day = dayjs(data.exclusionDates[0]).day();
        return data.exclusionDates.every((date) => dayjs(date).day() === day);
      } else {
        return true;
      }
    },
    {
      path: ["exclusionDates"],
      message: "Exlclusion dates must be on the same day",
    }
  );

const ContractNoteSchema = z.object({
  id: z.number(),
  note: z.string(),
  createdAt: z.date(),
  createdByUser: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
});

// Define the full contract schema
const ContractSchema = z
  .object({
    name: z.string().min(1, { message: "Name must cannot be empty." }),
    approvalStatusDate: z.string().optional(),
    grossCost: z.number(),
    taxes: z.number(),
    regionId: z.number(),
    vendorId: z.number(),
    approverId: z
      .string()
      .refine((val) => val != "", { message: "Approver must be selected" }),
    permitStatusId: z.number(),
    typeId: z.number(),
    approvalStatusId: z.number(),
    contractItems: z.array(ContractItemSchema),
    contractNotes: z.array(ContractNoteSchema),
  })
  .superRefine((data, ctx) => {
    Object.entries(data).forEach(([key, value]) => {
      if (
        typeof value === "number" &&
        value === 0 &&
        key !== "grossCost" &&
        key !== "taxes" &&
        key !== "approvalStatusId"
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Please select an option.`,
          path: [key],
        });
      }
    });
  });

type ContractType = z.infer<typeof ContractSchema>;
type ContractItemType = z.infer<typeof ContractItemSchema>;

const initialContract: ContractType = {
  name: "",
  approvalStatusDate: undefined,
  grossCost: 0,
  taxes: 0,
  regionId: 0,
  vendorId: 0,
  approverId: "",
  permitStatusId: 0,
  typeId: 0,
  approvalStatusId: 0,
  contractItems: [],
  contractNotes: [],
};

const initialContractItem: ContractItemType = {
  contractId: 0,
  venueId: 0,
  startDate: dayjs().set("hour", 0).set("minute", 0).set("second", 0).toDate(),
  endDate: dayjs().set("hour", 0).set("minute", 0).set("second", 0).toDate(),
  startTime: dayjs().set("hour", 18).set("minute", 0).set("second", 0).toDate(),
  endTime: dayjs().set("hour", 18).set("minute", 0).set("second", 0).toDate(),
  goesPastMidnight: false,
  day: dayjs().get("day"),
  exclusionDates: [],
};

function checkOverlappingDates(
  allItems: ContractItemType[],
  venues: VenuesHierarchyByVendorIdQuery["venuesHierarchyByVendorId"]
): { index: number; item1: number; item2: number }[] {
  const issues: { index: number; item1: number; item2: number }[] = [];
  // For each item check to see if any other items have overlapping times on the same day that has a relational venue match
  allItems.forEach((item, i) => {
    allItems.forEach((otherItem, j) => {
      if (i !== j && item.day === otherItem.day) {
        const itemVenue = venues.find((venue) => venue.id === item.venueId);
        const otherItemVenue = venues.find(
          (venue) => venue.id === otherItem.venueId
        );
        const itemStart = dayjs(item.startTime);
        const itemEnd = item.goesPastMidnight
          ? dayjs(item.endTime).add(1, "day")
          : dayjs(item.endTime);
        const otherItemStart = dayjs(otherItem.startTime);
        const otherItemEnd = otherItem.goesPastMidnight
          ? dayjs(otherItem.endTime).add(1, "day")
          : dayjs(otherItem.endTime);
        // Check if there's another related venue with overlapping time
        if (
          itemVenue &&
          otherItemVenue &&
          (itemVenue.id === otherItemVenue.id ||
            itemVenue.relationalVenues.findIndex(
              (relationalVenue) => relationalVenue.id === otherItemVenue.id
            ) !== -1) &&
          itemStart.isBefore(otherItemEnd) &&
          itemEnd.isAfter(otherItemStart)
        ) {
          // Check if the switch pair exists before adding it again
          if (
            issues.findIndex(
              (issue) => issue.item2 === i && issue.item1 === j
            ) === -1
          ) {
            issues.push({
              index: i,
              item1: i,
              item2: j,
            });
          }
        }
      }
    });
  });
  return issues;
}

type StatusType = {
  id: number;
  name: string;
};

const CreateContract: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { vendorList, selectedRegions } = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** STATES ***/
  const [contract, setContract] = useState<ContractType>(initialContract);
  const [contractNote, setContractNote] = useState<string>("");
  const [openConfimrModal, setOpenConfirmModal] = useState<boolean>(false);
  const [approvalUsers, setApprovalUsers] = useState<
    { id: string; name: string }[]
  >([]);
  const [permitStatuses, setPermitStatuses] = useState<StatusType[]>([]);
  const [contractTypes, setContractTypes] = useState<StatusType[]>([]);
  const [contractItemErrors, setContractItemErrors] = useState<
    { index: number; message: string }[]
  >([]);
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<ContractType, string>
  >({ _errors: [] });

  /*** QUERIES ***/
  const { loading: loadingContractApprovalUsers } =
    useGetContractApprovalUsersQuery({
      variables: {
        regionIds: selectedRegions.map((region) => +region.id),
      },
      onCompleted: (data) => {
        if (data) {
          const users = data.getContractApprovalUsers.map((user) => {
            return {
              id: user.id,
              name: user.firstName + " " + user.lastName,
            };
          });
          setApprovalUsers(users);
        }
      },
    });

  const { loading: loadingContractStatuses } = useGetContractStatusesQuery({
    onCompleted: (data) => {
      if (data) {
        setPermitStatuses(data.getContractStatuses.permitStatus);
        setContractTypes(data.getContractStatuses.contractType);
      }
    },
  });
  const { data: dataVenues, loading: loadingVenues } =
    useVenuesHierarchyByVendorIdQuery({
      variables: {
        vendorId: contract.vendorId,
      },
      skip: contract.vendorId === 0,
      onCompleted: (data) => {},
    });

  /*** MUTATIONS ***/
  const [createContract, { loading: loadingCreateContract }] =
    useContractCreateMutation();

  const [createContractNote, { loading: loadingCreateContractNote }] =
    useCreateContractNoteMutation();

  /*** UTILITY FUNCTIONS ***/
  async function onSubmit() {
    if (!dataVenues) {
      dispatch(displayAlertError("Could not find any venues"));
      return;
    }

    const res = checkOverlappingDates(
      contract.contractItems,
      dataVenues.venuesHierarchyByVendorId
    );
    const validationResult = ContractSchema.safeParse(contract);

    if (!validationResult.success) {
      dispatch(displayAlertError("Contract form errors foud"));
      return;
    }
    if (res.length > 0) {
      dispatch(displayAlertWarning("Overlapping dates found"));
      return;
    }

    await createContract({
      variables: {
        createContractInput: {
          contract: {
            id: undefined,
            regionId: +contract.regionId,
            vendorId: +contract.vendorId,
            typeId: +contract.typeId,
            name: contract.name,
            permitStatusId: +contract.permitStatusId,
            approverId: contract.approverId,
            grossCost: contract.grossCost,
            taxes: contract.taxes,
          },
          contractItems: contract.contractItems.map((contractItem) => {
            return {
              venueId: +contractItem.venueId,
              exclusionDates: contractItem.exclusionDates,
              day: contractItem.day,
              startDate: dateWithoutTimezone(contractItem.startDate),
              endDate: dateWithoutTimezone(contractItem.endDate),
              startTime: dayjs(contractItem.startTime).format("HH:mm:ss"),
              endTime: dayjs(contractItem.endTime).format("HH:mm:ss"),
              goesPastMidnight: contractItem.goesPastMidnight,
              contractId: contractItem.contractId,
            };
          }),
        },
      },
      onCompleted: async (data) => {
        const success = data.contractCreate.success;
        if (success) {
          dispatch(displayAlertSuccess(data.contractCreate.message));
        } else {
          dispatch(displayAlertError(data.contractCreate.message));
        }
        const newContractId = data.contractCreate.contract?.id;
        if (newContractId) {
          // Once the contract is succesfully created create a note for it.
          await createContractNote({
            variables: {
              createContractNoteInput: {
                note: success
                  ? contractNote
                  : `Attempted create with note: ${contractNote}`,
                contractId: newContractId,
              },
            },
            onCompleted: (data) => {
              if (data.createContractNote.success) {
                dispatch(displayAlertSuccess(data.createContractNote.message));
                navigate(-1);
              } else {
                dispatch(displayAlertError(data.createContractNote.message));
              }
            },
          });
        }
      },
    });
  }

  /*** USE EFFECTS ***/
  useEffect(() => {
    dispatch(getVendors({}));
  }, [dispatch]);

  const pathsBreadcrumbs = [{ name: "Contracts", url: "/ops/contracts" }];

  return (
    <main className="max-h-screen pb-10 overflow-auto">
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/contracts"
      ></BreadCrumbs>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between w-full max-h-screen mt-6">
          <Headline1Variable>Create New Contract</Headline1Variable>
          <div className="h-10">
            <Button
              variant={"primary"}
              onClick={() => {
                const validationResult = ContractSchema.safeParse(contract);
                if (!dataVenues) {
                  dispatch(displayAlertWarning("You must select a vendor"));
                  return;
                }
                const res = checkOverlappingDates(
                  contract.contractItems,
                  dataVenues ? dataVenues.venuesHierarchyByVendorId : []
                );
                let issueWithForm = false;
                if (!validationResult.success) {
                  setZodErrors(validationResult.error.format());
                  dispatch(
                    displayAlertWarning("There is an issue with the form")
                  );
                  console.log(validationResult.error.format());
                  issueWithForm = true;
                }
                if (res.length > 0) {
                  setContractItemErrors(
                    res.map((issue) => {
                      return {
                        index: issue.index,
                        message: `Contract item ${
                          issue.item1 + 1
                        } overlaps with contract item ${issue.item2 + 1}`,
                      };
                    })
                  );
                  issueWithForm = true;
                }
                if (issueWithForm) {
                  return;
                } else {
                  setOpenConfirmModal(true);
                }
              }}
            >
              Create Contract
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          {/* Contract Detail fields */}
          <Card>
            <Headline2Variable>Contract Details</Headline2Variable>
            <div className="flex flex-col">
              <div className="grid grid-cols-3 gap-6 mt-4 w-max-xl">
                <FormField
                  type="name"
                  name="name"
                  id="name"
                  value={contract.name}
                  label="Name"
                  inputChange={(value) => {
                    if (zodErrors.name && value) {
                      setZodErrors((prevState) => ({
                        ...prevState,
                        name: undefined,
                      }));
                    }
                    setContract((prevState) => ({
                      ...prevState,
                      name: value.target.value,
                    }));
                  }}
                  error={zodErrors.name !== undefined}
                  assistiveText={
                    zodErrors.name !== undefined
                      ? zodErrors.name._errors[0]
                      : ""
                  }
                  initialValue={contract.name}
                />
                <FormFieldSelect
                  name="regions"
                  id="regions"
                  value={contract.regionId ? contract.regionId.toString() : "0"}
                  inputChange={(value) => {
                    if (zodErrors.regionId && value) {
                      setZodErrors((prevState) => ({
                        ...prevState,
                        regionId: undefined,
                      }));
                    }
                    setContract((prevState) => ({
                      ...prevState,
                      regionId: +value,
                    }));
                  }}
                  error={zodErrors.regionId !== undefined}
                  assistiveText={
                    zodErrors.regionId !== undefined
                      ? zodErrors.regionId._errors[0]
                      : ""
                  }
                  label="Region"
                  placeholder="Select Region"
                  className="m-0"
                >
                  {[
                    ...[{ id: "0", name: "Select a Region" }],
                    ...selectedRegions,
                  ]}
                </FormFieldSelect>
                <FormFieldSelect
                  name="vendors"
                  id="vendors"
                  value={contract.vendorId ? contract.vendorId.toString() : "0"}
                  inputChange={(value) => {
                    if (zodErrors.vendorId && value) {
                      setZodErrors((prevState) => ({
                        ...prevState,
                        vendorId: undefined,
                      }));
                    }
                    setContract((prevState) => ({
                      ...prevState,
                      vendorId: +value,
                    }));
                  }}
                  error={zodErrors.vendorId !== undefined}
                  assistiveText={
                    zodErrors.vendorId !== undefined
                      ? zodErrors.vendorId._errors[0]
                      : ""
                  }
                  label="Vendor"
                  placeholder="Select vendor"
                  className="m-0"
                >
                  {[...[{ id: "0", name: "Select a Vendor" }], ...vendorList]}
                </FormFieldSelect>
              </div>
              <div className="grid grid-cols-3 gap-6 mt-4 w-max-xl">
                <FormFieldSelect
                  name="typeId"
                  id="typeId"
                  value={contract.typeId ? contract.typeId.toString() : "0"}
                  placeholder="All"
                  label="Type"
                  className="m-0"
                  inputChange={(value) => {
                    if (zodErrors.typeId && value) {
                      setZodErrors((prevState) => ({
                        ...prevState,
                        typeId: undefined,
                      }));
                    }
                    setContract((prevState) => ({
                      ...prevState,
                      typeId: +value,
                    }));
                  }}
                  error={zodErrors.typeId !== undefined}
                  assistiveText={
                    zodErrors.typeId !== undefined
                      ? zodErrors.typeId._errors[0]
                      : ""
                  }
                >
                  {[
                    { id: "0", name: "Select Contract Type" },
                    ...contractTypes,
                  ]}
                </FormFieldSelect>
                <FormFieldSelect
                  value={
                    contract.permitStatusId
                      ? contract.permitStatusId.toString()
                      : "0"
                  }
                  className="m-0"
                  name="permitStatusId"
                  id="permitStatusId"
                  placeholder="All"
                  inputChange={(value) => {
                    if (zodErrors.permitStatusId && value) {
                      setZodErrors((prevState) => ({
                        ...prevState,
                        permitStatusId: undefined,
                      }));
                    }
                    setContract((prevState) => ({
                      ...prevState,
                      permitStatusId: +value,
                    }));
                  }}
                  error={zodErrors.permitStatusId !== undefined}
                  assistiveText={
                    zodErrors.permitStatusId !== undefined
                      ? zodErrors.permitStatusId._errors[0]
                      : ""
                  }
                  label="Permit Status"
                >
                  {[
                    { id: "0", name: "Select Permit Status" },
                    ...permitStatuses,
                  ]}
                </FormFieldSelect>
                <FormFieldSelect
                  value={contract.approverId ? contract.approverId : "0"}
                  name="approverId"
                  id="approverId"
                  placeholder="All"
                  className="m-0"
                  inputChange={(value) => {
                    if (zodErrors.approverId && value) {
                      setZodErrors((prevState) => ({
                        ...prevState,
                        approverId: undefined,
                      }));
                    }
                    setContract((prevState) => ({
                      ...prevState,
                      approverId: value,
                    }));
                  }}
                  error={zodErrors.approverId !== undefined}
                  assistiveText={
                    zodErrors.approverId !== undefined
                      ? zodErrors.approverId._errors[0]
                      : ""
                  }
                  label="Approver"
                >
                  {[{ id: "0", name: "Select Approver" }, ...approvalUsers]}
                </FormFieldSelect>
              </div>
              <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-3 md:max-xl">
                <FormField
                  type="currency"
                  id="grossCost"
                  name="grossCost"
                  label="Gross Cost"
                  initialValue={
                    contract.grossCost ? contract.grossCost.toString() : "0"
                  }
                  inputChange={(e: any) =>
                    setContract((prevState) => ({
                      ...prevState,
                      grossCost: +e.target.value,
                    }))
                  }
                />
                <FormField
                  type="currency"
                  id="taxes"
                  name="taxes"
                  label="Taxes"
                  initialValue={
                    contract.taxes ? contract.taxes.toString() : "0"
                  }
                  inputChange={(value) =>
                    setContract((prevState) => ({
                      ...prevState,
                      taxes: +value.target.value,
                    }))
                  }
                ></FormField>
              </div>
            </div>
          </Card>
          {/* Form errors */}
          {contractItemErrors.length > 0 && (
            <Card>
              <div className="flex flex-col gap-2">
                {contractItemErrors.map((error) => {
                  return (
                    <Alert
                      size="small"
                      persist={true}
                      content={error.message}
                      variant="error"
                    />
                  );
                })}
              </div>
            </Card>
          )}
          {/* Contract Item Input */}
          <Card className="flex flex-col gap-4">
            <Headline2Variable>Contract Items</Headline2Variable>
            {contract.contractItems.length > 0 && (
              <div className="flex flex-col gap-4">
                {contract.contractItems.map(
                  (contractItem, contractItemIndex) => {
                    return (
                      <div
                        className={cn("flex flex-col gap-4")}
                        key={contractItemIndex}
                      >
                        <div className="flex flex-col gap-2 w-fit">
                          <Subtitle1>Item {contractItemIndex + 1}</Subtitle1>
                          <div className="flex flex-row items-center justify-between gap-8">
                            <FormFieldSelect
                              name="venues"
                              value={
                                contractItem.venueId
                                  ? contractItem.venueId.toString()
                                  : "0"
                              }
                              error={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] &&
                                zodErrors.contractItems[contractItemIndex]
                                  .venueId !== undefined
                              }
                              assistiveText={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] !==
                                  undefined
                                  ? zodErrors.contractItems[contractItemIndex]
                                      .venueId?._errors[0]
                                  : ""
                              }
                              loading={loadingVenues}
                              inputChange={(value) => {
                                setContract((prevState) => ({
                                  ...prevState,
                                  contractItems: prevState.contractItems.map(
                                    (item, index) =>
                                      index === contractItemIndex
                                        ? { ...item, venueId: +value }
                                        : item
                                  ),
                                }));
                              }}
                              label="Venue"
                              placeholder="Select Venue"
                              className="h-full m-0 min-w-[550px] max-w-[550px]"
                              containerClassName="w-fit"
                            >
                              {[
                                ...[{ id: "0", name: "Select a Venue" }],
                                ...(dataVenues !== undefined
                                  ? dataVenues.venuesHierarchyByVendorId
                                  : []),
                              ]}
                            </FormFieldSelect>
                            <div className={`flex flex-col w-full text-black`}>
                              <div className={`px-3 text-xs font-medium`}>
                                Exclusion Dates
                              </div>
                              <MultipleDatePicker
                                multiple
                                plugins={[<DatePanel />]}
                                inputClass={`font-normal pt-2 pb-2 mt-0.5 mb-0.5 pl-3 pr-3 w-full h-full rounded-lg bg-[var(--white)] border hover:border-[var(--secondary-50)] focus:outline-2 focus:outline-[var(--primary-80)]${
                                  zodErrors.contractItems !== undefined &&
                                  zodErrors.contractItems[contractItemIndex] &&
                                  zodErrors.contractItems[contractItemIndex]
                                    .exclusionDates !== undefined
                                    ? " border-[var(--error-30)]"
                                    : "border-[var(--secondary-80)]"
                                }`}
                                containerClassName={"w-full"}
                                value={
                                  contract.contractItems[contractItemIndex]
                                    .exclusionDates
                                }
                                name="exclusionDateFields"
                                //onChange throws Date as DateObject only
                                onChange={(selectedDates: DateObject[]) => {
                                  console.log(selectedDates);
                                  if (Array.isArray(selectedDates)) {
                                    const dates = selectedDates.map((date) => {
                                      return date.format("YYYY-MM-DD");
                                    });
                                    setContract((prevState) => ({
                                      ...prevState,
                                      contractItems:
                                        prevState.contractItems.map(
                                          (item, index) =>
                                            index === contractItemIndex
                                              ? {
                                                  ...item,
                                                  exclusionDates: dates,
                                                }
                                              : item
                                        ),
                                    }));
                                  }
                                }}
                              />
                              {zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] &&
                                zodErrors.contractItems[contractItemIndex]
                                  .exclusionDates !== undefined && (
                                  <div
                                    className={`px-3 text-xxs font-normal text-error-10 `}
                                  >
                                    {zodErrors.contractItems !== undefined &&
                                    zodErrors.contractItems[
                                      contractItemIndex
                                    ] !== undefined
                                      ? zodErrors.contractItems[
                                          contractItemIndex
                                        ].exclusionDates?._errors[0]
                                      : ""}
                                  </div>
                                )}
                            </div>
                            <Button
                              variant="negative"
                              onClick={() => {
                                setContract((prevState) => ({
                                  ...prevState,
                                  contractItems: prevState.contractItems.filter(
                                    (item, index) => contractItemIndex !== index
                                  ),
                                }));
                              }}
                            >
                              <Trash2 />
                            </Button>
                          </div>
                          <div className="flex flex-row items-end justify-between gap-8">
                            <FormFieldViewOnly
                              label="Day"
                              text={
                                dayOfWeek.find(
                                  (day) =>
                                    day.id === contractItem.startDate.getDay()
                                )?.name || "Error"
                              }
                              error={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] &&
                                zodErrors.contractItems[contractItemIndex]
                                  .day !== undefined
                              }
                              assistiveText={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] !==
                                  undefined
                                  ? zodErrors.contractItems[contractItemIndex]
                                      .day?._errors[0]
                                  : ""
                              }
                              className="h-10 max-w-52 w-52"
                              containerClassName="w-fit"
                            />
                            <DatePicker
                              className="w-52"
                              label="Start Date"
                              date={contractItem.startDate}
                              setDate={(date) => {
                                if (date) {
                                  const newStartDate = dayjs(date);
                                  const day = newStartDate.get("day");
                                  setContract((prevState) => ({
                                    ...prevState,
                                    contractItems: prevState.contractItems.map(
                                      (item, index) =>
                                        index === contractItemIndex
                                          ? {
                                              ...item,
                                              startDate: newStartDate.toDate(),
                                              day: day,
                                            }
                                          : item
                                    ),
                                  }));
                                }
                              }}
                              error={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] &&
                                zodErrors.contractItems[contractItemIndex]
                                  .startDate !== undefined
                              }
                              assistiveText={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] !==
                                  undefined
                                  ? zodErrors.contractItems[contractItemIndex]
                                      .startDate?._errors[0]
                                  : ""
                              }
                            />
                            <DatePicker
                              className="w-52"
                              label="End Date"
                              date={contractItem.endDate}
                              setDate={(date) => {
                                if (date) {
                                  const newEndDate = dayjs(date);
                                  setContract((prevState) => ({
                                    ...prevState,
                                    contractItems: prevState.contractItems.map(
                                      (item, index) =>
                                        index === contractItemIndex
                                          ? {
                                              ...item,
                                              endDate: newEndDate.toDate(),
                                            }
                                          : item
                                    ),
                                  }));
                                }
                              }}
                            />
                            <TimePickerDemo
                              includeClock={false}
                              minuteStep={5}
                              id={`startTime-${contractItemIndex.toString()}`}
                              excludeSecond={true}
                              date={contractItem.startTime}
                              setDate={(date) => {
                                if (date) {
                                  setContract((prevState) => ({
                                    ...prevState,
                                    contractItems: prevState.contractItems.map(
                                      (item, index) =>
                                        index === contractItemIndex
                                          ? {
                                              ...item,
                                              startTime: date,
                                            }
                                          : item
                                    ),
                                  }));
                                }
                              }}
                              label={"Start Time"}
                              error={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] &&
                                zodErrors.contractItems[contractItemIndex]
                                  .startTime !== undefined
                              }
                              assistiveText={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] !==
                                  undefined
                                  ? zodErrors.contractItems[contractItemIndex]
                                      .startTime?._errors[0]
                                  : ""
                              }
                            />
                            <TimePickerDemo
                              includeClock={false}
                              minuteStep={5}
                              id={`endTime-${contractItemIndex.toString()}`}
                              excludeSecond={true}
                              label={"End Time"}
                              error={
                                zodErrors.contractItems !== undefined &&
                                zodErrors.contractItems[contractItemIndex] &&
                                zodErrors.contractItems[contractItemIndex]
                                  .startTime !== undefined
                              }
                              date={contractItem.endTime}
                              setDate={(date) => {
                                if (date) {
                                  setContract((prevState) => ({
                                    ...prevState,
                                    contractItems: prevState.contractItems.map(
                                      (item, index) =>
                                        index === contractItemIndex
                                          ? {
                                              ...item,
                                              endTime: date,
                                            }
                                          : item
                                    ),
                                  }));
                                }
                              }}
                            />
                            <div className="flex flex-row gap-2 pb-2 w-fit">
                              <Checkbox
                                className="hover:bg-secondary-opacity-08 flex h-[25px] w-[25px] min-w-[25px] min-h-[25px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
                                checked={contractItem.goesPastMidnight}
                                onCheckedChange={(value: boolean) => {
                                  setContract((prevState) => ({
                                    ...prevState,
                                    contractItems: prevState.contractItems.map(
                                      (item, index) =>
                                        index === contractItemIndex
                                          ? {
                                              ...item,
                                              goesPastMidnight: value,
                                            }
                                          : item
                                    ),
                                  }));
                                }}
                                id={`goesPastMidnight-${contractItemIndex}`}
                                aria-label="goesPastMidnight"
                              />
                              <Label
                                htmlFor={`goesPastMidnight-${contractItemIndex}`}
                                className="hover:cursor-pointer"
                              >
                                <Body1 className=" whitespace-nowrap">
                                  Past Midnight?
                                </Body1>
                              </Label>
                              <HoverCard>
                                <HoverCardTrigger asChild>
                                  <CircleHelp className="w-4 h-4 text-light hover:text-dark hover:cursor-pointer" />
                                </HoverCardTrigger>
                                <HoverCardContent>
                                  <Body1>
                                    This will make the end date for the contract
                                    item the next day. The end time should be
                                    the time the contract item ends the day
                                    after ex. 1:00 AM.
                                  </Body1>
                                </HoverCardContent>
                              </HoverCard>
                            </div>
                          </div>
                        </div>
                        <Divider />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            <div className="flex flex-row gap-4">
              <Button
                variant="primary"
                className="w-fit"
                onClick={() => {
                  setContract((prevState) => {
                    const curContractItems = prevState.contractItems;
                    curContractItems.push(initialContractItem);
                    return {
                      ...prevState,
                      contractItems: curContractItems,
                    };
                  });
                }}
              >
                New Contract Item
              </Button>
            </div>
          </Card>
        </div>

        <Dialog
          open={openConfimrModal}
          onOpenChange={setOpenConfirmModal}
        >
          <DialogContent className="max-w-[600px]">
            <DialogHeader>
              <DialogTitle>Create Contract</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              <div className="flex flex-col gap-4">
                <Body1>
                  This note should reflect the changes you made to the contract.
                </Body1>
                <TextArea
                  className="mb-4"
                  name="notes"
                  label="Notes"
                  inputChange={(event) => {
                    setContractNote(event.target.value);
                  }}
                  value={contractNote}
                  rows={8}
                  cols={50}
                ></TextArea>
              </div>
            </DialogDescription>
            <DialogClose asChild>
              <div className="flex flex-row justify-between gap-4">
                <Button
                  variant="primary"
                  onClick={() => onSubmit()}
                  className="w-full"
                >
                  Confirm
                </Button>
                <Button
                  className="w-full"
                  variant="negative"
                >
                  Close
                </Button>
              </div>
            </DialogClose>
          </DialogContent>
        </Dialog>
      </div>
    </main>
  );
};

export default CreateContract;
