import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
  fromPromise,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import axios from "axios";
import { getJwtCookie, removeJwtCookie, setJwtCookie } from "../app/authSlice";
import { baseApiUrl } from "../utils/baseUrl";
import { useLocation } from "react-router-dom";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

const httpLink = new HttpLink({
  uri:
    process.env.REACT_APP_ENV === "production"
      ? "https://admin.jamsports.com/api/graphql"
      : process.env.REACT_APP_ENV === "preproduction"
      ? "https://ppadmin.jamsports.com/api/graphql"
      : process.env.REACT_APP_ENV === "staging"
      ? "https://admin.jamitall.com/api/graphql"
      : "http://localhost:3001/graphql",
  credentials: "same-origin",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url:
      process.env.REACT_APP_ENV === "production"
        ? "wss://admin.jamsports.com/api/graphql"
        : process.env.REACT_APP_ENV === "preproduction"
        ? "wss://ppadmin.jamsports.com/api/graphql"
        : process.env.REACT_APP_ENV === "staging"
        ? "wss://admin.jamitall.com/api/graphql"
        : "ws://localhost:3001/graphql",
  })
);
// Error handling link
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        // Check for UNAUTHENTICATED errors
        if (error.extensions?.code === "UNAUTHENTICATED") {
          const rememberMe = localStorage.getItem("rememberMe");
          const location = window.location.pathname;
          console.log(location);
          return fromPromise(
            axios
              .post(
                `${baseApiUrl}/auth/refresh`,
                {},
                {
                  withCredentials: true,
                  params: { rememberMe: rememberMe },
                }
              )
              .then((response) => {
                const newAccessToken = response.data.token;
                if (newAccessToken) {
                  // Save the new token
                  setJwtCookie(newAccessToken);

                  // Update the operation's headers
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      authorization: `Bearer ${newAccessToken}`,
                    },
                  }));
                } else {
                  removeJwtCookie();
                  window.location.href = `/login?sessionExpired=true${
                    location !== "/"
                      ? `&callbackUrl=${encodeURIComponent(location)}`
                      : ""
                  }`;
                }
              })
              .catch((error) => {
                removeJwtCookie();
                if (error.response.status === 403) {
                  window.location.href = `/login${
                    location !== "/"
                      ? `&callbackUrl=${encodeURIComponent(location)}`
                      : ""
                  }`;
                } else {
                  window.location.href = `/login?sessionExpired=true${
                    location !== "/"
                      ? `&callbackUrl=${encodeURIComponent(location)}`
                      : ""
                  }`;
                }
              })
          ).flatMap(() => forward(operation)); // Retry the original operation
        }
      }
    }

    if (networkError) {
      process.env.REACT_APP_ENV === "development" &&
        console.log(`[Network Error]: ${networkError}`);
      return;
    }
  }
);

// Auth link to add the access token to each request
const authLink = new ApolloLink((operation, forward) => {
  const token = getJwtCookie();

  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }));

  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

// Apollo Client setup
export const client = new ApolloClient({
  link: from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache(),
});
