import { gql } from "@apollo/client";

const GET_VENUE_OVERVIEW = gql`
  query VenueOverview($venueOverviewFilters: VenueOverviewFilters) {
    venueOverview(venueOverviewFilters: $venueOverviewFilters) {
      overview
      name
      id
      parentId
    }
  }
`;

const GET_VENUES_HIERARCHY = gql`
  query VenuesHierarchy($ids: [Int!]) {
    venuesHierarchy(ids: $ids) {
      id
      name
      parentId
      typeId
      sizeId
    }
  }
`;

const GET_VENUES_BY_VENDORID = gql`
  query VenuesHierarchyByVendorId($vendorId: Int!) {
    venuesHierarchyByVendorId(vendorId: $vendorId) {
      id
      name
      relationalVenues {
        id
        name
      }
    }
  }
`;

gql`
  query VenuesByVendorId($vendorId: Int!) {
    venuesByVendorId(vendorId: $vendorId) {
      id
      name
    }
  }
`;

gql`
  query Venues($venueFilters: VenueFilters, $page: Int, $pageSize: Int) {
    venues(venueFilters: $venueFilters, page: $page, pageSize: $pageSize) {
      venues {
        id
        name
      }
    }
  }
`;

gql`
  query VenuesManagement(
    $venueFilters: VenueFilters
    $page: Int
    $pageSize: Int
  ) {
    venues(venueFilters: $venueFilters, page: $page, pageSize: $pageSize) {
      venues {
        id
        name
        createdAt
        updatedAt
        parentId
        parentVenue {
          id
          name
        }
        venueType {
          id
          name
        }
        venueSize {
          id
          name
        }
        venueDetail {
          address
          zipCode
          region {
            id
            name
          }
          vendor {
            id
            name
          }
        }
      }
      count
    }
  }
`;
gql`
  query VenuesFacilityWithFilter(
    $venueFilters: VenueFilters
    $page: Int
    $pageSize: Int
  ) {
    venuesFacility(
      venueFilters: $venueFilters
      page: $page
      pageSize: $pageSize
    ) {
      id
      name
      createdAt
      updatedAt
      parentVenue {
        id
        name
      }
      venueType {
        id
        name
      }
      venueSize {
        id
        name
      }
      venueDetail {
        address
        zipCode
        region {
          id
          name
        }
        vendor {
          id
          name
        }
      }
    }
  }
`;

const GET_VENUE_BY_ID = gql`
  query VenueById($id: Int!) {
    venueById(id: $id) {
      id
      name
      startBuffer
      endBuffer
      parentId
      venueSetupId
      sizeId
      typeId
      islights
      venueDetailId
      venueDetail {
        address
        city
        country {
          id
          name
        }
        countryId
        facilityDescription
        facilityImage
        id
        intersection
        latitude
        locationLink
        longitude
        region {
          id
          name
        }
        regionId
        state {
          id
          name
        }
        stateId
        vendor {
          id
          name
        }
        vendorId
        zipCode
      }
      parentVenue {
        id
        name
      }
      facilityDetails {
        id
        name
        description
        facilityDetailTypeId
      }
      facilitySetups {
        id
        name
        description
        imageUrl
      }
    }
  }
`;

const CREATE_VENUE = gql`
  mutation VenueCreate($venueArgs: CreateVenueInput!) {
    venueCreate(venueArgs: $venueArgs) {
      code
      success
      message
      venue {
        id
        name
      }
    }
  }
`;

const UPDATE_VENUE = gql`
  mutation VenueUpdate($venueArgs: UpdateVenueInput!) {
    venueUpdate(venueArgs: $venueArgs) {
      code
      success
      message
      venue {
        id
        name
      }
    }
  }
`;
const GET_VENUES_TREE = gql`
  query VenuesTree($regionId: Int, $sizeId: Int) {
    venuesTree(regionId: $regionId, sizeId: $sizeId) {
      id
      name
      venueName
    }
  }
`;

gql`
  query VenuesExceptDescendants($venuesExceptDescendantsId: Int!) {
    venuesExceptDescendants(id: $venuesExceptDescendantsId) {
      id
      name
    }
  }
`;

gql`
  query VenueOverviewDay(
    $venueOverviewDayFilterInput: VenueOverviewDayFilterInput!
  ) {
    venueOverviewDay(
      venueOverviewDayFilterInput: $venueOverviewDayFilterInput
    ) {
      success
      message
      contractDetails {
        venue {
          id
          name
          parentId
          relationalVenues {
            id
            name
          }
        }
        contract {
          id
          name
        }
        contractItems {
          id
          startDateTimeLocal
          endDateTimeLocal
          isExcluded
        }
      }
    }
  }
`;
gql`
  query VenueOverviewFacilityDescendants(
    $venueOverviewFacilityDescendantsInput: VenueOverviewDayFilterInput!
  ) {
    venueOverviewFacilityDescendants(
      venueOverviewFacilityDescendantsInput: $venueOverviewFacilityDescendantsInput
    ) {
      success
      message
      venues {
        id
        name
        parentId
        contractItems {
          id
          startDateTimeLocal
          endDateTimeLocal
          isExcluded
          venueId
          contract {
            id
            name
          }
          day
        }
        relationalVenues {
          id
          name
        }
      }
    }
  }
`;

gql`
  query VenueRelational($venueId: Int!) {
    venueRelational(venueId: $venueId) {
      id
      name
      parentId
    }
  }
`;

gql`
  mutation SubVenueCreate($subVenueCreateInput: SubVenueInput!) {
    subVenueCreate(subVenueCreateInput: $subVenueCreateInput) {
      success
      code
      message
      venue {
        id
        name
        startBuffer
        endBuffer
        parentId
        venueSetupId
        sizeId
        typeId
        islights
        venueDetailId
      }
    }
  }
`;
gql`
  mutation SubVenueUpdate($subVenueUpdateInput: UpdateSubVenueInput!) {
    subVenueUpdate(subVenueUpdateInput: $subVenueUpdateInput) {
      success
      code
      message
      venue {
        id
        name
        startBuffer
        endBuffer
        parentId
        venueSetupId
        sizeId
        typeId
        islights
        venueDetailId
      }
    }
  }
`;

gql`
  query VenueSetupsByVenueDetailId($venueDetailId: Int!) {
    venueSetupsByVenueDetailId(venueDetailId: $venueDetailId) {
      id
      name
    }
  }
`;
export {
  GET_VENUE_OVERVIEW,
  GET_VENUES_HIERARCHY,
  GET_VENUES_BY_VENDORID,
  GET_VENUE_BY_ID,
  CREATE_VENUE,
  UPDATE_VENUE,
  GET_VENUES_TREE,
};
