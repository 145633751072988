import React, { useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { PaginationType } from "../../types/types";
import FormField from "../UI/FormField/FormField";
import dayjs from "dayjs";
import { DataTable } from "../UI/Table/DataTable";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import {
  useVenuesManagementQuery,
  VenuesManagementQuery,
} from "../../generated/graphql";
import { ColumnDef } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UI/shadcn/dropdown";
import { MoreVerticalIcon } from "lucide-react";

const Venues = () => {
  //Pagination Page Size
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 50,
    pageCount: undefined,
  });
  const [venues, setVenues] = useState<
    VenuesManagementQuery["venues"]["venues"]
  >([]);

  const [search, setSearch] = useState<string | null>(null);
  const { loading: loadingVenues } = useVenuesManagementQuery({
    variables: {
      venueFilters: {
        search: search,
      },
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    onCompleted: (data) => {
      console.log("refetch");
      setVenues(data.venues.venues);
      console.log(pagination);
      console.log(data.venues.count);
      setPagination((prevState: PaginationType) => {
        return {
          ...prevState,
          pageCount: data.venues.count,
        };
      });
    },
  });

  const venueColumns: ColumnDef<
    VenuesManagementQuery["venues"]["venues"][0]
  >[] = [
    {
      header: "Id",
      accessorKey: "id",
    },
    {
      header: "Parent Venue Id",
      accessorFn: (d) => (d.parentVenue ? d.parentVenue.id : "N/A"),
    },
    {
      header: "Venue",
      id: "name",
      cell: ({ row }) => {
        return (
          <a
            className="underline"
            href={`${
              !row.original.parentVenue?.id
                ? `/ops/venue-management/${row.original.id}`
                : `/ops/sub-venue-management/${row.original.parentVenue.id}/${row.original.id}`
            }`}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      header: "Region",
      accessorFn: (d) => d.venueDetail.region.name,
    },
    {
      header: "Type",
      accessorFn: (d) => d.venueType.name,
    },
    {
      header: "Vendor",
      accessorFn: (d) => d.venueDetail?.vendor.name,
    },
    {
      header: "Address",
      accessorFn: (d) => d.venueDetail.address ?? "Missing Address",
    },
    {
      header: "Parent",
      accessorFn: (d) => (d.parentVenue ? d.parentVenue.name : "Root Venue"),
    },
    {
      header: "Created",
      accessorFn: (d) => dayjs(d.createdAt).format("YYYY-MM-DD"),
    },
    {
      header: "Last Edit",
      accessorFn: (d) => dayjs(d.updatedAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      header: "Actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a
                  href={
                    !row.original.parentId
                      ? `/ops/venue-management/${row.original.id}`
                      : `/ops/sub-venue-management/${row.original.parentId}/${row.original.id}`
                  }
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Edit
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/sub-venue-management/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Create Sub-Venue
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return (
    <main className="pb-10">
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Venues</Headline1Variable>
        <div className="flex flex-row h-full gap-4">
          <div className="h-[90%] flex gap-2">
            <FormField
              className="w-96"
              initialValue={search || ""}
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(e.target.value);
                setPagination((prevState) => ({
                  ...prevState,
                  page: 0,
                }));
              }}
              name="startBuffer"
              placeholder="Search"
            ></FormField>
          </div>

          <Button
            variant="primary"
            onClick={() => navigate(`/ops/venue-management`)}
          >
            <AddIcon
              fontSize="large"
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
            <span> New Venue</span>
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <DataTable
          data={venues}
          columns={venueColumns}
          loading={loadingVenues}
        />
        <PaginationDataTable
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    </main>
  );
};

export default Venues;
