import React, { useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { PaginationType } from "../../types/types";
import moment from "moment";
import classes from "./Vendors.module.css";
import { LoadingMaterialUI } from "../UI";
import ActionsCell from "../UI/ActionCell";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import PaginationDataTable from "../UI/Pagination/PaginationDataTable";
import { DataTable } from "../UI/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  ListAllOperations,
  VendorsQuery,
  useVendorDeleteMutation,
  useVendorsCountQuery,
  useVendorsQuery,
} from "../../../src/generated/graphql";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../UI/shadcn/dialog";
import Body1 from "../UI/Text/Body/Body1";

const options = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Delete" },
];

const Vendors = () => {
  const publicUrl = process.env.PUBLIC_URL;
  //Pagination Page Size
  const pageSize = 10;

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: pageSize,
    pageCount: undefined,
  });
  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    vendorId?: number;
  }>({
    open: false,
  });
  const [vendorList, setVendorList] = React.useState<VendorsQuery["vendors"]>(
    []
  );

  const { loading: isLoading } = useVendorsQuery({
    variables: {
      vendorFilters: {
        vendorTypeId: null,
      },
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    onCompleted: (data) => {
      console.log("here");
      if (data.vendors) setVendorList(data.vendors);
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useVendorsCountQuery({
    variables: {
      vendorFilters: {
        vendorTypeId: null,
      },
    },
    onCompleted: (data) => {
      if (data.vendorsCount) {
        setPagination((prevState) => {
          return {
            ...prevState,
            pageCount: data.vendorsCount!,
          };
        });
      }
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [deleteVendor] = useVendorDeleteMutation({
    refetchQueries: [
      ListAllOperations.Query.Vendors,
      ListAllOperations.Query.VendorsCount,
    ],
    onCompleted: (data) => {
      if (data.vendorDelete.success) {
        dispatch(displayAlertSuccess(data.vendorDelete.message));
      } else {
        dispatch(displayAlertError("Failed to delete vendor"));
      }
      setDeleteModal({
        open: false,
      });
    },
  });

  const handleCreateVendor = () => {
    navigate(`${publicUrl}/ops/vendor`);
  };

  const handleDeleteVendor = () => {
    if (deleteModal.vendorId)
      deleteVendor({
        variables: {
          vendorDeleteId: deleteModal.vendorId.toString(),
        },
      });
  };

  const handleSelectedOption = (
    row: VendorsQuery["vendors"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`${publicUrl}/ops/vendor/${row.id}`, {
        state: { vendorId: row.id },
      });
    }
    if (idx === 2) {
      setDeleteModal({
        open: true,
        vendorId: +row.id!,
      });
    }
  };

  const columns: ColumnDef<VendorsQuery["vendors"][0]>[] = [
    {
      header: "Vendor",
      accessorKey: "id",
    },
    {
      header: "Vendor",
      cell: ({ row }) => {
        return (
          <a
            className="underline"
            href={`/ops/vendor/${row.original.id}`}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      header: "Type",
      cell: ({ row }) => {
        return (
          <div className="truncate text-nowrap">
            {row.original.vendorType?.name}
          </div>
        );
      },
    },
    {
      header: "Contact",
      cell: ({ row }) => {
        return <div className="truncate">{row.original.primaryContact}</div>;
      },
    },
    {
      header: "Phone",
      cell: ({ row }) => {
        return <div>{row.original.primaryPhone}</div>;
      },
    },
    {
      header: "After Hours Phone",
      accessorKey: "afterHoursPhone",
    },
    {
      header: "Created",
      cell: ({ row }) => {
        return (
          <div>
            {moment(row.original.createdAt).local().format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      header: "Last Edit",
      cell: ({ row }) => {
        return (
          <div>
            {moment(row.original.updatedAt).local().format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      header: " ",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row}
            options={options}
            handleSelectedOption={(_, i) =>
              handleSelectedOption(row.original, i)
            }
          />
        );
      },
    },
  ];

  if (isLoading) {
    return <LoadingMaterialUI />;
  }

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Vendors</Headline1Variable>
        <div className="flex flex-row items-end h-full gap-4">
          <Button
            variant="primary"
            onClick={handleCreateVendor}
          >
            <AddIcon
              fontSize="large"
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
            <span> New Vendor</span>
          </Button>
        </div>
      </div>
      {vendorList.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            data={vendorList}
            columns={columns}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
      {!(vendorList.length > 0) && (
        <div className={classes.table__contracts}>No Data Found</div>
      )}
      <Dialog
        open={deleteModal.open}
        onOpenChange={(open) =>
          setDeleteModal({
            open: false,
          })
        }
      >
        <DialogContent className="max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Delete Vendor</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col gap-4">
              <Body1>Are you sure you want to delete this vendor?</Body1>
            </div>
          </DialogDescription>
          <DialogClose asChild>
            <div className="flex flex-row justify-between gap-4">
              <Button
                className="w-full"
                variant="negative"
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => handleDeleteVendor()}
                className="w-full"
              >
                Confirm
              </Button>
            </div>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </main>
  );
};

export default Vendors;
