import { gql } from "@apollo/client";

gql`
  query CapacityGroupsPaginated(
    $capacityGroupFilter: CapacityGroupFiltersInput
    $page: Int
    $pageSize: Int
  ) {
    capacityGroupsPaginated(
      capacityGroupFilter: $capacityGroupFilter
      page: $page
      pageSize: $pageSize
    ) {
      capacityGroups {
        id
        name
        capacityGroupSessions {
          session {
            region {
              name
            }
            dayOfWeek
            registrationBatch {
              name
            }
          }
        }
      }
      count
    }
  }
`;

gql`
  query CapacityGroup($id: Int!) {
    capacityGroup(id: $id) {
      id
      name
      capacityGroupSessions {
        id
        session {
          id

          dayOfWeekType {
            name
          }
          league {
            name
          }
          region {
            id
          }
          endDate
          startDate
          dayOfWeek
        }
      }
      capacityGroupVenues {
        id
        numberOfGameSlot
        venue {
          id
          name
          venueDetail {
            region {
              id
              name
            }
          }
        }
      }
    }
  }
`;

gql`
  mutation MergeCapacityGroup(
    $name: String!
    $rootCapacityGroupId: Int!
    $branchCapacityGroupIds: [Int!]!
  ) {
    mergeCapacityGroup(
      name: $name
      rootCapacityGroupId: $rootCapacityGroupId
      branchCapacityGroupIds: $branchCapacityGroupIds
    ) {
      success
      message
    }
  }
`;

gql`
  mutation UpdateCapacityGroup(
    $id: Int!
    $name: String!
    $capacityGroupVenues: [UpdateCapacityGroupVenueInput!]!
  ) {
    updateCapacityGroup(
      id: $id
      name: $name
      capacityGroupVenues: $capacityGroupVenues
    ) {
      success
      message
      capacityGroup {
        id
        name
        capacityGroupSessions {
          session {
            id
            dayOfWeek
            league {
              name
            }
          }
        }
        capacityGroupVenues {
          venue {
            id
            name
          }
        }
      }
    }
  }
`;

gql`
  mutation UpdateCapacityGroupVenue(
    $updateCapacityGroupVenueInput: UpdateCapacityGroupVenueInput!
  ) {
    updateCapacityGroupVenue(
      updateCapacityGroupVenueInput: $updateCapacityGroupVenueInput
    ) {
      success
      message
      capacityGroupVenue {
        id
        numberOfGameSlot
        venue {
          id
          name
        }
      }
    }
  }
`;

const GET_CAPACITY_INFO = gql`
  query VenueWiseCapacity($venueWiseCapacityArgs: VenueWiseCapacityArgs) {
    venueWiseCapacity(venueWiseCapacityArgs: $venueWiseCapacityArgs) {
      parentVenue
      venueInPlay
      venueSize
      capacity
    }
  }
`;

const GET_CAPACITY_GROUP_OVERVIEW = gql`
  query CapacityGroupOverview(
    $capacityGroupOverviewFilters: CapacityGroupOverviewFilters
  ) {
    capacityGroupOverview(
      capacityGroupOverviewFilters: $capacityGroupOverviewFilters
    ) {
      id
      name
      parentId
      overview
    }
  }
`;

export { GET_CAPACITY_INFO, GET_CAPACITY_GROUP_OVERVIEW };
