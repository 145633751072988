import React, { useEffect } from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import { Month, PaginationType } from "../../../types/types";
import { getPayrollBatches } from "../../../app/financeSlice";
import ActionsCell from "../../UI/ActionCell";
import { LoadingMaterialUI } from "../../UI";
import moment from "moment";
import { IconButton } from "@mui/material";
import Link1 from "../../UI/Text/Link/Link1";
import { DataTable } from "../../UI/Table/DataTable";
import PaginationDataTable from "../../UI/Pagination/PaginationDataTable";
import { ColumnDef } from "@tanstack/react-table";
import { PayrollBatchesQuery } from "@/src/generated/graphql";

const PayrollBatches = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { isLoading, payrollBatches, payrollBatchesCount }: any = useSelector(
    (state: RootState) => state.finance
  );

  //Pagination Page Size
  const pageSize = 10;
  const [pagination, setPagination] = React.useState<PaginationType>({
    page: 0,
    pageCount: undefined,
    pageSize: pageSize,
  });

  const redirectCreatePayrollBatch = () => {
    navigate(`/finance/createPayrollBatch/`);
  };

  const handleSelectedOption = (
    row: PayrollBatchesQuery["payrollBatches"]["payrollBatches"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/finance/createPayrollBatch/${row.id}`);
    }
    if (idx === 2) {
      navigate(`/finance/payrollBatch/${row.id}`);
    }
  };

  useEffect(() => {
    setPagination((prevState) => {
      return {
        ...prevState,
        pageCount: payrollBatchesCount,
      };
    });
  }, [payrollBatchesCount]);

  useEffect(() => {
    dispatch(
      getPayrollBatches({
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (pagination) {
      dispatch(
        getPayrollBatches({
          page: pagination?.page,
          pageSize: pagination?.pageSize,
        })
      );
    }
  }, [pagination]);

  const columns: ColumnDef<
    PayrollBatchesQuery["payrollBatches"]["payrollBatches"][0]
  >[] = [
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <IconButton
            onClick={() => {
              navigate(`/finance/payrollBatch/${row.original.id}`);
            }}
          >
            <Link1>{row.original.name}</Link1>
          </IconButton>
        );
      },
    },
    {
      header: "Year",
      id: "year",
      accessorFn: (d) => d.year,
    },
    {
      header: "Month",
      id: "month",
      cell: ({ row }) => {
        return <div>{Month[row.original.month]}</div>;
      },
    },
    {
      header: "Shift Cutoff Date",
      id: "shiftCutoffDate",
      accessorFn: (d) => d.shiftCutoffDate,
    },
    {
      header: "Status",
      id: "status",
      accessorFn: (d) => d.payrollBatchStatus.name,
    },
    {
      header: "Number of Shifts",
      id: "numOfShifts",
      accessorFn: (d) => d.numOfShifts,
    },
    {
      header: "Pay Date",
      id: "payDate",
      accessorFn: (d) => d.payDate,
    },
    {
      header: "Created At",
      id: "created At",
      cell: ({ row }) => {
        return (
          <div>
            {moment(row.original.createdAt).local().format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      header: " ",
      cell: ({ row }) => {
        let options = [];
        if (row.original.payrollBatchStatus.name === "Paid") {
          options.push({ id: 2, text: "View" });
        } else {
          options.push({ id: 1, text: "Edit" });
        }

        return (
          <ActionsCell
            row={row.original}
            options={options}
            handleSelectedOption={(_, i) =>
              handleSelectedOption(row.original, i)
            }
          />
        );
      },
    },
  ];

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Payroll Batches</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            onClick={redirectCreatePayrollBatch}
          >
            <AddIcon />
            New Payroll Batch
          </Button>
        </div>
      </div>
      {isLoading && <LoadingMaterialUI />}
      {payrollBatches.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            columns={columns}
            data={payrollBatches}
          />
          <PaginationDataTable
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      )}
      {!(payrollBatches.length > 0) && <div>No Data Found</div>}
    </main>
  );
};

export default PayrollBatches;
